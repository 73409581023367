import React from "react";
import { Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import { CheckBox } from "@mui/icons-material";
// import FormControlLabel from '@mui/material/FormControlLabel';
const DialogCheckboxField = ({
  variant = null,
  onChange,
  label = "",
  value,
  placeholder = "",
  sx = {},
  ...rest
}) => {

  return (
    <Grid2 container rowSpacing={2} sx={{ width: "100%" ,display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Grid2 md={2} xs={12}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 md={10} xs={12}>
      <FormControlLabel
      variant={variant}
          value={value}
          control={<Checkbox />}
          label={null}
          onChange={onChange}
          placeholder={placeholder}
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              bgcolor: "white",
            },
            "& .MuiSvgIcon-root":{
              bgcolor: "white",
            },
            ...sx,
          }}
          {...rest}
          checked={value===true?true:false}
        />
      </Grid2>
    </Grid2>
  );
};

export default DialogCheckboxField;