import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import format from "../../utils/ISOToReadable";
import { useState } from "react";
import useRequest from "../../hooks/useRequest";
import { STATUS } from "../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../features/form";
import { Box } from "@mui/system";
import { Stack, TextField } from "@mui/material";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useControls from "../../hooks/useControls";

const ViewStatus = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewStatusLang = useSelector((state) => state.lang.value.viewStatus);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
      valueShape: {
        start: "",
        end: "",
      },
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: globalLang.statusName[lang],
    },
    {
      field: "date",
      headerName: globalLang.createdAt[lang],
      customContent: (params) => format(params.created_at),
    },
  ];

  //----store----
  const statusStore = useSelector((state) => state.status.value);

  const dispatch = useDispatch();

  //----request hooks----
  const [statusGetRequest, statusGetResponse] = useRequest({
    path: STATUS,
    method: "get",
  });

  //----state----
  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  //----effects----
  useEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item?.map(([key, value]) => urlParams.append(key, value))
    );
    delete requestParams.currentPage
    statusGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "status/set", payload: res.data });
      },
    });
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      ...old,
      filters: filters.map(({ query }) => query),
    }));
  };

  const [statusDeleteRequest, statusDeleteResponse] = useRequest({
    path: STATUS,
    method: "delete",
    successMessage: "تم حذف الحالة بنجاح",
  });

  const deleteStatus = (e, row) => {
    statusDeleteRequest({
      id: row.id,
      onSuccess: () => {
        dispatch({ type: "status/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteStatus, deleteStatusConfirmDialog] = useConfirmMessage({
    onConfirm: deleteStatus,
    text:globalLang.makesureofdelete[lang],
  });

  const isPermitted = useIsPermitted();

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.customers[lang], viewStatusLang.viewStatus[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={statusStore.results}
        isPending={statusGetResponse.isPending}
        total={statusStore.count}
        onDelete={isPermitted(
          (e, row) => handleDeleteStatus(e, row),
          ["delete_aqarevent"]
        )}
        onFilter={handleFilter}
        onAmountChange={handleChangeAmount}
        onPaginate={handlePaginate}
        filters={filters}
      />
      {deleteStatusConfirmDialog}
      {statusDeleteResponse.successAlert}
      {statusDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default ViewStatus;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [{ controls ,invalid,required}, { setControl, resetControls,setInvalid, validate}] = useControls(
    [
      {
        control: "startPoint",
        value:value.start,
        isRequired: true,
      },
      {
        control: "endPoint",
        value: value.end,
        isRequired: true,
      },
      // {
      //   control:"created_at",
      //   value:"",
      //   isRequired:false
        
      // }
    ],
    [value]
  );
  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!controls.startPoint && !controls.endPoint) return;
    validate().then((output) => {
      if (!output.isOk) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(controls.startPoint))}-${formatDate(String(controls.endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(controls.startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(controls.endPoint))}`,
      value: {
        start: controls.startPoint,
        end: controls.endPoint,
      },
    })
      })
  }, [controls.startPoint, controls.endPoint]);

  const handleChangeStartPoint = (e) => {
    setControl("startPoint",e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    if(controls.startPoint &&controls.startPoint>e.target.value){
      setInvalid((prevState) => ({
        ...prevState,
        endPoint: "End date should be greater than start date.",
      }));
    }else{
      setInvalid((prevState) => ({ ...prevState, endPoint: "" }));
      setControl("endPoint",e.target.value);

    }
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={controls?.startPoint}
        onChange={handleChangeStartPoint}
        required={required.includes("startPoint")}
        error={Boolean(invalid.startPoint)}
        helperText={invalid.startPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={controls?.endPoint}
        onChange={handleChangeEndPoint}
        required={required.includes("endPoint")}
        error={Boolean(invalid.endPoint)}
        helperText={invalid.endPoint}
      />
    </Stack>
  );
};
