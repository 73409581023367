import { configureStore } from "@reduxjs/toolkit";
import userInfoSlice from "./store/userInfoSlice";
import EmployeesSlice from "./store/pages/EmployeesSlice";
import PostsSlice from "./store/pages/PostsSlice";
import ProjectsSlice from "./store/pages/ProjectsSlice";
import ChannelsSlice from "./store/pages/ChannelsSlice";
import NotificationSlice from "./store/pages/NotificationSlice";
import ClientsSlice from "./store/pages/ClientsSlice";
import StatusSlice from "./store/pages/StatusSlice";
import ClientHistorySlice from "./store/pages/ClientHistorySlice";
import ClientCommentsSlice from "./store/pages/ClientCommentsSlice";
import DeletedClientsSlice from "./store/pages/DelectedClientsSlice";
import OverviewSlice from "./store/pages/OverviewSlice";
import ImportRegistrySlice from "./store/pages/ImportRegistrySlice";
import ExportRegistrySlice from "./store/pages/ExportRegistrySlice";
import JobsSlice from "./store/pages/JobsSlice";
import allPermissionsSlice from "./store/allPermissionsSlice";
import UnitsSlice from "./store/pages/UnitsSlice";
import ExportClientsSlice from "./store/pages/ExportClientsSlice";
import ImportClientsSlice from "./store/pages/ImportClientsSlice";
import LangSlice from "./store/LangSlice.js";
import FekhidmaticUsersSlice from "./store/pages/Fekhidmaticusers";
import FekhidmaticClientsSlice from "./store/pages/Fekhidmaticclients";
import FekhidmaticUnitsSlice from "./store/pages/Fekhidmticunits";
import AddpathofpageSlice from "./store/pages/AddPathOfPage";
import addimageusnitslice from "./store/pages/Addclientimage";
import FekhidmaticrequestsSlice from "./store/pages/FekhidmaticREquest";
import    NotificationbackSlice  from "./store/pages/Notificationfrombackground"
import  CountrySlice  from "./store/pages/Country";
import  ContractSlice  from "./store/pages/Contract";
import  UnreadSlice  from "./store/pages/Unreadnotification";
import  JobsTreeSlice  from "./store/pages/JobTrees";
export default configureStore({
  reducer: {
    lang: LangSlice,
    allPermissions: allPermissionsSlice,
    unread:UnreadSlice,
    userInfo: userInfoSlice,
    posts: PostsSlice,
    projects: ProjectsSlice,
    employees: EmployeesSlice,
    channels: ChannelsSlice,
    clients: ClientsSlice,
    deletedClients: DeletedClientsSlice,
    status: StatusSlice,
    clientHistory: ClientHistorySlice,
    clientComments: ClientCommentsSlice,
    overview: OverviewSlice,
    importRegistry: ImportRegistrySlice,
    exportRegistry: ExportRegistrySlice,
    jobs: JobsSlice,
    units: UnitsSlice,
    exportClients: ExportClientsSlice,
    importClients: ImportClientsSlice,
    notifications: NotificationSlice,
    fekhidmaticUsers: FekhidmaticUsersSlice,
    fekhidmaticClients: FekhidmaticClientsSlice,
    fekhidmaticUnits: FekhidmaticUnitsSlice,
    Addpathofpage: AddpathofpageSlice,
    addimageusnit: addimageusnitslice,
    Fekhidmaticrequests: FekhidmaticrequestsSlice,
    contractslice:ContractSlice,
    // notificationback:NotificationBackSlice,
    countryslice:CountrySlice,
    notificationfromback:NotificationbackSlice,
    jobstree:JobsTreeSlice
  },
});
