import { createSlice } from "@reduxjs/toolkit";

export const NotificationBack = createSlice({
  name: "notificationfromback",
  initialState: {
    value: [],
  },
  reducers: {
    set: (state, action) => {
      state.value=[...action.payload];
    },
    reset: (state) => {
      state.value = {
        title:"",
        body:""
      };
    },
    // deleteItem: (state, action) => {
    //   state.value.results = state.value.results.filter(
    //     (item) => item.id !== action.payload.id
    //   );
    //   state.value.count = state.value.count - 1;
    // },
    addItem: (state, action) => {
      state.value = [...state.value,...action.payload];
    },
    // putItem: (state, action) => {
    //   const index = state.value.results.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   state.value.results.splice(index, 1, action.payload.item);
    // },
    // patchItem: (state, action) => {
    //   const index = state.value.results.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   state.value.results.splice(index, 1, {
    //     ...state.value.results[index],
    //     ...action.payload.item,
    //   });
    // },
  },
});

export default NotificationBack.reducer;
