import {
  Box,
 
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import Breadcrumbs from "../../components/Breadcrumbs";
import DownloadIcon from "@mui/icons-material/Download";
import { InputField } from "../../features/form";
import { Stack } from "@mui/system";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef } from "react";
import { useState } from "react";
import useRequest from "../../hooks/useRequest";
import { CONTRACT, DELETECONTRACT, FILES_HISTORY } from "../../data/APIs";
import { useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import DataGrid from "../../components/DataGrid";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";
import format from "../../utils/ISOToReadable";
import { saveAs } from "file-saver";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page } from "react-pdf";
import axios from "axios";
import AWS from "aws-sdk";
import * as XLSX from "xlsx";
import FileViewer from "react-file-viewer";
import { DocumentViewer } from "react-documents";
// import { Document, Page } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
const ViewContract = () => {
  const type = "pdf";
  const file = "";
  const containerRef = useRef(null);

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // Change lang
  let [urlofdocument, seturlofdocument] = useState([]);
  const [page, setFile] = useState("");
  // let [urlofdocument,seturlofdocument]=useState([])
  const lang = useSelector((state) => state.lang.value.lang);
  const exportRegistryLang = useSelector(
    (state) => state.lang.value.exportRegistry
  );

  const handleUploadFile = (event) => {


    let selectfile = event;

    let reader = new FileReader();
    reader.onload = (e) => {
      seturlofdocument(e.target.result);
    };
    reader.readAsArrayBuffer(selectfile);
  };

  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  let contractStore = useSelector((state) => state.contractslice.value);

  const filters = [
    {
      name: globalLang.createdBy[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
    },
  ];
  const MyNoRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";

    if (fileText) {
      return <div>No Renderer Error! ({fileText})</div>;
    }

    return <div>No Renderer Error!</div>;
  };

  useEffect(() => {}, []);
  const [__html, setHTML] = React.useState("");
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  //  async function requestfrombackfile(url){
  //   await axios.get(url,{headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //     "Access-Control-Allow-Origin":"*"
  //  }, responseType: 'arraybuffer' }).then((ele)=>console.log(res))
  //   }

  const columns = [
    {
      field: "created_by",
      headerName: globalLang.createdBy[lang],
      customContent: (params) => {
        return params?.created_by;
      },
    },
    {
      field: "contract",
      headerName: globalLang.file[lang],
      customContent: (params) => {
        setFile(params.contract);
        return (
          <>
            <IconButton
              onClick={async () => {
                const link = document.createElement("a");
                link.href = params.contract;
                link.setAttribute("download", "sheet.xls");
                link.setAttribute("target", "_blank");

                document.body.appendChild(link);
                link.click();
              }}
            >
              <DownloadIcon />
            </IconButton>
           
            {params.contract.split(/[#?]/)[0].split(".").pop().trim() ==
            "pdf" ? (
              <object
                data={params.contract}
                download=""
                type="application/pdf"
              ></object>
            ) :["png" ,"jpeg","jpg"].includes(params.contract.split(/[#?]/)[0].split(".").pop().trim()) ?
            <img src={params.contract} alt="jpg"/>: (
              <DocViewer
                documents={[
                  {
                    uri: params.contract,
                    fileType: `${params.contract
                      .split(/[#?]/)[0]
                      .split(".")
                      .pop()
                      .trim()}`,
                  },
                ]}
                prefetchMethod="GET"
                pluginRenderers={DocViewerRenderers}
                requestHeaders={{ "Access-Control-Allow-Origin": "*" }}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                  csvDelimiter: ";", // "," as default,
                  // pdfZoom: {
                  // defaultZoom: .1, // 1 as default,
                  // zoomJump: 1, // 0.1 as default,
                  // },
                  pdfVerticalScrollByDefault: false,
                  // "Access-Control-Allow-Origin":"https://sadakatcdn.cyparta.com/"
                  // false as default
                }}
                // onClose={this.handleClose}
                style={{width:"365px"}}
              />
            )}

            {/* {console.log(String(params.contract).split(/\./g)[])} */}
            {/* <DocumentViewer
    queryParams="hl=Nl"
    url={params.contract}
    viewerUrl={params.contract}
    viewer={"url"}
    overrideLocalhost="https://react-doc-viewer.firebaseapp.com/">
  </DocumentViewer> */}
            {/* <object src={params.contract}><embed src={params.contract} width="50%" height="300"></embed></object> */}
            {/* <embed
              src={params.contract}
              width="500"
              height="375"
              // type="docs"
></embed>*/}
            {/* <iframe
              src={`https://docs.gooogle.com/gview?url=${params.contract} &embedded=true`}
              width="668"
              height="714"
              frameborder="0"
              marginwidth="0"
              marginheight="0"
              scrolling="no"
              // style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;"
              allowfullscreen
            >
              
            </iframe>  */}
            {/* <FileViewer
        className="file-viewer-style"
        fileType={type}
        filePath={params.contract}
        onError={onError}
      /> */}
            {/* <a href={params.contract}></a> */}
            {/* <img src={params.contract} alt={params.contract}/> */}
            {/* <Document
              file=""
            >
              <Page pageNumber={1} />
            </Document> */}
            {/* <Document file={} /> */}
            {/* <DocViewer pluginRenderers={DocViewerRenderers}  className="my-doc-viewer-style"
           config={{header: {"Access-Control-Allow-Origin":"*"},noRenderer:false}} prefetchMethod="GET" documents={{uri:String(params.contract).replace(/\/$/,""),fileData:params.contract,fileType:"pdf"}}/>  */}
            {/* <div>
          {}
        </div> */}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: globalLang.createdAt[lang],
      customContent: (params) => {
        return format(params?.created_at);
      },
    },
  ];

  const dispatch = useDispatch();

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      ...old,
      filters: filters.map(({ query }) => query),
    }));
  };

  const [contractGetRequest, contractGetResponse] = useRequest({
    path: CONTRACT,
    method: "get",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    // urlParams.append("type", "1");

    Object.values(requestParams).map((item) =>
      item?.map(([key, value]) => urlParams.append(key, value))
    );
    delete requestParams.currentPage
    contractGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "contractslice/set", payload: res.data });
      },
    });
  }, [requestParams]);

  //====start==== delete registry logic ============
  const [deleteContractRequest, deleteContractResponse] = useRequest({
    path: DELETECONTRACT,
    method: "delete",
    successMessage: "تم حذف السجل بنجاح",
  });

  const deleteRegistry = (e, row) => {
    deleteContractRequest({
      id: row.id,
      onSuccess: (res) => {

        dispatch({
          type: "contractslice/deleteItem",
          payload: { id: row.id },
        });
      },
    });
  };

  const [handleDeleteRegistry, deleteRegistryConfirmMessage] =
    useConfirmMessage({
      onConfirm: deleteRegistry,
      text: globalLang.makesureofdelete[lang],
    });

  const isPermitted = useIsPermitted();
  //====end==== delete registry logic ============

  return (
    <Wrapper>
      <Breadcrumbs
        path={[
          globalLang.contract[lang],
          globalLang.viewcontract[lang],
        ]}
      />
      
      <DataGrid
        columns={columns}
        rows={contractStore.results}
        total={contractStore.count}
        isPending={contractGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteRegistry,["delete_contract"])}
        filters={filters}
      />
      {deleteContractResponse.successAlert}
      {deleteContractResponse.failAlert}
      {deleteRegistryConfirmMessage}
    </Wrapper>
  );
};

export default ViewContract;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    let text=/^(?:[A-Za-z\u0600-\u06ff\s]*)$/
    let match=text.test(e.target.value)
    if(match){
    onChange({
      query: ["created_by", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  }
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint) return;
    onChange({
      query: [
        "created_at",
        `${String(startPoint)}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${
        String(startPoint)}`,
      value: {
        start: startPoint,
       
      },
    });
  }, [startPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    if(controls.startPoint &&controls.startPoint>e.target.value){
      setInvalid((prevState) => ({
        ...prevState,
        endPoint: "End date should be greater than start date.",
      }));
    }else{
      setInvalid((prevState) => ({ ...prevState, endPoint: "" }));
      setControl("endPoint",e.target.value);

    }
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        // label={globalLang.date[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
    
    </Stack>
  );
};
