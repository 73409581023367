import React, { useEffect, useRef, useState } from "react";
import Form, { InputField, PasswordField, PhoneField } from "../features/form";
import useControls from "../hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import cypartalogo from "../assets/cypartalogo.png";
import loginAnimation from "../assets/LoginAnimation.json";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
// import Image from "mui-image";
import Compress from "react-image-file-resizer";
import filter from "../utils/ClearNull";
import useRequest from "../hooks/useRequest";
import { REGISTER, USER_INFO } from "../data/APIs";
import Chip from "@mui/material/Chip";
import Lottie from "lottie-react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import logo from "../assets/logo.png";
import cloud from "../assets/Group14.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
// import logo from "../assets/cardlogo.png";
import { useNavigate } from "react-router-dom";

function Registeration() {
  const userInfo = useSelector((state) => state.userInfo.value);
  const token = useSelector((state) => state.userInfo.value.token);
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const global = useSelector((state) => state.lang.value.global);
  const dispatch = useDispatch();
  const [imgProfile, setImageProfile] = useState({});
  const [Profile, setProfile] = useState([]);
  const navigate = useNavigate();
  const isAvatarPending = false;
  const pictureFileInput = useRef();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "company_name",
      value: "",
      isRequired: true,
    },
    {
      control: "first_name",
      value: "",
      isRequired: true,
    }, {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
    {
      control: "code_admin",
      value: "+20",
      isRequired: false,
    },
    {
      control: "phone_admin",
      value: "",
      isRequired: true,
    },
    {
      control: "code",
      value: "+20",
      isRequired: false,
    },
    {
      control: "website",
      value: "",
      isRequired: true,
      validations: [
        {
          test: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          message: globalLang.emailinvalid[lang]
        },
      ],
    },
    {
      control: "last_name",
      value: "",
      isRequired: false,
    },
    {
      control: "phone",
      value: "",
      isRequired: true,
    },

    {
      control: "address",
      value: "",
      isRequired: false,
    },

    {
      control: "email",
      value: "",
      isRequired: true,
      validations: [
        {
          test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: globalLang.emailinvalid[lang]
        },
      ],
    },
    { control: "logo",
    value: "",
    isRequired: false}
  ]);
  const [registerPostRequest, reigsterPostResponse] = useRequest({
    path: REGISTER,
    method: "post",
    successMessage: globalLang.loginaccount[lang],
  });
  // const [OwnerPermissionGetRequest] = useRequest({
  //   path: OWNERPERMS,
  //   method: "get",
  // });
  const resize = (file) => {
    return new Promise((resolve) => {
      Compress.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => resolve(uri),
        "file"
      );
    });
  };
  const changeAavatar = async (file) => {
    setImageProfile(URL.createObjectURL(file));
    setProfile(file);
  };

  const handleRegister = (e) => {
    // e.preventDefault();
  
    validate().then(({ isOk }) => {
      if (!isOk) return;
     
      const requestBody = filter({
        obj: {
          owner: JSON.stringify({
            first_name: controls.first_name,
            last_name: controls.last_name,
            username:controls.username,
            password: controls.password,
            email: controls.email,
            phone: controls.code_admin + controls.phone_admin,
          }),
          logo: Profile,
          name: controls.company_name,
          url: controls.website,
          address: controls.address,
          phone: controls.code + controls.phone,
        },
        output: "formData",
      });
      registerPostRequest({
        body: requestBody,
        noHeaders: true,
        onSuccess: (res) => {
          dispatch({type:"userInfo/setImage"})
          setTimeout(() => navigate("/signin"), 1500);
          setImageProfile();
          setProfile();
        },
      })
    });
  };

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        width: "100%",
        flexWrap: "nowrap",
      }}
    >
      <Box
        sx={{
          width: "24.8%",

          position: "static",
          backgroundColor: (theme) => theme.palette.primary.main,
          display: {
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs:"none"
          },
        }}
      >
        <Stack
          sx={{
            width: "24.8%",
            height: "100vh",
            position: "fixed",
            color: "white",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box
            sx={{
              padding: {
                lg: "48px",
                md: "24px",
                sm: "24px",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={logo} alt="" />
            <h3 style={{ marginTop: "32px" }}>{global.create_acc[lang]}</h3>
            <p style={{ marginTop: "24px" }}>{global.paragraph[lang]}</p>
          </Box>
          <Box sx={{ width: "200px", order: 2 }}>
            <Lottie loop={true} animationData={loginAnimation} />

            <Box>
              <img src={cypartalogo} alt="" />{" "}
              <span>{global.rights[lang]}</span>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Stack
        sx={{
          width: { xl: "80%", lg: "80%", md: "100%", sm: "100%", xs: "100%" },
        }}
      >
        {/* <Stack
          sx={{
            textAlign: "center",
            mx: { xl: "25%", lg: "25%", md: "15%", sm: "10%" },
          }}
        >
         
        </Stack> */}
        <Form hideFooter hideHeader sx={{ mt: "32px" }}>
          <Stack sx={{ gap: 1 }}>
            <Box
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "90%",
                  xs: "90%",
                },
                mx: "auto",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "nowrap",
                position: "relative",
              }}
            >
              <Box sx={{ my: 4, backgroundColor: "#fff" }}>
                <Avatar
                  sx={{
                    height: "70px",
                    width: "70px",
                    mt: 1,
                    mr: { sm: 1, sx: 1 },
                  }}
                  src={imgProfile}
                />{" "}
                <IconButton
                  sx={{
                    bgcolor: "white",
                    color: "#8D9396",
                    boxShadow: "0 0 10px #fff",
                    "&:hover": {
                      bgcolor: "white",
                    },
                    borderRadius: "10px",
                    width: "59px",
                    border: "1px solid",
                    position: "absolute",

                    left: lang === "ar" ? "-4%" : "",
                    right: lang === "ar" ? "" : "-4%",
                    top: "58%",
                  }}
                  disabled={isAvatarPending}
                  onClick={(e) => {
                    pictureFileInput.current.click();
                  }}
                >
                  <img src={cloud} alt="" />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      changeAavatar(e.target.files[0]);
                      setProfile(e.target.files[0]);

                    }}
                    ref={pictureFileInput}
                  />
                </IconButton>
              </Box>
            </Box>
            <InputField
              label={globalLang.company_name[lang]}
              placeholder={globalLang.company_name[lang]}
              onChange={(e) => setControl("company_name", e.target.value)}
              value={controls.company_name}
              required={required.includes("company_name")}
              error={Boolean(invalid.company_name)}
              helperText={invalid.company_name}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <InputField
              label={globalLang.email[lang]}
              placeholder={globalLang.email[lang]}
              onChange={(e) => {
                setControl("email", e.target.value);
              }}
              value={controls.email}
              required={required.includes("email")}
              error={Boolean(invalid.email)}
              helperText={invalid.email}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <InputField
              label={globalLang.username[lang]}
              placeholder={globalLang.username[lang]}
              onChange={(e) => {
                setControl("username", e.target.value);
              }}
              value={controls.username}
              required={required.includes("username")}
              error={Boolean(invalid.username)}
              helperText={invalid.username}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <InputField
              label={globalLang.address[lang]}
              placeholder={globalLang.address[lang]}
              required={required.includes("address")}
              value={controls.address}
              onChange={(e) => setControl("address", e.target.value)}
              error={Boolean(invalid.address)}
              helperText={invalid.address}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <PhoneField
              label={global.phone_number[lang]}
              placeholder={global.phone_number[lang]}
              required={required.includes("phone")}
              requiredCode
              selectProps={{
                value: controls.code,
                onChange: (e) => {setControl("code", e.target.value);console.log(controls.value)},
              }}
              onChange={(e) => setControl("phone", e.target.value)}
              value={controls.phone}

              error={Boolean(invalid.phone)}
              helperText={invalid.phone}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <InputField
              label={globalLang.website[lang]}
              placeholder={globalLang.company_name_placeholder[lang]}
              required={required.includes("website")}
              value={controls.website}
              onChange={(e) => setControl("website", e.target.value)}
              error={Boolean(invalid.website)}
              helperText={invalid.website}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <PasswordField
              label={global.password[lang]}
              placeholder={global.password[lang]}
              required={required.includes("password")}
              value={controls.password}
              onChange={(e) => setControl("password", e.target.value)}
              error={Boolean(invalid.password)}
              helperText={invalid.password}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "24px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <Stack
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                display: "flex",
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <InputField
                label={global.admin_name[lang]}
                placeholder={global.first_name[lang]}
                required={required.includes("first_name")}
                value={controls.first_name}
                onChange={(e) => {
                  setControl("first_name", e.target.value);
                }}
                error={Boolean(invalid.first_name)}
                helperText={invalid.first_name}
                sx={{
                  width: {
                    xl: "48%",
                    lg: "48%",
                    md: "100%",
                    ms: "100%",
                    xs: "100%",
                  },
                  mb: "12px",
                  ".MuiInput-root": { padding: "8px 14px 8px 8px" },
                }}
              />
              <InputField
                label={"  "}
                placeholder={global.last_name[lang]}
                required={required.includes("last_name")}
                value={controls.last_name}
                onChange={(e) => setControl("last_name", e.target.value)}
                error={Boolean(invalid?.last_name)}
                helperText={invalid?.last_name}
                sx={{
                  width: {
                    xl: "48%",
                    lg: "48%",
                    md: "100%",
                    ms: "100%",
                    xs: "100%",
                  },
                  mb: "12px",
                  ".MuiInput-root": { padding: "8px 14px 8px 8px" },
                }}
              />
            </Stack>

            <PhoneField
              label={global.phone_number[lang]}
              placeholder={global.phone_number[lang]}
              required={required.includes("phone_admin")}
              requiredCode
              selectProps={{
                value: controls.code_admin,
                onChange: (e) => setControl("code_admin", e.target.value),
              }}
              value={controls.phone_admin}
              onChange={(e) => setControl("phone_admin", e.target.value)}
              error={Boolean(invalid.phone_admin)}
              helperText={invalid.phone_admin}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": {
                  padding: {
                    xl: "8px 14px 8px 8px",
                    sm: "8px 3px 8px 8px",
                    xs: "8px 3px 8px 0px",
                  },
                },
              }}
            />

            <Button
              sx={{
                backgroundColor: "#090979",
                borderRadius: "8px",
                height: "44px",
                p: "10px",
                ml: lang === "ar" ? "20%" : "",
                mr: lang === "ar" ? "" : "20%",
                ml: "20%",
                my: "32px",
                justifyContent: "center",
                color: "#fff",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                width: {
                  xl: "20%",
                  lg: "20%",
                  md: "20%",
                  sm: "50%",
                  xs: "50%",
                },
                ":hover": {
                  backgroundColor: "#090979",
                },
              }}
              onClick={(e) => handleRegister(e)}
              ispending={registerPostRequest.isPending}
            >
              {global.create_acc[lang]}
            </Button>
            <Divider width="60%" color="#ccc" sx={{ mx: "auto" }} />
            <Typography
              color="#ccc"
              sx={{
                ml: "20%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {global.haveaccount[lang]}
              <Button
                onClick={() => navigate("/signin")}
                sx={{
                  color: "#090979",
                  "&:hover": {
                    color: "GrayText",
                  },
                }}
              >
                {global.login[lang]}
                {lang === "ar" ? (
                  <ArrowBackIcon color="primary" />
                ) : (
                  <ArrowForwardOutlinedIcon color="primary" />
                )}
              </Button>
            </Typography>
          </Stack>
        </Form>
        {reigsterPostResponse.successAlert}
        {reigsterPostResponse.failAlert}
      </Stack>
    </Stack>
  );
}
export default Registeration;
