import { createSlice } from "@reduxjs/toolkit";

export const CountrySlice = createSlice({
  name: "countryslice",
  initialState: {
    value:[]
  },
  reducers: {
    set: (state, action) => {
        state.value=[...action.payload]
    },
   addcountry:(state,action)=>{
    state.value.push(...action.payload)
   },
    reset: (state) => {
      state.value = [{
        flag:"",
        name:"",
        short: ""
      }];
    },
  },
});

export default CountrySlice.reducer;
