import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataGrid from "../../components/DataGrid";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { JOBS, JOBTREE, JOB_PERMISSIONS, PERMISSIONS } from "../../data/APIs";
import useDataGrid from "../../hooks/useDataGrid";
import format from "../../utils/ISOToReadable";
import { InputField } from "../../features/form";
import { Box, Button, MenuItem, Tab, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import "./tree.css";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogContent,
  DialogForm,
  DialogHeading,
  DialogInputField,
  DialogSelectField,
} from "../../features/dialog";
import PermissionToggles from "../../components/PermissionToggles";
import useControls from "../../hooks/useControls";
import { useRef } from "react";
import useAfterEffect from "../../hooks/useAfterEffect";
import compare from "../../utils/Compare";
import filter from "../../utils/ClearNull";
import Tree from "react-d3-tree";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import { Tree } from 'rsuite';
// import Tree from "react-d3-tree";
// import { ManOutlined } from "@mui/icons-material";
import * as d3 from 'd3';
import PureSvgNodeElement from "./PropsTree";
const ViewJobs = () => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const jobsStore = useSelector((state) => state.jobs.value);
  const jobsTreeStore = useSelector((state) => state.jobstree.value);
  const [jobtreestore,setjobtreestore]=useState(null)
  const [openg, setopeng] = useState(false);
  const dispatch = useDispatch();
// console.log(Array.isArray(jobsTreeStore),jobsTreeStore)
  const [jobsGetRequest, jobsGetResponse] = useRequest({
    path: JOBS,
    method: "get",
  });
  const [jobtreesGetRequest, jobtreesGetResponse] = useRequest({
    path: JOBTREE,
    method: "get",
  });
  
  const getJobs = (urlParams) => {
    jobsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "jobs/set", payload: res.data });
      },
    });
  };
  const gettreeJobs = () => {
    jobtreesGetRequest({
      
      onSuccess: (res) => {
        setjobtreestore(res.data)
        dispatch({ type: "jobstree/set", payload: res.data });
      },
    });
  };
  
  const handleGraph = () => {
    if (!openg) {
      setopeng(true);
    } else {
      setopeng(false);
    }
  };
useEffect(()=>{
  
},[jobsTreeStore])
  const { handlePaginate, handleChangeAmount, handleFilter } = useDataGrid({
    onParamsChange: getJobs,
  });
  const svgRef = useRef(null);
  const data = {
    name: 'Root',
    children: [
      {
        name: 'Node 1',
        children: [
          { name: 'Leaf 1' },
          { name: 'Leaf 2' },
          { name: 'Leaf 3' }
        ]
      },
      {
        name: 'Node 2',
        children: [
          { name: 'Leaf 4' },
          { name: 'Leaf 5' }
        ]
      }
    ]
  }
 
  
  const [jobDeleteRequest, jobDeleteResponse] = useRequest({
    path: JOBS,
    method: "delete",
    successMessage:globalLang.detelesuccess[lang]
  });

  const handleDeleteJob = (e, row) => {
    jobDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "jobs/deleteItem", payload: { id: row.id } });
      },
    });
  };
  const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const [dimensions, setDimensions] = useState();
    const containerRef = useCallback((containerElem) => {
      if (containerElem !== null) {
        const { width, height } = containerElem.getBoundingClientRect();
        setDimensions({ width, height });
        setTranslate({ x: width / 2, y: height / 2 });
      }
    }, []);
    return [dimensions, translate, containerRef];
  };
  const [dimensions, translate, containerRef] = useCenteredTree();

  // console.log(jobsStore.results.map((j)=>j.parent===""))
  const jobsParent = jobsStore.results.map((j) => j.parent === "");
  const getVertices = (title, sub_jobs) => {
    const map = new Map();
    map.set(title, [sub_jobs.map((sub) => sub.title)]);
    // map.set(sub_jobs.map((sub) => sub.title)[0],[]);

    return map;
    //  return new Map([
    //    [title, sub_jobs.map((j) => j.title)],
    //    [sub_jobs.map((j) => j.title)[0], ["A", "B"]],
    //    [sub_jobs.map((j) => j.title)[1], ["C", "D"]],

    //    // ["A", []],
    //    // ["D", ["B", "C"]],
    //    // ["G", []],
    //    // ["M", ["H", "I", "J", "K", "L"]],
    //    // ["B", []],
    //    // ["C", []],
    //    // ["H", []],
    //    // ["I", []],
    //    // ["J", []],
    //    // ["K", []],
    //    // ["L", []],
    //  ]);
  };
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <rect width="20" height="20" x="-10" onClick={toggleNode} />
      <text fill="black" strokeWidth="1" x="20">
        {nodeDatum.name}
      </text>
      {nodeDatum.attributes?.department && (
        <text fill="black" x="20" dy="20" strokeWidth="1">
          Department: {nodeDatum.attributes?.department}
        </text>
      )}
    </g>
  );
  const orgChart = {
    name: "CEO",
    children: [
      {
        name: "Manager",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "Foreman",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
          {
            name: "Foreman",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
        ],
      },
    ],
  };
  const [handleDelete, deleteJobConfirmDialog] = useConfirmMessage({
    onConfirm: handleDeleteJob,
    text: globalLang.deletejobconfirm[lang],
  });

  const isPermitted = useIsPermitted();

  const [editData, setEditData] = useState(null);

  const handleEditJob = (e, row) => {
    setEditData(row);
  };

  // Filters
  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
    },
  ];

  /////////////////

  // Columns name
  const columns = [
    {
      field: "title",
      headerName: viewJobsLang.jobName[lang],
    },
    {
      field: "created_at",
      headerName: viewJobsLang.createdAt[lang],
      customContent: ({ created_at }) => format(created_at),
    },
  ];
  const svgSquare = {
    shape: "circle",
    shapeProps: {
      width: 20,
      height: 20,
      x: -10,
      y: -10,
    },
  };
  let objectResult
  /////////////////
useEffect(()=>{
  gettreeJobs()
  // if(jobsTreeStore){
  //   const hierarchy = d3.hierarchy(jobsTreeStore);
  //   const treeLayout = d3.tree().size([500, 300]);
  //   const treeData = treeLayout(hierarchy);
  //   console.log(treeData,hierarchy,jobsTreeStore,treeLayout)
  //   const svg = d3.select(svgRef.current);
  //   const links = svg
  //   .selectAll('.link')
  //   .data(treeData.links())
  //   .enter()
  //   .append('line')
  //   const nodes = svg
  //   .selectAll('.node')
  //   .data(treeData.descendants())
  //   .enter()
  //   .append('circle')
  //   const labels = svg
  //   .selectAll('.label')
  //   .data(treeData.descendants())
  //   .enter()
  //   .append('text')
  //   console.log(labels,nodes,links)
  // }
},[])
const [value, setValue] = React.useState('1');

const handleChange = (event, newValue) => {
  setValue(newValue);
};
useEffect(()=>{

},[editData])
  return (<>
 
      <TabContext value={value}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <TabList onChange={handleChange} aria-label="lab API tabs example">
      
      <Tab label={viewJobsLang.viewJobs[lang]} value="1" />
      <Tab label={viewJobsLang.viewJobstree[lang]} value="2" />
    </TabList>
  </Box>
  {/* <TabPanel value="1">Item One</TabPanel> */}
  <TabPanel value="1"> <Wrapper>
      
      <DataGrid
        columns={columns}
        rows={jobsStore.results}
        total={jobsStore.count}
        isPending={jobsGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDelete, ["delete_aqarjob"])}
        onEdit={isPermitted(handleEditJob, ["change_aqarjob"])}
        filters={filters}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      {deleteJobConfirmDialog}
      {jobDeleteResponse.successAlert}
      {jobDeleteResponse.failAlert}
    </Wrapper></TabPanel>
  <TabPanel value="2">  <div
      style={{ width: "100vw", margin: "auto", transform: "translateY(-5%)" }}
    > 

     
     {/* {jobsTreeStore.results?.map((ele,index)=>( */}
   
      
      <Tree
      // key={index}
      data={{
        name:"jobs",
        attributes:{
          job_count:jobtreestore?.length
        },
        children:jobtreestore?jobtreestore.map((ele)=>{return{
          name:ele?.name,
          attributes:{
            job_count:ele?.children?.length
          },
          children:ele?.children
        }}):[]
      }}
      rootNodeClassName="node__root"
      branchNodeClassName="node__branch"
      leafNodeClassName="node__leaf"
      orientation="vertical"
      zoomable={false}
      translate={{ x: 1000, y: 300 }}
      shouldCollapseNeighborNodes
      nodeSize={{x:200,y:200}}
      pathFunc="diagonal"
      // separation={s}
      svgClassName="tree"
      hasInteractiveNodes
      //  svgClassName="Tree"
      renderCustomNodeElement={(rd3tProps, appState) => (
        <PureSvgNodeElement
          nodeDatum={rd3tProps.nodeDatum}
          toggleNode={rd3tProps.toggleNode}
          orientation={"horizontal"}
        />)}

    />   
      {/* ))}  */}
      


   </div> </TabPanel>
</TabContext>
    
 
   
    </>
  );
};

export default ViewJobs;

const NameFilter = ({ value, onChange }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["title", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [{ controls ,invalid,required}, { setControl, resetControls,setInvalid, validate}] = useControls(
    [
      {
        control: "startPoint",
        value:value.start,
        isRequired: true,
      },
      {
        control: "endPoint",
        value: value.end,
        isRequired: true,
      },
      // {
      //   control:"created_at",
      //   value:"",
      //   isRequired:false
      // }
    ],
    [value]
  );
  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!controls.startPoint && !controls.endPoint) return;
    validate().then((output) => {
      if (!output.isOk) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(controls.startPoint))}-${formatDate(String(controls.endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(controls.startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(controls.endPoint))}`,
      value: {
        start: controls.startPoint,
        end: controls.endPoint,
      },
    })
      })
  }, [controls.startPoint, controls.endPoint]);

  const handleChangeStartPoint = (e) => {
    setControl("startPoint",e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    if(controls.startPoint &&controls.startPoint>e.target.value){
      setInvalid((prevState) => ({
        ...prevState,
        endPoint: "End date should be greater than start date.",
      }));
    }else{
      setInvalid((prevState) => ({ ...prevState, endPoint: "" }));
      setControl("endPoint",e.target.value);

    }
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={controls?.startPoint}
        onChange={handleChangeStartPoint}
        required={required.includes("startPoint")}
        error={Boolean(invalid.startPoint)}
        helperText={invalid.startPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={controls?.endPoint}
        onChange={handleChangeEndPoint}
        required={required.includes("endPoint")}
        error={Boolean(invalid.endPoint)}
        helperText={invalid.endPoint}
      />
    </Stack>
  );
};

const EditDialog = ({
  open,
  onClose,
  data = {
    name: "",
  },
}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const allPermissions = useSelector((state) => state.allPermissions.value);

  const dispatch = useDispatch();
  console.log(data)
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "name",
        value: data?.title,
      },
      {
        control: "to",
        value: data?.parent?.id,
      },
    ],
    [data]
  );

  //===Start==== Permissions Logic =========

  const [toggles, setToggles] = useState(allPermissions.length>0?allPermissions:[]);
  const initialPermissions = useRef([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [jobPermissionsGetRequest, jobPermissionsGetResponse] = useRequest({
    path: JOB_PERMISSIONS,
    method: "get",
  });

  const [parentPermissionsGetRequest, parentPermissionsGetResponse] =
    useRequest({
      path: JOB_PERMISSIONS,
      method: "get",
    });

  const [allPermissionsGetRequest, allPermissionsGetResponse] = useRequest({
    path: PERMISSIONS,
    method: "get",
  });

  const getJobPermissions = () => {
    jobPermissionsGetRequest({
      params: {
        id: data?.id,
      },
      onSuccess: (res) => {
        
        initialPermissions.current = res.data.map((perm) => perm.codename);
        setSelectedPermissions(res.data.map((perm) => perm.codename));
      },
    });
  };

  const getParentPermissions = () => {
    parentPermissionsGetRequest({
      params: {
        id: controls.parent ?? data.parent.id,
      },
      onSuccess: (res) => {
        
        setToggles(res.data);
        getJobPermissions();
      },
    });
  };

  // const getAllPermissions = () => {
  //   allPermissionsGetRequest({
  //     onSuccess: (res) => {
  //       console.log(res.data)
  //       dispatch({ type: "allPermissions/set", payload: res.data.permissions });
  //       getJobPermissions();
  //     },
  //   });
  // };
  useAfterEffect(() => {
    getJobPermissions();
  }, [data]);
  useEffect(() => {
    if (!open) return;
    console.log(allPermissions)
    if (data?.parent === "-") {
      !allPermissions.length;
      setToggles([...allPermissions]);
    } else {
      getParentPermissions();
    }
  }, [open]);

  useAfterEffect(() => {
    setToggles([...allPermissions]);
  }, [allPermissions.length]);

  //===End==== Permissions Logic =========

  //====Start====== Parent Logic =========

  const [parents, setParents] = useState([]);

  const [parentsGetRequest, parentsGetResponse] = useRequest({
    path: JOBS,
    method: "get",
  });

  const getParents = () => {
    parentsGetRequest({
      params: {
        top: 1,
        id: data.id,
        size: 1000,
      },
      onSuccess: (res) => {
        setParents(res.data);
      },
    });
  };

  //====End====== Parent Logic =========

  //====Start====== Submit Logic =========

  const [jobPatchRequest, jobPatchResponse] = useRequest({
    path: JOBS,
    method: "patch",
    successMessage:globalLang.successedit[lang]
  });

  const handleSubmit = () => {
    const isThereChange = compare(
      [
        [ controls.name,data?.title,"title"],
        [ controls.to,data?.parent?.id ?? "","parent"],
        [selectedPermissions, initialPermissions.current,"permissions"],
      ],
      false
    );
    if (!isThereChange.nochange) {
      onClose()
      return
    };

    const requestBody = filter({
      obj: {
        title: isThereChange.array["title"],
        parent: isThereChange.array["parent"],
        permissions: selectedPermissions.map((perm) => ({
          codename: perm,
        }))

      },
    });

    jobPatchRequest({
      id: data?.id,
      body: requestBody,
      onSuccess: (res) => {
        
        dispatch({
          type: "jobs/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        resetControls()
        onClose();
      },
    });
  };

  //====End====== Submit Logic =========
  useEffect(()=>{

  },[selectedPermissions])
  return (<>
    <Dialog
      open={open}
      onClose={onClose}
      isPending={
        jobPermissionsGetResponse.isPending ||
        parentPermissionsGetResponse.isPending ||
        allPermissionsGetResponse.isPending
      }
      paperProps={{ height: "100%" }}
    >
      <DialogHeading>{globalLang.editJob[lang]}</DialogHeading>
      <DialogForm>
        <DialogInputField
          label={globalLang.name[lang]}
          placeholder={globalLang.name[lang]}
          value={controls.name}
          onChange={(e) => setControl("name", e.target.value)}
        />
        {data?.parent && (
          <DialogInputField
            disabled
            label={viewJobsLang.followed[lang]}
            placeholder={viewJobsLang.followed[lang]}
            value={ parents.find((parent) => parent.id === controls.to)?.title ??
                data?.parent?.title}
            
          />
          // <DialogSelectField
          //   label={viewJobsLang.followed[lang]}
          //   placeholder={viewJobsLang.followed[lang]}
          //   onOpen={getParents}
          //   isPending={parentsGetResponse.isPending}
          //   value={controls.to}
          //   onChange={(e) => {
          //     setControl("to", e.target.value);
          //     getParentPermissions();
          //   }}
          //   renderValue={(selected) => {
          //     return (
          //       parents.find((parent) => parent.id === selected)?.title ??
          //       data?.parent?.title
          //     );
          //   }}
          // >
          //   {parents.map((parent, index) => (
          //     <MenuItem value={parent.id} key={`job ${index}`}>
          //       {parent.title}
          //     </MenuItem>
          //   ))}
          // </DialogSelectField>
        )}
      </DialogForm>
      <DialogContent>
        <PermissionToggles
          permissions={toggles}
          initialToggles={selectedPermissions}
          onToggle={({ toggles }) => setSelectedPermissions(toggles)}
        />
        
      </DialogContent>
      <DialogButtonsGroup>
        <DialogButton
          sx={{ width: "100%" }}
          disabled={jobPatchResponse.isPending}
          onClick={handleSubmit}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose} sx={{ width: "100%" }}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
    {jobPatchResponse.successAlert}
    {jobPatchResponse.failAlert}
    </>
  );
};
