import { StackedBarChart } from "@mui/icons-material";
import {
  Box,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { GETUSERACTIVE, OVERVIEW } from "../../data/APIs";
import useRequest from "../../hooks/useRequest";
import useAfterEffect from "../../hooks/useAfterEffect";
import filter from "../../utils/ClearNull";
import useControls from "../../hooks/useControls";
import Wrapper from "../../components/Wrapper";

function Statistics() {
  const lang = useSelector((state) => state.lang.value.lang);
  const overview = useSelector((state) => state.lang.value.overview);

  const statistics = useSelector((state) => state.lang.value.statistics);
  const dialyactivity = useSelector((state) => state.lang.value.dialyactivity);
  let [period, setPeriod] = useState("m");
  const [data, setDate] = useState([
    { name: "Group A", value: 530 },
    { name: "Group B", value: 753 },
  ]);
  const [keys, setkeys] = useState([""]);
  const [values, setValues] = useState([""]);
  const [responce, setResponce] = useState({});

  const COLORS = ["#F2BD00", "#F09343"];
  // const [{ controls, invalid }, { setControl, validate }] = useControls(
  //   keys.map((ele)=>{
  //    return {
  //     control:ele,
  //     value:
  //    }
  //   }),
  //   [data]
  // );
  const [getuseractive, getuseractiveResponse] = useRequest({
    path: GETUSERACTIVE,
    method: "get",
    // successMessage: "تم الحصول علي كل يوزر نشطين",
  });
  const [overviewrequest, overviewresponse] = useRequest({
    path: OVERVIEW,
    method: "get",
    // successMessage: "تم الحصول علي كل يوزر نشطين",
  });
  const getusersactive = () => {
    getuseractive({
      params: {
        period,
      },
      onSuccess: (res) => {
        // let response = res?.data;
        // console.log(response)
        // const responseBody = filter({
        //   obj: {
        //     date: response?.data,
        //     count: response?.count,
        //     month: response?.password,
        //     job: response?.job,
        //   },
        //   output: "formData",
        // });
        // console.log(responseBody);
        // setInvalid(responseBody);
        // dispatch({ type: "fekhunits/deleteItem", payload: { id: row.id } });/
        let newdate = [...res.data];
        setkeys(Object.keys(res.data[0]));
        setValues(Object.values(res.data[0]));

        setDate(newdate);
      },
    });
  };
  const getoverviewresult = () => {
    overviewrequest({
      onSuccess: (res) => {
        console.log(res);
        setResponce(res.data);
      },
    });
  };
  useEffect(() => {
    getusersactive();
  }, [period]);
  useEffect(() => {
    getoverviewresult();
  }, []);
  // const dataView=Object.keys(responce).map((ele,index)=>{return typeof responce[ele]==="object"?})
  const dataView = [
    { id: 1, title: overview.numberUser[lang], value: responce.users },
    {
      id: 2,
      title: overview.numberrequestpending[lang],
      value: responce?.requests?.pending,
    },
    {
      id: 3,
      title: overview.numberrequestcompleted[lang],
      value: responce?.requests?.completed,
    },
    {
      id: 4,
      title: overview.numberrequestall[lang],
      value: responce?.requests?.all,
    },{
      id: 5,
      title: overview.numberunits[lang],
      value: responce?.units,
    },{
      id: 6,
      title: overview.numberclients[lang],
      value: responce?.clients,
    },
  ];
  return (
    <>
      <Wrapper sx={{ marginTop: "1rem" }} className={lang}>
        <Typography
          variant="h5"
          sx={{ textAlign: lang === "ar" ? "left" : "right", marginY: "24px" }}
        >
          {statistics.title[lang]}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          flexWrap="wrap"
          gap="2"
        >
          {/* <Box
            sx={{
              boxShadow: "0px 0px 10px rgba(32, 80, 105, 0.16)",
              backdropFilter: "blur(50px)",
              borderRadius: "10px",
              width: "40%",
              padding: "24px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              variant="h6"
              color="black"
              sx={{
                textAlign: lang === "ar" ? "left" : "right",
              }}
            >
              {statistics.totaldownloads[lang]}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box>
                <Box>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="span">
                        {statistics.appstore[lang]}
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          display: "flex",

                          bgcolor: (theme) => theme.palette.orange,
                        }}
                      ></Typography>
                    </Box>
                    <Typography variant="h4" color="black">
                      {data[0].value}
                    </Typography>
                    <Typography variant="span" color="grey">
                      {statistics.Last6days[lang]}
                    </Typography>
                  </Stack>
                </Box>
                <Box>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="span">
                        {statistics.appstore[lang]}
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          display: "flex",
                          bgcolor: (theme) => theme.palette.orange,
                        }}
                      ></Typography>
                    </Box>
                    <Typography variant="h5" color="black">
                      {data[1]?.value}
                    </Typography>
                    <Typography variant="span" color="grey">
                      {statistics.Last6days[lang]}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              <ResponsiveContainer width="50%" height={300}>
                <PieChart width={100}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Stack>
          </Box> */}
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            width={"100%"}
            sx={{flexWrap:"wrap",rowGap:"24px"}}
          >
            {dataView.map((ele)=>{
              return <Box
              sx={{
                boxShadow: "0px 0px 10px rgba(32, 80, 105, 0.16)",
                backdropFilter: "blur(50px)",
                borderRadius: "10px",
                width: "30%",
                padding: "24px",
              }}
            >
              <Typography
                variant="h6"
                color="black"
                sx={{
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {ele.title}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
              >
                
                <Typography variant="h3" color="primary">
                 {ele.value}
                </Typography>
              </Stack>
            </Box>
            })}
            

            
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap="2"
          sx={{ marginTop: "40px" }}
        >
          <Typography variant="h5" color="black">
            {statistics.activeusers[lang]}
          </Typography>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={period ? period : "m"}
              onChange={(e) => {
                setPeriod(e.target.value);
                getusersactive();
              }}
              sx={{
                boxShadow: "0px 0px 10px rgba(32, 80, 105, 0.16)",
                backdropFilter: "blur(50px)",
                borderRadius: "10px",
                border: "none",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="m">Monthy</MenuItem>
              <MenuItem value="a">Yearly</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{ top: 22, right: 32, left: 32, bottom: 22 }}
            // isPending={totalProductionGetResponse}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ECE9F1" stopOpacity={0.8} />
              </linearGradient>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#422F8A" stopOpacity={0.8} />
              </linearGradient>
            </defs>
            <XAxis dataKey={keys[0]} padding={{ left: 40, right: 40 }} />
            <YAxis textAnchor="3" dataKey={keys[1]} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip cursor={{ stroke: "#422F8A", strokeWidth: 2 }} />
            <Line
              type="monotone"
              dataKey={keys[1]}
              stroke="#9897D0"
              activeDot={{ r: 30 }}
            />
          </LineChart>
        </ResponsiveContainer>
        
      </Wrapper>
      {overviewresponse.successAlert}
      {overviewresponse.failAlert}
      {/* {getuseractiveResponse.failAlert}
      {getuseractiveResponse.successAlert} */}
    </>
  );
}

export default Statistics;
