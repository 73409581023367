// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@550;823;1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  font-family: "Cairo", sans-serif;
}
.tree {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: fit-content;
  height: fit-content; */
}
.react-image-video-lightbox{
  position: relative;
  z-index: 1000000;
  
}
.react-image-video-lightbox>div{
  /* background-color: rgba(0, 0, 0, 0.1) !important; */
  width: 100px;
}
.react-image-video-lightbox img{
  width:50%;
}
/* 
body {
  direction: rtl;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB;wBACsB;AACxB;AACA;EACE,kBAAkB;EAClB,gBAAgB;;AAElB;AACA;EACE,qDAAqD;EACrD,YAAY;AACd;AACA;EACE,SAAS;AACX;AACA;;;GAGG","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Cairo:wght@550;823;1000&display=swap\");\n\n* {\n  padding: 0;\n  margin: 0;\n  font-family: \"Cairo\", sans-serif;\n}\n.tree {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* width: fit-content;\n  height: fit-content; */\n}\n.react-image-video-lightbox{\n  position: relative;\n  z-index: 1000000;\n  \n}\n.react-image-video-lightbox>div{\n  /* background-color: rgba(0, 0, 0, 0.1) !important; */\n  width: 100px;\n}\n.react-image-video-lightbox img{\n  width:50%;\n}\n/* \nbody {\n  direction: rtl;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
