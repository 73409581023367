import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import {
  COUNTRY_FILTER,
  REQUEST,
  REQUESTEMPLOYEE,
  UNITS_FEKHEDMETAK,
  USERFEKHEDMETAK,
} from "../../data/APIs";
import useDataGrid from "../../hooks/useDataGrid";
import {
  Container,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton as MuiIconButton,
  MenuItem,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Box,
  Tab,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import DataGrid from "../../components/DataGrid";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import { InputField } from "../../features/form";
import useControls from "../../hooks/useControls";
import useAfterEffect from "../../hooks/useAfterEffect";
import {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInputField,
  DialogPhoneField,
  DialogSelectField,
} from "../../features/dialog";
import Wrapper from "../../components/Wrapper";
import format from "../../utils/ISOToReadable";
import compare from "../../utils/Compare";
import filter from "../../utils/ClearNull";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import HomeIcon from "@mui/icons-material/Home";
import { styledialog, stylerow, styletd } from "../../css/tableview";
import usePropState from "../../hooks/usePropState";
import DialogPeopleWindow, {
  DialogSelectItem,
} from "../../features/dialog/components/DialogPeopleWindow";
import home from "../../assets/home.png";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";

// import { userInfo } from "../../store/userInfoSlice";

function Requests() {
  const globalLang = useSelector((state) => state.lang.value.global);
  const RequestStore = useSelector((state) => state.Fekhidmaticrequests.value);
  const userInfo = useSelector((state) => state.userInfo.value);
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(null);
  const [infoData, setInfoData] = useState(null);
  const [truefalse, settruefalse] = useState("false");

  const [paramData, setParamData] = useState(null);
  const [transferrequest, settransferrequest] = useState(false);
  const [rowrequest, setrowrequest] = useState({});
  const [selected, setSelected] = useState([]);
  const [dataresult, setdataresult] = useState([]);
  const lang = useSelector((state) => state.lang.value.lang);
  const employeesStore = useSelector((state) => state.employees.value);
  const [GetRequestS, GetResponseOfrequests] = useRequest({
    path: REQUEST,
    method: "get",
  });

  const deleteEmployee = (e, row) => {
    employeeDeteleRequest({
      id: row?.id,
      params:{all:"true"},
      onSuccess: (res) => {
        dispatch({ type: "fekhrequests/deleteItem", payload: { id: row?.id } });
      },
    });
  };
  const [handleDeleteEmployee, deleteEmployeeConfirmDialog] = useConfirmMessage(
    {
      onConfirm: deleteEmployee,
      text: globalLang.makesureofdelete[lang],
    }
  );
  const [employeeDeteleRequest, employeeDeleteResponse] = useRequest({
    path: REQUEST,
    method: "delete",
    successMessage: globalLang.deletesuccess[lang],
  });
  const handleGetRequest = (urlParams) => {
   
    urlParams.append("all",truefalse)

    GetRequestS({
      params:urlParams,
      onSuccess: (res) => {
       
        dispatch({ type: "fekhrequests/set", payload: res.data });
      },
    });
  };
  const handleGetRequestfalse = (value) => {

    GetRequestS({
      params:{all:value},
      onSuccess: (res) => {
        settruefalse(value)
        dispatch({ type: "fekhrequests/set", payload: res.data });
      },
    });
  };
  const { handlePaginate, handleChangeAmount, handleFilter ,requestParams} = useDataGrid({
    onParamsChange: handleGetRequest,
  });

  let columns = [
    {
      field: "id",
      headerName: globalLang.id[lang],
      customContent: (params) => params.id,
    },
    {
      control: "call_date",
      headerName: globalLang.call_date[lang],
      customContent: (params) =>params?.call_date?format(params?.call_date):"",
      customEmpty:"-"
      // format(params?.call_date) ? format(params?.call_date) : "",
    },
    {
      field: "middleman",
      headerName: globalLang.middleman[lang],
      customContent: (params) => params.middleman,
    },
    {
      field: "client_name",
      headerName: globalLang.client_name[lang],
      customContent: (params) => params.client_name,
    },
    {
      field: "client_number",
      headerName: globalLang.client_number[lang],
      customContent: (params) =>
        params.client_country_code + params.client_number,
    },
    // {
    //   field: "client_country_code",
    //   headerName: globalLang.code[lang],
    //   customContent: (params) => params.client_country_code,
    // },
    {
      field: "status",
      headerName: globalLang.status[lang],
      customContent: (params) => params.status,
    },
    {
      field: "user",
      headerName: globalLang.user[lang],
      customContent: (params) => params.user,
    },
    {
      field: "employee_name",
      headerName: globalLang.employee_name[lang],
      customContent: (ele) => ele.employee_name,
    },
    {
      field: "property",
      headerName: globalLang.property[lang],
      customContent: (params) => (
        <IconButton
          color="#0000"
          iconColor="red"
          onClick={(event) => {
            event.stopPropagation();
            setParamData(params?.property);
            handleSubmit(params?.property);
          }}
          sx={{ backgroundColor: "#00246b !important" }}
        >
          <Typography
            component={"img"}
            src={home}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
      ),
    },
  ];
  const NameFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      onChange({
        query: [nameQuery, e.target.value],
        renderedValue: e.target.value,
        value: e.target.value,
      });
    };

    return (
      <InputField
        placeholder={nameQuery}
        value={value}
        onChange={handleChange}
        type={type}
      />
    );
  };
  const SelectFilter = ({
    value,
    onChange,
    nameoffiter,
    arrayoption,
    functiontofetch,
  }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);
    ///////////////////////////////
    const handleChange = (e) => {
   
      onChange({
        query: [nameoffiter, e.target.value],
        renderedValue: e.target.value,
        value: e.target.value,
      });
    };

    return (
      <>
        <DialogSelectField
          onOpen={functiontofetch}
          placeholder={value}
          onChange={handleChange}
          renderValue={(selected) => {
            return arrayoption.find((mean) => mean.title === selected)?.id;
          }}
        >
       
          {arrayoption.map((ele) => {
            return <MenuItem name={ele.id} value={ele.title}>{ele.id}</MenuItem>;
          })}
        </DialogSelectField>
      </>
      // <InputField
      //   placeholder={globalLang.name[lang]}
      //   value={value}
      //   onChange={handleChange}
      // />
    );
  };
  const filters = [
  
    {
      name: globalLang.middleman[lang],
      component: (
        <SelectFilter
          arrayoption={[
            { title: "you", id: "you" },
            { title: "company", id:  "company" },
          ]}
          nameoffiter={"middleman"}
        />
      ),
    },
    // {
    //   name: globalLang.client_name[lang],
    //   component: <NameFilter nameQuery={"client_name"} />,
    // },
    {
      name: globalLang.status[lang],
      component: (
        <SelectFilter
          arrayoption={[
            { title: "pending", id: "pending" },
            { title: "completed", id: "completed" },
          ]}
          nameoffiter={"status"}
        />
      ),
    },
 
  ];
  const isPermitted = useIsPermitted();

  //test
  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: UNITS_FEKHEDMETAK,
    method: "get",
    successMessage: globalLang.success[lang],
  });

  const handleSubmit = (id) => {
    unitPatchRequest({
      id: id,
     
      onSuccess: (res) => {
        setdataresult({ ...res.data });
        dispatch({ type: "imageunit/set", payload: res.data.property_files });
      },
    });
  };
  const [employeesState, setEmployeesState] = useState([]);
  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: REQUESTEMPLOYEE,
    method: "get",
  });
  const getEmployees = () => {
    // if (employeesStore.results.length) return;
    employeesGetRequest({
      params: {
        fe_khedmetak: true,
      },
      onSuccess: (res) => {
        dispatch({ type: "employees/set", payload: res.data });
      },
    });
  };
  const [requestPatchRequest, requestPatchResponse] = useRequest({
    path: REQUEST,
    method: "PATCH",
    successMessage: globalLang.successedit[lang],
  });
  const handleRequsetSubmit = (event,row) => {
console.log(row);
    requestPatchRequest({
      id: row.id,
      body: {
        status: "completed",
      },
      params:{all:"false"},
      onSuccess: (res) => {
        dispatch({
          type: "fekhrequests/putItem",
          payload: {
            id: res?.data?.id,
            item: {
              call_date: res?.data?.call_date,
              client_country_code: row?.client_country_code,
              client_name: row?.client_name,
              client_number: row?.client_number,
              employee_name: res?.data?.employee_name,
              id: row?.id,
              middleman: res?.data?.middleman,
              property: row?.property,
              status: res?.data?.status,
              user: row?.user,
            },
          },
        });
      
      },
    });
  };
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    if(newValue==="2"){
      handleGetRequestfalse("true")
// handleGetRequest()
    }else{
      handleGetRequestfalse("false")
    }
    console.log(RequestStore)
    setValue(newValue);
  };
  useEffect(() => {
   console.log(RequestStore)
  }, [
    RequestStore
  ]);
  return (<>
    <TabContext value={value}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <TabList onChange={handleChange} aria-label="lab API tabs example">
        
        <Tab label={globalLang.myRequest[lang]} value="1" />
        { userInfo.user_permissions.map(
    (perm) => perm.codename
  ).includes("transfer_fekhedemtak_employee")?<Tab label={globalLang.requests[lang]} value="2"  />:<></>}
       
      </TabList>
    </Box>
    <TabPanel value="1">
    <Wrapper>
      {/* <Typography
        variant="h5"
        sx={{ textAlign: lang === "ar" ? "left" : "right", marginY: "24px" }}
        color="primary"
      >
        {globalLang.requests[lang]}
      </Typography>
     */}
      <DataGrid
        columns={columns}
        rows={RequestStore.results}
        total={RequestStore.count}
        isPending={GetResponseOfrequests.isPending}
        onDelete={isPermitted(handleDeleteEmployee, [
          "transfer_fekhedemtak_employee",
        ])}
        // onBlock={handleBlockEmployee}
        // onChangePassword={(e, row) => setOpenEditPassword(row.id)}
        onEditRequest={isPermitted((e,row)=>setEditData(row),["transfer_fekhedemtak_employee"])}
        // onEdit={}
        onView={(e, row) => {
          setInfoData(row);
        }}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
        onTransferEmployee={isPermitted(
          (e, row) => {
            settransferrequest(!transferrequest);
            setrowrequest(row);
          },
          ["transfer_fekhedemtak_employee"]
        )}
        // onTransfernotfekd={isPermitted((handleRequsetSubmit), ["transfer_fekhedemtak_employee"])}
        onTransfernotfekd={
          Boolean(
            userInfo?.user_permissions
              ?.map((perm) => perm.codename)
              ?.some((ele) => ele.includes(["transfer_fekhedemtak_employee"]))
          )
            ? false
            : handleRequsetSubmit
        }
        // onTransfer={handlegotoclient}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      />
      <ParamDialog
        open={Boolean(paramData)}
        onClose={() => setParamData(null)}
        data={dataresult}
      />
     
      <TransferToEmployeeDialog
        open={transferrequest}
        onClose={() => settransferrequest(false)}
        onOpen={getEmployees}
        id={selected.map((item) => item)}
        data={[
          ...employeesStore?.results?.map((employee) => ({
            title: `${employee.user?.first_name} ${employee.user?.last_name}`,
            body: employee?.job?.title,
            id: employee?.id,
            picture: employee?.user?.image,
          })),
        ]}
        onGoBack={() => settransferrequest(false)}
        isPending={employeesGetResponse.isPending}
        onClickRequest={rowrequest}
        // datarequest={}
        // submitPending={clientTransferPostResponse.isPending}
        // onSubmit={handleSubmitEmployeeTransfer}
      />
      {deleteEmployeeConfirmDialog}
      {employeeDeleteResponse.successAlert}
      {employeeDeleteResponse.failAlert}
      {requestPatchResponse.successAlert}
      {requestPatchResponse.failAlert}
    </Wrapper></TabPanel>
    <TabPanel value="2">
    <Wrapper>
      {/* <Typography
        variant="h5"
        sx={{ textAlign: lang === "ar" ? "left" : "right", marginY: "24px" }}
        color="primary"
      >
        {globalLang.requests[lang]}
      </Typography>
     */}
      <DataGrid
        columns={columns}
        rows={RequestStore.results}
        total={RequestStore.count}
        isPending={GetResponseOfrequests.isPending}
        onDelete={isPermitted(handleDeleteEmployee, [
          "transfer_fekhedemtak_employee",
        ])}
        // onBlock={handleBlockEmployee}
        // onChangePassword={(e, row) => setOpenEditPassword(row.id)}
        onEditRequest={isPermitted((e,row)=>setEditData(row),["transfer_fekhedemtak_employee"])}
        // onEdit={}
        onView={(e, row) => {
          setInfoData(row);
        }}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
        onTransferEmployee={isPermitted(
          (e, row) => {
            settransferrequest(!transferrequest);
            setrowrequest(row);
          },
          ["transfer_fekhedemtak_employee"]
        )}
        // onTransfernotfekd={isPermitted((handleRequsetSubmit), ["transfer_fekhedemtak_employee"])}
        onTransfernotfekd={
          Boolean(
            userInfo?.user_permissions
              ?.map((perm) => perm.codename)
              ?.some((ele) => ele.includes(["transfer_fekhedemtak_employee"]))
          )
            ? false
            : handleRequsetSubmit
        }
        // onTransfer={handlegotoclient}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      />
      <ParamDialog
        open={Boolean(paramData)}
        onClose={() => setParamData(null)}
        data={dataresult}
      />
     
      <TransferToEmployeeDialog
        open={transferrequest}
        onClose={() => settransferrequest(false)}
        onOpen={getEmployees}
        id={selected.map((item) => item)}
        data={[
          ...employeesStore?.results?.map((employee) => ({
            title: `${employee.user?.first_name} ${employee.user?.last_name}`,
            body: employee?.job?.title,
            id: employee?.id,
            picture: employee?.user?.image,
          })),
        ]}
        onGoBack={() => settransferrequest(false)}
        isPending={employeesGetResponse.isPending}
        onClickRequest={rowrequest}
        // datarequest={}
        // submitPending={clientTransferPostResponse.isPending}
        // onSubmit={handleSubmitEmployeeTransfer}
      />
      {deleteEmployeeConfirmDialog}
      {employeeDeleteResponse.successAlert}
      {employeeDeleteResponse.failAlert}
      {requestPatchResponse.successAlert}
      {requestPatchResponse.failAlert}
    </Wrapper>
      </TabPanel></TabContext>
    </>
  );
}

export default Requests;

const InfoDialog = ({ open, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const { getelementbyid } = useSelector((state) => state.fekhidmaticUsers);
  const dispatch = useDispatch();
  ///////////////////////////////

  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },

      {
        control: "middleman",
        value: data?.middleman,
        isRequired: false,
      },
      {
        control: "client_name",
        value: data?.client_name,
        isRequired: false,
      },
      {
        control: "client_number",
        value: data?.client_country_code + data?.client_number,
        isRequired: false,
      },
      {
        control: "status",
        value: data?.status,
        isRequired: false,
      },
      {
        control: "property",
        value: data?.property,
        isRequired: false,
      },
      {
        control: "code",
        value: data?.client_country_code,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;

    // handleSubmit();
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: USERFEKHEDMETAK,
    method: "get",
    successMessage: "تم  بنجاح",
  });

  // const handleSubmit = () => {
  //   unitPatchRequest({
  //     id: data.id,

  //     onSuccess: (res) => {

  //       setControl(...res.data);
  //     },
  //   });
  // };

  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // paperProps={{ height: "100%" }}
      sx={{ ".MuiPaper-root": { borderRadius: "10px" } }}
    >
      <DialogHeading>
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          {globalLang.detailofrequest[lang]}
        </Typography>
      </DialogHeading>
      <DialogContent
        sx={{ border: "1px solid gray", borderRadius: "10px", margin: "24px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.client_name[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.client_name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.client_number[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.client_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.status[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.status}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.points[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.points}
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.property[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.property}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>

      {/* <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup> */}
      {/* {unitPatchResponse.failAlert} */}
    </Dialog>
  );
};

// const ParamDialog = ({ open, onClose = () => {}, data = {} }) => {
//   // Change lang
//   const lang = useSelector((state) => state.lang.value.lang);
//   const globalLang = useSelector((state) => state.lang.value.global);
//   const { getelementbyid } = useSelector((state) => state.fekhidmaticUsers);
//   const dispatch = useDispatch();
//   const [dataresult, setdataresult] = useState(data ? data : {});
//   ///////////////////////////////

//   const [{ controls }, { setControl, resetControls }] = useControls(
//     [
//       {
//         control: "id",
//         value: data?.id,
//         isRequired: false,
//       },

//       {
//         control: "middleman",
//         value: data?.middleman,
//         isRequired: false,
//       },
//       {
//         control: "client_name",
//         value: data?.client_name,
//         isRequired: false,
//       },
//       {
//         control: "client_number",
//         value: data?.client_number,
//         isRequired: false,
//       },
//       {
//         control: "status",
//         value: data?.status,
//         isRequired: false,
//       },
//       {
//         control: "property",
//         value: data?.property,
//         isRequired: false,
//       },
//       {
//         control: "code",
//         value: data?.country_code,
//         isRequired: false,
//       },
//     ],
//     [data]
//   );

//   useAfterEffect(() => {
//     if (!open) return;

//     // handleSubmit();
//   }, [open]);

//   const [unitPatchRequest, unitPatchResponse] = useRequest({
//     path: USERFEKHEDMETAK,
//     method: "get",
//     successMessage: "تم  بنجاح",
//   });

//   // const handleSubmit = () => {
//   //   unitPatchRequest({
//   //     id: data.id,

//   //     onSuccess: (res) => {

//   //       setControl(...res.data);
//   //     },
//   //   });
//   // };

//   //===Start===== Get Countries logic =========

//   //===End===== Get Countries logic =========

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       paperProps={{ height: "100%" }}
//       sx={{ ".MuiPaper-root": { borderRadius: "10px" } }}
//     >
//       <DialogHeading>
//         <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
//           {globalLang.detailUserData[lang]}
//         </Typography>
//       </DialogHeading>
//       <DialogContent
//         sx={{ border: "1px solid gray", borderRadius: "10px", margin: "24px" }}
//       >
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{ border: "none" }}>
//                 {globalLang.client_name[lang]}
//               </TableCell>
//               <TableCell color="primary" sx={{ border: "none" }}>
//                 {controls.client_name}
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               <TableCell sx={{ border: "none" }}>
//                 {globalLang.client_number[lang]}
//               </TableCell>
//               <TableCell color="primary" sx={{ border: "none" }}>
//                 {controls.client_number}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell sx={{ border: "none" }}>
//                 {globalLang.status[lang]}
//               </TableCell>
//               <TableCell color="primary" sx={{ border: "none" }}>
//                 {controls.status}
//               </TableCell>
//             </TableRow>
//             {/* <TableRow>
//               <TableCell sx={{ border: "none" }}>
//                 {globalLang.points[lang]}
//               </TableCell>
//               <TableCell color="primary" sx={{ border: "none" }}>
//                 {controls.points}
//               </TableCell>
//             </TableRow> */}
//             <TableRow>
//               <TableCell sx={{ border: "none" }}>
//                 {globalLang.property[lang]}
//               </TableCell>
//               <TableCell color="primary" sx={{ border: "none" }}>
//                 {controls.property}
//               </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </DialogContent>

//       {/* <DialogButtonsGroup>
//         <DialogButton variant="close" onClick={onClose}>
//           {globalLang.cancel[lang]}
//         </DialogButton>
//       </DialogButtonsGroup> */}
//       {/* {unitPatchResponse.failAlert} */}
//     </Dialog>
//   );
// };

const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [checkState,setCheckState]=useState(false)
  ///////////////////////////////
  // const [countriesData, setCountriesData] = useState([]);

  const [GetRequests, GetResponses] = useRequest({
    path: REQUEST,
    method: "get",
  });

  // const getCountries = () => {
  //   if (countriesData.length) return;
  //   countriesGetRequest({
  //     onSuccess: (res) => {
  //       setCountriesData(res.data.data);
  //     },
  //   });
  // };
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      {
        control: "call_date",
        value: data?.call_date ? format(data?.call_date) : "",
        isRequired: false,
      },
      {
        control: "client_name",
        value: data?.client_name,
        isRequired: false,
      },
      {
        control: "client_number",
        value: data?.client_number,
        isRequired: false,
      },
      {
        control: "middleman",
        value: data?.middleman,
        isRequired: false,
      },
      {
        control: "property",
        value: data?.property,
        isRequired: false,
      },
      {
        control: "status",
        value: data?.status,
        isRequired: false,
      },
      {
        control: "code",
        value: data?.client_country_code ? data?.client_country_code : "+20",
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
    
  }, [open]);
  useAfterEffect(()=>{
    if(controls.status==="pending"){
    setCheckState(false)

    }else{
    setCheckState(true)

    }
  },[])
  const [requestPatchRequest, requestPatchResponse] = useRequest({
    path: REQUEST,
    method: "patch",
    successMessage: globalLang.successedit[lang],
  });
  // const saleTypes = [
  //   {
  //     en: "sell",
  //     ar: "بيع",
  //   },
  //   {
  //     en: "rent",
  //     ar: "ايجار",
  //   },
  // ];
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const isThereChange = compare(
      [
        // [controls.call_date, data?.call_date, "call_date"],
        [controls.client_name, data?.client_name, "client_name"],
        [controls.client_number, data?.client_number, "client_number"],
        [controls.middleman, data?.middleman, "middleman"],
        [controls.property, data?.property, "property"],
        [controls.status, data?.status, "status"],
      ],
      false
    );
    if (isThereChange.nochange) {
      const requestBody = filter({
        obj: {
          // call_date: isThereChange.array["call_date"],
          client_name: isThereChange.array["client_name"],
          client_number: isThereChange.array["client_number"],
          // password: controls?.password,
          middleman: isThereChange.array["middleman"],
          property: isThereChange.array["property"],
          status: isThereChange.array["status"],
        },
        output: "formData",
      });

      requestPatchRequest({
        id: data?.id,
        body: requestBody,params:{all:"true"},
        onSuccess: (res) => {
          dispatch({
            type: "fekhrequests/putItem",
            payload: { id: data?.id, item:{
              call_date: res.data.call_date,
              client_country_code: data?.client_country_code,
              client_name: data?.client_name,
              client_number: data?.client_number,
              employee_name: res.data.employee_name,
              id: data?.id,
              middleman: res.data.middleman,
              property: data?.property,
              status: res.data.status,
              user: data?.user,
            }, },
          });
          resetControls();
          onClose();
        },
      });
    } else {
      // resetControls();
      onClose();
    }
  };

  //===Start===== Get Countries logic =========
  // const [countriesData, setCountriesData] = useState([]);

  // const [governoratesData, setGovernoratesData] = useState([]);

  // const [statesGetRequest, statesGetResponse] = useRequest({
  //   path: STATES,
  //   method: "post",
  // });

  // const getGovernorates = () => {
  //   if (governoratesData.length) return;
  //   statesGetRequest({
  //     body: {
  //       country: controls.country,
  //     },
  //     onSuccess: (res) => {
  //       setGovernoratesData(res.data.data);
  //     },
  //   });
  // };
  //===End===== Get Countries logic =========

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "100%" }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "750px",
          },
        }}
      >
        <DialogHeading>{globalLang.editRequestData[lang]}</DialogHeading>
        <DialogForm>
          {/* <DialogSelectField
            label={globalLang.country[lang]}
            placeholder={globalLang.country[lang]}
            // onOpen={getCountries}
            renderValue={(selected) => {
            
              return countriesData.find((mean) => mean === selected);
            }}
            value={controls.country}
            onChange={(e) => {
              setControl("country", e.target.value);
              // setControl("governorate", "");
              // setControl("city", "");
            }}
          >
            {countriesData.map((country, index) => (
              <MenuItem value={country} key={`country ${index}`}>
                {country}
              </MenuItem>
            ))}
          </DialogSelectField> */}
          {/* <DialogSelectField
            label={globalLang.governorate[lang]}
            placeholder={globalLang.governorate[lang]}
            // onOpen={getGovernorates}
            renderValue={(selected) => {
              return governoratesData.find((mean) => mean === selected);
            }}
            value={controls.state}
            onChange={(e) => {
              setControl("state", e.target.value);
              setControl("city", "");
            }}
          >
            {governoratesData.map((country, index) => (
              <MenuItem value={country} key={`country ${index}`}>
                {country}
              </MenuItem>
            ))}
          </DialogSelectField> */}

          {/* </DialogInputField> */}
          <DialogInputField
            label={globalLang.client_name[lang]}
            placeholder={globalLang.client_name[lang]}
            // onOpen={getGovernorates}
            // isPending={statesGetResponse.isPending}
            value={controls.client_name}
            onChange={(e) => {
              setControl("client_name", e.target.value);
            }}
            type="text"
            disabled
          />

          <DialogSelectField
            label={globalLang.middleman[lang]}
            placeholder={globalLang.middleman[lang]}
            value={controls.middleman}
            onChange={(e) => {
              setControl("middleman", e.target.value);
            }}
          >
            {["you", "company"].map((state, index) => (
              <MenuItem value={state} key={`state ${index}`}>
                {state}
              </MenuItem>
            ))}
          </DialogSelectField>
          <DialogInputField
            label={globalLang.property[lang]}
            placeholder={globalLang.property[lang]}
            value={controls.property}
            onChange={(e) => setControl("property", e.target.value)}
            type="text"
            disabled
          />

          <DialogPhoneField
            placeholder={globalLang.client_number[lang]}
            label={globalLang.client_number[lang]}
            selectProps={{
              value: controls.code,
              onChange: (e) => setControl("code", e.target.value),
            }}
            value={controls.client_number}
            onChange={(e) => setControl("client_number", e.target.value)}
          />
          <DialogSelectField
            label={globalLang.status[lang]}
            placeholder={globalLang.status[lang]}
            value={controls.status}
            onChange={(e) => {
              setControl("status", e.target.value);
            }}
            disabled={checkState}
          >
            {["pending", "completed"].map((state, index) => (
              <MenuItem value={state} key={`state ${index}`}>
                {state}
              </MenuItem>
            ))}
          </DialogSelectField>
        </DialogForm>
        <DialogButtonsGroup
          sx={{
            flex: "1 1 auto",
            height: "200px",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogButton
            onClick={handleSubmit}
            disabled={requestPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
      </Dialog>

      {requestPatchResponse.failAlert}
      {requestPatchResponse.successAlert}
    </>
  );
};

const ParamDialog = ({ open, onClose = () => {}, data }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const [rows, setrows] = useState([]);
  let dispatch = useDispatch();
  const globalLang = useSelector((state) => state.lang.value.global);
  let imageunit = useSelector((state) => state.addimageusnit?.files);
  const [dataresult, setdataresult] = useState({});
  let [imageId, setimageid] = useState(null);
  const [countriesData, setCountriesData] = useState([]);
  const clickref = useRef();
  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    if (countriesData.length) return;
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };
  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      {
        control: "agent",
        value: data?.agent,
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },
      {
        control: "phone_client",
        value: data?.phone_client,
        isRequired: false,
      },
      {
        control: "countryCode",
        value: data?.phone_client_country_code,
        isRequired: false,
      },
      {
        control: "code",
        value: data?.country_code,
        isRequired: false,
      },
      {
        control: "client",
        value: data?.client,
        isRequired: false,
      },
      {
        control: "address",
        value: data?.address,
        isRequired: false,
      },
      {
        control: "country",
        value: data?.country,
        isRequired: false,
      },
      {
        control: "images",
        value: [],
        isRequired: false,
      },
      {
        control: "city",
        value: data?.city,
        isRequired: false,
      },

      {
        control: "part",
        value: data?.part,
        isRequired: false,
      },
      {
        control: "created_by",
        value: data?.created_by,
        isRequired: false,
      },
      {
        control: "area_size",
        value: data?.area_size,
        isRequired: false,
      },
      {
        control: "area",
        value: data?.area,
        isRequired: false,
      },
      {
        control: "price",
        value: data?.price,
        isRequired: false,
      },
      {
        control: "category",
        value: data?.category,
        isRequired: false,
      },
      {
        control: "comment",
        value: data?.comment,
        isRequired: false,
      },
      {
        control: "floor_number",
        value: data?.floor_number,
        isRequired: false,
      },
      {
        control: "flat_number",
        value: data?.flat_number,
        isRequired: false,
      },
      {
        control: "unit_type",
        value: data?.unit_type,
        isRequired: false,
      },
      {
        control: "complete_type",
        value: data?.complete_type,
        isRequired: false,
      },
      {
        control: "sales_type",
        value: data?.sales_type,
        isRequired: false,
      },
      {
        control: "property_images",
        value: data?.property_files,
        isRequired: true,
      },{
        control: "rooms",
        value: data?.room_number,
        isRequired: false,
      },
      {
        control: "bathrooms",
        value: data?.bath_count,
        isRequired: false,
      },{
        control: "kitchen_count",
        value: data?.kitchen_count,
        isRequired: false,
      },
    ],
    [data]
  );
  // const [unitPatchRequest, unitPatchResponse] = useRequest({
  //   path: UNITS_FEKHEDMETAK + data,
  //   method: "get",
  //   successMessage: globalLang.success[lang],
  // });

  // const handleSubmit = () => {
  //   unitPatchRequest({
  //     onSuccess: (res) => {
  //       setdataresult({ ...res.data });
  //       dispatch({ type: "imageunit/set", payload: res.data.property_files });
  //     },
  //   });
  // };

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  // useEffect(() => {
  //   handleSubmit();
  // }, [data]);
  const [addimageRequest, addimageResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + data?.id + "/files/",
    method: "post",
    successMessage: globalLang.success[lang],
  });

  const addimagesubmit = (e) => {
    const requestBody = filter({
      obj: {
        file: e,
      },
      output: "formData",
    });
    addimageRequest({
      body: requestBody,
      onSuccess: (res) => {
        dispatch({ type: "imageunit/addItem", payload: res.data });
      },
    });
  };

  const [deleteimageRequest, deleteimageResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + data?.id + "/files/" + imageId + "/",
    method: "delete",
    successMessage: globalLang.deletesuccess[lang],
  });

  const deleteimagesubmit = (e) => {
    deleteimageRequest({
      onSuccess: (res) => {
        dispatch({ type: "imageunit/deleteItem", payload: { id: imageId } });
      },
    });
  };
  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========
  useAfterEffect(() => {
    deleteimagesubmit();
  }, [imageId]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "100%" }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "16px",
            minWidth: "750px",
            padding: "24px",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          {globalLang.detailUnitData[lang]}
        </Typography>
        <Typography variant="div" sx={{ display: "flex", flexWrap: "wrap" }}>
          {imageunit &&
            imageunit.map((item, index) => (
              <Typography
                sx={{
                  position: "relative",
                  width: "20%",
                  height: "20%",
                  marginLeft: lang === "ar" ? "20px" : "",
                  marginRight: lang === "en" ? "20px" : "",
                }}
              >{["mp4","MOV","wmv","webm","mpeg","WMV","WEBM","MPEG"].includes(item.file.split(".").pop().trim())?<video width="150px" height="100" controls>
              <source src={item.file}></source>
            </video>:<img
                src={item.file}
                alt=""
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "black",
                  height: "100px",
                }}
              />}
               
                <MuiIconButton
                  color="#f8c6c6"
                  iconColor="red"
                  onClick={(event) => {
                    setimageid(item.id);
                    // event.stopPropagation();
                    // onDelete(event, row);
                  }}
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    left: lang === "en" ? "78%" : "",
                    right: lang === "ar" ? "78%" : "",
                    backgroundColor: "#f8c6c6",
                    color: "red",
                  }}
                >
                  <DeleteIcon sx={{ width: 20, height: 20 }} />
                </MuiIconButton>
              </Typography>
            ))}
          <Typography
            variant="lable"
            sx={{
              backgroundColor: (theme) => theme.palette.grey.main,
              borderRadius: "5px",

              height: "100px",
              width: "100px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: lang === "ar" ? "20px" : "",
              marginRight: lang === "en" ? "20px" : "",
              color: (theme) => theme.palette.primary.main,
              cursor: "pointer",
            }}
            onClick={() => clickref.current.click()}
          >
            <input
              id="fileInput"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                if (Object.keys(e.target.files).length < 1) {
                  alert("قم باضافه صوره  ");
                  e.target.value = [];
                  return;
                }
                setControl("images", [
                  ...Object.keys(e.target.files).map(
                    (key) => e.target.files[key]
                  ),
                ]);
                addimagesubmit([
                  ...Object.keys(e.target.files).map(
                    (key) => e.target.files[key]
                  ),
                ]);
              }}
              multiple
              ref={clickref}
            />
            <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
              {globalLang.add[lang]}
            </label>
          </Typography>
        </Typography>
        <DialogContent sx={styledialog}>
          <Table
            border={0}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.area[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.area_size}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={stylerow}>{globalLang.address[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.unitType[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.unit_type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.buildingNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.flat_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.saleType[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.sales_type}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.roomsNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.rooms}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.bathroomsNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.bathrooms}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.area[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.area_size}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.price[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.price}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogContent sx={styledialog}>
          <Table
            border={0}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.customerName[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.client}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={stylerow}>{globalLang.agent[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.agent}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.customerPhone[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.phone_client}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.notes[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.comment}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.createdAt[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.created_at}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>

      {addimageResponse.failAlert}

      {addimageResponse.successAlert}
      {deleteimageResponse.failAlert}
      {deleteimageResponse.successAlert}
    </>
  );
};

const TransferToEmployeeDialog = ({
  open,
  onOpen = () => {},
  onClose = () => {},
  onGoBack = () => {},
  isPending = false,
  submitPending = false,
  id,
  data = [],
  initSelected,
  onSubmit = () => {},
  onClickRequest,
}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const resquest = useSelector((state) => state.Fekhidmaticrequests.value);
  ///////////////////////////////
  const [selected, setSelected] = usePropState(initSelected, [initSelected]);
  const [searchValue, setSearchValue] = useState("");
  const [method, setMethod] = useState(1);
  const dispatch = useDispatch();
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const [requestPatchRequest, requestPatchResponse] = useRequest({
    path: REQUEST,
    method: "patch",
    successMessage: globalLang.successedit[lang],
  });
  
  const handleSubmit = () => {
    requestPatchRequest({
      id: onClickRequest.id,
      params:{all:"true"},
      body: {
        employee: selected,
      },
      onSuccess: (res) => {
        dispatch({
          type: "fekhrequests/putItem",
          payload: { id: onClickRequest?.id, item:{
            call_date: res.data.call_date,
            client_country_code: onClickRequest?.client_country_code,
            client_name: onClickRequest?.client_name,
            client_number: onClickRequest?.client_number,
            employee_name: res.data.employee_name,
            id: onClickRequest?.id,
            middleman: res.data.middleman,
            property: onClickRequest?.property,
            status: res.data.status,
            user: onClickRequest?.user,
          }  },
        });
        onClose();
      },
    });
  };
  useAfterEffect(() => {
    if (!open) return;


    onOpen();
  }, [open]);

  // const handleSubmit = () => {
  //     const requestBody = filter({
  //       obj: {
  //         employee:
  //       },
  //       output: "formData",
  //     });
  //     console.log(requestBody);
  //     requestPatchRequest({
  //       id: data?.id,
  //       body: requestBody,
  //       onSuccess: (res) => {
  //         dispatch({
  //           type: "fekhrequests/putItem",
  //           payload: { id: res.data.id, item: res.data },
  //         });
  //         // resetControls();
  //         onClose();
  //       },
  //     });

  // };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        paperProps={{
          maxWidth: 450,
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#233975",
          },
        }}
      >
        <DialogHeading onGoBack={onGoBack}>
          <Typography sx={{color:"#fff !important"}}>

            {globalLang.TransferEmployee[lang]}
          </Typography>
        </DialogHeading>
        <DialogPeopleWindow
          isPending={isPending}
          searchValue={searchValue}
          onSearch={handleChangeSearchValue}
          sx={{ height: 400 }}
        >
          {data
            ?.filter(
              (item) =>
                item.title.toLowerCase().includes(searchValue) ||
                item?.body?.toLowerCase().includes(searchValue)
            )
            ?.map((item, index) => (
              <DialogSelectItem
                key={`selectEmployeeItem ${index}`}
                selected={item.id === selected}
                picture={item.picture}
                title={item.title}
                body={item.body}
                onClick={() => setSelected(item.id)}
              />
            ))}
        </DialogPeopleWindow>
        {/* <PermissionsGate permissions={["delete_historicalaqarclient"]}>
        <DialogContent>
          <FormGroup>
            <RadioGroup
              name="transfer-method"
              value={method}
              onChange={(e) => setMethod(e.target.value)}
            >
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      color: "white",
                      "& *": {
                        color: "white",
                      },
                    }}
                  />
                }
                label={globalLang.sameLevel[lang]}
                value={1}
              />
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      color: "white",
                      "& *": {
                        color: "white",
                      },
                    }}
                  />
                }
                label={globalLang.deleteRecords[lang]}
                value={0}
              />
            </RadioGroup>
          </FormGroup>
        </DialogContent>
      </PermissionsGate> */}
        <DialogButtonsGroup>
          <DialogButton onClick={handleSubmit} disabled={submitPending}>
            {globalLang.submit[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
      </Dialog>
      {requestPatchResponse.successAlert}
      {requestPatchResponse.failAlert}
    </>
  );
};

TransferToEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  isPending: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      picture: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.string,
    })
  ),
  onClickRequest: PropTypes.func,
};
