import {
  Avatar,
  Container,
  Dialog,
  DialogContent,
  IconButton as MuiIconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  TextField,
  Box,
  InputLabel,
  Tab,
  Badge,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputField, PhoneField } from "../../features/form";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  CLIENTS_FEkhedmetak,
  COUNTRY_FILTER,
  EMPLOYEES,
  EMPLOYEES_PERMISSIONS,
  JOBS,
  JOB_PERMISSIONS,
  MEANSOFCOMFORT,
  STATES,
  UNITS_FEKHEDMETAK,
} from "../../data/APIs";
import useDataGrid from "../../hooks/useDataGrid";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import Zoom from "react-reveal/Zoom";
import Jump from "react-reveal/Jump";
import {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInputField,
  DialogNumberField,
  DialogPhoneField,
  DialogSelectField,
} from "../../features/dialog";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PermissionToggles from "../../components/PermissionToggles";
import useControls from "../../hooks/useControls";
import useAfterEffect from "../../hooks/useAfterEffect";
import compare from "../../utils/Compare";
import filter from "../../utils/ClearNull";
import { styledialog, stylerow, styletd } from "../../css/tableview";
import { add, max } from "lodash";
import DialogCheckboxField from "../../features/dialog/components/DialogCheckbox";
import useSelectMessage from "../../hooks/useSelectMessage";
import { useRef } from "react";
import Wrapper from "../../components/Wrapper";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AutocompleteField from "../../features/form/components/AutocompleteField";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import usePropState from "../../hooks/usePropState";
function Units() {
  const unitsStore = useSelector((state) => state.fekhidmaticUnits.value);
  let imageunit = useSelector((state) => state.addimageusnit?.files);
  const countrys=useSelector((state)=>state.countryslice.value)
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.value.lang);
  const [countriesData, setCountriesData] = useState([]);
  
  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    
    countriesGetRequest({
      onSuccess: (res) => {
        
        setCountriesData(res.data.data);
      },
    });
  };
  const viewEmployeesLang = useSelector(
    (state) => state.lang.value.viewEmployees
  );
  // const confirmMessage = useSelector(
  //   (state) => state.lang.value.DeleteMessages.EmployeeMessage
  // );

  const globalLang = useSelector((state) => state.lang.value.global);
  const [editData, setEditData] = useState(null);
  const [infoData, setInfoData] = useState(null);
  const [clientGetRequest, clientGetResponse] = useRequest({
    path: UNITS_FEKHEDMETAK,
    method: "get",
  });
  const handleGetUnit = (urlParams) => {
    clientGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "fekhunits/set", payload: res.data });
      },
    });
  };
  const NameFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      onChange({
        query: [nameQuery, e.target.value],
        renderedValue: e.target.value,
        value: e.target.value,
      });
    };

    return (
      <InputField
        placeholder={nameQuery}
        value={value}
        onChange={handleChange}
        type={type}
      />
    );
  };
  const NumberFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
      const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0); // Ensure value is not NaN and greater than or equal to 0

      onChange({
        query: [nameQuery, newValue],
        renderedValue: newValue,
        value: newValue,
      });
    };

    return (
      <InputField
        placeholder={nameQuery}
        value={value}
        onChange={handleChange}
        type={type}
      />
    );
  };

  const SelectFilter = ({
    value,
    onChange,
    nameoffiter,
    arrayoption,
    functiontofetch
    // isPending=true
  }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      onChange({
        query: [nameoffiter, e.target.value],
        renderedValue: e.target.value,
        value: e.target.value,
      });
    };
   
    return (
      <>
        <DialogSelectField
          onOpen={functiontofetch}
          placeholder={value}
          // isPending={isPending}
          onChange={handleChange}
          renderValue={(selected) => {
            return arrayoption.find((mean) => mean === selected);
          }}
        >
          {arrayoption?.map((ele) => {
            return <MenuItem value={ele}>{ele}</MenuItem>;
          })}
        </DialogSelectField>
      </>
      // <InputField
      //   placeholder={globalLang.name[lang]}
      //   value={value}
      //   onChange={handleChange}
      // />
    );
  };
  const { handlePaginate, handleChangeAmount, handleFilter, requestParams } =
    useDataGrid({
      onParamsChange: handleGetUnit,
    });
  const deleteEmployee = (e, row) => {
    // if (Boolean(row.client_count)) {
    //   handlePreventDeleteEmployee();
    //   return;
    // }
    employeeDeteleRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "fekhunits/deleteItem", payload: { id: row.id } });
      },
    });
  };
  const [handleDeleteEmployee, deleteEmployeeConfirmDialog] = useConfirmMessage(
    {
      onConfirm: deleteEmployee,
      text: globalLang.makesureofdelete[lang],
    }
  );
  const [employeeDeteleRequest, employeeDeleteResponse] = useRequest({
    path: UNITS_FEKHEDMETAK,
    method: "delete",
    successMessage: globalLang.deletesuccess[lang],
  });

  const [openEditInfo, setOpenEditInfo] = useState(null);

  const handleOpenEdit = (e, row) => {
    setOpenEditInfo(row);
  };

  // const [blockEmployeePostRequest] = useRequest({
  //   path: BLOCK_EMPLOYEE,
  //   method: "post",
  // });

  // const handleBlockEmployee = (e, row) => {
  //   blockEmployeePostRequest({
  //     body: {
  //       agent: row.id,
  //     },
  //     onSuccess: (res) => {
  //       dispatch({ type: "employees/blockItem", payload: { id: row.id } });
  //     },
  //   });
  // };
  const [gotoclientRequest, gotoclientResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + "transfer/",
    method: "post",
    successMessage: globalLang.successtransfer[lang],
  });
  const transferunit = (e, row, result) => {
    gotoclientRequest({
      id: row.id,
      body: result,
      onSuccess: (res) => {
        if (result.deleted) {
          dispatch({ type: "fekhunits/deleteItem", payload: { id: row.id } });
        } else {
          dispatch({ type: "fekhunits/set", payload: unitsStore });
          const urlParams = new URLSearchParams();

          Object.values(requestParams).map((item) =>
            item.map(([key, value]) => {
              if (!Array.isArray(key)) {
                urlParams.append(key, value);
              } else {
                urlParams.append(key[0], key[1]);
                urlParams.append(value[0], value[1]);
              }
            })
          );
          // urlParams.append
          delete requestParams.currentPage;
          handleGetUnit(urlParams);
        }
      },
    });
  };

  const [handleparent, gotoparent, result] = useSelectMessage({
    onConfirm: (e, row) => transferunit(e, row, result),
    text: globalLang.makesureoftransfer[lang],
    is_aqar_: "is_aqar_property",
    // is_aqar_:"is_aqar_pro"
  });
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];
  const [openEditPassword, setOpenEditPassword] = useState(null);

  const userInfo = useSelector((state) => state.userInfo.value);

  const isPermitted = useIsPermitted();

  // console.log(unitsStore.results);
  let columns = [
    {
      field: "main_image",
      headerName: globalLang.main_image[lang],
      customContent: (params) => {
        return (
          <Avatar
            src={params?.main_image}
            variant="rounded"
            sx={{ width: 90, height: 90 }}
          >
            U
          </Avatar>
        );
      },
      customEmpty: "-",
    },
    {
      field: "city",
      headerName: globalLang.city[lang],
      customContent: (params) => params?.city,
      customEmpty: "-",
    },
    {
      field: "area",
      headerName: globalLang.region[lang],
      customContent: (params) => params?.area,
      customEmpty: "-",
    },
    {
      field: "address",
      headerName: globalLang.address[lang],
      customContent: (params) => params?.address,
      customEmpty: "-",
    },
    {
      field: "unit_type",
      headerName: globalLang.unitType[lang],
      customContent: (params) => params?.unit_type,
      customEmpty: "-",
    },
    {
      field: "floor_number",
      headerName: globalLang.floor[lang],
      customContent: (params) => params?.floor_number,
      customEmpty: "-",
    },
    {
      field: "area_size",
      headerName: globalLang.area[lang],
      customContent: (params) => params?.area_size,
      customEmpty: "-",
    },
    {
      field: "complete_type",
      headerName: globalLang.finishingType[lang],
      customContent: (params) => params?.complete_type,
      customEmpty: "-",
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: (params) => params?.price,
      customEmpty: "-",
    },

    // {
    //   field: "agent",
    //   headerName: globalLang.agent[lang],
    //   customContent: (params) => params?.agent,

    //   // custom
    // },
    {
      field: "phone",
      headerName: globalLang.phone[lang],
      customContent: (params) => params.country_code + params?.phone,
      customEmpty: "-",
    },
    {
      field: "phone_client",
      headerName: globalLang.phone_client[lang],
      customContent: (params) =>
        params.phone_client_country_code + params?.phone_client,
      customEmpty: "-",
    },
    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: (params) => params?.client,
      customEmpty: "-",
    },

    {
      field: "country",
      headerName: globalLang.country[lang],
      customContent: (params) => params?.country,
      customEmpty: "-",
    },
    {
      field: "state",
      headerName: globalLang.governorate[lang],
      customContent: (params) => params?.state,
      customEmpty: "-",
    },

    {
      field: "part",
      headerName: globalLang.part[lang],
      customContent: (params) => params?.part,
      customEmpty: "-",
    },

    {
      field: "category",
      headerName: globalLang.category[lang],
      customContent: (params) => params?.category,
      customEmpty: "-",
    },
    {
      field: "comment",
      headerName: globalLang.notes[lang],
      customContent: (params) => params?.comment,
      customEmpty: "-",
    },

    {
      field: "sales_type",
      headerName: globalLang.saleType[lang],
      customContent: (params) => params?.sales_type,
      customEmpty: "-",
    },
    {
      field: "is_public",
      headerName: globalLang.isPublic[lang],
      customContent: (params) =>
      params.is_sold ?"تم البيع":params.is_public? <CheckIcon /> : <CloseIcon />,
        
    },
    {
      field: "created_by_username",
      headerName: globalLang.createdByUserName[lang],
      customContent: (params) => params.created_by_username,
      customEmpty: "-",
    }, {
      field: "created_by_phone",
      headerName: globalLang.createdByPhone[lang],
      customContent: (params) => params.created_by_phone,
      customEmpty: "-",
    },
    {
      field: "created_by_name",
      headerName: globalLang.createdByName[lang],
      customContent: (params) => params.created_by_name,
      customEmpty: "-",
    },
    // {
    //   field: "picture",
    //   headerName: globalLang.picture[lang],
    //   customContent: (params) => {
    //     return (
    //       <Avatar
    //         src={params?.property_files[0]?.file}
    //         variant="rounded"
    //         sx={{ width: "90px", height: "90px" }}
    //       >
    //         U
    //       </Avatar>
    //     );
    //   },
    // },
    // {
    //   field:"property_files",
    //   headerName:globalLang.picture[lang],
    //   customContent: (params) =>  params.property_files
    // }
    // {field:"kitchen_count",headerName:globalLang.kitchen_count[lang],customContent:(params)=>params.kitchen_count}
  ];
  const PriceFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const [minPrice, setminPrice] = useState("");
    const [maxPrice, setmaxPrice] = useState("");

    const formatDate = (date) => {
      return date?.split("-").reverse().join("/");
    };

    useEffect(() => {
      onChange({
        query: [
          ["price_min", `${String(minPrice)}`],
          ["price_max", `${String(maxPrice)}`],
        ],
        renderedValue: `${globalLang.from[lang]} ${String(minPrice)} - ${
          globalLang.to[lang]
        } ${String(maxPrice)}`,
        value: {
          start: minPrice,
          end: maxPrice,
        },
      });
    }, [minPrice, maxPrice]);
   
    const handleChangeStartPoint = (e) => {
      const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
      const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0);
      setminPrice(newValue);
    };

    const handleChangeEndPoint = (e) => {
      const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
      const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0);
      setmaxPrice(newValue);
    };

    return (
      <Stack spacing={2}>
        <TextField
          variant="standard"
          type="number"
          label={globalLang.from[lang]}
          value={minPrice}
          onChange={handleChangeStartPoint}
        />
        <TextField
          variant="standard"
          type="number"
          label={globalLang.to[lang]}
          value={maxPrice}
          onChange={handleChangeEndPoint}
        />
      </Stack>
    );
  };
  const filters = [
    {
      name: globalLang.client[lang],
      component: <NameFilter nameQuery={"client"} />,
    },
    // {
    //   name: globalLang.id[lang],
    //   component: <NameFilter nameQuery={"id"} type="number"/>,
    // },
    // {
    //   name: globalLang.agent[lang],
    //   component: <NameFilter nameQuery={"agent"} />,
    // },
    {
      name: globalLang.address[lang],
      component: <NameFilter nameQuery={"address"} />,
    },
    {
      name: globalLang.country[lang],
      component: (
        <SelectFilter
          nameoffiter={"country"}
          arrayoption={countriesData}
          functiontofetch={getCountries}
          // isPending={countriesGetResponse.isPending}
        />
      ),
    },
    {
      name: globalLang.city[lang],
      component: <NameFilter nameQuery={"city"} />,
    },
    {
      name: globalLang.part[lang],
      component: <NameFilter nameQuery={"part"} />,
    },
    // {
    //   name: globalLang.created_by[lang],
    //   component: <NameFilter nameQuery={"created_by_name"} />,
    // },
    {
      name: globalLang.area[lang],
      component: <NumberFilter nameQuery={"area_size"} type="number" />,
    },
    {
      name: globalLang.price[lang],
      component: <PriceFilter />,
    },
    {
      name: globalLang.category[lang],
      component: (
        <SelectFilter
          nameoffiter={"category"}
          arrayoption={["resale", "sharing", "projects"]}
        />
      ),
    },
    {
      name: globalLang.floor[lang],
      component: (
        <SelectFilter
          nameoffiter={"floor_number"}
          arrayoption={floors.map((ele) => ele.value)}
        />
      ),
    },
    {
      name: globalLang.unitType[lang],
      component: <NameFilter nameQuery={"unit_type"} />,
    },
    {
      name: globalLang.finishingType[lang],
      component: <NameFilter nameQuery={"complete_type"} />,
    },
    {
      name: globalLang.saleType[lang],
      component: (
        <SelectFilter
          nameoffiter={"sales_type"}
          arrayoption={["sell", "rent"]}
        />
      ),
    },
    // {
    //   name: globalLang.job[lang],
    //   component: <JobFilter />,
    // },
  ];
  useAfterEffect(() => {}, [unitsStore]);

  useEffect(()=>{
    getCountries()
  },[])
  return (
    <>
      <Wrapper>
        <Typography
          variant="h5"
          sx={{ textAlign: lang === "ar" ? "left" : "right", marginY: "24px" }}
          color="primary"
        >
          {globalLang.units[lang]}
        </Typography>

        <DataGrid
          columns={columns}
          rows={unitsStore.results}
          total={unitsStore.count}
          isPending={clientGetResponse.isPending}
          onDelete={handleDeleteEmployee}
          // onBlock={handleBlockEmployee}
          // onChangePassword={(e, row) => setOpenEditPassword(row.id)}
          onEdit={(e, row) => setEditData(row)}
          onView={(e, row) => setInfoData(row)}
          onPaginate={handlePaginate}
          onAmountChange={handleChangeAmount}
          onFilter={handleFilter}
          filters={filters}
          onTransfer={handleparent}
        />
        <EditDialog
          open={Boolean(editData)}
          onClose={() => setEditData(null)}
          data={editData}
        />
        <InfoDialog
          open={Boolean(infoData)}
          onClose={() => setInfoData(null)}
          data={infoData}
        />
        {deleteEmployeeConfirmDialog}
        {employeeDeleteResponse.successAlert}
        {employeeDeleteResponse.failAlert}

        {gotoclientResponse.failAlert}
        {gotoclientResponse.successAlert}
        {gotoparent}
        {/* <EditInfoDialog
          open={Boolean(openEditInfo)}
          onClose={() => setOpenEditInfo(null)}
          data={openEditInfo}
        /> */}
      </Wrapper>
    </>
  );
}

export default Units;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [meanspfcomfort, setMeansOfConfort] = useState([]);
  let imageunit = useSelector((state) => state.addimageusnit?.files);
  let [imageId, setimageid] = useState(null);
  ///////////////////////////////
  // const [countriesData, setCountriesData] = useState([]);
  const addPictureRef = useRef(null);
  const changePictureRef = useRef(null);
  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };
  const [meansofcomfortRequest, meansofcomfortResponse] = useRequest({
    path: MEANSOFCOMFORT,
    method: "get",
    successMessage: globalLang.getamenities[lang],
  });
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      // {
      //   control: "agent",
      //   value: data?.agent,
      //   isRequired: false,
      // },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },
      {
        control: "phone_client",
        value: data?.phone_client,
        isRequired: false,
      },
      {
        control: "countryCode",
        value: data?.phone_client_country_code
          ? data?.phone_client_country_code
          : "+20",
        isRequired: false,
      },
      {
        control: "code",
        value: data?.country_code ? data?.country_code : "+20",
        isRequired: false,
      },
      {
        control: "client",
        value: data?.client,
        isRequired: false,
      },
      {
        control: "address",
        value: data?.address,
        isRequired: false,
      },
      {
        control: "country",
        value: data?.country,
        isRequired: false,
      },
      {
        control: "state",
        value: data?.state,
        isRequired: false,
      },
      {
        control: "city",
        value: data?.city,
        isRequired: false,
      },

      {
        control: "part",
        value: data?.part,
        isRequired: false,
      },
      {
        control: "created_by",
        value: data?.created_by,
        isRequired: false,
      },
      {
        control: "area_size",
        value: data?.area_size,
        isRequired: false,
      },
      {
        control: "area",
        value: data?.area,
        isRequired: false,
      },
      {
        control: "price",
        value: data?.price,
        isRequired: false,
      },
      {
        control: "category",
        value: data?.category,
        isRequired: false,
      },
      {
        control: "comment",
        value: data?.comment,
        isRequired: false,
      },
      {
        control: "floor_number",
        value: data?.floor_number,
        isRequired: false,
      },
      {
        control: "unit_type",
        value: data?.unit_type,
        isRequired: false,
      },

      {
        control: "complete_type",
        value: data?.complete_type,
        isRequired: false,
      },
      {
        control: "sales_type",
        value: data?.sales_type,
        isRequired: false,
      },
      { control: "is_public", value: data?.is_public, isRequired: false },
      { control: "password", value: data?.password, isRequired: false },
      { control: "flat_number", value: data?.flat_number, isRequired: false },
      {
        control: "rooms",
        value: data?.room_number,
        isRequired: false,
      },
      {
        control: "bathrooms",
        value: data?.bath_count,
        isRequired: false,
      },
      {
        control: "kitchen_count",
        value: data?.kitchen_count,
        isRequired: false,
      },
      {
        control: "living_room_count",
        value: data?.living_room_count,
        isRequired: false,
      },
      {
        control: "amenities_ids",
        value: data?.amenities,
        isRequired: false,
      }, {
        control: "main_image",
        value: data?.main_image,
        isRequired: false,
      },
    ],
    [data]
  );
 
  const [picturePatchRequest, picturePatchResponse] = useRequest({
    path: UNITS_FEKHEDMETAK,
    method: "patch",
    successMessage:globalLang.successedit[lang]
  });
  const handleAddPicture = (e) => {
 
    const requestBody = filter({
      obj: {
        files: Object.keys(e.target.files).map((ele)=>e.target.files[ele]),
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        // dispatch({
        //   type: "units/putItem",
        //   payload: { id: res.data.id, item: res.data },
        // });
        setPictures(res.data.property_files);
      },
    });
  };
  useAfterEffect(() => {
    if (!open) return;
    getCountries();
    getGovernorates();
    dispatch({ type: "imageunit/set", payload: data.property_files });
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: UNITS_FEKHEDMETAK,
    method: "patch",
    successMessage: globalLang.successedit[lang],
  });
  // const saleTypes = [
  //   {
  //     en: "sell",
  //     ar: "بيع",
  //   },
  //   {
  //     en: "rent",
  //     ar: "ايجار",
  //   },
  // ];
  const saleTypes = [
    {
      name: "بيع",
      value: "sale",
    },
    {
      name: "ايجار",
      value: "rent",
    },
  ];
  const dispatch = useDispatch();

  const handleSubmit = () => {

    const isThereChange = compare(
      [
        // [controls.agent, data?.agent, "agent"],
        [controls.phone_client, data?.phone_client, "phone_client"],
        [controls.state, data?.state, "state"],
        [controls.client, data?.client, "client"],
        [controls.address, data?.address, "address"],
        [controls.country, data?.country, "country"],
        [controls.main_image, data?.main_image?data?.main_image:"", "main_image"],
        [
          Array.isArray(controls.amenities_ids)
            ? controls.amenities_ids.join(",")
            : "",
          Array.isArray(data?.amenities) ? data?.amenities.join(",") : "",
          "amenities_ids",
        ],
        [controls.city, data?.city, "city"],
        [controls.part, data?.part, "part"],
        [Boolean(controls.is_public), data?.is_public, "is_public"],
        [controls.area_size, data?.area_size, "area_size"],
        [controls.area, data?.area, "area"],
        [controls.category, data?.category, "category"],
        [controls.price, data?.price, "price"],
        [controls.comment, data?.comment, "comment"],
        [controls.phone, data?.phone, "phone"],
        [controls.floor_number, data?.floor_number, "floor_number"],
        [controls.flat_number, data?.flat_number, "flat_number"],
        [controls.unit_type, data?.unit_type, "unit_type"],
        [controls.complete_type, data?.complete_type, "complete_type"],
        [controls.sales_type, data?.sales_type, "sales_type"],
        [controls.rooms, data?.room_number, "room_number"],
        [controls.bathrooms, data?.bath_count, "bath_count"],
        [controls.kitchen_count, data?.kitchen_count, "kitchen_count"],
        [
          controls.living_room_count,
          data?.living_room_count,
          "living_room_count",
        ],
      ],
      false
    );
    if (isThereChange.nochange) {
      const requestBody = filter({
        obj: {
          // agent: isThereChange.array["agent"],
          phone: isThereChange.array["country_code"]
            ? isThereChange.array["country_code"] + isThereChange.array["phone"]
              ? isThereChange.array["phone"]
              : controls.phone
            : isThereChange.array["phone"]
            ? controls.code + isThereChange.array["phone"]
            : undefined,
          phone_client: isThereChange.array["phone_client_country_code"]
            ? isThereChange.array["phone_client_country_code"] +
              isThereChange.array["phone_client"]
              ? isThereChange.array["phone_client"]
              : controls.phone
            : isThereChange.array["phone_client"]
            ? controls.countryCode + isThereChange.array["phone_client"]
            : undefined,
          // password: controls?.password,
          client: isThereChange.array["client"],
          address: isThereChange.array["address"],
          country: isThereChange.array["country"],
          city: isThereChange.array["city"],
          part: isThereChange.array["part"],
          area: isThereChange.array["area"],
          category: isThereChange.array["category"],
          area_size: isThereChange.array["area_size"],
          floor_number: isThereChange.array["floor_number"],
          flat_number: isThereChange.array["flat_number"],
          kitchen_count: isThereChange.array["kitchen_count"],
          living_room_count: isThereChange.array["living_room_count"],
          room_number: isThereChange.array["room_number"],
          bath_count: isThereChange.array["bath_count"],
          unit_type: isThereChange.array["unit_type"],
          price: isThereChange.array["price"],
          complete_type: isThereChange.array["complete_type"],
          sales_type: isThereChange.array["sales_type"],
          state: isThereChange.array["state"],
          comment: isThereChange.array["comment"],
          is_public:
            isThereChange.array["is_public"] === true ||
            isThereChange.array["is_public"] === false
              ? String(isThereChange.array["is_public"])
              : undefined,
          // is_public:
          //  ( isThereChange.array["is_public"]
          //     ? String(isThereChange.array["is_public"])
          //     : undefined,
          amenities_ids: JSON.stringify([
            ...controls.amenities_ids?.map((ele) =>
              ele.id ? ele.id : ele.value
            ),
          ]),main_image:isThereChange.array["main_image"],
        },
        output: "formData",
      });

      unitPatchRequest({
        id: data?.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "fekhunits/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          // resetControls();
          onClose();
        },
      });
    } else {
      // resetControls();
      onClose();
    }
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];
  const [governoratesData, setGovernoratesData] = useState([]);
  const [pictures, setPictures] = usePropState(data?.property_files, [data]);
  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getGovernorates = () => {
    // if (governoratesData.length) return;

    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res?.data?.data);
      },
    });
  };
  const getMeansOfComfort = () => {
    meansofcomfortRequest({
      onSuccess: (res) => {
        setMeansOfConfort(res.data.results);
      },
    });
  };
  
  const [addimageRequest, addimageResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + data?.id + "/files/",
    method: "post",
    successMessage: globalLang.success[lang],
  });

  const addimagesubmit = (e) => {
    const requestBody = filter({
      obj: {
        file: e,
      },
      output: "formData",
    });
    addimageRequest({
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "fekhunits/putImageItem",
          payload: { id: data?.id, item: res.data },
        });
        dispatch({ type: "imageunit/addItem", payload: res.data });
      },
    });
  };
  const [deleteimageRequest, deleteimageResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + data?.id + "/files/" ,
    method: "delete",
    successMessage: globalLang.success[lang],
  });

  const deleteimagesubmit = (id) => {
    deleteimageRequest({
      id:id,
      onSuccess: (res) => {
        dispatch({
          type: "fekhunits/deleteImageItem",
          payload: { id: data?.id, idimage: id },
        });
        dispatch({ type: "imageunit/deleteItem", payload: { id: id } });
      },
    });
  };
  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========
  useAfterEffect(() => {
    deleteimagesubmit();
  }, [imageId]);
  //===End===== Get Countries logic =========
  useAfterEffect(() => {
    if (controls.country) {
      getGovernorates();
    }
    // getGovernorates()
  }, [controls.country]);

  return (
    <>
    <Dialog
            open={open}
            onClose={onClose}
            paperProps={{ height: "100%" }}
            sx={{
              ".MuiPaper-root": {
                minWidth: "750px",
              },
            }}
          >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              ".MuiButtonBase-root.MuiTab-root.Mui-selected": { color: "#1a2a56" },
            }}
          >
            <Tab label={globalLang.editUnitData[lang]} value="1" />
            <Tab
              label={lang == "ar" ? " معرض الصور " : " Gallery "}
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          
            <DialogHeading>{globalLang.editUnitData[lang]}</DialogHeading>
            <DialogForm>
              <DialogSelectField
                label={globalLang.country[lang]}
                placeholder={globalLang.country[lang]}
                onOpen={getCountries}
                renderValue={(selected) => {
                  return countriesData.find((mean) => mean === selected);
                }}
                value={controls.country}
                onChange={(e) => {
                  setControl("country", e.target.value);
                  // setControl("governorate", "");
                  // setControl("city", "");
                }}
              >
                {countriesData.map((country, index) => (
                  <MenuItem value={country} key={`country ${index}`}>
                    {country}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogSelectField
                label={globalLang.governorate[lang]}
                placeholder={globalLang.governorate[lang]}
                onOpen={getGovernorates}
                // onClose={}
                renderValue={(selected) => {
                  return governoratesData.find((mean) => mean === selected);
                }}
                value={controls.state}
                onChange={(e) => {
                  setControl("state", e.target.value);
                  // setControl("city", "");
                }}
              >
                {governoratesData.map((country, index) => (
                  <MenuItem value={country} key={`state ${index}`}>
                    {country}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogSelectField
                label={globalLang.category[lang]}
                placeholder={globalLang.category[lang]}
                value={controls.category}
                onChange={(e) => {
                  setControl("category", e.target.value);
                }}
              >
                {["resale", "sharing", "projects"].map((category, index) => (
                  <MenuItem value={category} key={`category ${index}`}>
                    {category}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogSelectField
                label={globalLang.saleType[lang]}
                placeholder={globalLang.saleType[lang]}
                value={controls.sales_type}
                onChange={(e) => {
                  setControl("sales_type", e.target.value);
                }}
              >
                {saleTypes.map((saleType, index) => (
                  <MenuItem value={saleType.value} key={`saleType ${index}`}>
                    {saleType.name}
                  </MenuItem>
                ))}
              </DialogSelectField>
              {/* </DialogInputField> */}
              {/* <DialogInputField
            label={globalLang.job[lang]}
            placeholder={globalLang.job[lang]}
            // onOpen={getGovernorates}
            // isPending={statesGetResponse.isPending}
            value={controls.job}
            onChange={(e) => {
              setControl("job", e.target.value);
            }}
            type="text"
          /> */}
              <DialogInputField
                label={globalLang.city[lang]}
                placeholder={globalLang.city[lang]}
                value={controls.city}
                onChange={(e) => setControl("city", e.target.value)}
                type="text"
              />
              <DialogInputField
                placeholder={globalLang.region[lang]}
                label={globalLang.region[lang]}
                value={controls.area}
                onChange={(e) => setControl("area", e.target.value)}
              />
              <DialogInputField
          label={globalLang.addmainImage[lang]}
          placeholder={globalLang.addmainImage[lang]}
          // required={required.includes("main_image")}
          // sx={{".MuiInputBase-root input":{color:"#fff",backgroundColor:"#fff"},".MuiFormLabel-root":{
          //   color:"#fff"
          // },".MuiFormControl-root":{
          //   display:"flex"
          // }}}
          onChange={(e) =>{
            
            setControl("main_image", e.target.files[0])
            
          }
          }
          type="file"
          inputProps={{ accept: 'image/*' }}
          // value={controls.main_image}
          // error={Boolean(invalid.main_image)}
          // helperText={invalid.main_image}
        />
              {/* <DialogInputField
            label={globalLang.area[lang]}
            placeholder={globalLang.area[lang]}
            value={controls.area}
            onChange={(e) => setControl("area", e.target.value)}
            type="text"
          /> */}
              <DialogInputField
                label={globalLang.neighboring[lang]}
                placeholder={globalLang.neighboring[lang]}
                value={controls.part}
                onChange={(e) => setControl("part", e.target.value)}
                type="text"
              />
              <DialogInputField
                label={globalLang.address[lang]}
                placeholder={globalLang.address[lang]}
                value={controls.address}
                onChange={(e) => setControl("address", e.target.value)}
                type="text"
              />
              {/* <DialogInputField
            label={globalLang.agent[lang]}
            placeholder={globalLang.agent[lang]}
            value={controls.agent}
            onChange={(e) => { let text=/^(?:[A-Za-z\u0600-\u06ff\s]*)$/
            let match=text.test(e.target.value)
            if(match){
            setControl("agent", e.target.value)}
           }}
            type="text"
          /> */}
              <DialogInputField
                label={globalLang.buildingNumber[lang]}
                placeholder={globalLang.buildingNumber[lang]}
                value={controls.flat_number}
                onChange={(e) => {
                  const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
                  const newValue = isNaN(inputValue)
                    ? 0
                    : Math.max(inputValue, 0);
                  setControl("flat_number", newValue);
                }}
                type="number"
              />
              <DialogInputField
                label={globalLang.unitType[lang]}
                placeholder={globalLang.unitType[lang]}
                value={controls.unit_type}
                onChange={(e) => setControl("unit_type", e.target.value)}
                type="text"
              />
              <DialogSelectField
                placeholder={globalLang.roomsNumber[lang]}
                label={globalLang.roomsNumber[lang]}
                value={controls.rooms}
                onChange={(e) => setControl("rooms", e.target.value)}
              >
                {rooms.map((room, index) => (
                  <MenuItem value={room} key={`room ${index}`}>
                    {room}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogSelectField
                placeholder={globalLang.livingNumber[lang]}
                label={globalLang.livingNumber[lang]}
                value={controls.living_room_count}
                onChange={(e) =>
                  setControl("living_room_count", e.target.value)
                }
              >
                {rooms.map((room, index) => (
                  <MenuItem value={room} key={`room ${index}`}>
                    {room}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogSelectField
                placeholder={globalLang.bathroomsNumber[lang]}
                label={globalLang.bathroomsNumber[lang]}
                value={controls.bathrooms}
                onChange={(e) => setControl("bathrooms", e.target.value)}
              >
                {bathrooms.map((room, index) => (
                  <MenuItem value={room} key={`room ${index}`}>
                    {room}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogSelectField
                placeholder={globalLang.kitchen_count[lang]}
                label={globalLang.kitchen_count[lang]}
                value={controls.kitchen_count}
                thousandSeparator
                onChange={(e) => setControl("kitchen_count", e.target.value)}
              >
                {bathrooms.map((room, index) => (
                  <MenuItem value={room} key={`room ${index}`}>
                    {room}
                  </MenuItem>
                ))}
              </DialogSelectField>
              {/* <DialogSelectField
                label={globalLang.saleType[lang]}
                placeholder={
                  controls.sales_type ? controls.sales_type : data?.sales_type
                }
                // value={controls.sales_type}
                renderValue={controls.sales_type}
                // renderValue={(selected)=>selected}
                onChange={(e) => setControl("sales_type", e.target.value)}
              >
                {saleTypes.map((saleType, index) => (
                  <MenuItem value={saleType["en"]} key={`saleType ${index}`}>
                    {saleType["en"]}
                  </MenuItem>
                ))}
              </DialogSelectField> */}
              <DialogSelectField
                label={globalLang.floor[lang]}
                placeholder={globalLang.floor[lang]}
                value={controls.floor_number}
                renderValue={(selected) =>
                  floors.find((f) => f.value === selected)?.name
                }
                onChange={(e) => setControl("floor_number", e.target.value)}
              >
                {floors.map((floor, index) => (
                  <MenuItem value={floor.value} key={` ${index}`}>
                    {floor.name}
                  </MenuItem>
                ))}
              </DialogSelectField>
              <DialogNumberField
                label={globalLang.area[lang]}
                placeholder={globalLang.area[lang]}
                // isAllowed={({ value }) => value.length <= 5}
                value={controls.area_size}
                onChange={(e) =>
                  setControl(
                    "area_size",
                    e.target.value.replace("متر", "").trim()
                  )
                }
              />
              {/* <DialogNumberField
          placeholder={globalLang.area[lang]}
          label={globalLang.area[lang]}
          prefix="متر "
          value={controls.area}
          onChange={(e) =>
            setControl("area", e.target.value.replace(/متر/gi, "").trim())
          }
        /> */}
              <DialogInputField
                label={globalLang.finishingType[lang]}
                placeholder={globalLang.finishingType[lang]}
                value={controls.complete_type}
                onChange={(e) => setControl("complete_type", e.target.value)}
              />
              <DialogInputField
                label={globalLang.price[lang]}
                placeholder={globalLang.price[lang]}
                value={controls.price}
                onChange={(e) => setControl("price", e.target.value)}
                type="number"
              />
              <DialogInputField
                label={globalLang.customerName[lang]}
                placeholder={globalLang.customerName[lang]}
                value={controls.client}
                onChange={(e) => {
                  let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;
                  let match = text.test(e.target.value);
                  if (match) {
                    setControl("client", e.target.value);
                  }
                }}
                type="text"
              />
              <DialogPhoneField
                placeholder={globalLang.phone[lang]}
                label={globalLang.phone[lang]}
                selectProps={{
                  value: controls.code,
                  onChange: (e) => setControl("code", e.target.value),
                }}
                value={controls.phone}
                onChange={(e) => setControl("phone", e.target.value)}
              />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <InputLabel
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    width: "20%",
                  }}
                >
                  {globalLang.meansofcomfort[lang]}
                </InputLabel>
                <AutocompleteField
                  getOptionLabel={(option) =>
                    option.name ? option.name : option.label
                  }
                  placeholder={globalLang.meansofcomfort[lang]}
                  onOpen={getMeansOfComfort}
                  isPending={meansofcomfortResponse.isPending}
                  value={
                    Array.isArray(controls?.amenities_ids)
                      ? controls?.amenities_ids
                      : []
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.name || option.label === value.label
                  }
                  onChange={(e, options, reason) => {
                    console.log(options);
                    switch (reason) {
                      case "selectOption":
                        setControl("amenities_ids", [
                          ...options.map((ele) => ele),
                        ]);
                        break;
                      case "removeOption":
                        setControl(
                          "amenities_ids",
                          options.map((ele) => ele)
                        );
                        break;
                      case "clear":
                        setControl("amenities_ids", "");
                    }
                  }}
                  data={[
                    ...meanspfcomfort.map((employee) => ({
                      label: `${employee.name}`,
                      value: employee.id,
                      image: employee.icon,
                    })),
                  ]}
                  multiple
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ".MuiInput-root": {
                      backgroundColor: "white",
                    },
                  }}
                />{" "}
              </Box>
              <DialogPhoneField
                placeholder={globalLang.customerPhone[lang]}
                label={globalLang.customerPhone[lang]}
                selectProps={{
                  value: controls.countryCode,
                  onChange: (e) => setControl("countryCode", e.target.value),
                }}
                value={controls.phone_client}
                onChange={(e) => setControl("phone_client", e.target.value)}
              />
              <DialogInputField
                label={globalLang.notes[lang]}
                placeholder={globalLang.notes[lang]}
                multiline
                variant="standard"
                value={controls.comment}
                onChange={(e) => setControl("comment", e.target.value)}
                sx={{ backgroundColor: "primary" }}
                type="text"
              />
              {data?.is_sold?<></>:<DialogCheckboxField
                label={globalLang.isPublic[lang]}
                placeholder={globalLang.isPublic[lang]}
                value={controls.is_public}
                onClick={(e) => setControl("is_public", !controls.is_public)}
              />}
              
              {/* <DialogCheckboxField
          label={globalLang.can_login[lang]}
          placeholder={globalLang.can_login[lang]}
          value={controls.canlogin}
          onClick={(e) => setControl("canlogin", !controls.canlogin)}
        /> */}
            </DialogForm>
            <DialogButtonsGroup
              sx={{
                flex: "1 1 auto",
                
                boxSizing: "border-box",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DialogButton
                onClick={handleSubmit}
                disabled={unitPatchResponse.isPending}
              >
                {globalLang.save[lang]}
              </DialogButton>
              <DialogButton variant="close" onClick={onClose}>
                {globalLang.cancel[lang]}
              </DialogButton>
            </DialogButtonsGroup>
          
        </TabPanel>
        <TabPanel value="2">
          <DialogContent>
            <Stack
              direction="row"
              sx={{ width: "100%" }}
              justifyContent="center"
              gap="20px"
            >
              {/* <Box
              component="input"
              type="file"
              multiple={true}
              ref={changePictureRef}
              sx={{ display: "none" }}
              onChange={(e) => {
                handleChangePicture({
                  id: e.target.id,
                  image: e.target.files[0],
                });
              }}
            ></Box> */}

              <Box
                component="input"
                type="file"
                accept="image/*,video/*"
                multiple={true}
                sx={{ display: "none" }}
                ref={addPictureRef}
                onChange={(e)=>addimagesubmit(e.target.files[0])}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 120,
                  height: 120,
                  bgcolor: "rgba(0, 0, 0, 0.2)",
                  borderRadius: 1.5,
                  cursor: "pointer",
                }}
                onClick={() => addPictureRef.current.click()}
              >
                <AddIcon sx={{ color: "white" }} />
              </Box>

              {imageunit && (
                <>
                  {addimageResponse.isPending && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "50%",
                        height: "auto",
                        bgcolor: "rgba(0, 0, 0, 0.2)",
                        zIndex: 1,
                      }}
                    >
                      <CircularProgress sx={{ color: "white" }} />
                    </Stack>
                  )}
                </>
              )}
            </Stack>

            <Stack
              direction="row"
              sx={{ width: "100%", flexWrap: "wrap", marginY: "50px" }}
              justifyContent="center"
              gap="20px"
            >
              {/* <Box
                          component="input"
                          type="file"
                          accept="image/*"
                          multiple={true}
                          ref={changePictureRef}
                          sx={{ display: "none" }}
                          onChange={(e) => {
                            handleChangePicture({
                              id: e.target.id,
                              files: e.target.files[0],
                            });
                          }}
                        ></Box> */}

              {imageunit &&
                imageunit?.map((item, index) => (
                  <Badge
                    badgeContent={
                      <IconButton
                        sx={{
                          bgcolor: "red",
                          position: "relative",
                          "&:hover": {
                            bgcolor: "red",
                          },
                        }}
                        onClick={() => deleteimagesubmit(item?.id)}
                        key={`image ${index}`}
                      >
                     
                        <DeleteIcon sx={{ color: "white" }} />
                      </IconButton>
                    }
                  >
                    
                    {addimageResponse.isPending && (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "50%",
                          height: "auto",
                          bgcolor: "rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CircularProgress sx={{ color: "white" }} />
                      </Stack>
                    )}

                    <a
                      href={item.image}
                      target="_blank"
                      style={{
                        position: "relative",
                        zIndex: "2",
                        top: "0",
                        right: "0",
                        marginTop: "-17px",

                        color: "red",
                        fontWeight: "bold",
                        fontSize: "40px",
                      }}
                    >
                      <OpenInNewOutlinedIcon
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "40px",
                        }}
                      />
                    </a>
                    <Zoom>
                      <Avatar
                        variant="rounded"
                        src={item.file}
                        sx={{
                          width: "250px",
                          height: "250px",
                          cursor: "pointer",
                        }}
                        
                      >
                        {[
                          "mp4",
                          "MOV",
                          "wmv",
                          "webm",
                          "mpeg",
                          "WMV",
                          "WEBM",
                          "MPEG",
                        ].includes(item.file.split(".").pop().trim()) ? (
                          <video
                            style={{ width: "100%", height: "100%" }}
                            controls
                          >
                            <source src={item.file}></source>
                          </video>
                        ) : (
                          <img
                            src={item.file}
                            alt=""
                            controls
                            className="VideoInput_video"
                          />
                        )}
                      </Avatar>
                    </Zoom>
                  </Badge>
                ))}
              {/* <Box
                          component="input"
                          type="file"
                          accept="image/*,video/*"
                          multiple={true}
                          sx={{ display: "none" }}
                          ref={addPictureRef}
                          onChange={handleAddPicture}
                        ></Box> */}
            </Stack>
          </DialogContent>
        </TabPanel>
      </TabContext></Dialog>
      {unitPatchResponse.failAlert}
      {unitPatchResponse.successAlert}
    </>
  );
};

const InfoDialog = ({ open, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const unitsStore = useSelector((state) => state.fekhidmaticUnits.value);
  const [rows, setrows] = useState([]);
  let dispatch = useDispatch();
  const globalLang = useSelector((state) => state.lang.value.global);
  let imageunit = useSelector((state) => state.addimageusnit?.files);
  const [dataresult, setdataresult] = useState({});
  let [imageId, setimageid] = useState(null);
  const [countriesData, setCountriesData] = useState([]);
  const clickref = useRef();
  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
   
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };
  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      // {
      //   control: "agent",
      //   value: data?.agent,
      //   isRequired: false,
      // },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },
      {
        control: "phone_client",
        value: data?.phone_client,
        isRequired: false,
      },
      {
        control: "countryCode",
        value: data?.phone_client_country_code,
        isRequired: false,
      },
      {
        control: "code",
        value: data?.country_code,
        isRequired: false,
      },
      {
        control: "client",
        value: data?.client,
        isRequired: false,
      },
      {
        control: "address",
        value: data?.address,
        isRequired: false,
      },
      {
        control: "country",
        value: data?.country,
        isRequired: false,
      },
      {
        control: "images",
        value: [],
        isRequired: false,
      },
      {
        control: "city",
        value: data?.city,
        isRequired: false,
      },

      {
        control: "part",
        value: data?.part,
        isRequired: false,
      },
      {
        control: "created_by",
        value: data?.created_by,
        isRequired: false,
      },
      {
        control: "area_size",
        value: data?.area_size,
        isRequired: false,
      },
      {
        control: "area",
        value: data?.area,
        isRequired: false,
      },
      {
        control: "price",
        value: data?.price,
        isRequired: false,
      },
      {
        control: "category",
        value: data?.category,
        isRequired: false,
      },
      {
        control: "comment",
        value: data?.comment,
        isRequired: false,
      },
      {
        control: "floor_number",
        value: data?.floor_number,
        isRequired: false,
      },
      {
        control: "flat_number",
        value: data?.flat_number,
        isRequired: false,
      },
      {
        control: "unit_type",
        value: data?.unit_type,
        isRequired: false,
      },
      {
        control: "complete_type",
        value: data?.complete_type,
        isRequired: false,
      },
      {
        control: "sales_type",
        value: data?.sales_type,
        isRequired: false,
      },
      {
        control: "property_images",
        value: data?.property_files,
        isRequired: true,
      },
      {
        control: "category",
        value: data?.category,
        isRequired: false,
      },
      {
        control: "rooms",
        value: data?.room_number,
        isRequired: false,
      },
      {
        control: "bathrooms",
        value: data?.bath_count,
        isRequired: false,
      },
      {
        control: "kitchen_count",
        value: data?.kitchen_count,
        isRequired: false,
      },
      {
        control: "living_room_count",
        value: data?.living_room_count,
        isRequired: false,
      },
      {
        control: "amenities_ids",
        value: data?.amenities,
        isRequired: false,
      },
      {
        control: "created_by_username",
        value: data?.created_by_username,
        isRequired: false,
      },
      {
        control: "created_by_name",
        value: data?.created_by_name,
        isRequired: false,
      },
      {
        control: "created_by_phone",
        value: data?.created_by_phone,
        isRequired: false,
      },
    ],
    [data]
  );
  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: UNITS_FEKHEDMETAK,
    method: "get",
    successMessage: globalLang.success[lang],
  });
  const handleSubmit = () => {
    unitPatchRequest({
      id: data?.id,
      onSuccess: (res) => {
        setdataresult({ ...res.data });

        dispatch({ type: "imageunit/set", payload: res.data.property_files });
        // onClose();
      },
    });
  };
  useAfterEffect(() => {
    if (!open) return;
    console.log(imageunit)
  }, [open]);
 

  const [addimageRequest, addimageResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + data?.id + "/files/",
    method: "post",
    successMessage: globalLang.success[lang],
  });

  const addimagesubmit = (e) => {
    const requestBody = filter({
      obj: {
        file: e,
      },
      output: "formData",
    });
    addimageRequest({
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "fekhunits/putImageItem",
          payload: { id: data?.id, item: res.data },
        });
        dispatch({ type: "imageunit/addItem", payload: res.data });
      },
    });
  };
  const [deleteimageRequest, deleteimageResponse] = useRequest({
    path: UNITS_FEKHEDMETAK + data?.id + "/files/" + imageId + "/",
    method: "delete",
    successMessage: globalLang.success[lang],
  });

  const deleteimagesubmit = () => {
    deleteimageRequest({
      onSuccess: (res) => {
        dispatch({
          type: "fekhunits/deleteImageItem",
          payload: { id: data?.id, idimage: imageId },
        });
        dispatch({ type: "imageunit/deleteItem", payload: { id: imageId } });
      },
    });
  };
  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========
  // useAfterEffect(() => {
  //   deleteimagesubmit();
  // }, [imageId]);
  useEffect(() => {}, [unitsStore]);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "100%" }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "16px",
            minWidth: "56%",
            padding: "24px",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          {globalLang.detailUnitData[lang]}
        </Typography>
        {/* <Typography variant="div" sx={{ display: "flex", flexWrap: "wrap" }}>
          {imageunit &&
            imageunit.map((item, index) => (
              <Typography
                sx={{
                  position: "relative",
                  width: "20%",
                  height: "20%",
                  marginLeft: lang === "ar" ? "20px" : "",
                  marginRight: lang === "en" ? "20px" : "",
                }}
              >
                {[
                  "mp4",
                  "MOV",
                  "wmv",
                  "webm",
                  "mpeg",
                  "WMV",
                  "WEBM",
                  "MPEG",
                ].includes(item.file.split(".").pop().trim()) ? (
                  <video width="150px" height="100" controls>
                    <source src={item.file}></source>
                  </video>
                ) : (
                  <img
                    src={item.file}
                    alt=""
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "black",
                      height: "100px",
                    }}
                  />
                )}

                <MuiIconButton
                  color="#f8c6c6"
                  iconColor="red"
                  onClick={(event) => {
                    setimageid(item.id);
                    // event.stopPropagation();
                    // onDelete(event, row);
                  }}
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    left: lang === "en" ? "78%" : "",
                    right: lang === "ar" ? "78%" : "",
                    backgroundColor: "#f8c6c6",
                    color: "red",
                  }}
                >
                  <DeleteIcon sx={{ width: 20, height: 20 }} />
                </MuiIconButton>
              </Typography>
            ))}
          <Typography
            variant="lable"
            sx={{
              backgroundColor: (theme) => theme.palette.grey.main,
              borderRadius: "5px",

              height: "100px",
              width: "100px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: lang === "ar" ? "20px" : "",
              marginRight: lang === "en" ? "20px" : "",
              color: (theme) => theme.palette.primary.main,
              cursor: "pointer",
            }}
            onClick={() => clickref.current.click()}
          >
            <input
              id="fileInput"
              accept="image/*,video/*"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                // if (Object.keys(e.target.files).length < 1) {
                //   alert("قم باضافه صوره  ");
                //   e.target.value = [];
                //   return;
                // }
                setControl("images", [
                  ...Object.keys(e.target.files).map(
                    (key) => e.target.files[key]
                  ),
                ]);
                addimagesubmit([
                  ...Object.keys(e.target.files).map(
                    (key) => e.target.files[key]
                  ),
                ]);
              }}
              multiple
              ref={clickref}
            />
            <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
              {globalLang.add[lang]}
            </label>
          </Typography>
        </Typography> */}
        <DialogContent sx={styledialog}>
          <Table
            border={0}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.area[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.area_size}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={stylerow}>{globalLang.address[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.unitType[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.unit_type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.floor[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.floor_number}</TableCell>
              </TableRow>
             
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.createdByName[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.created_by_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.buildingNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.flat_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.saleType[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.sales_type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.kitchen_count[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.kitchen_count}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.livingNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.living_room_count}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.roomsNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.rooms}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.bathroomsNumber[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.bathrooms}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.area[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.area_size}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.price[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.price}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.createdByUserName[lang]}
                </TableCell>
                <TableCell sx={styletd}>
                  {controls.created_by_username}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.createdByPhone[lang]}
                </TableCell>
                <TableCell sx={styletd}>
                  {controls.created_by_phone}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogContent sx={styledialog}>
          <Table
            border={0}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.customerName[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.client}</TableCell>
              </TableRow>

              {/* <TableRow>
                <TableCell sx={stylerow}>{globalLang.agent[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.agent}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.customerPhone[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.phone_client}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={stylerow}>{globalLang.notes[lang]}</TableCell>
                <TableCell sx={styletd}>{controls.comment}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={stylerow}>
                  {globalLang.createdAt[lang]}
                </TableCell>
                <TableCell sx={styletd}>{controls.created_at}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      {unitPatchResponse.failAlert}
      {addimageResponse.failAlert}

      {addimageResponse.successAlert}
      {deleteimageResponse.failAlert}
      {deleteimageResponse.successAlert}
    </>
  );
};
const rooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];
const bathrooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];
