import { createSlice } from "@reduxjs/toolkit";
export const LangSlice = createSlice({
  name: "lang",
  initialState: {
    value: {
      lang: localStorage.getItem("lang")
        ? JSON.parse(localStorage.getItem("lang"))
        : "ar",
      home: { ar: "الرئيسية", en: "Home" },

      control: {
        changePassword: { ar: "تغير الرقم السري", en: "Change Password" },
        changeLanguage: { ar: "تغير اللغه", en: "Change Language" },
      },

      // Employees
      addEmployees: {
        addEmployee: { ar: "إضافة موظف", en: "Add Employee" },
        job: { ar: "الوظيفة", en: "Job" },
        followed: { ar: "التابع له", en: "Followed by" },
      },
      addJob: {
        addEmployee: { ar: "إضافة وظيفة موظف", en: "Add Employee Job" },
        followed: { ar: "التابع له", en: "Followed by" },
      },
      viewJobs: {
        viewJobs: { ar: "عرض وظائف الموظفين", en: "View employee jobs" },
        viewJobstree: { ar: " عرض شجرة وظائف الموظفين", en: "View employee jobs tree" },

        followed: { ar: "التابع له", en: "Followed by" },
        jobName: { ar: "اسم الوظيفة", en: "Job Name" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
      },
      viewEmployees: {
        viewEmployees: { ar: "عرض الموظفين", en: "View Employees" },
        viewTreeEmployees:{ar:"عرض  شجره موظفين",en:"View employees tree"},
        avatar: { ar: "الصورة الشخصية", en: "Avatar" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
        employeesNumbers: { ar: "عدد العملاء", en: "Number of clients" },
        newEmployeesNumbers: {
          ar: "عدد العملاء الجدد",
          en: "Number of new clients",
        },
      },

      //////////////

      // client

      overview: {
        statistics: { ar: "الإحصائيات", en: "Statistics" },
        allClients: { ar: "جميع العملاء", en: "All clients" },
        newCustomers: { ar: "العملاء الجدد", en: "New Customers" },
        allEmployees: { ar: "جميع الموظفين", en: "All Employees" },
        newEmployees: { ar: "الموظفين الجدد", en: "New Employees" },
        customerCase: { ar: "حالة العميل", en: "Customer Case" },
        bestEmployee: { ar: "افضل موظف", en: "Best Employee" },
        numberUser:{ar:"عدد مستخدمين",en:"Number of users"},
        numberrequest:{ar:" عدد طلبات  ",en:"Number of requests"},
        numberunits:{ar:" عدد وحدات  ",en:"Number of units"},
        numberclients:{ar:" عدد عملاء  ",en:"Number of clients"},
        numberrequestall:{ar:" عدد كل طلبات  ",en:"Number of all requests"},
        numberrequestpending:{ar:" عدد طلبات الانتظار  ",en:"Number of pending requests"},
        numberrequestcompleted:{ar:" عدد طلبات مكتمله  ",en:"Number of complete requests"},
        advertisingChannel: {
          ar: "القناة الاعلانية",
          en: "AdvertisingChannel",
        },
      },

      addClients: {
        addClients: { ar: "إضافة عميل", en: "Add Clients" },
        addUsers:{ar:"إضافة مستخدم",en:"Add Users"},
      },

      viewClients: {
        allClients: { ar: "جميع العملاء", en: "All Clients" },
      },
      deleteCustomer: {
        deleteCustomer: { ar: "العملاء المحذوفين", en: "Deleted Customers" },
      },
      addStatus: {
        addStatus: { ar: "إضافة حالة عميل", en: "Add Status" },
      },
      viewStatus: {
        viewStatus: { ar: "عرض حالات العملاء", en: "View customer cases" },
      },
      importClients: {
        importClients: { ar: "إستيراد عملاء", en: "Import Clients" },
      },
      exportClients: {
        exportClients: { ar: "تصدير عملاء", en: "Export Clients" },

      },
      exportRegistry: {
        exportRegistry: { ar: "سجل التصدير", en: "Export Registry" },
      },
      importLog: {
        importLog: { ar: "سجل الإستيراد", en: "Import Log" },
      },

      ////////////////////

      // Project
      addProjects: {
        addProjects: { ar: "إضافة مشروع", en: "Add Project" },
        addPhoto: { ar: "اضف صورة المشروع هنا", en: "Add Photo Here" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
        projectAddress: { ar: "عنوان المشروع", en: "Project Address" },
      },

      viewProjects: {
        viewProjects: { ar: "عرض المشاريع", en: "View Projects" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
      },

      ////////////////////

      // Channels
      addChannel: {
        addChannel: { ar: "إضافة قناة", en: "Add Channel" },
        addChannelPhoto: {
          ar: "اضف صورة القناة هنا",
          en: "Add Channel Photo Here",
        },
        channelName: { ar: "إسم القناة", en: "Channel Name" },
      },

      viewChannels: {
        viewChannels: { ar: "عرض القنوات", en: "View Channels" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
      },

      ////////////////////

      // Units
      addUnits: {
        addUnits: { ar: "إضافة وحدة", en: "Add Units" },
      },
      viewUnits: {
        viewUnits: { ar: "عرض الوحدات", en: "Show Units" },
      },

      ////////////////////

      form: {
        title: { ar: "مرحباً بك", en: "Hello" },
        subtitle: {
          ar: "قم بملئ الحقول للحصول على النتائج المطلوبة",
          en: "Fill in the fields to get the required results",
        },
      },
      global: {
        id: { ar: "رقم", en: "Id" },
        min_price: { ar: "السعر الأدنى", en: "Min Price" },
        max_price: { ar: "السعر الأعلى", en: "Max Price" },
        call_date: { ar: "تاريخ اضافة", en: "Add date" },
        middleman: { ar: "الوسيط", en: "Middleman" },
        client_name: { ar: "اسم العميل", en: "Client name" },
        client_number: { ar: "رقم العميل", en: "Client number" },
        property_type: { ar: "نوع العقار", en: "Property Type" },
        message:{ar:"رسالة",en:"Message"},
        rating:{ar:"تقييم",en:"Rating"},
        employee_name:{ar:"اسم موظف",en:"Employee name"},

        rent:{ar:"ايجار",en:"Rent"},
        buy:{ar:"بيع",en:"Sell"},
        clients:{ar:"عملاء",en:"Clients"},
        detailofrequest:{ar:"تفاصيل الطلب ",en:"Detail of request"},
        greatpricethanmin:{ar:"سعر الاعلي يجيب ان يكون اكبر من سعر الادني",en:"Max price should be greater than min price"},
        makesureoftransfer:{ar:"هل انت متأكد من انك تريد نقل  الوحدة ؟",en:"Are you sure you want to delete this unit and move it to Units?"},
        makesureofclienttransfer:{ar:"هل انت متأكد من انك تريد نقل  عميل ؟",en:"Are you sure you want to delete this unit and move it to client?"},
        kitchen_count:{ar:"عدد مطبخ",en:"Kitchen count"},
        deletejobconfirm:{ar:"هل انت متأكد من أنك تريد حذف هذه الوظيفة؟",en:"Are you sure you want to delete the job?"},
        changepassword:{ar: "تم تغير الرقم السري بنجاح",en:"Done change of password"},
        property: { ar: "العقار", en: "Property" },
        description: { ar: "الوصف", en: "Description" },
        logout: { ar: "تسجيل الخروج", en: "Logout" },
        control: { ar: "التحكم", en: "Control" },
        create_acc: { ar: "إنشاء حساب", en: "Create New Account" },
        company_name: { ar: "اسم الشركة والشعار", en: " Company/Factory Name" },
        loginaccount:{ar:"تم تسجيل الحساب بنجاح",en:"Account has been successfully registered"},
        successlogincan:{ar:"العملاء المحددين تم تعديل تسجيل الدخول بنجاح",en:"Selected users can update login successfully"},
        emailinvalid:{ar:"البريد غير صالح",en:"Invalid mail"},
        passwordvalidation:{ar:" كلمه السر يجب ان تحتوي علي8 حروف او ارقام ",en:"Invalid password ! please write 8 characters"},
        companyname: { ar: "اسم الشركة", en: " Company Name" },
        contract:{ar:"عقود",en:"Contract"},
        addcontract:{ar:"اضافه ملف",en:"Add contract"},
        viewcontract:{ar:"اضهار ملفات",en:"View contract"},
        viewcontract:{ar:"ملفات",en:"Contracts"},
        phone_client: { ar: "رقم هاتف العميل", en: "Phone Number" },
        deletesuccess:{ar:"تم حذف بنجاح",en:"Delete successfully"},
        client: { ar: "العميل", en: "Client" },
        owner: { ar: "المالك", en: "Owner" },
        owner_phone: { ar: "رقم هاتف المالك", en: "Owner Phone" },
        can_login:{ar:"تستطيع الدخول",en:"Can login"},
        canlogin:{ar:"تستطيع الدخول",en:"Can login"},
        followers_count:{ar:"عدد المتابعين",en:"The followers count"},
        restoreuser:{ar:"استرجاع مستخدم",en:"Restore user"},
        is_verified:{ar:"تم التحقق منه",en:"Is verified"},
        cannotlogin:{ar:"لا تستطيع دخول",en:"Cannot login"},
        video:{ar:"اضافه فيديو",en:"Add video"},
        getamenities:{ar:"تم بنجاح",en:"Success get amenities"},
        organization:{ar:"منظمه",en:"Organization"},
        meansofcomfort:{ar:"وسائل الراحة",en:"Means of comfort"},
        isPublic:{ar:"تظهر في تطبيق",en:"Access by app"},
        editProject:{ar:"تعديل بيانات مشروع",en:"Edit Project"},
        detailProject:{ar:"تفاصيل مشروع",en:"Detail of project"},
        waitresponce:{ar:"انتظار استيراد",en:"Wait for importing...."},
        addsuccessrequest: {
          ar: "تم إضافة طلب بنجاح",
          en: "Add Request Successfully",
        },
        addsuccessclient: {
          ar: "تم إضافة عميل بنجاح",
          en: "Add Client Successfully",
        },
        addsuccessunit: {
          ar: "تم نقل وحده بنجاح",
          en: "Transfer unit Successfully",
        },
        addusersuccess: {
          ar: "تم إضافة عميل بنجاح",
          en: "Add Client Successfully",
        },
        deleteclientsuccess:{ar:"تم حذف الموظف بنجاح",en:"Delete Employee Successfully"},
        addemlpoyeesuccess:{ar:"تم اضافه الموظف بنجاح",en:"Add employee done successfully"},
        pleasefillinfo: {
          ar: "الرجاء ملئ المعلومات الآتية لاضافة عميل جديد",
          en: "Please fill in the following information to add a new client",
        },
        adddataneed: {
          ar: "قم بإدخال البيانات المطلوبة",
          en: "Enter the required data",
        },
        meter: { ar: "متر", en: "m" },
        addimage: { ar: "قم باضافه صوره  ", en: "Add Image" },
        image:{ar:"صوره",en:"Image"},
        leader:{ar:"قائد",en:"Leader"},
        forgetpassword: {
          ar: "هل نسيت كلمة السر ؟",
          en: "Forgot your password ?",
        },
        company_name_placeholder: {
          ar: "رابط موقع الشركه",
          en: "Company website link",
        },
        textname:{
          ar:"ادخل نص",
          en:"Enter text"
        },
        transferred_by:{
          ar:"تم النقل بواسطة",
          en:"Transferred by"
        },
        website: { ar: " الرابط الالكتروني  ", en: "Your link " },
        haveaccount: {
          ar: "لديك حساب بالفعل؟",
          en: "Already have an account?",
        },
        users: { ar: "المستخدمين", en: "Users" },
        makeaccount: { ar: "إنشاء حساب", en: "Create New Account" },
        noaccount: { ar: " ليس لديك حساب؟", en: "Don't have an account?" },
        admin_name: { ar: "اسم المسؤول", en: "Admin Name" },
        first_name: { ar: " الإسم الاول", en: "First Name" },
        second_name: { ar: "الاسم ثنائي", en: "Second Name" },
        last_name: { ar: " الإسم الاخير", en: "Last Name" },
        phone_number: {
          ar: "رقم الهاتف المحمول  ",
          en: "Your  Phone Number ",
        },
        username_en: {
          ar: "الرجاء كتابة اسم المستخدم باللغة الانجليزية",
          en: "Please Enter Username in English",
        },
        login: { ar: "تسجيل الدخول", en: "LogIn" },
        fekhidmtak: { ar: "في خدمتك", en: "Fe Khidmtak" },
        // user data
        name: { ar: "الإسم", en: "Name" },
        username: { ar: "إسم المستخدم", en: "Username" },
        customer: { ar: "العميل", en: "Customer" },
        customerName: { ar: "اسم المالك", en: "Owner Name" },
        customerPhone: { ar: "هاتف المالك", en: "Owner Phone" },
        employeeName: { ar: "اسم الموظف", en: "Employee Name" },
        employeePhone: { ar: "رقم الموظف", en: "Employee Phone" },
        phone: { ar: "الهاتف", en: "Phone" },
        email: { ar: "البريد الإلكتروني", en: "E-mail" },
        password: { ar: "الرقم السري", en: "Password" },
        newPassword: { ar: "كلمه المرور ", en: "Password" },
        oldPassword: { ar: "الرقم السري القديم", en: "Old Password" },
        confirmPassword: { ar: "تأكيد الرقم السري", en: "Confirm Password" },
        whatsApp: { ar: "الواتساب", en: "Whats app" },
        contactWay: { ar: "طريقة التواصل", en: "Contact Way" },
        password: { ar: "كلمه المرور", en: "Password" },
        job: { ar: "الوظيفة", en: "Job" },
        employee: { ar: "الموظف", en: "Employee" },
        TransferEmployee: {
          ar: "تحويل إلى موظف",
          en: "Transfer To An Employee",
        },
        datecreated: { ar: "تاريخ الإنشاء", en: "Date Created" },
        salesOfficer: { ar: "مسؤول المبيعات", en: "Sales Officer" },
        newCustomers: { ar: "العملاء الجدد", en: "New Customers" },
        editor: { ar: "المحرر", en: "Editor" },
        createdBy: { ar: "تم الإنشاء بواسطة", en: "Created By" },
        createdByName: { ar: "تم الإنشاء بواسطة [الاسم]", en: "Created By [Name]" },
        createdByUserName: { ar: "تم الإنشاء بواسطة [اسم مستخدم]", en: "Created By [UserName]" },
        createdByPhone: { ar: "تم الإنشاء بواسطة [رقم هاتف]", en: "Created By [Phone]" },

        userCanLogin:{ar:" تسجيل دخول",en:"Can login"},
        rights: { ar: "حقوق النشر", en: "Copy Right" },
        requests: { ar: "الطلبات", en: "Requests" },
        all:{ar:"هل تحتاج كل طلبات او طلباتك؟",en:"Do you need all request or your request?"},
        requests:{ar:"طلبات",en:"Requests"},
        myRequest:{ar:"طلباتي",en:"My requests"},
        //////////////////////
        keepintouch: {
          ar: "سنراجع هذه البيانات ونعود إليك عاجلاً",
          en: "We will look into this , and get back to you sooner",
        },
        contact: { ar: "أبق على اتصال", en: "Keep in touch" },
        cypartacom: { ar: "Cyparta.com", en: "Cyparta.com" },
        emailcyparta: { ar: "Support@cyparta.com", en: "Support@cyparta.com" },
        phonecyparta: { ar: "0215 551 551155", en: "0215 551 551155" },
        history_date: { ar: "تاريخ", en: "Date" },
        phone_number_table: { ar: "الهاتف", en: "Phone" },
        points: { ar: "النقاط", en: "Points" },
        // Actions
        actions: { ar: "إجرائات", en: "Actions" },
        save: { ar: "حفظ", en: "Save" },
        delete: { ar: "حذف", en: "Delete" },
        deleteTo: { ar: "نقل إلى سلة المهملات", en: "Move to trash" },
        cancel: { ar: "الغاء", en: "Cancel" },
        submit: { ar: "تنفيذ", en: "Submit" },
        add: { ar: "إضافة", en: "Add" },
        addComment: { ar: "اكتب تعليق", en: "Add Comment" },
        editPost: { ar: "تعديل المنشور", en: "Edit Post" },
        editUnitData: { ar: "تعديل بيانات الوحدة", en: "Edit Unit Data" },
        editRequestData: { ar: "تعديل بيانات الطلب", en: "Edit Request Data" },
        user:{ar:"مستخدم",en:"User"},
        jokers:{ar:"الجوكرز",en:"Jokers"},
        
        editUserData: { ar: "تعديل بيانات المستخدم", en: "Edit User Data" },
        detailUserData: { ar: "تفاصيل بيانات المستخدم", en: "Detail User Data" },
        detailHistoryUserData:{ar:"تفاصيل تاريخ بيانات المستخدم",en:"Details of a user's data history"},
        followerdetail:{ar:"تفاصيل المتابعين",en:"Details of follower"},
        detailUnitData: { ar: "تفاصيل بيانات الوحدة", en: "Detail Unit Data" },
        areyousuretodelete:{ar: "؟هل انت متأكد من انك تريد حذف هذه الوحدة",en:"Are you sure to delete the unit?"},
        detaildataclient:{ar:"تفاصيل  سجل العميل",en:"Detail  history Client"},
        detailClients: { ar: "تفاصيل العميل", en: "Detail Clients" },
        dataofclients:{ar:"تعديل بيانات العميل",en:"Edit Data of client"},
        search: { ar: "بحث", en: "Search" },
        viewItems: { ar: "عرض العناصر", en: "View Items" },
        editJob: { ar: "تعديل الوظيفة", en: "Edit Job" },
        showAll: { ar: "عرض الجميع", en: "Show All" },
        filter: { ar: "تصفية", en: "Filter" },
        addFilter: { ar: "إضافة تصفية", en: "Add Filter" },
        status: { ar: "الحالة", en: "Status" },
        deleteRecords: { ar: "حذف السجلات", en: "Delete Records" },
        downloadFile: { ar: "تنزيل ملف", en: "Download File" },
        uploadFile: { ar: "رفع ملف", en: "Upload File" },
        reviewfile:{ar:"عرض ملف",en:"Review file"},
        editphones:{ar:"اضافه ارقام هاتف",en:"Add phone numbers"},
        downloadTemplate: { ar: "تنزيل نموذج", en: "Download Template" },
        created_by: { ar: "بواسطة", en: "Created By" },
        downloadCustomerData: {
          ar: "قم بتحميل ملف العملاء",
          en: "Download Customer Data",
        },
        uploadCustomerData: {
          ar: "قم برفع ملف العملاء",
          en: "Upload Customer Data",
        },
        uploadfile:{ar:"ارفع ملف",en:"Add file"},
        pleaseConfirm: { ar: "الرجاء التأكيد", en: "Please, confirm" },
        areUSure: {
          ar: "هل انت متأكد من انك تريد اتمام العملية؟",
          en: "Are you sure you want to delete this client?",
        },
        editCusomerData: {
          ar: "تعديل بيانات العميل",
          en: "Edit Customer Data",
        },
        cusomerDetails: {
          ar: "تفاصيل العميل",
          en: "Customer Details",
        },
        customerRecord: {
          ar: "سجل العميل",
          en: "Customer Record",
        },

        ///////////////////////

        // Selected
        deleteSelected: { ar: "حذف المحدد", en: "Delete Selected" },
        retrievalSelected: { ar: "إسترجاع المحدد", en: "Retrieval Selected" },
        exportSelected: { ar: "تصدير المحدد", en: "Export Selected" },
        sendalert:{ar:"ارسال تنبيه",en:"Send alert"},
        transferSelected: { ar: "تحويل المحدد", en: "Transfer Selected" },
        changeSelected: {
          ar: "تغيير مشاريع المحدد",
          en: "Change Selected Projects",
        },
        ///////////////////////

        // Single word
        no: { ar: "لا", en: "No" },
        yes: { ar: "نعم", en: "Yes" },
        ok: { ar: "حسناً", en: "OK" },
        admin: { ar: "أدمن", en: "Admin" },
        like: { ar: "أعجبني", en: "Like" },
        comment: { ar: "التعليق", en: "Comment" },
        comments: { ar: "تعليقات", en: "Comments" },
        theDescription: { ar: "الوصف", en: "The Description" },
        file: { ar: "الملف", en: "File" },
        picture: { ar: " صور وفيديو", en: "Multiple Picture" },
        picturejust: { ar: " صور ", en: " Picture" },

        reviewunit:{ar:" اضافة صور او فيديو ",en:"Add picture or video"},
        addmainImage:{ar:"اضافة صوره رئيسية",en:"Add main picture"},
        main_image:{ar:" صوره رئيسية",en:" Main picture"},
        details: { ar: "تفاصيل", en: "Details" },
        notes: { ar: "ملاحظات", en: "Notes" },

        ///////////////////

        // Location
        country: { ar: "البلد", en: "Country" },
        governorate: { ar: "المحافظة", en: "Governorate" },
        city: { ar: "المدينة", en: "City" },
        region: { ar: "المنطقة", en: "Region" },
        neighboring: { ar: "المجاورة", en: "Neighboring" },
        part: { ar: "الجزء", en: "Part" },
        buildingNumber: { ar: "رقم العمارة", en: "Building Number" },
        unitType: { ar: "نوع الوحدة", en: "Unit Type" },
        saleType: { ar: "نوع البيع", en: "Sale Type" },
        floor: { ar: "الدور", en: "Floor" },
        area: { ar: "المساحة", en: "Area" },
        roomsNumber: { ar: "عدد الغرف", en: "Rooms Number" },
        bathroomsNumber: { ar: "عدد الحمامات", en: "Bathrooms Number" },
        finishingType: { ar: "نوع التشطيب", en: "Finishing Type" },
        price: { ar: "السعر", en: "Price" },
        address: { ar: "العنوان", en: "Address" },
        agent: { ar: "وكيل", en: "Agent" },
        category: { ar: "الفئة", en: "Category" },
        paragraph: {
          ar: "نطلب بعض المعلومات الشخصية. من خلال توفير معلومات دقيقة ، يمكنك المساعدة في الحفاظ على أمان حسابك وجعل خدماتنا أكثر فائدة",
          en: "We ask for some personal information. By providing accurate information, you can help keep your account secure and make our Services more useful",
        },
        ///////////////////

        // scale unites
        meter: { ar: "متر", en: "Meter" },

        ///////////////////

        latestPosts: { ar: "احدث المنشورات", en: "Latest Posts" },
        yourMind: { ar: "مالذي يدور في بالك،", en: "what's on your mind," },
        followed: { ar: "التابع له", en: "Followed by" },
        allPermission: { ar: "جميع الصلاحيات", en: "All Permission" },
        notFound: { ar: "لا يوجد", en: "Not Found" },
        unknown: { ar: "غير معروف", en: "Unknown" },
        unknownLink: { ar: "الرابط غير معروف", en: "Unknown Link" },
        code: { ar: "الكود", en: "Code" },
        numberOfClients: { ar: "عدد العملاء", en: "Number of clients" },
        project: { ar: "المشروع", en: "Project" },
        projects: { ar: "المشروعات", en: "Projects" },
        budget: { ar: "الميزانية", en: "Budget" },
        success:{ar:"تم  بنجاح",en:"Done successfully"},
        detelesuccess:{ar:"تم حذف  بنجاح",en:"Delete done successfully"},
        successedit:{ar:"تم تعديل بنجاح",en:"Done update successfully"},
        changeproject:{ar:"تم تغيير المشروع",en:"Done change project"},
        donereturn:{ar:"تم الاسترجاع بنجاح",en:"Done return of client successfully"},
        addenoughphone:{ar:"تم اضافه كل لارقام مسموح اضافتها",en:"You add enough phones"},
        addsuccess:{ar:"تم اضافه بنجاح",en:"Done add successfully"},
        successpatchclient:{ar:"تم تعديل عميل بنجاح",en:"Done edit to client"},
        successpatchuser:{ar:"تم تعديل مستخدم بنجاح",en:"Done edit to user"},
        logindone:{ar:"تم تسجيل دخول بنجاح",en:"Login successfully"},
        makesureofdelete:{ar:"هل انت متأكد من انك تريد حذف؟",en:"Are you sure you want to delete ?"},
        makesureoflogin:{ar:"هل انت متأكد من انك تريد تسجيل دخول  مستخدمين؟",en:"Are you sure you want to login there users?"},
        
        makesureofpdf:{ar:"هل انت متأكد من انك تريد رفع ملف؟",en:"Are you sure you want to upload file?"},
        successtransfer:{ar:"تم نقل  بنجاح",en:"Done transfer successfully"},
        totalNumber: { ar: "العدد الكلي", en: "Total number" },
        sameLevel: { ar: "نفس المرحلة", en: "Same Level" },
        livingNumber:{ar:"غرف المعيشة",en:"Living rooms"},
        editEmployeeData: {
          ar: "تعديل بيانات الموظفين",
          en: "Edit Employee Data",
        },
        adChannel: {
          ar: "القناة الإعلانية",
          en: "Ad Channel",
        },
        channels: {
          ar: "القنوات",
          en: "Channels",
        },
        mediator: {
          ar: "الوسيط",
          en: "Mediator",
        },
        statusName: {
          ar: "اسم الحالة",
          en: "Status name",
        },

        // Budget
        budget: { ar: "الميزانية", en: "Budget" },
        equal: { ar: "يساوي", en: "Equal" },
        bigger: { ar: "اكبر من", en: "Bigger" },
        smaller: { ar: "اصغر من", en: "Smaller" },

        //////////////////

        // Dates
        day: { ar: "اليوم", en: "Day" },
        week: { ar: "الإسبوع", en: "Week" },
        month: { ar: "الشهر", en: "Month" },
        year: { ar: "السنة", en: "Year" },

        date: { ar: "تاريخ", en: "Date" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
        previewDate: { ar: "تاريخ المعاينة", en: "Preview Date" },
        dateOfProcedure: { ar: "تاريخ الإجراء", en: "Date Of Procedure" },
        from: { ar: "من", en: "From" },
        periodFrom: { ar: "الفترة من", en: "Period From" },
        to: { ar: "الي", en: "To" },
        periodTo: { ar: "الفترة إلى", en: "Period To" },
        ///////////////

        // Sidebar
        Employees: { ar: "الموظفين", en: "Employees" },
        customers: { ar: "العملاء", en: "Customers" },
        projects: { ar: "المشاريع", en: "Projects" },
        channels: { ar: "القنوات", en: "Channels" },
        units: { ar: "الوحدات", en: "Units" },
        additional_phone: { ar: "هاتف ثاني", en: "Additional Phone" },
        files_number: { ar: "عدد الملفات", en: "Number" },
      },
      statistics: {
        title: { ar: "الإحصائيات", en: "Statistics" },
        totaldownloads: { ar: "إجمالي التنزيلات", en: "Total downloads" },
        appstore: { ar: "متجر التطبيقات", en: "App Store" },
        Last6days: { ar: "آخر 6 أيام", en: "Last 6 days" },
        numberofdownloadapp: {
          ar: "عدد تطبيقات التنزيل",
          en: "Number of download app",
        },
        activeusers: {
          ar: "المستخدمين النشطين",
          en: "Active Users",
        },
      },
      clientsfekhidmtak: {
        title: { ar: "عملاء ", en: "Clients" },
      },

      dialyactivity: {
        title: { ar: "النشاط اليومي", en: "Dialy Activity" },
        data: [
          {
            taskfinished: { ar: "انتهت المهمة", en: "Task finished" },
            date: { ar: "29 oct 2022", en: "29 oct 2022" },
            address: {
              ar: "كريم أدهم أنهى زيارته للجيزة",
              en: "karim adham finished visit to giza",
            },
          },
          {
            taskfinished: { ar: "انتهت المهمة", en: "Task finished" },
            date: { ar: "29 oct 2022", en: "29 oct 2022" },
            address: {
              ar: `أسامة يأخذ العضوية من
              المستخدم # 525963`,
              en: `osama take the membership from user #525963`,
            },
          },
        ],
      },
    },
  },
  reducers: {
    toggleLang: (state, action) => {
      if (
        Boolean(action.payload) &&
        action.payload != "ar" &&
        action.payload != "en"
      ) {
        throw new Error(
          "The only possible languages to toggle between are either ar or en"
        );
      }

      if (action.payload) {
        state.value.lang = action.payload;
        localStorage.setItem("lang", JSON.stringify(action.payload));
      } else {
        switch (state.value.lang) {
          case "en":
            state.value.lang = "ar";
            break;
          case "ar":
            state.value.lang = "en";
            break;
          default:
            state.value.lang = "en";
        }
      }
    },
  },
});

export default LangSlice.reducer;
