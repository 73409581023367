import React from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { COUNTRY_FILTER, PROJECTEDIT, PROJECTS, STATES } from "../../data/APIs";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import { useState } from "react";
import { useEffect } from "react";
import format from "../../utils/ISOToReadable";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Dialog, DialogContent, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import { InputField, PictureField } from "../../features/form";
import { LteMobiledataOutlined } from "@mui/icons-material";
import useControls from "../../hooks/useControls";
import useAfterEffect from "../../hooks/useAfterEffect";
import { DialogButton, DialogButtonsGroup, DialogForm, DialogHeading, DialogInputField, DialogNumberField, DialogPhoneField, DialogSelectField } from "../../features/dialog";
import compare from "../../utils/Compare";
import filter from "../../utils/ClearNull";
import { styledialog, stylerow, styletd } from "../../css/tableview";
import { set } from "lodash";

const ViewProjects = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  let [editDate,setEditDate]=useState(null)
  let [viewDate,setViewDate]=useState(null)

  const viewProjectsLang = useSelector(
    (state) => state.lang.value.viewProjects
  );
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const projectsStore = useSelector((state) => state.projects.value);

  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter nameQuery={"name"}/>,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
      valueShape: {
        start: "",
        end: "",
      },
    }
  ];

  const columns = [
    {
      field: "picture",
      headerName: globalLang.picturejust[lang],
      customContent: (params) => (
        <Avatar src={params?.logo}>{params?.name?.split("")?.[0]}</Avatar>
      ),
    },
    {
      field: "name",
      headerName: viewProjectsLang.projectName[lang],
      customContent: (params) =>
        params?.name ? params?.name : globalLang.unknown[lang],
    },
    {
      field: "address",
      headerName: globalLang.address[lang],
      customContent: (params) =>
        params?.address ? params?.address : globalLang.unknown[lang],
    },
    {
      field: "comment",
      headerName: globalLang.details[lang],
      customContent: (params) =>
        params?.comment ? params?.comment : globalLang.unknown[lang],
    },
    {
      field: "created_at",
      headerName: globalLang.createdAt[lang],
      customContent: (params) =>
        params?.created_at
          ? format(params?.created_at)
          : globalLang.unknown[lang],
    },
  ];

  const dispatch = useDispatch();

  const handleDeleteProject = (e, row) => {
    projectsDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "projects/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDelete, deleteProjectConfirmDialog] = useConfirmMessage({
    onConfirm: handleDeleteProject,
    text: "هل انت متأكد من انك تريد حذف هذا المشروع؟",
  });

  const [projectsGetRequest, projectsGetResponse] = useRequest({
    path: PROJECTS,
    method: "get",
  });

  const [projectsDeleteRequest,projectresponce] = useRequest({
    path: PROJECTS,
    method: "delete",
    successMessage:globalLang.deletesuccess[lang]
  });

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item?.map(([key, value]) => urlParams.append(key, value))
    );
    delete requestParams.currentPage
    projectsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "projects/set", payload: res.data });
      },
    });
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      ...old,
      filters: filters.map(({ query }) => query),
    }));
  };

  const isPermitted = useIsPermitted();

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.projects[lang], viewProjectsLang.viewProjects[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={projectsStore.results}
        isPending={projectsGetResponse.isPending}
        total={projectsStore.count}
        onDelete={isPermitted(handleDelete, ["delete_aqarproject"])}
        onFilter={handleFilter}
        onAmountChange={handleChangeAmount}
        onPaginate={handlePaginate}
        filters={filters}
        onEdit={isPermitted((e,row)=>setEditDate(row),["change_aqarproject"])}
        onView={(e,row)=>setViewDate(row)}
      />
       <EditDialog
        open={Boolean(editDate)}
        onClose={() => setEditDate(null)}
        data={editDate}
      />
       <InfoDialog
          open={Boolean(viewDate)}
          onClose={() => setViewDate(null)}
          data={viewDate}
        />
      {deleteProjectConfirmDialog}
      {projectresponce.successAlert}
      {projectresponce.failAlert}
    </Wrapper>
  );
};

export default ViewProjects;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "address",
        value: data?.address,
        isRequired: false,
        
      },
      {
        control: "comment",
        value: data?.comment,
        isRequired: false,
      },
      {
        control: "name",
        value: data?.name,
        isRequired: false,
      },
      {
        control: "organization",
        value: data?.organization,
        isRequired: false,
      },
      {
        control: "logo",
        value: data?.logo,
        isRequired: false,
      },
      {
        control: "created_at",
        value: data?.created_at,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: PROJECTEDIT,
    method: "patch",
    successMessage: "تم تعديل مشروع بنجاح",
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const isThereChange = compare(
      [
        [controls.logo, data?.logo,"logo"],
        [controls.comment, data?.comment,"comment"],
        [controls.name, data?.name,"name"],
        [controls.created_at, data?.created_at,"created_at"],
        // [controls.organization, data?.organization,"organization"],
        [controls.address, data?.address,"address"],
      ],
      false
    );

    if (isThereChange.nochange) {
      const requestBody = filter({
        obj: {
          logo:isThereChange.array["logo"],
          comment: isThereChange.array["comment"],
          name: isThereChange.array["name"],
          created_at: isThereChange.array["created_at"],
          // organization: isThereChange.array["organization"],
          address: isThereChange.array["address"],
       
        },output:"formData"
      });
      unitPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "projects/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };


  return (<>
    <Dialog open={open} onClose={onClose} paperProps={{ height: "100%" }}>
      <DialogHeading>{globalLang.editProject[lang]}</DialogHeading>
      <DialogForm>
        <DialogInputField
          label={globalLang.address[lang]}
          placeholder={globalLang.address[lang]}
          value={controls.address}
          onChange={(e) => setControl("address", e.target.value)}
        />
         <DialogInputField
          label={globalLang.picturejust[lang]}
          placeholder={globalLang.picturejust[lang]}
          onChange={(e) => {
            
           setControl("logo", e.target.files[0])
        
          }}
          inputProps={{ accept: 'image/*' }}
          type="file"
        />
        <DialogInputField
          placeholder={globalLang.comment[lang]}
          label={globalLang.comment[lang]}
          value={controls.comment}
          onChange={(e) => setControl("comment", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.name[lang]}
          label={globalLang.name[lang]}
          value={controls.name}
          onChange={(e) => setControl("name", e.target.value)}
        />
        {/* <DialogInputField
          placeholder={globalLang.organization[lang]}
          label={globalLang.organization[lang]}
          value={controls.organization}
          onChange={(e) => setControl("organization", e.target.value)}
        /> */}
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={unitPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
   
    </Dialog>
       {unitPatchResponse.failAlert}
       {unitPatchResponse.successAlert}</>
  );
};
const InfoDialog = ({ open, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const [rows, setrows] = useState([]);
  const globalLang = useSelector((state) => state.lang.value.global);
 
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      {
        control: "name",
        value: data?.name,
        isRequired: false,
      },
      {
        control: "address",
        value: data?.address,
        isRequired: false,
      },
      {
        control: "comment",
        value: data?.comment,
        isRequired: false,
      },
      {
        control: "created_at",
        value: data?.created_at,
        isRequired: false,
      },
      {
        control: "logo",
        value: data?.logo,
        isRequired: false,
      },
      {
        control: "description",
        value: data?.description,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
   
  }, [open]);

  
  function createData(
    name,
    phone,
    min_price,
    max_price,
    history_date,
    property_type,
    created_by
  ) {
    return {
      name,
      phone,
      min_price,
      max_price,
      history_date,
      property_type,
      created_by,
    };
  }

useAfterEffect(()=>{

},[rows])
  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "16px",
          minWidth: "600px",
        },
      }}
    >
      <DialogHeading>
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          {globalLang.detailProject[lang]}
        </Typography>
        
           
      </DialogHeading>
      <Typography
              sx={{
                position: "relative",
                width: "20%",
                height: "20%",
                marginLeft: lang === "ar" ? "20px" : "",
                marginRight: lang === "en" ? "20px" : "",
              }}
            >
              <img
                src={controls.logo}
                alt=""
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "black",
                  height: "100px",
                }}
              />
              {/* <MuiIconButton
                color="#f8c6c6"
                iconColor="red"
                onClick={(event) => {
                  // setimageid(item.id);
                  // event.stopPropagation();
                  // onDelete(event, row);
                }}
                sx={{
                  position: "absolute",
                  top: "-5px",
                  left: lang === "en" ? "78%" : "",
                  right: lang === "ar" ? "78%" : "",
                  backgroundColor: "#f8c6c6",
                  color: "red",
                }}
              >
                <DeleteIcon sx={{ width: 20, height: 20 }} />
              </MuiIconButton> */}
            </Typography>
        
      <DialogContent sx={styledialog}>
        <Table border={0}>
          <TableHead>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.name[lang]}</TableCell>
              <TableCell sx={styletd}>{controls.name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.address[lang]}</TableCell>
              <TableCell sx={styletd}>{controls.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.comment[lang]}</TableCell>
              <TableCell sx={styletd}>{controls.comment}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>
                {globalLang.createdAt[lang]}
              </TableCell>
              <TableCell sx={styletd}>{controls.createdAt}</TableCell>
            </TableRow>
         
          </TableBody>
        </Table>
      </DialogContent>
      

     
    </Dialog>
  );
};
const NameFilter = ({ value, onChange, nameQuery,type="text" }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: [nameQuery, e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={nameQuery}
      value={value}
      onChange={handleChange}
      type={type}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [{ controls ,invalid,required}, { setControl, resetControls,setInvalid, validate}] = useControls(
    [
      {
        control: "startPoint",
        value:value.start,
        isRequired: true,
      },
      {
        control: "endPoint",
        value: value.end,
        isRequired: true,
      },
      // {
      //   control:"created_at",
      //   value:"",
      //   isRequired:false
        
      // }
    ],
    [value]
  );
  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!controls.startPoint && !controls.endPoint) return;
    validate().then((output) => {
      if (!output.isOk) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(controls.startPoint))}-${formatDate(String(controls.endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(controls.startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(controls.endPoint))}`,
      value: {
        start: controls.startPoint,
        end: controls.endPoint,
      },
    })
      })
  }, [controls.startPoint, controls.endPoint]);

  const handleChangeStartPoint = (e) => {
    setControl("startPoint",e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    if(controls.startPoint &&controls.startPoint>e.target.value){
      setInvalid((prevState) => ({
        ...prevState,
        endPoint: "End date should be greater than start date.",
      }));
    }else{
      setInvalid((prevState) => ({ ...prevState, endPoint: "" }));
      setControl("endPoint",e.target.value);

    }
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={controls?.startPoint}
        onChange={handleChangeStartPoint}
        required={required.includes("startPoint")}
        error={Boolean(invalid.startPoint)}
        helperText={invalid.startPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={controls?.endPoint}
        onChange={handleChangeEndPoint}
        required={required.includes("endPoint")}
        error={Boolean(invalid.endPoint)}
        helperText={invalid.endPoint}
      />
    </Stack>
  );
};
