import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import GroupsIcon from "@mui/icons-material/Groups";
import StoreIcon from "@mui/icons-material/Store";
import BadgeIcon from "@mui/icons-material/Badge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import Home from "../pages/Home";

import AddClients from "../pages/client/AddClients";
import AddStatus from "../pages/client/AddStatus";
import DeletedClients from "../pages/client/DeletedClients";
import ExportClients from "../pages/client/ExportClients";
import ExportRegistry from "../pages/client/ExportRegistry";
import ImportClients from "../pages/client/ImportClients";
import ImportRegistry from "../pages/client/ImportRegistry";
import Overview from "../pages/client/Overview";
import ViewClients from "../pages/client/ViewClients";
import ViewStatus from "../pages/client/ViewStatus";
import AddProjects from "../pages/project/AddProjects";
import ViewProjects from "../pages/project/ViewProjects";
import { InfoDialog } from "../pages/client/ViewClients";
import AddEmployees from "../pages/employee/AddEmployees";
import AddJobs from "../pages/employee/AddJobs";
import ViewEmployees from "../pages/employee/ViewEmployees";
import ViewJobs from "../pages/employee/ViewJobs";
import icon from "../assets/icon.png";
import AddChannels from "../pages/channel/AddChannels";
import ViewChannels from "../pages/channel/ViewChannels";

import AddUnits from "../pages/unit/AddUnits";
import ViewUnits from "../pages/unit/ViewUnits";

import Setting from "../pages/control/Setting";
import Statistics from "../pages/fe-khidmtak/Statistics";
import Clients from "../pages/fe-khidmtak/Clients";
import Viewcustomercases from "../pages/fe-khidmtak/Viewcustomercases";
import Units from "../pages/fe-khidmtak/Units";
import Users from "../pages/fe-khidmtak/Users";
import Addclients from "../pages/fe-khidmtak/Addclients";
import Addusers from "../pages/fe-khidmtak/Addusers";
import Addunit from "../pages/fe-khidmtak/Addunit";
import AddRequest from "../pages/fe-khidmtak/AddRequest";
import Requests from "../pages/fe-khidmtak/Requests";
import AddContract from "../pages/contract/AddContract";
import ViewContract from "../pages/contract/ViewContract";
import Transfers from "../pages/fe-khidmtak/Transfers";

const pages = [
  {
    name: {
      ar: "الرئيسية",
      en: "Home",
    },
    icon: <AutoAwesomeMosaicIcon />,
    path: "/",
    element: <Home />,
    permitted: [],
  },
  {
    name: {
      ar: "الموظفين",
      en: "Employees",
    },
    icon: <BadgeIcon />,
    path: "/employees",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة وظيفة موظف",
        //   en: "Add an employee job",
        // },
        name_en: "Add an employee job",
        name: "إضافة وظيفة موظف",
        path: "/create-jobs",
        element: <AddJobs />,
        permitted: ["add_aqarjob"],
      },
      {
        // name: {
        //   ar: "عرض وظائف الموظفين",
        //   en: "View employee jobs",
        // },
        name_en: "View employee jobs",
        name: "عرض وظائف الموظفين",
        path: "/view-jobs",
        element: <ViewJobs />,
        permitted: ["view_aqarjob"],
      },
      {
        // name: {
        //   ar: "إضافة موظف",
        //   en: "Add an employee",
        // },
        name_en: "Add an employee",
        name: "إضافة موظف",
        path: "/create-employees",
        element: <AddEmployees />,
        permitted: ["add_aqaremployee"],
      },
      {
        // name: {
        //   ar: "عرض الموظفين",
        //   en: "View the staff",
        // },
        name_en: "View the staff",
        name: "عرض الموظفين",
        path: "/view-employees",
        element: <ViewEmployees />,
        permitted: ["view_aqaremployee"],
      },
    ],
  },
  {
    name: {
      ar: "العملاء",
      en: "Customers",
    },
    icon: <GroupsIcon />,
    path: "/clients",
    subtabs: [
      {
        // name: {
        //   ar: "الإحصائيات",
        //   en: "statistics",
        // },
        name_en: "Statistics",
        name: "الإحصائيات",
        path: "/overview",
        element: <Overview />,
        permitted: ["aqarstatistics"],
      },
      {
        // name: {
        //   ar: "إضافة عميل جديد",
        //   en: "Add a new customer",
        // },
        name_en: "Add a new customer",
        name: "إضافة عميل جديد",
        path: "/create-client",
        element: <AddClients />,
        permitted: ["add_aqarclient"],
      },

      {
        // name: {
        //   ar: "جميع العملاء",
        //   en: "all clients",
        // },
        name_en: "All clients",
        name: "جميع العملاء",
        path: "/view-clients",
        element: <ViewClients />,
        permitted: ["view_aqarclient"],
      },
      {
        // name: {
        //   ar: "العملاء الحذوفة",
        //   en: "Deleted clients",
        // },
        name_en: "Deleted clients",
        name: "العملاء المحذوفين",
        path: "/deleted-clients",
        element: <DeletedClients />,
        permitted: ["can_restoreclient"],
      },
      {
        // name: {
        //   ar: "إضافة حالة عميل",
        //   en: "Add a customer case",
        // },
        name_en: "Add a customer status",
        name: "إضافة حالة عميل",
        path: "/create-status",
        element: <AddStatus />,
        permitted: ["add_aqarevent"],
      },
      {
        // name: {
        //   ar: "عرض حالات العملاء",
        //   en: "View customer cases",
        // },
        name_en: "View customer status",
        name: "عرض حالات العملاء",
        path: "/view-status",
        element: <ViewStatus />,
        permitted: ["view_aqarevent"],
      },
      {
        // name: {
        //   ar: "إستيراد عملاء",
        //   en: "import clients",
        // },
        name_en: "Export clients",
        name: "تصدير عملاء",
        path: "/export-clients",
        element: <ImportClients />,
        permitted: ["aqarexport_file"],
      },
      {
        // name: {
        //   ar: "تصدير عملاء",
        //   en: "export clients",
        // },
        name_en: "Import clients",
        name: "استيراد عملاء",
        path: "/import-clients",
        element: <ExportClients />,
        permitted: ["aqarimport_file"],
      },
      {
        // name: {
        //   ar: "سجل الإستيراد",
        //   en: "import log",
        // },
        name_en: "Export log",
        name: "سجل التصدير",
        path: "/export-records",
        element: <ImportRegistry />,
        permitted: ["view_aqarimportexportfiels"],
      },
      {
        // name: {
        //   ar: "سجل التصدير",
        //   en: "export record",
        // },
        name_en: "Import record",
        name: "سجل الإستيراد",
        path: "/import-records",
        element: <ExportRegistry />,
        permitted: ["view_aqarimportexportfiels"],
      },
    ],
  },
  {
    name: {
      ar: "المشاريع",
      en: "Projects",
    },
    icon: <StoreIcon />,
    path: "/projects",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة مشروع",
        //   en: "add project",
        // },
        name_en: "Add project",
        name: "إضافة مشروع",
        path: "/create-projects",
        element: <AddProjects />,
        permitted: ["add_aqarproject"],
      },
      {
        // name: {
        //   ar: "عرض المشاريع",
        //   en: "View projects",
        // },
        name_en: "View projects",
        name: "عرض المشاريع",
        path: "/view-projects",
        element: <ViewProjects />,
        permitted: ["view_aqarproject"],
      },
    ],
  },
  {
    name: {
      ar: "القنوات",
      en: "Channels",
    },
    icon: <AddCircleIcon />,
    path: "/channels",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة قناة",
        //   en: "Add channel",
        // },
        name_en: "Add channel",
        name: "إضافة قناة",
        path: "/create-channels",
        element: <AddChannels />,
        permitted: ["add_aqarchannel"],
      },
      {
        // name: {
        //   ar: "عرض القنوات",
        //   en: "View channels",
        // },
        name_en: "View channels",
        name: "عرض القنوات",
        path: "/view-channels",
        element: <ViewChannels />,
        permitted: ["view_aqarchannel"],
      },
    ],
  },
  {
    name: {
      ar: "الوحدات",
      en: "Units",
    },
    icon: <ApartmentIcon />,
    path: "/units",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة وحدة",
        //   en: "add unit",
        // },
        name_en: "Add unit",
        name: "إضافة وحدة",
        path: "/create-units",
        element: <AddUnits />,
        permitted: ["add_aqarunit"],
      },
      {
        // name: {
        //   ar: "عرض الوحدات",
        //   en: "Show units",
        // },
        name_en: "Show units",
        name: "عرض الوحدات",
        path: "/view-units",
        element: <ViewUnits />,
        permitted: ["view_aqarunit"],
      },
    ],
  }, {
    name: {
      ar: "عقود",
      en: "Contract",
    },

    icon: <PictureAsPdfSharpIcon />,
    path: "/contract",
    subtabs: [
      {
        // name: {
        //   ar: "تغيير الرقم السري",
        //   en: "Change Password",
        // },
        name_en: "Add contract",
        name: "اضافه ملف",
        path: "/addcontract",
        element: <AddContract />,
        permitted: ["add_contract"],
      },{
        // name: {
        //   ar: "تغيير الرقم السري",
        //   en: "Change Password",
        // },
        name_en: "Contracts",
        name: "ملفات",
        path: "/contracts",
        element: <ViewContract />,
        permitted: ["view_contract"],
      },
    ],
    permitted:["view_contract","add_contract"]
  },
  {
    name: {
      ar: "Fe khidmtak",
      en: "Fe khidmtak",
    },

    icon: <img src={icon} alt="" />,
    path: "/fe-khidmtak",
    subtabs: [
      {
        // name: {
        //   ar: "تغيير الرقم السري",
        //   en: "Change Password",
        // },
        name_en: "Statistics",
        name: "الإحصائيات",
        path: "/statistics",
        element: <Statistics />,
        permitted: ["fe_khedemtak_employee"],
      },
      {
        name_en: "Clients",
        name: "العملاء",
        path: "/clients",
        element: <Clients />,
        permitted: ["fe_khedemtak_employee"],
      },

      // {
      //   // name: {
      //   //   ar: "تغيير الرقم السري",
      //   //   en: "Change Password",
      //   // },
      //   name_en: "View customer cases",
      //   name: "عرض حالات العملاء",
      //   path: "/viewcustomercases",
      //   element: <Viewcustomercases />,
      //   permitted: [],
      // },
      {
        name_en: "Units",
        name: "الوحدات",
        path: "/units",
        element: <Units />,
        permitted: ["fe_khedemtak_employee"],
      },
      {
        name_en: "Jokers",
        name: "الجوكرز",
        path: "/users",
        element: <Users />,
        permitted: ["fe_khedemtak_employee"],
      },

      {
        name_en: "Requests",
        name: "طلبات علي وحدات",
        path: "/requests",
        element: <Requests />,
        permitted: ["fe_khedemtak_employee"],
      },
      
    ],
    permitted: ["fe_khedemtak_employee"],
  },
  null,
  {
    name: {
      ar: "التحكم",
      en: "Control",
    },

    icon: <SettingsIcon />,
    path: "/control",
    subtabs: [
      {
        // name: {
        //   ar: "تغيير الرقم السري",
        //   en: "Change Password",
        // },
        name_en: "Setting",
        name: "الاعدادات",
        path: "/setting",
        element: <Setting />,
        permitted: [],
      },
    ],
  },
 
 
  
  null
];

export default pages;
