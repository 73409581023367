import React from "react";
import PropTypes from "prop-types";
import { Box, Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { useSelector } from "react-redux";

const Breadcrumbs = ({ path }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);

  return (
    <Box sx={{ p: "20px 0" }}>
      {lang === "ar" ? (
        <MuiBreadcrumbs
          separator={
            <KeyboardDoubleArrowLeftIcon
              sx={{
                transform: lang === "en" ? "rotate(180deg)" : "rotate(0deg)",
              }}
              color="primary"
            />
          }
        >
          {Boolean(path?.length) &&
            path?.map((location, index) => (
              <Link
                underline="none"
                sx={{ cursor: "pointer" }}
                color="primary.main"
                key={index}
                // to={location}
              >
                {String(location)}
              </Link>
            ))}
        </MuiBreadcrumbs>
      ) : (
        <MuiBreadcrumbs
          separator={
            <KeyboardDoubleArrowRightIcon
              sx={{
                transform: lang === "ar" ? "rotate(180deg)" : "rotate(0deg)",
              }}
              color="primary"
            />
          }
        >
          {Boolean(path?.length) &&
            path?.map((location, index) => (
              <Link
                underline="none"
                sx={{ cursor: "pointer" }}
                color="primary.main"
                key={index}
                // to={location}
              >
                {String(location)}
              </Link>
            ))}
        </MuiBreadcrumbs>
      )}
    </Box>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
};
