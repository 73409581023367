import {
  Avatar,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { InputField, PhoneField } from "../../features/form";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import {
  CLIENTS_FEkhedmetak,
  CLIENTS_FEkhedmetak_history,
  COUNTRY_FILTER,
  EMPLOYEES,
  EMPLOYEES_PERMISSIONS,
  JOBS,
  JOB_PERMISSIONS,
  STATES,
} from "../../data/APIs";
import useDataGrid from "../../hooks/useDataGrid";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import compare from "../../utils/Compare";
import {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInputField,
  DialogPhoneField,
  DialogSelectField,
} from "../../features/dialog";
import PermissionToggles from "../../components/PermissionToggles";
import useControls from "../../hooks/useControls";
import useAfterEffect from "../../hooks/useAfterEffect";
import { styledialog, stylerow, styletd } from "../../css/tableview";
import useSelectMessage from "../../hooks/useSelectMessage";
import Wrapper from "../../components/Wrapper";
import format from "../../utils/ISOToReadable";
function Clients() {
  const clientsStore = useSelector((state) => state.fekhidmaticClients.value);
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(null);
  const lang = useSelector((state) => state.lang.value.lang);
  const clientsfekhidmtak = useSelector(
    (state) => state.lang.value.clientsfekhidmtak
  );
  const [infoData, setInfoData] = useState(null);
  const viewEmployeesLang = useSelector(
    (state) => state.lang.value.viewEmployees
  );
  // const confirmMessage = useSelector(
  //   (state) => state.lang.value.DeleteMessages.EmployeeMessage
  // );

  const globalLang = useSelector((state) => state.lang.value.global);
  const [clientGetRequest, clientGetResponse] = useRequest({
    path: CLIENTS_FEkhedmetak,
    method: "get",
  });
  const handleGetClient = (urlParams) => {
    clientGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "fekhclients/set", payload: res.data });
      },
    });
  };
  const NameFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;
      let match = text.test(e.target.value);
      if (match) {
        onChange({
          query: [nameQuery, e.target.value],
          renderedValue: e.target.value,
          value: e.target.value,
        });
      }
    };

    return (
      <InputField
        placeholder={nameQuery}
        value={value}
        onChange={handleChange}
        type={type}
      />
    );
  };
  const NumberFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
      const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0); // Ensure value is not NaN and greater than or equal to 0

      onChange({
        query: [nameQuery, newValue],
        renderedValue: newValue,
        value: newValue,
      });
    };

    return (
      <InputField
        placeholder={nameQuery}
        value={value}
        onChange={handleChange}
        type={type}
      />
    );
  };
  const SelectFilter = ({ value, onChange }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      onChange({
        query: ["property_type", e.target.value],
        renderedValue: e.target.value,
        value: e.target.value,
      });
    };

    return (
      <>
        <DialogSelectField
          label={globalLang.property_type[lang]}
          placeholder={value}
          onChange={handleChange}
        >
          <MenuItem value="rent">rent</MenuItem>
          <MenuItem value="sell">sell</MenuItem>
        </DialogSelectField>
      </>
      // <InputField
      //   placeholder={globalLang.name[lang]}
      //   value={value}
      //   onChange={handleChange}
      // />
    );
  };
  const PhoneFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);
    const [{ controls,invalid,required }, { setControl, resetControls }] = useControls([
      {
        control: "code",
        value: "+20",
        isRequired: false,
      },
      {
        control: "phone",
        value: "",
        isRequired: false,
      },
  
    ])
    ///////////////////////////////
    const handleChange = (value) => {
      // const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
      // const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0); // Ensure value is not NaN and greater than or equal to 0
      setControl('phone',value)
      let text =  /^(?:\+[0-9\d]*)$/;
      let match = text.test(controls.code+value)
     
      if(match){
        onChange({
          query: [nameQuery, controls.code+value],
          renderedValue:controls.code+value,
          value: controls.code+value,
        });
      }
    
    };
    

    return (
      <PhoneField
          label={globalLang.phone[lang]}
          placeholder={globalLang.phone[lang]}
         
          requiredCode
          selectProps={{
            value: controls.code,
            onChange: (e) => setControl("code", e.target.value),
          }}
          onChange={(e) =>handleChange(e.target.value)}
          // onBlur={}
          value={controls.phone}

         
        />
    );
  };
  const { handlePaginate, handleChangeAmount, handleFilter, requestParams } =
    useDataGrid({
      onParamsChange: handleGetClient,
    });
  const [employeeDeteleRequest, employeeDeleteResponse] = useRequest({
    path: CLIENTS_FEkhedmetak,
    method: "delete",
    successMessage: globalLang.detelesuccess[lang],
  });
  const deleteEmployee = (e, row) => {
    // if (Boolean(row.client_count)) {
    //   handlePreventDeleteEmployee();
    //   return;
    // }
    employeeDeteleRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "fekhclients/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteEmployee, deleteEmployeeConfirmDialog] = useConfirmMessage(
    {
      onConfirm: deleteEmployee,
      text: globalLang.makesureofdelete[lang],
    }
  );

  const [openEditInfo, setOpenEditInfo] = useState(null);

  const handleOpenEdit = (e, row) => {
    setOpenEditInfo(row);
  };

  const [openEditPassword, setOpenEditPassword] = useState(null);

  const userInfo = useSelector((state) => state.userInfo.value);

  const isPermitted = useIsPermitted();

  // console.log(fekhclientsStore.results);
  let columns = [
    {
      field: "id",
      headerName: globalLang.id[lang],
      customContent: (params) => params.id,
    },
    {
      field: "username",
      headerName: globalLang.name[lang],
      customContent: (params) => params.name,
      customEmpty: "-",
    },
    {
      field: "phone",
      headerName: globalLang.phone[lang],
      customContent: (params) => params.country_code + params.phone,
      customEmpty: "-",
    },
    {
      field: "min_price",
      headerName: globalLang.min_price[lang],
      customContent: (params) => params.min_price,
      customEmpty: "0",
    },
    {
      field: "max_price",
      headerName: globalLang.max_price[lang],
      customContent: (params) => params.max_price,
      customEmpty: "0",
    },
    {
      field: "property_type",
      headerName: globalLang.property_type[lang],
      customContent: (params) => params.property_type,
      customEmpty: "-",
    },
    // {
    //   field: "created_by",
    //   headerName: globalLang.createdBy[lang],
    //   customContent: (params) => params.created_by,
    //   customEmpty: "-",
    // },
    {
      field: "created_by_username",
      headerName: globalLang.createdByUserName[lang],
      customContent: (params) => params.created_by_username,
      customEmpty: "-",
    },
    {
      field: "created_by_name",
      headerName: globalLang.createdByName[lang],
      customContent: (params) => params.created_by_name,
      customEmpty: "-",
    },
    {
      field: "description",
      headerName: globalLang.comments[lang],
      customContent: (params) => params.description,
      customEmpty: "-",
    },
  ];

  const [gotoclientRequest, gotoclientResponse] = useRequest({
    path: "fe-khedmetak/client/" + "transfer/",
    method: "post",
    successMessage: globalLang.successtransfer[lang],
  });
  const transferunit = (e, row, result) => {
    gotoclientRequest({
      id: row.id,
      body: result,
      onSuccess: (res) => {
        if (result.deleted) {
          dispatch({ type: "fekhclients/deleteItem", payload: { id: row.id } });
        } else {
          // dispatch({ type: "fekhclients/set", payload: unitsStore });
          console.log(requestParams);
          const urlParams = new URLSearchParams();

          Object.values(requestParams).map((item) =>
            item.map(([key, value]) => {
              if (!Array.isArray(key)) {
                urlParams.append(key, value);
              } else {
                urlParams.append(key[0], key[1]);
                urlParams.append(value[0], value[1]);
              }
            })
          );
          // urlParams.append
          delete requestParams.currentPage;
          // delete requestParams.page

          handleGetClient(urlParams);
          // handleGetUnit();
        }
      },
    });
  };

  const [handleparent, gotoparent, result] = useSelectMessage({
    onConfirm: (e, row) => transferunit(e, row, result),
    text: globalLang.makesureofclienttransfer[lang],
    is_aqar_: "is_aqar_client",
  });
  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter nameQuery={"name"} />,
    },
    {
      name: globalLang.min_price[lang],
      component: <NumberFilter nameQuery={"min_price"} type="number" />,
    },
    {
      name: globalLang.property_type[lang],
      component: <SelectFilter />,
    },
    {
      name: globalLang.id[lang],
      component: <NumberFilter nameQuery={"id"} type="number" />,
    },
    {
      name: globalLang.max_price[lang],
      component: <NumberFilter nameQuery={"max_price"} type="number" />,
    },{
      name: globalLang.phone[lang],
      component: <PhoneFilter nameQuery={"phone"}  />,
    },
  ];

  return (
    <>
      <Wrapper>
        <Typography
          variant="h5"
          sx={{ textAlign: lang === "ar" ? "left" : "right", marginY: "24px" }}
          color="primary"
        >
          {clientsfekhidmtak.title[lang]}
        </Typography>
        <DataGrid
          columns={columns}
          rows={clientsStore.results}
          total={clientsStore.count}
          isPending={clientGetResponse.isPending}
          onDelete={handleDeleteEmployee}
          // onBlock={handleBlockEmployee}
          // onChangePassword={(e, row) => setOpenEditPassword(row.id)}
          onEdit={(e, row) => setEditData(row)}
          onPaginate={handlePaginate}
          onAmountChange={handleChangeAmount}
          onFilter={handleFilter}
          onView={(e, row) => setInfoData(row)}
          filters={filters}
          onTransfer={handleparent}
        />
        <EditDialog
          open={Boolean(editData)}
          onClose={() => setEditData(null)}
          data={editData}
        />
        {/* <EditInfoDialog
          open={Boolean(openEditInfo)}
          onClose={() => setOpenEditInfo(null)}
          data={openEditInfo}
        /> */}
        <InfoDialog
          open={Boolean(infoData)}
          onClose={() => setInfoData(null)}
          data={infoData}
        />
        {gotoparent}
        {gotoclientResponse.failAlert}
        {gotoclientResponse.successAlert}
        {deleteEmployeeConfirmDialog}
        {employeeDeleteResponse.successAlert}
        {employeeDeleteResponse.failAlert}
      </Wrapper>
    </>
  );
}

export default Clients;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, validate, setInvalid },
  ] = useControls(
    [
      {
        control: "name",
        value: data?.name,
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },
      {
        control: "min_price",
        value: data?.min_price,
        isRequired: false,
      },
      {
        control: "max_price",
        value: data?.max_price,
        isRequired: false,
        validations: [
          {
            customValidation: (controls) =>
              controls.max_price > controls.min_price,
            message: globalLang.greatpricethanmin[lang],
          },
        ],
      },
      {
        control: "property_type",
        value: data?.property_type,
        isRequired: false,
      },
      {
        control: "description",
        value: data?.description,
        isRequired: false,
      },
      {
        control: "country_code",
        value: data?.country_code ? data?.country_code : "+20",
        isRequired: false,
      }, {control:"status",value:data?.status,isRequired:false},
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: CLIENTS_FEkhedmetak,
    method: "patch",
    successMessage: globalLang.successpatchclient[lang],
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const isThereChange = compare(
        [
          [controls.name, data?.name, "name"],
          [controls.phone, data?.phone, "phone"],
          [controls.status,data.status,"status"],
          // [controls.country_code, data?.country_code, "country_code"],
          // [controls.password, data.city],
          [Number(controls.min_price), data?.min_price, "min_price"],
          [Number(controls.max_price), data?.max_price, "max_price"],
          [controls.property_type, data?.property_type, "property_type"],
          [controls.description, data?.description, "description"],
          // [controls.type, data.unit_type],
          // [controls.floor, data.floor_number],
          // [controls.area, data.area_size],
          // [controls.rooms, data.room_number],
          // [controls.bathrooms, data.bath_count],
          // [controls.genre, data.complete_type],
          // [controls.price, data.price],
          // [controls.client, data.client],
          // [controls.phone, data.phone],
          // [controls.countryCode, data.country_code_phone_client],
          // [controls.notes, data.comment],
        ],
        false
      );
      if (isThereChange.nochange) {
        const requestBody = filter({
          obj: {
            name: isThereChange.array["name"],
            phone: isThereChange.array["country_code"]
              ? isThereChange.array["country_code"] +
                isThereChange.array["phone"]
                ? isThereChange.array["phone"]
                : controls.phone
              : isThereChange.array["phone"]
              ? controls.country_code + isThereChange.array["phone"]
              : undefined,
            min_price: isThereChange.array["min_price"],
            max_price: isThereChange.array["max_price"],
            property_type: isThereChange.array["property_type"],
            description: isThereChange.array["description"],
            status: isThereChange.array["status"],
          },
        });

        unitPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => {
            dispatch({
              type: "fekhclients/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
            // resetControls()
          },
        }).then((res) => {
          let response = res?.response?.data;
          const responseBody = filter({
            obj: {
              name: response?.name,
              phone: response?.phone,
              min_price: response?.min_price,
              max_price: response?.max_price,
              property_type: response?.property_type,
              description: response?.description,
              status: response?.status,
            },
            output: "object",
          });

          setInvalid(responseBody);
        });
      } else {
        // resetControls()
        onClose();
      }
    });
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    if (countriesData.length) return;
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = () => {
    // if (governoratesData.length) return;
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };

  //===End===== Get Countries logic =========

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "100%" }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "16px",
            minWidth: "50%",
          },
        }}
      >
        <DialogHeading>
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "20px",
            }}
          >
            {globalLang.dataofclients[lang]}
          </Typography>
        </DialogHeading>
        <DialogForm sx={{ border: "1px solid gray" }}>
          <DialogInputField
            label={globalLang.name[lang]}
            placeholder={globalLang.name[lang]}
            value={controls.name}
            onChange={(e) => {
              setControl("name", e.target.value);
            }}
            type="text"
            required={required.includes("name")}
            error={Boolean(invalid.name)}
            helperText={invalid.name}
          />

          {/* </DialogInputField> */}
          <DialogInputField
            label={globalLang.min_price[lang]}
            placeholder={globalLang.min_price[lang]}
            // onOpen={getGovernorates}
            // isPending={statesGetResponse.isPending}
            value={controls.min_price}
            onChange={(e) => {
              const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
              const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0);
              setControl("min_price", newValue);
            }}
            type="number"
            required={required.includes("min_price")}
            error={Boolean(invalid.min_price)}
            helperText={invalid.min_price}
          />
            <DialogSelectField
          placeholder={globalLang.status[lang]}
          label={globalLang.status[lang]}
          value={controls.status}
          onChange={(e) => setControl("status", e.target.value)}
          renderValue={(selected) => {
            return ['deal','nodeal','communication'].find((mean) => mean === selected);
          }}
          // error={Boolean(invalid.status)}
          // helperText={invalid.status}
        >
          {['deal','nodeal','communication'].map((mean, index) => (
            <MenuItem value={mean} key={`${mean} ${index}`}>
              {mean}
            </MenuItem>
          ))}
        </DialogSelectField>
          <DialogInputField
            label={globalLang.max_price[lang]}
            placeholder={globalLang.max_price[lang]}
            value={controls.max_price}
            onChange={(e) => {
              const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
              const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0);
              setControl("max_price", newValue);
            }}
            type="number"
            required={required.includes("max_price")}
            error={Boolean(invalid.max_price)}
            helperText={invalid.max_price}
          />
          <DialogPhoneField
            placeholder={globalLang.customerPhone[lang]}
            label={globalLang.customerPhone[lang]}
            selectProps={{
              value: controls.country_code,
              onChange: (e) => setControl("countryCode", e.target.value),
            }}
            value={controls.phone}
            onChange={(e) => setControl("phone", e.target.value)}
            required={required.includes("phone")}
            error={Boolean(invalid.phone)}
            helperText={invalid.phone}
          />

          <DialogSelectField
            label={globalLang.property_type[lang]}
            placeholder={
              controls.property_type
                ? controls.property_type
                : data?.property_type
            }
            renderValue={controls.property_type}
            onChange={(e) => setControl("property_type", e.target.value)}
            required={required.includes("property_type")}
            error={Boolean(invalid.property_type)}
            helperText={invalid.property_type}
          >
            <MenuItem value="rent">rent</MenuItem>
            <MenuItem value="sell">sell</MenuItem>
          </DialogSelectField>
          <DialogInputField
            label={globalLang.description[lang]}
            placeholder={globalLang.description[lang]}
            value={controls.description}
            onChange={(e) => setControl("description", e.target.value)}
            required={required.includes("description")}
            error={Boolean(invalid.description)}
            helperText={invalid.description}
          />
        </DialogForm>
        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            disabled={unitPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
      </Dialog>
      {unitPatchResponse.failAlert}
      {unitPatchResponse.successAlert}
    </>
  );
};
const InfoDialog = ({ open, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const [rows, setrows] = useState([]);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [dataresult, setdataresult] = useState({});
  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      {
        control: "name",
        value: data?.name,
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },
      {
        control: "min_price",
        value: data?.min_price,
        isRequired: false,
      },
      {
        control: "max_price",
        value: data?.max_price,
        isRequired: false,
      },
      {
        control: "property_type",
        value: data?.property_type,
        isRequired: false,
      },
      {
        control: "description",
        value: data?.description,
        isRequired: false,
      },
      {
        control: "country_code",
        value: data?.country_code,
        isRequired: false,
      },
      {
        control: "created_by_username",
        value: data?.created_by_username,
        isRequired: false,
      },
      {
        control: "created_by_name",
        value: data?.created_by_name,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;

    handleSubmit();
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: CLIENTS_FEkhedmetak,
    method: "get",
    // successMessage: "تم  بنجاح",
  });
  const [clienthistoryRequest, clienthistoryResponse] = useRequest({
    path: CLIENTS_FEkhedmetak_history,
    method: "get",
    // successMessage: "تم  بنجاح",
  });
  function createData(
    name,
    phone,
    min_price,
    max_price,
    history_date,
    property_type,
    created_by
  ) {
    return {
      name,
      phone,
      min_price,
      max_price,
      history_date,
      property_type,
      created_by,
    };
  }
  const handleSubmit = () => {
    clienthistoryRequest({
      id: data.id,
      onSuccess: (res) => {
        let rowsdata = [];
        res.data.map((ele) => {
          rowsdata.push(
            createData(
              ele.name,
              ele.phone,
              ele.min_price,
              ele.max_price,
              ele.history_date,
              ele.property_type,
              ele.created_by
            )
          );
        });
        setrows(rowsdata);
        // setControl(...res.data)
      },
    });
  };
  useAfterEffect(() => {}, [rows]);
  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "16px",
          minWidth: "80%",
        },
      }}
    >
      <DialogHeading>
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          {globalLang.detailClients[lang]}
        </Typography>
      </DialogHeading>

      <DialogContent sx={styledialog}>
        <Table border={0} sx={{ display: "block", width: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.name[lang]}</TableCell>
              <TableCell sx={styletd}>{controls.name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.phone[lang]}</TableCell>
              <TableCell sx={styletd}>
                {controls.country_code + controls.phone}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.min_price[lang]}</TableCell>
              <TableCell sx={styletd}>{controls.min_price}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>
                {globalLang.property_type[lang]}
              </TableCell>
              <TableCell sx={styletd}>{controls.property_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>{globalLang.max_price[lang]}</TableCell>
              <TableCell sx={styletd}>{controls.max_price}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>
                {globalLang.createdByUserName[lang]}
              </TableCell>
              <TableCell sx={styletd}>{controls.created_by_username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>
                {globalLang.createdByName[lang]}
              </TableCell>
              <TableCell sx={styletd}>{controls.created_by_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={stylerow}>
                {globalLang.description[lang]}
              </TableCell>
              <TableCell sx={styletd}>{controls.description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogHeading>
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          {globalLang.detaildataclient[lang]}
        </Typography>
      </DialogHeading>
      <DialogContent sx={styledialog}>
        <Table border={0}>
          <TableCell sx={stylerow}>{globalLang.name[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.phone[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.min_price[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.max_price[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.history_date[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.property_type[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.created_by[lang]}</TableCell>

          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.min_price}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.max_price}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {format(row.history_date)}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {" "}
                  {row.property_type}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {" "}
                  {row.created_by}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>

      {unitPatchResponse.failAlert}
      {unitPatchResponse.successAlert}
      {clienthistoryResponse.failAlert}
      {clienthistoryResponse.successAlert}
    </Dialog>
  );
};
//   const lang = useSelector((state) => state.lang.value.lang);
//   const globalLang = useSelector((state) => state.lang.value.global);

//   ///////////////////////////////
//   const userInfo = useSelector((state) => state.userInfo.value);

//   const dispatch = useDispatch();

//   const [{ controls, invalid }, { setControl, validate }] = useControls(
//     [
//       {
//         control: "name",
//         value: `${data?.user?.first_name} ${data?.user?.last_name}`,
//       },
//       {
//         control: "email",
//         value: data?.user?.email,
//         validations: [
//           {
//             test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
//             message: "البريد غير صالح",
//           },
//           ``,
//         ],
//       },
//       {
//         control: "countryCode",
//         value: data?.user?.country_code,
//       },
//       {
//         control: "phone",
//         value: data?.user?.phone,
//         validations: [
//           {
//             customValidation: ({ countryCode }) => countryCode !== "",
//             message: "انت تحاول إضافة كود دولة بدون رقم",
//           },
//         ],
//       },
//       {
//         control: "username",
//         value: data?.user?.username.split("@")[0],
//       },
//       {
//         control: "job",
//         value: data?.job?.id,
//       },
//       {
//         control: "to",
//         value: data?.parent?.id,
//       },
//     ],
//     [data]
//   );

//   const [jobs, setJobs] = useState([]);

//   const [jobsGetRequest, jobsGetResponse] = useRequest({
//     path: JOBS,
//     method: "get",
//   });

//   const getJobs = () => {
//     jobsGetRequest({
//       params: {
//         size: 1000,
//       },
//       onSuccess: (res) => {
//         setJobs(res.data.results);
//       },
//     });
//   };

//   const [employees, setEmployees] = useState([]);

//   const [employeesGetRequest, employeesGetResponse] = useRequest({
//     path: EMPLOYEES,
//     method: "get",
//   });

//   const getEmployees = () => {
//     employeesGetRequest({
//       params: {
//         top: 1,
//         job: controls.job,
//         size: 1000,
//       },
//       onSuccess: (res) => {
//         setEmployees(res.data);
//       },
//     });
//   };

//   const originalEmployeePermissions = useRef();

//   const [employeePermissions, setEmployeePermissions] = useState([]);

//   const [employeePermissionsGetRequest, employeePermissionsGetResponse] =
//     useRequest({
//       path: EMPLOYEES_PERMISSIONS,
//       method: "get",
//     });

//   const getEmployeePermissions = () => {
//     employeePermissionsGetRequest({
//       params: {
//         id: data.id,
//       },
//       onSuccess: (res) => {
//         originalEmployeePermissions.current = res.data.map(
//           (perm) => perm.codename
//         );
//         setEmployeePermissions(res.data.map((perm) => perm.codename));
//       },
//     });
//   };

//   const [permissionsState, setPermissionsState] = useState([]);

//   const [jobPermissionsGetRequest, jobPermissionsGetResponse] = useRequest({
//     path: JOB_PERMISSIONS,
//     method: "get",
//   });

//   const getJobPermissions = () => {
//     jobPermissionsGetRequest({
//       params: {
//         id: controls.job,
//       },
//       onSuccess: (res) => {
//         setPermissionsState(res.data);
//         getEmployeePermissions();
//       },
//     });
//   };

//   useAfterEffect(() => {
//     if (!Boolean(controls.job)) return;
//     getJobPermissions();
//   }, [controls.job]);

//   const [employeePatchRequest, employeePatchResponse] = useRequest({
//     path: EMPLOYEES,
//     method: "patch",
//     successMessage: "تم التعديل بنجاح",
//   });

//   const handleSubmit = () => {
//     const isThereChange = !compare(
//       [
//         [controls.name.split(" ").slice(0, -1).join(" "), data.user.first_name],
//         [controls.name.split(" ").slice(-1).join(" "), data.user.last_name],
//         [controls.email, data.user.email],
//         [controls.job, data.job.id],
//         [controls.to, data.parent],
//         [originalEmployeePermissions.current, employeePermissions],
//         [controls.phone, data?.user?.phone],
//         [controls.countryCode, data?.user?.country_code],
//         [controls.username, data?.user?.username],
//       ],
//       true
//     );

//     if (isThereChange) {
//       validate().then((output) => {
//         const { isOk } = output;
//         if (!isOk) return;
//         console.log(output);
//         const requestBody = filter({
//           obj: {
//             user: {
//               first_name: controls.name.split(" ").slice(0, -1).join(" "),
//               last_name: controls.name.split(" ").slice(-1).join(" "),
//               email: controls.email,
//               username:
//                 !compare([
//                   [
//                     controls.username +
//                       `@${userInfo?.organization?.name?.replace(
//                         /\s/gi,
//                         ""
//                       )}.com`,
//                     data?.user?.username,
//                   ],
//                 ]) &&
//                 controls.username +
//                   `@${userInfo?.organization?.name?.replace(/\s/gi, "")}.com`,
//               phone: controls.countryCode + controls.phone,
//               user_permissions: employeePermissions.map((perm) => ({
//                 codename: perm,
//               })),
//             },
//             parent: controls.to,
//             job: controls.job,
//           },
//         });

//         employeePatchRequest({
//           body: requestBody,
//           id: data.id,
//           onSuccess: (res) => {
//             dispatch({
//               type: "employees/putItem",
//               payload: { id: res.data.id, item: res.data },
//             });
//             onClose();
//           },
//         });
//       });
//     }
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       paperProps={{ height: "100vmax" }}
//       isPending={
//         jobPermissionsGetResponse.isPending ||
//         employeePermissionsGetResponse.isPending
//       }
//     >
//       <DialogHeading>{globalLang.editEmployeeData[lang]}</DialogHeading>
//       <DialogForm>
//         <DialogInputField
//           label={globalLang.name[lang]}
//           placeholder={globalLang.name[lang]}
//           value={controls.name}
//           onChange={(e) => setControl("name", e.target.value)}
//         />
//         <DialogInputField
//           label={globalLang.email[lang]}
//           placeholder={globalLang.email[lang]}
//           value={controls.email}
//           onChange={(e) => setControl("email", e.target.value)}
//           error={Boolean(invalid.email)}
//           helperText={invalid.email}
//         />
//         <DialogInputField
//           label={globalLang.username[lang]}
//           placeholder={globalLang.name[lang]}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment
//                 position="start"
//                 sx={{ direction: "rtl", paddingLeft: "10px" }}
//               >
//                 @{userInfo?.organization?.name?.replace(/\s/gi, "")}.com
//               </InputAdornment>
//             ),
//           }}
//           value={controls.username}
//           onChange={(e) => setControl("username", e.target.value)}
//           error={Boolean(invalid.username)}
//           helperText={invalid.username}
//         />
//         <DialogPhoneField
//           label={globalLang.phone[lang]}
//           placeholder={globalLang.phone[lang]}
//           selectProps={{
//             value: controls.countryCode,
//             onChange: (e) => {
//               setControl("countryCode", e.target.value);
//             },
//           }}
//           value={controls.phone}
//           onChange={(e) => setControl("phone", e.target.value)}
//           error={Boolean(invalid.phone)}
//           helperText={invalid.phone}
//         />
//         <DialogSelectField
//           label={globalLang.job[lang]}
//           placeholder={globalLang.job[lang]}
//           onOpen={getJobs}
//           isPending={jobsGetResponse.isPending}
//           value={controls.job}
//           onChange={(e) => {
//             setControl("job", e.target.value);
//             setControl("to", "");
//           }}
//           renderValue={(selected) => {
//             return Boolean(jobs.find((job) => job.id === controls.job))
//               ? jobs.find((job) => job.id === selected).title
//               : data?.job?.title;
//           }}
//         >
//           {jobs.map((job, index) => (
//             <MenuItem value={job.id} key={`employeeJobEdit ${index}`}>
//               {job.title}
//             </MenuItem>
//           ))}
//         </DialogSelectField>
//         <DialogSelectField
//           label={globalLang.followed[lang]}
//           placeholder={globalLang.followed[lang]}
//           onOpen={getEmployees}
//           isPending={employeesGetResponse.isPending}
//           disabled={!Boolean(controls.job)}
//           value={controls.to}
//           onChange={(e) => setControl("to", e.target.value)}
//           renderValue={(selected) => {
//             return Boolean(employees.find((job) => job.id === controls.to))
//               ? `${
//                   employees.find((job) => job.id === selected).user.first_name
//                 } ${
//                   employees.find((job) => job.id === selected).user.last_name
//                 }`
//               : data?.parent?.name;
//           }}
//         >
//           {employees
//             .filter((employee) => employee.id !== data?.id)
//             .map((employee, index) => (
//               <MenuItem value={employee.id} key={`employeeSuperEdit ${index}`}>
//                 {employee.user.first_name} {employee.user.last_name}
//               </MenuItem>
//             ))}
//         </DialogSelectField>
//         <PermissionToggles
//           permissions={permissionsState}
//           initialToggles={employeePermissions}
//           onToggle={({ toggles }) => setEmployeePermissions(toggles)}
//         />
//       </DialogForm>
//       <DialogButtonsGroup>
//         <DialogButton
//           onClick={handleSubmit}
//           disabled={employeePatchResponse.isPending}
//         >
//           {globalLang.save[lang]}
//         </DialogButton>
//         <DialogButton variant="close" onClick={onClose}>
//           {globalLang.cancel[lang]}
//         </DialogButton>
//       </DialogButtonsGroup>
//       {employeePatchResponse.failAlert}
//     </Dialog>
//   );
// };
