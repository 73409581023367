import React, { useRef } from "react";
import {
  Alert,
  Avatar,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import useControls from "../hooks/useControls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DafaLogo from "../svg/DafaLogo";
import Lottie from "lottie-react";
import loginAnimation from "../assets/LoginAnimation.json";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import useRequest from "../hooks/useRequest";
import BASEURL, { FCMDRIVERS, LOGIN } from "../data/APIs";
import filter from "../utils/ClearNull";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import useRequest from "../hooks/useRequest";
import logo from "../assets/logo.png";
import cypartalogo from "../assets/cypartalogo.png";
import Form, { InputField, PasswordField, PhoneField } from "../features/form";
import cloud from "../assets/Group14.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { gettoken, requestPermission } from "../firebase";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { getToken } from "firebase/messaging";
const Login = () => {
  const sm = useMediaQuery(`(max-width: 1259px)`);
  const [visibilities, setVisibilities] = useState({
    password: false,
  });
  const navigate = useNavigate();
  const lang = useSelector((state) => state.lang.value.lang);
  const [imgProfile, setImageProfile] = useState({});
  const isAvatarPending = false;
  const global = useSelector((state) => state.lang.value.global);
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "company_name",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
  ]);
  const pictureFileInput = useRef();
  const globalLang = useSelector((state) => state.lang.value.global);
  const userInfo = useSelector((state) => state.userInfo.value);
  
  const [isTokenFound, setTokenFound] = useState({
    status:false,
    token:""
  });
  const [loginPostRequest, loginPostResponse] = useRequest({
    path: LOGIN,
    method: "post",
    successMessage:globalLang.logindone[lang],
  });

  const dispatch = useDispatch();
  
  // const sendtoken=(tokenofdevice)=>{
  //   console.log(tokenofdevice,JSON.parse(localStorage.getItem("userInfo")).token)
  //   if(JSON.parse(localStorage.getItem("userInfo"))?.token){
  //     sendtokenRequest({
  //       body:{
  //         registration_id:tokenofdevice,
  //         type:"web"
  //       },onSuccess:(res)=>{
         
  //         // console.log()
  //       },customToken:JSON.parse(localStorage.getItem("userInfo")).token
  //     })
  //   }
    
  // }
  const handleKeypress = e => {
    if (e.key === "Enter") {
      handleLogin(e)
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          username: controls.company_name,
          password: controls.password,
        },
      });

      loginPostRequest({
        body: requestBody,
        noHeaders: true,
        onSuccess: async(res) => {
          dispatch({ type: "userInfo/setToken", payload: res.data.token })
          setTokenFound({status:true,token:res.data.token})
         
          // if (
          //   !userInfo.user_permissions
          //     .map((perm) => perm.codename)
          //     .some((ele) => ele === "well")
          // ) {
            

          //   navigate("/keep");
          // }
          resetControls();
        },
      });
    })
  };
//  useEffect(()=>{
// if(isTokenFound?.token){
//   console.log(isTokenFound)

// }
//  },[userInfo.token,isTokenFound.token])
// useEffect(()=>{
// return async()=>{

  
//   // console.log(result)
//   // if(result?.status){
//   //   sendtoken(result.token)
//   // }
// }
// },[])
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        width: "100%",
        flexWrap: "nowrap",
      }}
    >
      <Box
        sx={{
          width: "24.8%",

          position: "static",
          backgroundColor: (theme) => theme.palette.primary.main,
          display: {
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs:"none"
          },
        }}
      >
        <Stack
          sx={{
            width: "24.8%",
            height: "100vh",
            position: "fixed",
            color: "white",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box
            sx={{
              padding: {
                lg: "48px",
                md: "24px",
                sm: "24px",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={logo} alt="" />
            <h3 style={{ marginTop: "32px" }}>{global.create_acc[lang]}</h3>
            <p style={{ marginTop: "24px" }}>{global.paragraph[lang]}</p>
          </Box>
          <Box sx={{ width: "200px", order: 2 }}>
            <Lottie loop={true} animationData={loginAnimation} />

            <Box>
              <img src={cypartalogo} alt="" />{" "}
              <span>{global.rights[lang]}</span>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Stack
        sx={{
          width: { xl: "80%", lg: "80%", md: "100%", sm: "100%", xs: "100%" },
        }}
      >
        {/* <Stack
        sx={{
          textAlign: "center",
          mx: { xl: "25%", lg: "25%", md: "15%", sm: "10%" },
        }}
      >
       
      </Stack> */}
        <Form hideFooter hideHeader sx={{ mt: "32px" }}>
          <Stack
            sx={{
              gap: 1,
              display: "flex",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <InputField
              label={globalLang.company_name[lang]}
              placeholder={globalLang.company_name[lang]}
              onChange={(e) => setControl("company_name", e.target.value)}
              value={controls.company_name}
              required={required.includes("company_name")}
              error={Boolean(invalid.company_name)}
              helperText={invalid.company_name}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />

            <PasswordField
              label={global.password[lang]}
              placeholder={global.password[lang]}
              required={required.includes("password")}
              value={controls.password}
              onChange={(e) => setControl("password", e.target.value)}
              error={Boolean(invalid.password)}
              helperText={invalid.password}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "80%",
                  xs: "80%",
                },
                mx: "auto",
                mb: "24px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
              onKeyPress={handleKeypress}

            />

            <Link to="/forgetpassword" style={{ textDecoration: "none"}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: lang === "ar" ? "flex-end" : "flex-start",

                  color: "#7F7F7F",
                 
                  marginLeft: lang === "ar" ? "" : "20%",
                  marginRight: lang === "ar" ? "20%" : "",
                }}
              >
                {global.forgetpassword[lang]}
              </Box>
            </Link>

            <Button
              sx={{
                backgroundColor: "#090979",
                borderRadius: "8px",
                height: "44px",
                p: "10px",
                ml: lang === "ar" ? "20%" : "",
                mr: lang === "ar" ? "" : "20%",
                ml: "20%",
                my: "32px",
                justifyContent: "center",
                color: "#fff",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                width: {
                  xl: "20%",
                  lg: "20%",
                  md: "20%",
                  sm: "50%",
                  xs: "50%",
                },
                ":hover": {
                  backgroundColor: "#090979",
                },
              }}
              onClick={handleLogin}
              ispending={loginPostResponse.isPending}
            >
              {global.login[lang]}
            </Button>
            <Divider width="60%" color="#ccc" sx={{ mx: "auto" }} />
            <Typography sx={{ ml: "20%" }}>
              {global.noaccount[lang]}
              <Button
                onClick={() => navigate("/register")}
                sx={{
                  color: "#090979",
                  "&:hover": {
                    color: "GrayText",
                  },
                }}
              >
                {global.makeaccount[lang]}
                {lang === "ar" ? (
                  <ArrowBackIcon color="primary" />
                ) : (
                  <ArrowForwardOutlinedIcon color="primary" />
                )}
                {/* <ArrowBackIcon color="primary" /> */}
              </Button>
            </Typography>
          </Stack>
        </Form>
        {loginPostResponse.successAlert}
        {loginPostResponse.failAlert}
        {/* {sendtokenResponse.failAlert}
        {sendtokenResponse.successAlert}
        {sendtokenResponse.failAlert}
        {sendtokenResponse.successAlert} */}
      </Stack>
    </Stack>
  );
};

export default Login;
