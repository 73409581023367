import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  IconButton as MuiIconButton,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Divider,
  MenuItem,
  Chip,
  Menu,
  CircularProgress,
  Button,
  Grow,
  Collapse,
  Fade,
  Dialog,
  Avatar,
  CardMedia,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import KeyIcon from "@mui/icons-material/Key";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";
import useAfterEffect from "../hooks/useAfterEffect";
import EmptyBox from "../svg/EmptyBox";
import { v4 as uuid } from "uuid";
import usePropState from "../hooks/usePropState";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import checkifinclientornot from "../assets/Vector (1).png";
import itinclient from "../assets/Vector (2).png";
import { Gallery, Item } from "react-photoswipe-gallery";
import imagetransfer from "../assets/Vector3.png";
import transfericon from "../assets/Vector.png";
import completedicon from "../assets/complete.png";
import imagevideo from "../assets/video.png";
import ReactImageVideoLightbox from "react-image-video-lightbox";
// import video from "../assets/vecteezy_the-footage-animation-of-countdown-timer-from-5-seconds__666.mp4"
import "photoswipe/dist/photoswipe.css";
import styled from "@emotion/styled";
import { includes } from "lodash";
import { Row } from "rsuite";
const DataGrid = ({
  rows = [],
  columns,
  onCheck = null,
  onView = null,
  onDelete = null,
  onArchive = null,
  onChangePassword = null,
  onBlock = null,
  onEdit = null,
  onEditRequest = null,
  isPending = false,
  onFilter = null,
  total = 1,
  onAmountChange = () => {},
  onPaginate = () => {},
  availableAmounts = [8, 10, 50, 100, 200],
  aditionProceduresButtons,
  filters,
  onTransfer = null,
  onTransferEmployee = null,
  onTransfernotfekd = null,
  // onTrueorFalse=null,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [check, setcheck] = useState(false);
  const [open, setopen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rowdata, setrowdata] = useState();
  const openlightbox = (index, row) => {
    
    //   setrowdata(
    //     row?.files
    //       ? row?.files.map((file, index) => {
    //           return {
    //             url: file.file,
    //             index: index,
    //             type: ["jpeg", "jpg", "png"].includes(
    //               file.file.split(".").pop().trim()
    //             )
    //               ? "photo"
    //               : "video",
    //             thumbnail: file.file,
    //           };
    //         })
    //       : row?.property_files
    //       ? row?.property_files.map((file, index) => {
    //           return {
    //             url: file.file,
    //             index: index,
    //             type: ["jpeg", "jpg", "png"].includes(
    //               file.file.split(".").pop().trim()
    //             )
    //               ? "photo"
    //               : "video",
    //             thumbnail: file.file,
    //           };
    //         })
    //       : row?.logo
    //       && [{ url: row.logo, index: index, type: "photo", thumbnail: row.logo }]
          
    //   );
    // // }else{
    //   setrowdata(row?.main_image&&[{ url: row.main_image, index: index, type: "photo", thumbnail: row.main_image }])
    // // }
    setrowdata(Array.isArray(row)?row.map((file, index) => {
      return {
        url: file.file,
        index: index,
        type: ["jpeg", "jpg", "png"].includes(
          file.file.split(".").pop().trim()
        )
          ? "photo"
          : "video",
        thumbnail: file.file,
      };
    }):[{ url: row, index: index, type: "photo", thumbnail: row }])
   
    setCurrentIndex(index);
    setopen(true);
  };
  // const Newvideoreactlibrary=styled(ReactImageVideoLightbox)({
  //   backgroundColor:"red",
  //   zIndex:"1000000"
  // })
  ///////////////////////////////
  //----states----
  const [rowsState, setRowsState] = useState(rows);
  const [columnsState, setColumnsState] = useState(columns);
  const [filtersList, setFiltersList] = useState([]);
  const [amount, setAmount] = useState(availableAmounts[0]);
  const [checked, setChecked] = useState({
    type: "",
    checks: [],
  });
  const [files, setfiles] = useState([]);
  const handleOpenEdit = (e, row) => {
    setOpenEditInfo(row);
  };
  //----conditions----
  const renderProcedures = Boolean(
    onDelete || onArchive || onChangePassword || onBlock || onEdit || onView
  );
  const rootCheckboxCheck =
    Boolean(rowsState?.length) && checked?.checks?.length === rowsState?.length;
  const rootCheckboxIndeterminate = Boolean(
    checked?.checks?.length > 0 && checked?.checks?.length < rowsState?.length
  );

  //----effects----
  useEffect(() => {
    setRowsState(rows);
    setChecked({
      type: "",
      checks: [],
    });
  }, [rows]);

  useEffect(() => {
    setColumnsState(columns);
  }, [columns]);

  useAfterEffect(() => {
    onCheck && onCheck(checked);
  }, [checked]);

  useAfterEffect(() => {
    onFilter && onFilter(filtersList);
  }, [filtersList]);

  //----functions----
  const handleRootCheckboxChange = (e) => {
    switch (e.target.checked) {
      case false:
        setChecked({ type: "root", checks: [] });
        break;
      case true:
        setChecked({ type: "root", checks: [...rowsState] });
        break;
    }
  };

  const handleChildCheckboxChange = async (e, row) => {
    switch (e.target.checked) {
      case false:
        setChecked((old) => ({
          type: "child",
          checks: old.checks.filter((item) => item.id !== row.id),
        }));
        break;
      case true:
        setChecked((old) => ({ type: "child", checks: [...old.checks, row] }));
        break;
    }
  };

  const handleAmountChange = (params) => {
    setAmount(params.value);
    onAmountChange(params);
  };
  const clickproperityimage = (value, row) => {
    setcheck(!check);
    if (row.files) {
      setfiles(row.files);
    } else {
      setfiles(row);
    }
  };
  const handleClose = () => {
    setcheck(false);
  };
  useEffect(() => {}, []);
  return (
    <>
      <Paper sx={{ overflowX: "auto", marginBlock: 5 }} elevation={2}>
        <Paper
          variant="outlined"
          sx={{
            position: "relative",
          }}
        >
          <Stack
            direction="row"
            sx={{
              p: 2,
              maxWidth: "100%",
              flexWrap: "wrap",
              rowGap: 2,
            }}
            spacing={2}
          >
            <SelectAmount
              availableAmounts={availableAmounts}
              onChange={handleAmountChange}
            />
            <Stack direction="row" flexWrap="wrap" gap={2}>
              {filtersList.map((filter) => (
                <ChipsFilterItem
                  key={`filter ${filter.id}`}
                  name={filter.name}
                  renderedValue={filter.renderedValue}
                  query={filter.query}
                  value={filter.value}
                  id={filter.id}
                  onRemove={() => {
                    setFiltersList((old) =>
                      old.filter((item) => item.id !== filter.id)
                    );
                  }}
                  component={filter.component}
                  onSubmit={(params) =>
                    setFiltersList((old) =>
                      old.map((item) => {
                        if (item.id === filter.id) {
                          return { ...item, ...params };
                        }
                        return item;
                      })
                    )
                  }
                />
              ))}
            </Stack>
            {filters && (
              <ChipsFilter
                filters={filters}
                onFilter={(param) => {
                  setFiltersList((old) => [...old, param]);
                }}
              />
            )}
          </Stack>
          <Divider orientation="horizontal" />
          <Box
            sx={{
              overflow: isPending ? "hidden" : "auto",
            }}
          >
            <Stack
              direction="column"
              sx={{
                width: "max-content",
                minWidth: "100%",
              }}
            >
              {/* Grid Header */}

              {/* Grid Content */}
              <Box
                sx={{
                  maxHeight: 600,
                  height: 600,
                  minHeight: 600,
                  position: "relative",
                }}
              >
                {!Boolean(rowsState.length) && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <EmptyBox sx={{ opacity: 0.2, width: 200, height: 200 }} />
                  </Box>
                )}
                {isPending && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "white",
                    }}
                  >
                    <CircularProgress sx={{ color: "gray" }} />
                  </Box>
                )}
                {columnsState && (
                  <Table
                    sx={{
                      width: "100%",
                      "& .MuiTableCell-root": {
                        height: "max-content",
                        border: "none",
                      },
                    }}
                  >
                    <TableHead
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 500,
                        bgcolor: "white",
                      }}
                    >
                      <TableRow>
                        {onCheck && (
                          <TableCell sx={{ position: "relative" }}>
                            <Checkbox
                              checked={rootCheckboxCheck}
                              indeterminate={rootCheckboxIndeterminate}
                              onChange={handleRootCheckboxChange}
                              sx={{
                                padding: 0,
                              }}
                              disableRipple
                            />
                          </TableCell>
                        )}
                        {columns?.map((column, index) => (
                          <TableCell key={`column ${index}`} onClick={() => {}}>
                            {column.headerName
                              ? column.headerName
                              : column.field}
                          </TableCell>
                        ))}

                        {renderProcedures && (
                          <TableCell>{globalLang.actions[lang]}</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ height: "max-content" }}>
                      {!isPending &&
                        rowsState?.map((row, rowIndex) => {
                          return (
                            <TableRow
                              sx={{
                                bgcolor: row.isblock
                                  ? "#ffb6b6"
                                  : rowIndex % 2 == 0
                                  ? "#f5f5f5"
                                  : "#fff",
                              }}
                              key={rowIndex}
                            >
                              {onCheck && (
                                <TableCell sx={{ position: "relative" }}>
                                  <Checkbox
                                    checked={Boolean(
                                      checked.checks.find(
                                        (item) => item.id === row.id
                                      )
                                    )}
                                    onChange={(e) =>
                                      handleChildCheckboxChange(e, row)
                                    }
                                    sx={{
                                      padding: 0,
                                    }}
                                    disableRipple
                                  />
                                </TableCell>
                              )}

                              {columnsState &&
                                columnsState?.map((column, columnIndex) => {
                                  if (
                                    column.customContent &&
                                    column.field !== "picture" &&
                                    column.field !== "avatar"&&column.field !== "mainImage"&&column.field !== "main_image"
                                  ) {
                                    return (
                                      <TableCell key={columnIndex} onClick={()=>{console.log(column.field)}}>
                                        {column.customContent({
                                          ...row,
                                          columnIndex: columnIndex,
                                          rowIndex: rowIndex,
                                        })
                                          ? column.customContent({
                                              ...row,
                                              columnIndex: columnIndex,
                                              rowIndex: rowIndex,
                                            })
                                          : column.customEmpty
                                          ? column.customEmpty
                                          : globalLang.unknown[lang]}
                                      </TableCell>
                                    );
                                  }

                                  if (column.field === "picture") {
                                    return (
                                      <>
                                        <TableCell
                                          key={columnIndex}
                                          width={200}
                                        >
                                          <>
                                            {column.customContent({
                                              ...row,
                                              columnIndex: columnIndex,
                                              rowIndex: rowIndex,
                                            }).props.src ? (
                                              <>
                                                {
                                                  Object.keys(row).map((ele)=>{
                                                  //   if(ele==="files"){
                                                  //   return row["files"]?.map(
                                                  //     (item, index) => {
                                                  //       return ["jpeg", "jpg", "png"].includes(
                                                  //         item.file
                                                  //           .split(".")
                                                  //           .pop()
                                                  //           .trim()
                                                  //       ) ? (
                                                  //         index === 0 ? (
                                                  //           <img
                                                  //           src={item.file}
                                                  //           onClick={() =>
                                                  //             openlightbox(
                                                  //               index,
                                                  //               row.files
                                                  //             )
                                                  //           }
                                                  //           style={{
                                                  //             width: "46%",
                                                  //             height: "100px",
                                                  //           }}
                                                  //         />
                                                  //         ) : (
                                                  //           <></>
                                                  //         )
                                                  //       ) : index === 0 ? (
                                                  //         <img
                                                  //             src={imagevideo}
                                                  //             onClick={() =>
                                                  //               openlightbox(
                                                  //                 index,
                                                  //                 row.files
                                                  //               )
                                                  //             }
                                                  //             style={{
                                                  //               width: "46%",
                                                  //               height: "100px",
                                                  //             }}
                                                  //           />
                                                          
                                                  //       ) : (
                                                  //         <></>
                                                  //       );
                                                  //     }
                                                  //   )
                                                  // }else if(ele==="property_files"){
                                                  //   return row["property_files"].map(
                                                  //     (item, index) => {
                                                  //       return  ["jpeg", "jpg", "png"].includes(
                                                  //         item.file
                                                  //           .split(".")
                                                  //           .pop()
                                                  //           .trim()
                                                  //       ) ? (
                                                  //         index === 0 ? (
                                                  //           <img
                                                  //             src={item.file}
                                                  //             onClick={() =>
                                                  //               openlightbox(
                                                  //                 index,
                                                  //                 row?.property_files
                                                  //               )
                                                  //             }
                                                  //             style={{
                                                  //               width: "46%",
                                                  //               height: "100px",
                                                  //             }}
                                                  //           />
                                                  //         ) : (
                                                  //           <></>
                                                  //         )
                                                  //       ) : index === 0 ? (
                                                  //         <img
                                                  //           src={imagevideo}
                                                  //           onClick={() =>
                                                  //             openlightbox(
                                                  //               index,
                                                  //               row?.property_files
                                                  //             )
                                                  //           }
                                                  //           style={{
                                                  //             width: "46%",
                                                  //             height: "100px",
                                                  //           }}
                                                  //         />
                                                  //       ) : (
                                                  //         <></>
                                                  //       );
                                                  //     }
                                                  //   )
                                                  // }else 
                                                  if(ele==="logo"){
                                                    return  <img
                                                    src={row?.logo}
                                                    onClick={() =>
                                                      openlightbox(0, row?.logo)
                                                    }
                                                    style={{
                                                      width: "46%",
                                                      height: "100px",
                                                    }}
                                                  />
                                                  }})
                                                // row?.files ? (
                                                //   row?.files?.map(
                                                //     (item, index) => {
                                                //       return ["jpeg", "jpg", "png"].includes(
                                                //         item.file
                                                //           .split(".")
                                                //           .pop()
                                                //           .trim()
                                                //       ) ? (
                                                //         index === 0 ? (
                                                //           <img
                                                //           src={item.file}
                                                //           onClick={() =>
                                                //             openlightbox(
                                                //               index,
                                                //               row.files
                                                //             )
                                                //           }
                                                //           style={{
                                                //             width: "46%",
                                                //             height: "100px",
                                                //           }}
                                                //         />
                                                //         ) : (
                                                //           <></>
                                                //         )
                                                //       ) : index === 0 ? (
                                                //         <img
                                                //             src={imagevideo}
                                                //             onClick={() =>
                                                //               openlightbox(
                                                //                 index,
                                                //                 row.files
                                                //               )
                                                //             }
                                                //             style={{
                                                //               width: "46%",
                                                //               height: "100px",
                                                //             }}
                                                //           />
                                                        
                                                //       ) : (
                                                //         <></>
                                                //       );
                                                //     }
                                                //   )
                                                // ) : row?.property_files? (
                                                //   row?.property_files?.map(
                                                //     (item, index) => {
                                                //       return [
                                                //         "mp4",
                                                //         "MOV",
                                                //       ].includes(
                                                //         item.file
                                                //           .split(".")
                                                //           .pop()
                                                //           .trim()
                                                //       ) ? (
                                                //         index === 0 ? (
                                                //           <img
                                                //             src={imagevideo}
                                                //             onClick={() =>
                                                //               openlightbox(
                                                //                 index,
                                                //                 row?.property_files
                                                //               )
                                                //             }
                                                //             style={{
                                                //               width: "46%",
                                                //               height: "100px",
                                                //             }}
                                                //           />
                                                //         ) : (
                                                //           <></>
                                                //         )
                                                //       ) : index === 0 ? (
                                                //         <img
                                                //           src={item.file}
                                                //           onClick={() =>
                                                //             openlightbox(
                                                //               index,
                                                //               row?.property_files
                                                //             )
                                                //           }
                                                //           style={{
                                                //             width: "46%",
                                                //             height: "100px",
                                                //           }}
                                                //         />
                                                //       ) : (
                                                //         <></>
                                                //       );
                                                //     }
                                                //   )
                                                // ) : row?.logo && (
                                                //   <img
                                                //     src={row?.logo}
                                                //     onClick={() =>
                                                //       openlightbox(0, row?.logo)
                                                //     }
                                                //     style={{
                                                //       width: "46%",
                                                //       height: "100px",
                                                //     }}
                                                //   />
                                                // )
                                                }
                                              
                                                {open && (
                                                  <div className="react-image-video-lightbox">
                                                    <ReactImageVideoLightbox
                                                      data={rowdata && rowdata}
                                                      startIndex={currentIndex}
                                                      showResourceCount={true}
                                                      onCloseCallback={() =>
                                                        setopen(false)
                                                      }
                                                      onNavigationCallback={(
                                                        currentIndex
                                                      ) =>
                                                        console.log(
                                                          `Current index: ${currentIndex}`
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </>
                                            ) : column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              }).props.src === undefined ? (
                                              column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })
                                            ) : (
                                              column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })
                                            )}
                                            {/* {row?.files?row?.files.map((item, index) => {
                                                 ( <img
                                                 src={imagevideo}
                                                 onClick={() =>
                                                   openlightbox(index,row)
                                                 }
                                                 style={{width:"46%",height:"100px"}}
                                               />)
                                                }):row?.property_files?.map((item, index) => {
                                               (<img
                                                  src={imagevideo}
                                                  onClick={() =>
                                                    openlightbox(index,row)
                                                  }
                                                  style={{width:"46%",height:"100px"}}
                                                />): (<img
                                                src={item.file}
                                                onClick={() =>
                                                  openlightbox(index,row)
                                                }
                                                style={{width:"46%",height:"100px"}}
                                              />))
                                                })}

                                                 */}
                                          </>
                                          {/* // <Gallery style={{ width: "20%" }}>
                                            //   {row.files ? (
                                            //     row.files.map(
                                            //       (ele, index) =>
                                            //         ele.file
                                            //           .split(".")
                                            //           .pop()
                                            //           .trim() !== "mp4" && (
                                            //           <Item
                                            //             original={ele.file}
                                            //             thumbnail={ele.file}
                                            //             width={752}
                                            //             height={450}
                                            //           >
                                            //             {({ ref, open }) => {
                                            //               return (
                                            //                 <Avatar
                                            //                   ref={ref}
                                            //                   onClick={open}
                                            //                   src={
                                            //                     index === 0
                                            //                       ? ele.file
                                            //                       : ""
                                            //                   }
                                            //                   sx={{
                                            //                     width:
                                            //                       index === 0
                                            //                         ? "46%"
                                            //                         : "",
                                            //                     height:
                                            //                       index === 0
                                            //                         ? "100px"
                                            //                         : "",
                                            //                     objectFit:
                                            //                       "cover",
                                            //                     borderRadius:
                                            //                       "10px",
                                            //                   }}
                                            //                 />
                                            //               );
                                            //             }}
                                            //           </Item>
                                            //         )
                                            //     )
                                            //   ) : row.property_files ? (
                                            //     row.property_files.map(
                                            //       (ele, index) => (
                                            //         <Item
                                            //           original={ele.file}
                                            //           thumbnail={ele.file}
                                            //           width={752}
                                            //           height={450}
                                            //         >
                                            //           {({ ref, open }) => {
                                            //             return (
                                            //               <Avatar
                                            //                 ref={ref}
                                            //                 onClick={open}
                                            //                 src={
                                            //                   index === 0
                                            //                     ? ele.file
                                            //                     : ""
                                            //                 }
                                            //                 sx={{
                                            //                   width:
                                            //                     index === 0
                                            //                       ? "46%"
                                            //                       : "",
                                            //                   height:
                                            //                     index === 0
                                            //                       ? "100px"
                                            //                       : "",
                                            //                   objectFit:
                                            //                     "cover",
                                            //                   borderRadius:
                                            //                     "10px",
                                            //                 }}
                                            //               />
                                            //             );
                                            //           }}
                                            //         </Item>
                                            //       )
                                            //     )
                                            //   ) : row.logo ? (
                                            //     <Item
                                            //       original={row.logo}
                                            //       thumbnail={row.logo}
                                            //       width={752}
                                            //       height={450}
                                            //     >
                                            //       {({ ref, open }) => {
                                            //         return (
                                            //           <Avatar
                                            //             ref={ref}
                                            //             onClick={open}
                                            //             src={row.logo}
                                            //             sx={{
                                            //               width: "46%",
                                            //               height: "100px",

                                            //               objectFit: "cover",
                                            //               borderRadius: "10px",
                                            //             }}
                                            //           />
                                            //         );
                                            //       }}
                                            //     </Item>
                                            //   ) : (
                                            //     <></>
                                            //   )}
                                            // </Gallery> */}
                                              
                                        </TableCell>
                                      </>
                                    );
                                  }
                                  if(column.field==="mainImage"){
                                   
                                    return (
                                      <>
                                        <TableCell
                                          key={columnIndex}
                                          width={200}
                                        >
                                           {column.customContent({
                                              ...row,
                                              columnIndex: columnIndex,
                                              rowIndex: rowIndex,
                                            }).props.src?<>
                                            {row?.main_image && (
                                                  <><img
                                                    src={row?.main_image}
                                                    onClick={() =>
                                                      openlightbox(0, row?.main_image)
                                                    }
                                                    style={{
                                                      width: "46%",
                                                      height: "100px",
                                                    }}
                                                  />
                                                   {open && (
                                                  <div className="react-image-video-lightbox">
                                                    <ReactImageVideoLightbox
                                                      data={rowdata && rowdata}
                                                      startIndex={currentIndex}
                                                      showResourceCount={true}
                                                      onCloseCallback={() =>
                                                        setopen(false)
                                                      }
                                                      onNavigationCallback={(
                                                        currentIndex
                                                      ) =>
                                                        console.log(
                                                          `Current index: ${currentIndex}`
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )}
                                                  </>
                                                )}
                                            </>:column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              }).props.src === undefined ? (
                                              column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })
                                            ) : (
                                              column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })
                                            )}
                                          </TableCell></>)
                                  }
                                  if(column.field==="main_image"){
                               
                                    return (
                                      <>
                                        <TableCell
                                          key={columnIndex}
                                          width={200}
                                        >
                                           {column.customContent({
                                              ...row,
                                              columnIndex: columnIndex,
                                              rowIndex: rowIndex,
                                            }).props.src?<>
                                            {row?.main_image && (
                                                 <> <img
                                                    src={row?.main_image}
                                                    onClick={() =>
                                                      openlightbox(0, row?.main_image)
                                                    }
                                                    style={{
                                                      width: "46%",
                                                      height: "100px",
                                                    }}
                                                  />
                                                   {open && (
                                                  <div className="react-image-video-lightbox">
                                                    <ReactImageVideoLightbox
                                                      data={rowdata && rowdata}
                                                      startIndex={currentIndex}
                                                      showResourceCount={true}
                                                      onCloseCallback={() =>
                                                        setopen(false)
                                                      }
                                                      onNavigationCallback={(
                                                        currentIndex
                                                      ) =>
                                                        console.log(
                                                          `Current index: ${currentIndex}`
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )}
                                                  </>
                                                )}
                                            </>:column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              }).props.src === undefined ? (
                                              column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })
                                            ) : (
                                              column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })
                                            )}
                                          </TableCell></>)
                                  }
                                  if (column.field === "avatar") {
                                    return (
                                      <>
                                        <TableCell
                                          key={columnIndex}
                                          onClick={() =>
                                            clickproperityimage(
                                              column.field,
                                              row
                                            )
                                          }
                                        >
                                         
                                          {column.customContent({
                                            ...row,
                                            columnIndex: columnIndex,
                                            rowIndex: rowIndex,
                                          }).props.src === undefined
                                            ? column.customEmpty
                                            : column.customContent({
                                                ...row,
                                                columnIndex: columnIndex,
                                                rowIndex: rowIndex,
                                              })}
                                        </TableCell>
                                      </>
                                    );
                                  }

                                  if (row[column.field] !== undefined) {
                                    return (
                                      <TableCell key={columnIndex}>
                                        {row[column.field]
                                          ? row[column.field]
                                          : column.customEmpty
                                          ? column.customEmpty
                                          : globalLang.unknown[lang]}
                                      </TableCell>
                                    );
                                  } else {
                                    throw Error(
                                      `The field "${column.field}" does not match any key in the object, error fired at index ${rowIndex}`
                                    );
                                  }
                                })}

                              {renderProcedures && (
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {onChangePassword && (
                                      <IconButton
                                        color="#495f9b"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onChangePassword(event, row);
                                        }}
                                      >
                                        <KeyIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    )}
                                    {onView && (
                                      <IconButton
                                        color="#495f9b"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onView(event, row);
                                        }}
                                      >
                                        <RemoveRedEyeIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    )}
                                    {onEdit && (
                                      <IconButton
                                        color="#96ee9d"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onEdit(event, row);
                                        }}
                                      >
                                        <EditIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    )}
                                    {onEditRequest &&
                                    row.status !== "completed" ? (
                                      <IconButton
                                        color="#96ee9d"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onEditRequest(event, row);
                                        }}
                                      >
                                        <EditIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    ) : (
                                      ""
                                    )}
                                    {/* {console.log(row)} */}
                                    {onDelete && (
                                      <IconButton
                                        color="#f8c6c6"
                                        iconColor="red"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onDelete(event, row);
                                        }}
                                      >
                                        <DeleteIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    )}
                                    {onTransferEmployee &&
                                    row.status !== "completed" ? (
                                      <IconButton
                                        color="#00246B"
                                        iconColor="red"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onTransferEmployee(event, row);
                                        }}
                                      >
                                        <Typography
                                          component={"img"}
                                          src={imagetransfer}
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    ) : (
                                      ""
                                    )}
                                    {onTransfernotfekd && (
                                      <IconButton
                                        color={
                                          row.status === "completed"
                                            ? "transparent"
                                            : "#00246B"
                                        }
                                        iconColor="red"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          if (row.status === "pending") {
                                            onTransfernotfekd(event, row);
                                          }
                                        }}
                                      >
                                        {row.status === "completed" ? (
                                          <Typography
                                            component={"img"}
                                            src={completedicon}
                                            sx={{
                                              width: 20,
                                              height: 20,
                                              cursor: "default",
                                            }}
                                          />
                                        ) : (
                                          <Typography
                                            component={"img"}
                                            src={transfericon}
                                            sx={{ width: 20, height: 20 }}
                                          />
                                        )}
                                      </IconButton>
                                    )}
                                    {onBlock && (
                                      <IconButton
                                        color="#ff3c3c"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onBlock(event, row);
                                        }}
                                      >
                                        <BlockIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    )}
                                    {aditionProceduresButtons &&
                                      aditionProceduresButtons.map(
                                        (item, index) => (
                                          <IconButton
                                            key={`aditionalProceduralButton ${index}`}
                                            color={
                                              item.bgcolor
                                                ? item.bgcolor
                                                : "#495f9b"
                                            }
                                            iconColor={
                                              item.color ? item.color : "#fff"
                                            }
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              item.callback(event, row);
                                            }}
                                          >
                                            {React.cloneElement(item.icon, {
                                              sx: { width: 20, height: 20 },
                                            })}
                                          </IconButton>
                                        )
                                      )}
                                    {location.pathname.includes(
                                      "fe-khidmtak"
                                    ) ? (
                                      row.is_aqar_property === false ||
                                      row.is_aqar_client === false ? (
                                        <IconButton
                                          color="white"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            onTransfer(event, row);
                                          }}
                                        >
                                          <img
                                            src={checkifinclientornot}
                                            alt=""
                                          />
                                        </IconButton>
                                      ) : row.is_aqar_client === true ||
                                        row.is_aqar_property === true ? (
                                        <IconButton
                                          color="white"
                                          disaplayclick={true}
                                        >
                                          <img src={itinclient} alt="" />
                                        </IconButton>
                                      ) : null
                                    ) : null}
                                    {row.is_transferred === true &&
                                    !location.pathname.includes(
                                      "fe-khidmtak"
                                    ) ? (
                                      <IconButton
                                        color="white"
                                        disaplayclick={true}
                                      >
                                        <img src={itinclient} alt="" />
                                      </IconButton>
                                    ) : null}
                                    {/* {row.is_aqar_property === false ||
                                    (row.is_aqar_client === false &&
                                      location.pathname.includes(
                                        "fe-khidmtak"
                                      )) ? (
                                      <IconButton
                                        color="white"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          onTransfer(event, row);
                                        }}
                                      >
                                        <img
                                          src={checkifinclientornot}
                                          alt=""
                                        />
                                      </IconButton>
                                    ) : (row.is_aqar_property === true &&location.pathname.includes(
                                      "fe-khidmtak"
                                    ))||
                                      (row.is_aqar_client === true &&
                                        location.pathname.includes(
                                          "fe-khidmtak"
                                        )) ? (
                                      <IconButton
                                        color="white"
                                        disaplayclick={true}
                                      >
                                        <img src={itinclient} alt="" />
                                      </IconButton>
                                    ) : null} */}
                                  </Stack>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Stack>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ direction: "rtl", paddingBlock: 1, position: "relative" }}
          >
            <Typography
              sx={{
                position: "absolute",
                left: 20,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {checked?.checks?.length
                ? "المحدد"
                : globalLang.totalNumber[lang]}
              :{" "}
              {(checked?.checks?.length
                ? `${checked?.checks?.length} من `
                : "") + total}
            </Typography>
            <TablePagination
              limit={Math.ceil(total / amount)}
              onChange={onPaginate}
            />
          </Stack>
        </Paper>
      </Paper>
      {files?.length > 0 ? (
        <Dialog open={check} onClose={handleClose}>
          <Gallery>
            <Box
              className="gallery_overlay"
              sx={{
                img: {
                  width: "100%",
                },
              }}
            >
              {files?.map((ele) => (
                <Item
                  original={ele.file}
                  // originalSrcset={ele}
                  thumbnail={ele.file}
                  width={752}
                  height={450}
                >
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={ele.file} />
                  )}
                </Item>
              ))}
            </Box>
          </Gallery>
        </Dialog>
      ) : files && !Array.isArray(files) ? (
        <Dialog open={check} onClose={handleClose}>
          <Gallery>
            <Box
              className="gallery_item"
              sx={{
                img: {
                  width: "100%",
                },
              }}
            >
              <img
                className="img-fluid img-circle-rounded"
                src={files?.user?.image}
                alt="fp1.jpg"
              />
            </Box>
          </Gallery>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default DataGrid;

const IconButton = ({
  children,
  color = "#495f9b",
  iconColor = "white",
  onClick,
  disaplayclick = false,
}) => {
  return (
    <MuiIconButton
      size="small"
      sx={{
        bgcolor: color,
        color: iconColor,
        borderRadius: 2,
        "&:hover": !disaplayclick
          ? {
              bgcolor: color,
              filter: "brightness(.8)",
            }
          : {
              bgcolor: color,
            },
        height: "max-content",
        cursor: disaplayclick ? "auto" : "pointer",
      }}
      onClick={onClick}
    >
      {children}
    </MuiIconButton>
  );
};

const TablePagination = ({ current = 1, limit, onChange = () => {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [page, setPage] = useState(1);
  const max = useRef(0);

  //----conditions----
  const disableRight = Boolean(page >= limit);
  const disableLeft = Boolean(page <= 1) || Boolean(page > limit);

  //----effects----
  useEffect(() => {
    setPage(current);
  }, [current]);

  useEffect(() => {
    max.current = limit;
  }, [limit]);

  useAfterEffect(() => {
    setPage(1);
  }, [limit]);

  //----functions----
  const handleKeyDown = (e) => {
    if (
      e.code !== "Enter" ||
      parseInt(e.target.value) < 1 ||
      parseInt(e.target.value) > parseInt(limit)
    )
      return;

    onChange({ current: page, limit: max });
  };

  const handleMoveForward = () => {
    setPage((old) => {
      if (old + 1 > limit) return old;
      onChange({ current: old + 1, limit: max });
      return old + 1;
    });
  };

  const handleMoveBackward = () => {
    setPage((old) => {
      if (old - 1 < 1) return old;
      onChange({ current: old - 1, limit: max });
      return old - 1;
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      spacing={1}
      sx={{ direction: lang === "ar" ? "ltr" : "" }}
    >
      <MuiIconButton
        onClick={handleMoveForward}
        disabled={disableRight}
        sx={{
          "&.Mui-disabled": {
            opacity: ".2",
          },
        }}
      >
        <ArrowForwardIosIcon sx={{ width: 15, height: 15 }} color="primary" />
      </MuiIconButton>
      <Typography variant="caption" sx={{ fontWeight: "bold" }} color="primary">
        {max.current > 0 ? max.current : 1}
      </Typography>
      <Typography variant="caption" sx={{ fontWeight: "bold" }} color="primary">
        {globalLang.from[lang]}
      </Typography>
      <Box sx={{ color: "primary" }}>
        <NumericFormat
          customInput={TextField}
          variant="standard"
          sx={{
            width: 50,
            "& input": {
              fontSize: 13,
              fontWeight: "bold",
              textAlign: "center",
            },
          }}
          isAllowed={({ formattedValue }) => formattedValue !== ""}
          allowNegative={false}
          onKeyDown={handleKeyDown}
          value={page}
          decimalScale={0}
          onValueChange={(e) => setPage(e.floatValue)}
        />
      </Box>
      <MuiIconButton
        onClick={handleMoveBackward}
        disabled={disableLeft}
        sx={{
          "&.Mui-disabled": {
            opacity: ".2",
          },
        }}
      >
        <ArrowBackIosNewIcon sx={{ width: 15, height: 15 }} color="primary" />
      </MuiIconButton>
    </Stack>
  );
};

const SelectAmount = ({ availableAmounts, onChange = () => {} }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  //----states----
  const [value, setValue] = useState(availableAmounts[0]);

  //----functions----
  const handleChange = (e) => {
    setValue(e.target.value);
    onChange({ value: e.target.value });
  };

  return (
    <Paper variant="outlined" sx={{ paddingBlock: "5px" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ paddingInline: 2, height: "100%" }}
        spacing={2}
      >
        <Typography variant="body2">{globalLang.viewItems[lang]}</Typography>
        <TextField
          variant="standard"
          select
          sx={{
            width: 70,
            borderRadius: "100vmax",
            "& .MuiInputBase-root, & .MuiSelect-standard:focus": {
              borderRadius: "100vmax",
            },
            "& .MuiSelect-standard": {
              paddingBlock: "0",
              borderRadius: "100vmax",
            },
          }}
          value={value}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
          }}
          onChange={handleChange}
        >
          {availableAmounts.map((item, index) => (
            <MenuItem value={item} key={item + " " + index}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Paper>
  );
};

const ChipsFilterItem = ({
  name,
  renderedValue,
  value,
  id,
  onRemove,
  component,
  onSubmit,
}) => {
  //----states----
  const [emerge, setEmerge] = useState(false);
  const [grow, setGrow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //----variables----
  const open = Boolean(anchorEl);

  //----effects----
  useEffect(() => {
    setEmerge(true);
  }, []);

  //----functions----
  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleGrow = () => {
    setGrow(true);
  };

  const handleShrink = () => {
    setEmerge(false);
  };

  const handleRemove = () => {
    onRemove();
  };

  const handleSubmit = (params) => {
    onSubmit({ ...params, id });
    setAnchorEl(null);
  };

  return (
    <Collapse
      in={emerge}
      orientation="horizontal"
      timeout={300}
      onEntered={() => handleGrow()}
      onExited={() => handleRemove()}
    >
      <Grow in={grow} timeout={200} onExited={() => handleShrink()}>
        <Box>
          <Chip
            label={
              <Typography>
                <b>{name}:</b> {renderedValue}
              </Typography>
            }
            sx={{
              height: "40px",
              borderRadius: "100vmax",
              p: 1,
              direction: "rtl",
              boxShadow: "0 3px 6px #0005",
              "& .MuiChip-deleteIcon": {
                marginLeft: 0,
              },
            }}
            onDelete={(e) => setGrow(false)}
            onClick={handleOpenMenu}
          />
          <Menu open={open} anchorEl={anchorEl} onClose={handleCloseMenu}>
            <FilterTemplate
              name={name}
              component={component}
              value={value}
              onSubmit={handleSubmit}
              onClose={handleCloseMenu}
            />
          </Menu>
        </Box>
      </Grow>
    </Collapse>
  );
};

const ChipsFilter = ({ filters = [], sx, onFilter = () => {} }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  //----states----
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);

  //----variables
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!anchorEl) return;
    setSelectedFilter(null);
  }, [open]);

  //----functions----
  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnFilter = (params) => {
    onFilter({ ...params, ...selectedFilter });
    setAnchorEl(null);
  };

  return (
    <Box>
      <Chip
        label={globalLang.addFilter[lang]}
        icon={<AddCircleIcon />}
        onClick={handleOpenMenu}
        sx={{
          height: "40px",
          borderRadius: "100vmax",
          p: 1,
          direction: "rtl",
          boxShadow: "0 3px 6px #0005",
          "& .MuiChip-icon": {
            marginRight: 0,
          },
          ...sx,
        }}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {!Boolean(filters.length) && (
          <MenuItem disabled sx={{ minWidth: 150 }}>
            فارغ
          </MenuItem>
        )}
        {Boolean(filters.length) &&
          (!Boolean(selectedFilter) ? (
            filters.map((filter, index) => {
              return (
                <MenuItem
                  onClick={() =>
                    setSelectedFilter({ valueShape: "", ...filter })
                  }
                  key={`filter ${index}`}
                  sx={{ minWidth: 150 }}
                >
                  {filter.name}
                </MenuItem>
              );
            })
          ) : (
            <FilterTemplate
              name={selectedFilter.name}
              component={selectedFilter.component}
              value={selectedFilter.valueShape}
              onSubmit={handleOnFilter}
              onClose={handleCloseMenu}
            />
          ))}
      </Menu>
    </Box>
  );
};

const FilterTemplate = ({
  onSubmit,
  name,
  renderedValue = "",
  query = [],
  value = "",
  component,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  //----states----
  const [valueState, setValueState] = usePropState(value, [value]);
  const [renderedValueState, setRenderedValueState] = usePropState(
    renderedValue,
    [renderedValue]
  );
  const [queryState, setQueryState] = usePropState(query, [query.length]);

  //----conditions----
  const nullishValue =
    !Boolean(valueState) ||
    !Boolean(renderedValueState) ||
    !Boolean(queryState.length);

  //----functions----
  const handleChange = ({ renderedValue, query, value }) => {
    setRenderedValueState(renderedValue);
    setQueryState(query);
    setValueState(value);
  };

  const handleSubmit = () => {
    if (nullishValue) return;
    onSubmit({
      renderedValue: renderedValueState,
      query: queryState,
      value: valueState,
      id: uuid(),
    });
  };
  return (
    <Box
      sx={{
        paddingInline: 3,
        paddingBlock: 1,
        maxWidth: 400,
        width: "100vmax",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <Typography variant="body2">{name}</Typography>
      {React.cloneElement(component, {
        value: valueState,
        onChange: handleChange,
      })}
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        <Button color="error" onClick={onClose}>
          {globalLang.cancel[lang]}
        </Button>
        <Button onClick={handleSubmit}>{globalLang.submit[lang]}</Button>
      </Box>
    </Box>
  );
};

//----typechecking----
DataGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  filters: PropTypes.arrayOf(PropTypes.object),
  isPending: PropTypes.bool,
  onCheck: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
  onArchive: PropTypes.func,
  onChangePassword: PropTypes.func,
  onBlock: PropTypes.func,
  onEdit: PropTypes.func,
  onEditRequest: PropTypes.func,
  onFilter: PropTypes.func,
  onTransferEmployee: PropTypes.func,
  onTransfernotfekd: PropTypes.func,
};
