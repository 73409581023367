import React, { useEffect } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AutocompleteField = ({
  variant = null,
  onChange,
  label = "",
  value,
  placeholder = "",
  children,
  isPending,
  onOpen = () => {},
  onClose = () => {},
  data = [],
  ...rest
}) => {
  useEffect(()=>{
 console.log(value)
  },[value])
 
  return (
    <Autocomplete
      loading={isPending}
      loadingText="الرجاء الإنتظار..."
      options={!isPending ? data : []}
      onOpen={onOpen}
      onClose={onClose}
      onChange={onChange}
      renderOption={(props, option)=>(<li {...props}>
            <ListItemAvatar>
              <Avatar alt={option.label} src={option.image} sx={{width:"20px",height:"20px"}}/>
            </ListItemAvatar>
            <ListItemText primary={option.label} />
          </li>)}
      fullWidth
      popupIcon={<KeyboardArrowDownIcon />}
      noOptionsText="فارغ"
      sx={{
        "& .MuiInput-root .MuiInput-input": {
          paddingInline: "10px",
        },
      }}
      {...rest}
      
      value={value?value:[]}
      renderInput={(params) => {
       return <TextField
          variant={variant ? variant : "standard"}
          {...params}
          InputLabelProps={{ shrink: true }}
          label={label}
          placeholder={placeholder}
          
        />
      }}
    />
  );
};

export default AutocompleteField;
