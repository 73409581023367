import React from "react";
import useControls from "../../hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { REQUEST, USERFEKHEDMETAK } from "../../data/APIs";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import Form, { InputField, PhoneField } from "../../features/form";
import filter from "../../utils/ClearNull";
function AddRequest() {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----

  //----states----
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "call_date",
      value: "",
      isRequired: true,
    },
    {
      control: "middleman",
      value: "",
      isRequired: true,
    },
    {
      control: "client_name",
      value: "",
      isRequired: true,
    },
    {
      control: "client_number",
      value: "",
      isRequired: true,
    },
    {
      control: "code",
      value: "",
      isRequired: false,
    },
    {
      control: "status",
      value: "",
      isRequired: true,
    },
    {
      control: "property",
      value: "",
      isRequired: true,
    },
  ]);

  const [requestPost, requestPostResponse] = useRequest({
    path: REQUEST,
    method: "post",
    successMessage: globalLang.addsuccessrequest[lang],
  });

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const requestBody = filter({
        obj: {
          call_date: controls.call_date,
          client_number: controls.code + controls.client_number,
          middleman: controls.middleman,
          client_name: controls.client_name,
          status: controls.status,
          property: controls.property,
        },
        output: "object",
      });
      requestPost({
        body: requestBody,
        onSuccess: () => {
          resetControls();
        },
      }).then((res) => {
        let response = res?.response?.data;
        const responseBody = filter({
          obj: {
            call_date: response?.call_date,
            client_number: response?.client_number,
            middleman: response?.middleman,
            client_name: response?.client_name,
            status: response?.status,
            property: response?.property,
          },
          output: "object",
        });
   
        setInvalid(responseBody);
      });
    });
  };

  return (
    <>
      <Breadcrumbs
        path={[globalLang.fekhidmtak[lang], globalLang.requests[lang]]}
      />
      <Form
        subtitle={globalLang.pleasefillinfo[lang]}
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: requestPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: requestPostResponse.isPending,
          },
        }}
      >
        <InputField
          label={globalLang.call_date[lang]}
          placeholder={globalLang.call_date[lang]}
          onChange={(e) => setControl("call_date", e.target.value)}
          value={controls.call_date}
          required={required.includes("call_date")}
          error={Boolean(invalid.call_date)}
          helperText={invalid.call_date}
        />
        <PhoneField
          label={globalLang.client_number[lang]}
          placeholder={globalLang.client_number[lang]}
          requiredCode
          selectProps={{
            value: controls.code,
            onChange: (e) => {
              setControl("code", e.target.value);
            },
          }}
          onChange={(e) => setControl("client_number", e.target.value)}
          value={controls.client_number}
          required={required.includes("client_number")}
          error={Boolean(invalid.client_number)}
          helperText={invalid.client_number}
        />

        <InputField
          label={globalLang.middleman[lang]}
          placeholder={globalLang.middleman[lang]}
          onChange={(e) => {
            setControl("middleman", e.target.value);
          }}
          value={controls.middleman}
          required={required.includes("middleman")}
          error={Boolean(invalid.middleman)}
          helperText={invalid.middleman}
        />
        <InputField
          label={globalLang.status[lang]}
          placeholder={globalLang.status[lang]}
          onChange={(e) => {
            setControl("status", e.target.value);
          }}
          value={controls.status}
          required={required.includes("status")}
          error={Boolean(invalid.status)}
          helperText={invalid.status}
        />
        <InputField
          label={globalLang.property[lang]}
          placeholder={globalLang.property[lang]}
          onChange={(e) => {
            setControl("property", e.target.value);
          }}
          value={controls.property}
          required={required.includes("property")}
          error={Boolean(invalid.property)}
          helperText={invalid.property}
        />
        <InputField
          label={globalLang.client_name[lang]}
          placeholder={globalLang.client_name[lang]}
          onChange={(e) => {
            setControl("client_name", e.target.value);
          }}
          value={controls.client_name}
          required={required.includes("client_name")}
          error={Boolean(invalid.client_name)}
          helperText={invalid.client_name}
        />
      </Form>
      {requestPostResponse.successAlert}
      {requestPostResponse.failAlert}
    </>
  );
}

export default AddRequest;
