import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grow } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow direction="up" ref={ref} {...props} unmountOnExit />;
  });
function useSelectMessage({ onConfirm = () => {},
title = "الرجاء التأكيد",
text = "هل انت متأكد انك تريد المتابعة؟",
variant = "confirm",
is_aqar_="is_aqar_property",
onReject = () => {},}) {
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);
     
    ///////////////////////////////
    const [open, setOpen] = useState(false);
    const [args, setArgs] = useState(null);
    const [result,setresult]=useState({
      [is_aqar_]:true,deleted:false
    })
    const handleOpen = (...params) => {
      setArgs([...params]);
      setOpen(true);
    };
  const clickcheckbox=(e)=>{
    let copyres={...result}
    if(copyres[e.target.name]){
        copyres[e.target.name]=false
    }else{
        copyres[e.target.name]=true
    }
    setresult(copyres)
  }
    return [
        handleOpen,
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              width: 500,
            },
          }}
          TransitionComponent={Transition}
        >
          <DialogTitle>{globalLang.pleaseConfirm[lang]}</DialogTitle>
          <DialogContent>{text}</DialogContent>
          {/* <FormGroup> */}
          <FormControlLabel control={<Checkbox />} label="مع الخذف" name='deleted' onClick={clickcheckbox} sx={{margin:"0px"}}/>
          
          {/* <FormControlLabel control={<Checkbox />}name={`${is_aqar_}`} label="نقل للعملاء crm" onClick={clickcheckbox} sx={{margin:"0px"}} checked={is_aqar_?is_aqar_:true}/> */}
          {/* </FormGroup> */}
          <DialogActions>
            {variant === "confirm" && (
              <>
                <Button
                  color="error"
                  onClick={() => {
                    onReject(...args);
                    setOpen(false);
                  }}
                >
                  {globalLang.no[lang]}
                </Button>
                <Button
                  onClick={() => {
                    onConfirm(...args);
                    setOpen(false);
                  }}
                >
                  {globalLang.yes[lang]}
                </Button>
              </>
            )}
            {variant === "alert" && (
              <Button onClick={() => setOpen(false)}>{globalLang.ok[lang]}</Button>
            )}
          </DialogActions>
        </Dialog>,
        result
      ];
}

export default useSelectMessage