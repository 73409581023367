import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Stack } from "@mui/system";
import DropBox from "../../components/DropBox";
import useRequest from "../../hooks/useRequest";
import { IMPORT_CLIENTS } from "../../data/APIs";
import { Button, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { SelectField } from "../../features/form";

const ImportClients = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const exportLang = useSelector((state) => state.lang.value.exportClients);
  const importClientsLang = useSelector(
    (state) => state.lang.value.importClients
  );
    const userInfo = useSelector((state) => state.userInfo.value);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [number, setNumber] = useState(1);

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
 const fileProgress = useSelector(
   (store) => store.importClients.value.filesProgress
 );

  ///////////////////////////////

  const [importClientsGetRequest, importClientsGetResponse] = useRequest({
    path: IMPORT_CLIENTS,
    method: "get",
    responseType: "blob",
  });

  const handleDownload = () => {
    importClientsGetRequest({
      params: {
        chunk: number,
      },
      onSuccess: (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sheet.xls");
        document.body.appendChild(link);
        link.click();
      },
    });
  };

  const handleDownloadModel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "name",
        "phone",
        "email",
        "project",
        "comment",
      ],
    ]);
  // XLSX.utils.sheet_to_txt
    XLSX.utils.book_append_sheet(wb, ws, "clientsSheet");

    XLSX.writeFile(wb, "CRM Clients Sheet.xls");
  };

  return (
    <Wrapper>
      <Breadcrumbs
        path={[
          globalLang.customers[lang],
          exportLang.exportClients[lang],
        ]}
      />
      <Stack alignItems="center" spacing={2}>
        <SelectField
          placeholder={globalLang.files_number[lang]}
          sx={{ maxWidth: 600 }}
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          renderValue={(selected) => {
            return numbers.find((number) => number === selected);
          }}
        >
          {numbers.map((number, index) => (
            <MenuItem key={` ${index}`} value={number}>
              {number}
            </MenuItem>
          ))}
        </SelectField>
        <DropBox
          buttonLabel={globalLang.downloadFile[lang]}
          onClick={handleDownload}
          isPending={ (fileProgress===true && fileProgress!==null) || userInfo.is_exporting===true}
          download={true}
          title={globalLang.downloadCustomerData[lang]}
        />
        <Button
          variant="contained"
          sx={{ width: "200px", height: "50px" }}
          onClick={handleDownloadModel}
        >
          {globalLang.downloadTemplate[lang]}
        </Button>
      </Stack>
    </Wrapper>
  );
};

export default ImportClients;
