import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Stack } from "@mui/system";
import DropBox, { ProgressCard } from "../../components/DropBox";
import { Box, Button, MenuItem } from "@mui/material";
import { useRef } from "react";
import useRequest from "../../hooks/useRequest";
import {
  CONTRACT,
  EMPLOYEES,
  EXPORT_CLIENTS,
  FILES_HISTORY,
} from "../../data/APIs";
import useAfterEffect from "../../hooks/useAfterEffect";
import { SelectField } from "../../features/form";
import filter from "../../utils/ClearNull";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import image from "../../../public/wordimage.jpg"
import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import { writeFileXLSX, writeXLSX } from "xlsx";
import useConfirmMessage from "../../hooks/useConfirmMessage";
// import FileViewer from 'react-file-viewer';
const AddContract = () => {
  // Change lang

  // const type = "docx";

  let reftofile = useRef(null);
  const lang = useSelector((state) => state.lang.value.lang);
  const exportClientsLang = useSelector(
    (state) => state.lang.value.exportClients
  );
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  //----request hooks----
  const [ContractPostRequest, ContractPostResponse] = useRequest({
    path: CONTRACT,
    method: "post",
    successMessage: globalLang.addsuccess[lang],
  });
  const dispatch = useDispatch();
  //----states----
  const uploadInputRef = useRef(null);
  const fileProgress = useSelector(
    (store) => store.exportClients.value.fileProgress
  );
  let newPlugin = defaultLayoutPlugin();
  const [selected, setSelected] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeesState, setEmployeesState] = useState([]);
  const [profile, setprofile] = useState(null);
  const [paragraphs, setParagraphs] = useState([]);

  const [__html, setHTML] = React.useState("");
  let [urlofdocument, seturlofdocument] = useState([]);
  const filetype = ["application/pdf", "text/plain"];
  // function getParagraphs(page) {
  //   const zip = new PizZip(page);
  //   const xml = str2xml(zip.load[page].asText());
  //   const paragraphsXml = xml.getElementsByTagName("w:p");
  //   const paragraphs = [];
  
  //   for (let i = 0, len = paragraphsXml.length; i < len; i++) {
  //     let fullText = "";
  //     const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
  //     for (let j = 0, len2 = textsXml.length; j < len2; j++) {
  //       const textXml = textsXml[j];
  //       if (textXml.childNodes) {
  //         fullText += textXml.childNodes[0].nodeValue;
  //       }
  //     }
  //     if (fullText) {
  //       paragraphs.push(fullText);
  //     }
  //   }
  //   return paragraphs;
  // }
  const handleUploadFile = async(event) => {
  
    let selectfile=event.target.files[0]
    if (selectfile){

        setSelected(event.target.files[0])
        
       
       
       let reader=new FileReader()
        reader.onload=(e)=>{
          

          let copyofurl=[]
          if(event.target.files[0]?.type==="application/pdf"){
            copyofurl.push({uri:e.target.result,fileType:"pdf"})

          }else if(event.target.files[0]?.type===("application/vnd.openxmlformats-officedocument.wordprocessingml.document")){
          
            copyofurl.push({uri:e.target.result,fileType:"doc"})
            
          }else if(event.target.files[0]?.type==="application/msword"){
          
            copyofurl.push({uri:e.target.result,fileType:"doc"})
          }else if(event.target.files[0]?.type==="image/jpg"){
            copyofurl.push({uri:e.target.result,fileType:"jpg"})
          }else if(event.target.files[0]?.type==="image/jpeg"){
            copyofurl.push({uri:e.target.result,fileType:"jpeg"})
          }else if(event.target.files[0]?.type==="image/png"){
            console.log(event.target.files[0]?.type)
            copyofurl.push({uri:e.target.result,fileType:"png"})
          }else{
            copyofurl.push({uri:e.target.result})

          }
        
          seturlofdocument(copyofurl)
          
        }
        reader.readAsDataURL(selectfile)
        // uploadInputRef.current=selectfile
    }else{
      // uploadInputRef.current=selectfile

      setprofile(null)
    }
   
  };
  const [addcontract, resultofcontract] = useConfirmMessage({
    onConfirm: handleUploadFile,
    text: globalLang.makesureofdelete[lang],
  });
  

  const handleDragFile = (e) => {
    setSelected(e.dataTransfer.files[0]);
  };
  function submitdoc() {
    const requestBody = filter({
      obj: {
        contract: selected,
        // agent: selectedEmployee,
      },
      output: "formData",
    });
    ContractPostRequest({
      body: requestBody,
      onSuccess: (res) => {
    
        dispatch({ type: "contractslice/addItem", payload: res.data });
        setSelected("")
        seturlofdocument([])
        // handleUploadFile()
      },
    });
  }
  useEffect(() => {
    // submitdoc();
  }, [selected,urlofdocument]);
  

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.contract[lang], globalLang.addcontract[lang]]}
      />
      <Stack alignItems="center" spacing={2}>
        <Box
          component="input"
          type="file"
          sx={{ display: "none" }}
          onChange={handleUploadFile}
          ref={reftofile}
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,.doc,.jpg,.jpeg,.png,.pdf"
        
        />
        <DropBox
          files={fileProgress && <ProgressCard progress={fileProgress} />}
           isPending={ContractPostResponse.isPending}
          onClick={() => reftofile.current.click()}
          onDrag={handleDragFile}
          buttonLabel={globalLang.reviewfile[lang]}
          title={globalLang.reviewfile[lang]}
        />
      
        {urlofdocument[0]?.fileType !=="doc" ? (<>
        <DocViewer
           documents={urlofdocument}
           prefetchMethod="GET"
           pluginRenderers={DocViewerRenderers}
           requestHeaders={{ "Access-Control-Allow-Origin": "*"}}
           config={{
             header: {
               disableHeader: true,
               disableFileName: true,
               retainURLParams: true,
             },
             csvDelimiter: ";", // "," as default,
            
             pdfVerticalScrollByDefault: false,
           }}
         />
       
           </>
        ) : urlofdocument[0]?.fileType ==="doc"?(
          <> <img src={image} width={200} height={200}/> 
   
    </>
        ):<></>}
        {urlofdocument[0]?.uri?<Button
              variant="contained"
              size="large"
              sx={{ width: 150, height: 50 }}
              disabled={ContractPostResponse.isPending}
              // disabled={isPending}
              onClick={submitdoc}
            >
              {globalLang.uploadFile[lang]}
            </Button>
        :<></>}
        
      </Stack>
      {ContractPostResponse.successAlert}
      {ContractPostResponse.failAlert}
    </Wrapper>
  );
};

export default AddContract;
