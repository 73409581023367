// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node__root>circle {
    fill: #0a0f1d;
    
    stroke: #313b4d;
}

.node__branch>circle {
    fill: #233975;
    
    stroke: #2d3748;
}

.node__leaf>circle {
    fill: #233975;

    stroke: #2d3748; 
}
.tree{

    width: 100%;
    margin: auto;
    height: 100vh; 
 
    
}

`, "",{"version":3,"sources":["webpack://./src/pages/employee/tree.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,eAAe;AACnB;;AAEA;IACI,aAAa;;IAEb,eAAe;AACnB;;AAEA;IACI,aAAa;;IAEb,eAAe;AACnB;AACA;;IAEI,WAAW;IACX,YAAY;IACZ,aAAa;;;AAGjB","sourcesContent":[".node__root>circle {\n    fill: #0a0f1d;\n    \n    stroke: #313b4d;\n}\n\n.node__branch>circle {\n    fill: #233975;\n    \n    stroke: #2d3748;\n}\n\n.node__leaf>circle {\n    fill: #233975;\n\n    stroke: #2d3748; \n}\n.tree{\n\n    width: 100%;\n    margin: auto;\n    height: 100vh; \n \n    \n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
