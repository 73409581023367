import React from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert, Box, Button, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import useControls from "../../hooks/useControls";
import { useState } from "react";
import { CHANGE_PASSWORD, USER_INFO } from "../../data/APIs";
import filter from "../../utils/ClearNull";
import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { PhoneField } from "../../features/form";
import compare from "../../utils/Compare";
import useAfterEffect from "../../hooks/useAfterEffect";

const Setting = () => {
  const [visibilities, setVisibilities] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const userInfo = useSelector((state) => state.userInfo.value);
  
  const [failAlert, setFailAlert] = useState({
    open: false,
    message: "",
  });
  const [
    { controls, invalid, required },
    { setControl, resetControls, validate },
  ] = useControls([
    {
      control: "old",
      value: "",
      isRequired: true,
    },
    {
      control: "new",
      value: "",
      isRequired: true,
    },
    {
      control: "confirm",
      value: "",
      isRequired: true,
      validations: [
        {
          test: (controls) => new RegExp(`^${controls.new}$`),
          message: "الرقم السري لا يطابق",
        },
      ],
    },
    {control:"additional_phone",value:userInfo.additional_phones[0]?.phone,isRequired:false},
    {control:"additional_phone_2",value:userInfo.additional_phones[1]?.phone,isRequired:false},
    {control:"additional_phone_3",value:userInfo.additional_phones[2]?.phone,isRequired:false},
    {control:"country_code_phone",value:userInfo.additional_phones[0]?.country_code?userInfo.additional_phones[0].country_code:userInfo.country_code,isRequired:false},
    {control:"country_code_phone_2",value:userInfo.additional_phones[1]?.country_code?userInfo.additional_phones[1].country_code:userInfo.country_code,isRequired:false},
    {control:"country_code_phone_3",value:userInfo.additional_phones[2]?.country_code?userInfo.additional_phones[2].country_code:userInfo.country_code,isRequired:false}


  ]);
 
  const handleVisibilityToggle = (which) => {
    switch (which) {
      case "old":
        setVisibilities({ ...visibilities, old: !visibilities.old });
        break;
      case "new":
        setVisibilities({ ...visibilities, new: !visibilities.new });
        break;
        break;
      case "confirm":
        setVisibilities({ ...visibilities, confirm: !visibilities.confirm });
        break;
      default:
        setVisibilities(visibilities);
    }
  };

  const [changePasswordPostRequest, changePasswordPostResponse] = useRequest({
    path: CHANGE_PASSWORD,
    method: "put",
    successMessage: "تم تغيير الرقم السري بنجاح",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;
      const requestBody = filter({
        obj: {
          old_password: controls.old,
          new_password: controls.new,
          confirm_new_password: controls.confirm,
        },
      });
      changePasswordPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          resetControls();
        },
      });
    });
  };

  // change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const controlLang = useSelector((state) => state.lang.value.control);
  const globalLang = useSelector((state) => state.lang.value.global);

  const [toggleLang, setToggleLang] = useState(lang);

  const dispatch = useDispatch();

  const handleToggleLang = () => {
    setToggleLang((old) => (old === "ar" ? "en" : "ar"));
  };
  let [phoneNumbers, setPhoneNumbers] = useState([...userInfo.additional_phones]);
  let [code, setCode] = useState(userInfo.country_code);

  const handlePhoneNumberChange = (index, value) => {
    
    let numbers = [...phoneNumbers];
 
    numbers[index].phone = value;
    setPhoneNumbers(numbers);
  };
  const handleCodeChange = (index, value) => {
    const numbers = [...phoneNumbers];
    numbers[index].country_code= value;
    setPhoneNumbers(numbers);
  };
  // const handleAddPhoneNumber = () => {
  //   setPhoneNumbers([...phoneNumbers, '']);
  // };
  // const handleRemovePhoneNumber = (index) => {
  //   const numbers = [...phoneNumbers];
  //   numbers.splice(index, 1);
  //   setPhoneNumbers(numbers);
  // };
  useEffect(() => {

    dispatch({ type: "lang/toggleLang", payload: toggleLang });
  }, [toggleLang]);
  let [updateuesr,setuserphone]=useRequest({
   method:"patch",
   path:USER_INFO,
   successMessage:globalLang.successedit[lang]

  })
  ////////////////////
  const handleEditPhone = () => {
    const isThereChange = compare(
      [
        [controls.additional_phone, userInfo.additional_phones[0].phone,"additional_phone"],
        [controls.additional_phone_2, userInfo.additional_phones[1].phone,"additional_phone_2"],
        [controls.additional_phone_3, userInfo.additional_phones[2].phone,"additional_phone_3"],
      
      ],
      false
    );
  
   if(isThereChange.nochange){
    const requestBody = filter({
      
      obj: {
        additional_phone:isThereChange.array["additional_phone"]!==undefined&&isThereChange.array["additional_phone"]!==""?controls.country_code_phone+isThereChange.array["additional_phone"]:isThereChange.array["additional_phone"]===""?"":undefined,
        additional_phone_2:isThereChange.array["additional_phone_2"]!==undefined&&isThereChange.array["additional_phone_2"]!==""?controls.country_code_phone_2+isThereChange.array["additional_phone_2"]:isThereChange.array["additional_phone_2"]===""?"":undefined,
        additional_phone_3:isThereChange.array["additional_phone_3"]!==undefined&&isThereChange.array["additional_phone_3"]!==""?controls.country_code_phone_3+isThereChange.array["additional_phone_3"]:isThereChange.array["additional_phone_3"]===""?"":undefined
      },
    });
    updateuesr({
      body: requestBody,
      id: userInfo.id,
      onSuccess: (res) => {
       
   
        dispatch({
          type: "userInfo/setUserInfo",
          payload:  res.data ,
        });
        // console.log(res)
        // setPostEditData(res);
      },
    });
   }
  };
  

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.control[lang], [controlLang.changePassword[lang]]]}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: 500 }}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ width: 350 }}
          spacing={3}
        >
          <TextField
            type={visibilities.old ? "text" : "password"}
            variant="standard"
            label={globalLang.oldPassword[lang]}
            sx={{
              "& .MuiInputLabel-formControl": {
                fontSize: 20,
                fontWeight: "normal",
                transform: "translate(10px, -10.5px) scale(0.75)",
                color: (theme) => theme.palette.primary.main,
                // borderColor:(theme)=>theme.palette.primary.main
              },
              "& .MuiInput-input": {
                paddingBlock: 1.2,
                fontSize: 15,
              },
              "& .MuiInputBase-formControl": {
                borderColor: "#233975",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => handleVisibilityToggle("old")}
                >
                  <IconButton>
                    {visibilities.old ? (
                      <VisibilityIcon color="primary" />
                    ) : (
                      <VisibilityOffIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={controls.old}
            onChange={(e) => setControl("old", e.target.value)}
            required={required.includes("old")}
            error={Boolean(invalid?.old)}
            helperText={invalid?.old}
          />
          <TextField
            type={visibilities.new ? "text" : "password"}
            variant="standard"
            label={globalLang.newPassword[lang]}
            sx={{
              "& .MuiInputLabel-formControl": {
                fontSize: 20,
                fontWeight: "normal",
                transform: "translate(10px, -10.5px) scale(0.75)",
                color: (theme) => theme.palette.primary.main,
              },
              "& .MuiInput-input": {
                paddingBlock: 1.2,
                fontSize: 15,
              },
              "& .MuiInputBase-formControl": {
                borderColor: "#233975",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleVisibilityToggle("new")}>
                    {visibilities.new ? (
                      <VisibilityIcon color="primary" />
                    ) : (
                      <VisibilityOffIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={controls.new}
            onChange={(e) => setControl("new", e.target.value)}
            required={required.includes("new")}
            error={Boolean(invalid?.new)}
            helperText={invalid?.new}
          />
          <TextField
            type={visibilities.confirm ? "text" : "password"}
            variant="standard"
            label={globalLang.confirmPassword[lang]}
            sx={{
              "& .MuiInputLabel-formControl": {
                fontSize: 20,
                fontWeight: "normal",
                transform: "translate(10px, -10.5px) scale(0.75)",
                color: (theme) => theme.palette.primary.main,
              },
              "& .MuiInput-input": {
                paddingBlock: 1.2,
                fontSize: 15,
              },
              "& .MuiInputBase-formControl": {
                borderColor: "#233975",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleVisibilityToggle("confirm")}>
                    {visibilities.confirm ? (
                      <VisibilityIcon color="primary" />
                    ) : (
                      <VisibilityOffIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={controls.confirm}
            onChange={(e) => setControl("confirm", e.target.value)}
            required={required.includes("confirm")}
            error={Boolean(invalid?.confirm)}
            helperText={invalid?.confirm}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={changePasswordPostResponse.isPending}
          >
            {globalLang.save[lang]}
          </Button>
        </Stack>
      </Stack>
      {changePasswordPostResponse.successAlert}
      {changePasswordPostResponse.failAlert}

      <Breadcrumbs
        path={[globalLang.control[lang], controlLang.changeLanguage[lang]]}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ width: 350 }}
          spacing={3}
        >
          <Button variant="contained" onClick={handleToggleLang}>
            {toggleLang === "ar" ? "English" : "عربي"}
          </Button>
        </Stack>
      </Stack>
      {userInfo.job_title==="Company Owner"?
     <> <Breadcrumbs
        path={[globalLang.control[lang], [globalLang.editphones[lang]]]}
      />
       <Stack>
      {/* {phoneNumbers.length<4?phoneNumbers.map((phoneNumber, index) => ( */}
    
        <Box  sx={{
          width: "35%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          marginY:"20px"
      }}>
          <PhoneField
            label={globalLang.phone_number[lang]}
            variant="standard"
          
            selectProps={{
              value: controls.country_code_phone,
              onChange: (e) =>setControl("country_code_phone",e.target.value)
            }}
            value={controls.additional_phone}
            onChange={(e) => setControl("additional_phone",e.target.value)}
          
           sx={{
            "& .MuiInputLabel-formControl": {
              fontSize: 20,
              fontWeight: "normal",
              transform: "translate(10px, -10.5px) scale(0.75)",
              color: (theme) => theme.palette.primary.main,
              // borderColor:(theme)=>theme.palette.primary.main
            },"& .muirtl-1nduh99-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":{
              height:"0px"
            },
            "& .MuiInput-input": {
              paddingBlock: 1.2,
              fontSize: 15,
            },
            "& .MuiInputBase-formControl": {
              borderColor: "#233975",
            },width:"100%"
          }}
         
          />
          
          {/* {index === phoneNumbers.length - 1 && (
            <Button onClick={handleAddPhoneNumber}>+</Button>
          )}
          {index !== phoneNumbers.length - 1 && (
            <Button onClick={() => handleRemovePhoneNumber(index)}>-</Button>
          )} */}
        </Box>
        <Box  sx={{
          width: "35%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          marginY:"20px"
      }}>
        <PhoneField
            label={globalLang.phone_number[lang]}
            variant="standard"
          
            selectProps={{
              value: controls.country_code_phone_2,
              onChange: (e) =>setControl("country_code_phone_2",e.target.value)
            }}
            value={controls.additional_phone_2}
            onChange={(e) => setControl("additional_phone_2",e.target.value)}
          
           sx={{
            "& .MuiInputLabel-formControl": {
              fontSize: 20,
              fontWeight: "normal",
              transform: "translate(10px, -10.5px) scale(0.75)",
              color: (theme) => theme.palette.primary.main,
              // borderColor:(theme)=>theme.palette.primary.main
            },"& .muirtl-1nduh99-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":{
              height:"0px"
            },
            "& .MuiInput-input": {
              paddingBlock: 1.2,
              fontSize: 15,
            },
            "& .MuiInputBase-formControl": {
              borderColor: "#233975",
            },width:"100%"
          }}
         
          /></Box>
          <Box  sx={{
          width: "35%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          marginY:"20px"
      }}>
        <PhoneField
            label={globalLang.phone_number[lang]}
            variant="standard"
          
            selectProps={{
              value: controls.country_code_phone_3,
              onChange: (e) =>setControl("country_code_phone_3",e.target.value)
            }}
            value={controls.additional_phone_3}
            onChange={(e) => setControl("additional_phone_3",e.target.value)}
          
           sx={{
            "& .MuiInputLabel-formControl": {
              fontSize: 20,
              fontWeight: "normal",
              transform: "translate(10px, -10.5px) scale(0.75)",
              color: (theme) => theme.palette.primary.main,
              // borderColor:(theme)=>theme.palette.primary.main
            },"& .muirtl-1nduh99-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":{
              height:"0px"
            },
            "& .MuiInput-input": {
              paddingBlock: 1.2,
              fontSize: 15,
            },
            "& .MuiInputBase-formControl": {
              borderColor: "#233975",
            },width:"100%"
          }}
         
          /></Box>
      {/* )):<></>} */}
       <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        component="form"
        // onSubmit={handleSubmit}
        // noValidate
      >
       <Button variant="contained" onClick={handleEditPhone}>
       {globalLang.editphones[lang]}
          </Button></Stack>
    </Stack></>:<></>}
     
    
    {setuserphone.successAlert}
    {setuserphone.failAlert}
    </Wrapper>
  );
};

export default Setting;
