import React, { useEffect, useState } from "react";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Zoom from "react-reveal/Zoom";
import Jump from "react-reveal/Jump";
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Tab,
  Typography,
} from "@mui/material";

import useRequest from "../../hooks/useRequest";
import { COUNTRY_FILTER, MEANSOFCOMFORT, STATES, STATE_CITIES, UNITS } from "../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import Form, { InputField, NumberField, PictureField, SelectField } from "../../features/form";
import useControls from "../../hooks/useControls";
import filter from "../../utils/ClearNull";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogContent,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogPhoneField,
  DialogSelectField,
} from "../../features/dialog";
import useAfterEffect from "../../hooks/useAfterEffect";
import DialogNumberField from "../../features/dialog/components/DialogNumberField";
import compare from "../../utils/Compare";
import AddIcon from "@mui/icons-material/Add";
import { Box, Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef } from "react";
import usePropState from "../../hooks/usePropState";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  EmailIcon,
} from "react-share";
import format from "../../utils/ISOToReadable";
import { NavLink, useNavigate } from "react-router-dom";
import { TypeSpecimenTwoTone } from "@mui/icons-material";
import DialogCheckboxField from "../../features/dialog/components/DialogCheckbox";
import AutocompleteField from "../../features/form/components/AutocompleteField";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const ViewUnits = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const unitsStore = useSelector((state) => state.units.value);
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];

  const columns = [
    {
      field: "mainImage",
      headerName: globalLang.main_image[lang],
      customContent: (params) => {
        return (
          <Avatar
            src={params?.main_image}
            variant="rounded"
            sx={{ width: 90, height: 90 }}
          >
            U
          </Avatar>
        );
      },
    },
    {field:"city",headerName:globalLang.city[lang],customContent:(params)=>params.city},
    {field:"client",headerName:globalLang.owner[lang],customContent:(params)=>params.client},
    {
      field: "sales_type",
      headerName: globalLang.saleType[lang],
      customContent: (params) => params?.sales_type,
      customEmpty: "-",
    },
    {
      field: "area",
      headerName: globalLang.region[lang],
      customContent: (params) => {
        return params.area;
      },
      customEmpty:"-"
    }, {
      field: "address",
      headerName: globalLang.address[lang],
      customContent: (params) => {
        return params.address;
      },customEmpty:"-"
    },{
      field: "unit_type",
      headerName: globalLang.unitType[lang],
      customContent: (params) => params?.unit_type,
      customEmpty: "-",
    },{
      field: "floor_number",
      headerName: globalLang.floor[lang],
      customContent: (params) => params?.floor_number,
      customEmpty: "-",
    },{
      field: "area_size",
      headerName: globalLang.area[lang],
      customContent: (params) => params?.area_size,
      customEmpty: "-",
    },{
      field: "complete_type",
      headerName: globalLang.finishingType[lang],
      customContent: (params) => params?.complete_type,
      customEmpty: "-",
    },{
      field: "price",
      headerName: globalLang.price[lang],
      customContent: (params) => {
        return params.price;
      },customEmpty:"-"
    },
    {
      field: "rooms",
      headerName: globalLang.roomsNumber[lang],
      customContent: (params) => {
        return params.room_number;
      },
      customEmpty:"-"
    },
    {
      field: "category",
      headerName: globalLang.category[lang],
      customContent: (params) => {
        return params.category;
      },customEmpty:"-"
    } ,
    {
      field: "amenities_ids",
      headerName: globalLang.meansofcomfort[lang],
      customContent: (params) => {
        return params.amenities?.map((ele)=>ele.name).join(",");
      },customEmpty:"-"
    }, {
      field: "transferred_by",
      headerName: globalLang.transferred_by[lang],
      customContent: (params) => {
        return params.transferred_by;
      },customEmpty:"-"
    }, 
    //  {
    //   field: "picture",
    //   headerName: globalLang.picture[lang],
    //   customContent: (params) => {
    //     return (
    //       <Avatar
    //         src={params?.files[0]?.file}
    //         variant="rounded"
    //         sx={{ width: 90, height: 90 }}
    //       >
    //         U
    //       </Avatar>
    //     );
    //   },
    // },
   
  ];

  const dispatch = useDispatch();

  const [unitsGetRequest, unitsGetResponse] = useRequest({
    path: UNITS,
    method: "get",
  });

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item?.map(([key, value]) => urlParams.append(key, value))
    );
    delete requestParams.currentPage

    unitsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "units/set", payload: res.data });
      },
    });
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [unitDeleteRequest, unitDeleteResponse] = useRequest({
    path: UNITS,
    method: "delete",
    successMessage: "تم حذف الوحدة بنجاح",
  });

  const deleteUnit = (e, row) => {
    unitDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "units/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteUnit, deleteUnitConfirmDialog] = useConfirmMessage({
    onConfirm: deleteUnit,
    text: "هل انت متأكد من انك تريد حذف هذه الوحدة",
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "country",
      value: "",
      isRequired: false,
    },
    {
      control: "governorate",
      value: "",
      isRequired: false,
    },
    {
      control: "city",
      value: "",
      isRequired: false,
    },
    {
      control: "district",
      value: "",
      isRequired: false,
    },
    {
      control: "near",
      value: "",
      isRequired: false,
    },
    {
      control: "number",
      value: "",
      isRequired: false,
    },
    {
      control: "type",
      value: "",
      isRequired: false,
    },
    {
      control: "sale",
      value: "",
      isRequired: false,
    },
    {
      control: "floor",
      value: "",
      isRequired: false,
    },
    {
      control: "area",
      value: "",
      isRequired: false,
    },
    {
      control: "rooms",
      value: "",
      isRequired: false,
    },
    {
      control: "bathrooms",
      value: "",
      isRequired: false,
    },
    {
      control: "genre",
      value: "",
      isRequired: false,
    },
    {
      control: "price",
      value: "",
      isRequired: false,
    },
    {
      control: "client",
      value: "",
      isRequired: false,
    },
  ]);

  const handleFilter = () => {
    // const isThereChange = compare(
    //   [
    //     [controls.country, data?.country,"country"],
    //     [controls.governorate, data?.governorate,"governorate"],
    //     [controls.city, data?.city,"city"],
    //     // [controls.district, data?.area,"area"],
    //     [controls.near, data?.near,"near"],
    //     [controls.number, data?.number,"number"],
    //     [controls.type, data?.unit_type,"unit_type"],
    //     [controls.sale, data?.sale,"sale"],
    //     [controls.floor, data?.floor,"floor"],
    //     [controls.area,data?.area,"area"],
    //     [controls.rooms,data?.rooms,"rooms"],
    //     [controls.bathrooms, data?.bathrooms,"bathrooms"],
    //     [controls.genre, data?.genre,"genre"],
    //     [controls.price,data?.price,"price"],
    //     [controls.client,data?.client,"client"],
    //   ],
    //   false
    // );

    // if (isThereChange.nochange) {
      const requestBody = filter({
        obj: {
          country: controls.country,
          state: controls.governorate,
          city: controls.city,
          area: controls.district,
          part: controls.near,
          flat_number: controls.number,
          unit_type: controls.type,
          sales_type: controls.sale,
          floor_number: controls.floor,
          area_size: controls.area,
          room_number: controls.rooms,
          bath_count: controls.bathrooms,
          complete_type: controls.genre,
          price: controls.price,
          client: controls.client,
        },
      });
   
    

    setRequestParams((old) => ({
      ...old,
      filters: Object.entries(requestBody).filter(([key,value])=>value)
    }));
  };

  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = (country) => {
    statesGetRequest({
      body: {
        country: country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };
  const bathrooms = [
    ...Array(10)
      .fill(0)
      .map((_, index) => index + 1),
  ];
  
  const rooms = [
    ...Array(10)
      .fill(0)
      .map((_, index) => index + 1),
  ];
  
  //===End===== Get Countries logic =========

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.units[lang], viewUnitsLang.viewUnits[lang]]}
      />
      <Form
        childrenProps={{
          saveBtn: {
            children: globalLang.filter[lang],
            onClick: handleFilter,
          },
          closeBtn: {
            onClick: handleResetFilter,
          },
        }}
      >
        <SelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            setControl("governorate", "");
            setControl("city", "");
          }}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={()=>getGovernorates(controls.country)}
          isPending={statesGetResponse.isPending}
          value={controls.governorate}
          onChange={(e) => {
            setControl("governorate", e.target.value);
            setControl("city", "");
          }}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </SelectField>
        <InputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
        />
        <InputField
          label={globalLang.region[lang]}
          placeholder={globalLang.region[lang]}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
        />
         <InputField
          label={globalLang.unitType[lang]}
          placeholder={globalLang.unitType[lang]}
          value={controls.type}
          onChange={(e) => setControl("type", e.target.value)}
        />
         <SelectField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          value={controls.floor}
          renderValue={(selected) =>
            floors.find((f) => f.value === selected).name
          }
          onChange={(e) => setControl("floor", e.target.value)}
        >
          {floors.map((floor, index) => (
            <MenuItem value={floor.value} key={` ${index}`}>
              {floor.name}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.area[lang]}
          placeholder={globalLang.area[lang]}
          suffix=" متر"
          // isAllowed={({ value }) => value.length <= 5}
          // required={required.includes("area")}
          value={controls.area}
          onChange={(e) =>
            setControl("area", e.target.value.replace("متر", "").trim())
          }
          // error={Boolean(invalid.area)}
          // helperText={invalid.area}
        />
        <InputField
          label={globalLang.finishingType[lang]}
          placeholder={globalLang.finishingType[lang]}
          value={controls.genre}
          onChange={(e) => setControl("genre", e.target.value)}
        />
         <NumberField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          value={controls.price}
          onChange={(e) => setControl("price", e.target.value)}
        />
        <InputField
          label={globalLang.neighboring[lang]}
          placeholder={globalLang.neighboring[lang]}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
        />
     
        <NumberField
          label={globalLang.buildingNumber[lang]}
          placeholder={globalLang.buildingNumber[lang]}
          value={controls.number}
          onChange={(e) => setControl("number", e.target.value)}
        />
       
         <SelectField
          label={globalLang.saleType[lang]}
          placeholder={globalLang.saleType[lang]}
          value={controls.sale}
          renderValue={(selected) =>
            ["rent","sell"].find((f) => f === selected)
          }
          onChange={(e) => setControl("sale", e.target.value)}
        >
          {["rent","sell"].map((floor, index) => (
            <MenuItem value={floor} key={` ${index}`}>
              {floor}
            </MenuItem>
          ))}
        </SelectField>
        
    
       
        {/* <InputField
          label={globalLang.area[lang]}
          placeholder={globalLang.area[lang]}
          value={controls.area}
          onChange={(e) => setControl("area", e.target.value)}
        /> */}
      
         <SelectField
          label={globalLang.roomsNumber[lang]}
          placeholder={globalLang.roomsNumber[lang]}
          // required={required.includes("rooms")}
          value={controls.rooms}
          onChange={(e) => setControl("rooms", e.target.value)}
          // error={Boolean(invalid.rooms)}
          // helperText={invalid.rooms}
        >
          {rooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        <SelectField
          label={globalLang.bathroomsNumber[lang]}
          placeholder={globalLang.bathroomsNumber[lang]}
          // required={required.includes("bathrooms")}
          value={controls.bathrooms}
          onChange={(e) => setControl("bathrooms", e.target.value)}
          // error={Boolean(invalid.bathrooms)}
          // helperText={invalid.bathrooms}
        >
          {bathrooms.map((bathroom, index) => (
            <MenuItem value={bathroom} key={`bathroom ${index}`}>
              {bathroom}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        
        
       
        <InputField
          label={globalLang.customerName[lang]}
          placeholder={globalLang.customerName[lang]}
          value={controls.client}
          onChange={(e) =>{
            let text=/^(?:[A-Za-z\u0600-\u06ff\s]*)$/
            let match=text.test(e.target.value)
            if(match){
            setControl("client", e.target.value)
            }
          }
            
          }
        />
      </Form>

      <DataGrid
        columns={columns}
        rows={unitsStore.results}
        total={unitsStore.count}
        isPending={unitsGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onView={(e, row) => setInfoData(row)}
      />

      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      />
      {deleteUnitConfirmDialog}
      {unitDeleteResponse.successAlert}
      {unitDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default ViewUnits;

const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const unitsStore = useSelector((state) => state.units.value);
  const [opened, setOpened] = useState(false);
  const [actived, setActived] = useState(false);
  ///////////////////////////////
 
  const saleTypes = [
    {
      name: "بيع",
      value: "sell",
    },
    {
      name: "إيجار",
      value: "rent",
    },
  ];
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "country",
        value: data?.country,
        isRequired: false,
        
      },
      {
        control: "governorate",
        value: data?.state,
        isRequired: false,
      },
      {
        control: "city",
        value: data?.city,
        isRequired: false,
      },
      {
        control: "district",
        value: data?.area,
        isRequired: false,
      },
      {
        control: "near",
        value: data?.part,
        isRequired: false,
      },
      {
        control: "address",
        value: data?.address,
        isRequired: false,
      },
      {
        control: "number",
        value: data?.flat_number,
        isRequired: false,
      },
      {
        control: "type",
        value: data?.unit_type,
        isRequired: false,
      },
      {
        control: "floor",
        value: data?.floor_number,
        isRequired: false,
      },
      {
        control: "area",
        value: data?.area_size,
        isRequired: false,
      },
      {
        control: "rooms",
        value: data?.room_number,
        isRequired: false,
      },{
        control: "kitchen_count",
        value: data?.kitchen_count,
        isRequired: false,
      },
      {
        control: "bathrooms",
        value: data?.bath_count,
        isRequired: false,
      },
      {
        control: "genre",
        value: data?.complete_type,
        isRequired: false,
      },
      {
        control: "price",
        value: data?.price,
        isRequired: false,
      },
      {
        control: "client",
        value: data?.client,
        isRequired: false,
      },
      {
        control: "countryCode",
        value: data?.country_code?data.country_code:"+20",
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone_client,
        isRequired: false,
      },
      {
        control: "notes",
        value: data?.comment,
        isRequired: false,
      },
      {
        control: "is_public",
        value: data?.is_public,
        isRequired: false,
      },{
        control: "living_room_count",
        value: data?.living_room_count,
        isRequired: false,
      },{
        control:"amenities_ids",
        value:data?.amenities,
        isRequired:false
      },{
        control:"sale",
        value:data?.sales_type,
        isRequired:false
      },{
        control:"main_image",
        value:data?.main_image,
        isRequired:false
      }
    ],
    [data]
  );
   
  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: UNITS,
    method: "patch",
    successMessage: "تم تعديل الوحدة بنجاح",
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const isThereChange = compare(
      [
        [controls.country, data?.country,"country"],
        [controls.governorate, data?.state,"state"],
        [controls.city, data?.city,"city"],
        [controls.district, data?.area,"area"],
        [controls.living_room_count,data?.living_room_count,"living_room_count"],
        [Array.isArray(controls.amenities_ids)?controls.amenities_ids.join(","):"",Array.isArray(data?.amenities)?data?.amenities.join(","):"","amenities_ids"],
        [controls.kitchen_count,data?.kitchen_count,"kitchen_count"],
        [controls.near, data?.part,"part"],
        [controls.address, data?.address,"address"],
        [controls.number, data?.flat_number,"flat_number"],
        [controls.type, data?.unit_type,"unit_type"],
        [controls.floor, data?.floor_number,"floor_number"],
        [controls.area, data?.area_size,"area_size"],
        [controls.rooms, data?.room_number,"room_number"],
        [controls.bathrooms, data?.bath_count,"bath_count"],
        [controls.genre, data?.complete_type,"complete_type"],
        [controls.price, data?.price,"price"],
        [controls.client, data?.client,"client"],
        [controls.phone, data?.phone_client,"phone_client"],
        [controls.countryCode, data?.country_code_phone_client,"country_code_phone_client"],
        [controls.notes, data?.comment,"comment"],
        [Boolean(controls.is_public),data?.is_public,"is_public"],
        [controls.main_image,  data?.main_image?data?.main_image:"", "main_image"]
      ],
      false
    );

    if (isThereChange.nochange) {
      const requestBody = filter({
        obj: {
          country: isThereChange.array["country"],
          state: isThereChange.array["state"],
          city: isThereChange.array["city"],
          area: isThereChange.array["area"],
          area_size: isThereChange.array["area_size"],
          part:isThereChange.array["part"],
          address: isThereChange.array["address"],
          flat_number: isThereChange.array["flat_number"],
          is_public:isThereChange.array["is_public"]===true||isThereChange.array["is_public"]===false?String(isThereChange.array["is_public"]):undefined,
          unit_type: isThereChange.array["unit_type"],
          sales_type: isThereChange.array["sales_type"],
          floor_number: isThereChange.array["floor_number"],
          kitchen_count:isThereChange.array["kitchen_count"],
          living_room_count:isThereChange.array["living_room_count"],
          amenities_ids:Array.isArray(controls.amenities_ids)?JSON.stringify([...controls.amenities_ids?.map((ele)=>ele.id?ele.id:ele.value)]):"[]",

          // area_size: isThereChange.array["area_size"],
          room_number: isThereChange.array["room_number"],
          bath_count: isThereChange.array["bath_count"],
          complete_type: isThereChange.array["complete_type"],
          price: isThereChange.array["price"],
          client: isThereChange.array["client"],
          phone_client: isThereChange.array["country_code_phone_client"]?isThereChange.array["country_code_phone_client"]+isThereChange.array["phone_client"]?isThereChange.array["phone_client"]:controls.phone_client:isThereChange.array["phone_client"]?controls.countryCode+isThereChange.array["phone_client"]:undefined,
          comment: isThereChange.array["comment"],
          main_image:isThereChange.array["main_image"]
        },output:"formData"
      });
      
      unitPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "units/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          // resetControls()
          onClose();
        },
      });
    }else{
      // resetControls()
      onClose()
    }
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    if (countriesData.length) return;
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };
  const handleDeletePicture = (id) => {
    const requestBody = filter({
      obj: {
        file_id: id,
      },
      output: "formData",
    });
    
    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        dispatch({
          type: "units/putItem",
          payload: { id: res.data.id, item: res.data },
        });

        setPictures(res.data.files);
      },
    });
  };
  const [governoratesData, setGovernoratesData] = useState([]);
  const [meanspfcomfort, setMeansOfConfort] = useState([]);
  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });
  const [meansofcomfortRequest,meansofcomfortResponse] = useRequest({
    path: MEANSOFCOMFORT,
    method: "get",
    successMessage: globalLang.getamenities[lang],
  });
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];
  const getGovernorates = () => {
    // if (governoratesData.length) return;
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {

        setGovernoratesData(res.data.data);
      },
    });
  };
  useAfterEffect(()=>{
    if(controls.country){
      getGovernorates()
    }

  },[controls.country])
  //===End===== Get Countries logic =========
  const getMeansOfComfort=()=>{
    meansofcomfortRequest({
      onSuccess:(res)=>{

        setMeansOfConfort(res.data.results)
      }
    })
  }
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const addPictureRef = useRef(null);
  const changePictureRef = useRef(null);
  const [pictures, setPictures] = usePropState(data?.files, [data]);
  const [picturePatchRequest, picturePatchResponse] = useRequest({
    path: UNITS,
    method: "patch",
    successMessage:globalLang.successedit[lang]
  });
  const handleAddPicture = (e) => {
 
    const requestBody = filter({
      obj: {
        files: Object.keys(e.target.files).map((ele)=>e.target.files[ele]),
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        // dispatch({
        //   type: "units/putItem",
        //   payload: { id: res.data.id, item: res.data },
        // });
        setPictures(res.data.files);
      },
    });
  };
  // const handleOpened = () => {
  //   setOpened(true);
  //   setActived(true);
  // };
  // const handleClosed = () => {
  //   setOpened(false);
  //   setActived(false);
  // };
  return (<>
    <Dialog open={open} onClose={onClose} paperProps={{ height: "100%" }}>
   <TabContext value={value}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <TabList onChange={handleChange} aria-label="lab API tabs example"sx={{".MuiButtonBase-root.MuiTab-root.Mui-selected":{color:'#fff'},'.MuiButtonBase-root.MuiTab-root':{
        color:"gray"
      }}}>
        <Tab label={globalLang.editUnitData[lang]} value="1" />
        <Tab label={lang == "ar" ? " معرض الصور " : " Gallery "} value="2" />
      </TabList>
    </Box>  
    <TabPanel value="1">
    <Wrapper>
  
      {/* <DialogHeading>{globalLang.editUnitData[lang]}</DialogHeading> */}
      <DialogForm>
        <DialogSelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            // setControl("governorate", "");
            // setControl("city", "");
          }}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogSelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={()=>getGovernorates()}
          isPending={statesGetResponse.isPending}
          value={controls.governorate}
          onChange={(e) => {
            setControl("governorate", e.target.value);
            // setControl("city", "");
          }}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogInputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.region[lang]}
          label={globalLang.region[lang]}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.neighboring[lang]}
          label={globalLang.neighboring[lang]}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.address[lang]}
          label={globalLang.address[lang]}
          value={controls.address}
          onChange={(e) => setControl("address", e.target.value)}
        />
        <DialogNumberField
          placeholder={globalLang.buildingNumber[lang]}
          label={globalLang.buildingNumber[lang]}
          value={controls.number}
          onChange={(e) => setControl("number", e.target.floatValue)}
        />

        <DialogInputField
          placeholder={globalLang.unitType[lang]}
          label={globalLang.unitType[lang]}
          value={controls.type}
          onChange={(e) => setControl("type", e.target.value)}
        />
        {/* <DialogNumberField
          placeholder={globalLang.floor[lang]}
          label={globalLang.floor[lang]}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.floatValue)}
        /> */}
         <DialogSelectField
          placeholder={globalLang.floor[lang]}
          label={globalLang.floor[lang]}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.value)}
        >
          {floors.map((room, index) => (
            <MenuItem value={room.value} key={`room ${index}`}>
              {room.name}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogSelectField
          label={globalLang.saleType[lang]}
          placeholder={globalLang.saleType[lang]}
          
          value={controls.sale}
          renderValue={(selected) =>
            saleTypes.find((saleType) => saleType.value === selected).name
          }
          onChange={(e) => setControl("sale", e.target.value)}
          
        >
          {saleTypes.map((saleType, index) => (
            <MenuItem value={saleType.value} key={`saleType ${index}`}>
              {saleType.name}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </DialogSelectField>
        <DialogSelectField
          placeholder={globalLang.livingNumber[lang]}
          label={globalLang.livingNumber[lang]}
          value={controls.living_room_count}
          onChange={(e) => setControl("living_room_count", e.target.value)}
        >
          {rooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
        </DialogSelectField>
        
        <DialogNumberField
          placeholder={globalLang.area[lang]}
          label={globalLang.area[lang]}
          // prefix="متر "
          value={controls.area}
          onChange={(e) =>
            setControl("area", e.target.value.replace(/متر/gi, "").trim())
          }
        />
         <DialogSelectField
          placeholder={globalLang.roomsNumber[lang]}
          label={globalLang.roomsNumber[lang]}
          value={controls.rooms}
          onChange={(e) => setControl("rooms", e.target.value)}
        >
          {rooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogSelectField
          placeholder={globalLang.bathroomsNumber[lang]}
          label={globalLang.bathroomsNumber[lang]}
          value={controls.bathrooms}
          onChange={(e) => setControl("bathrooms", e.target.value)}
        >
          {bathrooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogSelectField
        placeholder={globalLang.kitchen_count[lang]}
        label={globalLang.kitchen_count[lang]}
        value={controls.kitchen_count}
        thousandSeparator
        onChange={(e) => setControl("kitchen_count", e.target.value)}
      >
        {bathrooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
      </DialogSelectField>
        <DialogInputField
          placeholder={globalLang.finishingType[lang]}
          label={globalLang.finishingType[lang]}
          value={controls.genre}
          onChange={(e) => setControl("genre", e.target.value)}
        />
         <DialogCheckboxField
        label={globalLang.isPublic[lang]}
        placeholder={globalLang.isPublic[lang]}
        value={controls.is_public}
      onClick={(e) =>setControl("is_public", !controls.is_public)}
        />
        <DialogNumberField
          placeholder={globalLang.price[lang]}
          label={globalLang.price[lang]}
          value={controls.price}
          thousandSeparator
          onChange={(e) => setControl("price", e.target.floatValue)}
        />
        <DialogInputField
          placeholder={globalLang.customerName[lang]}
          label={globalLang.customerName[lang]}
          value={controls.client}
          onChange={(e) =>{
            let text=/^(?:[A-Za-z\u0600-\u06ff\s]*)$/
            let match=text.test(e.target.value)
            if(match){
            setControl("client", e.target.value)
            }
          }
          }
        />
        <DialogPhoneField
          placeholder={globalLang.customerPhone[lang]}
          label={globalLang.customerPhone[lang]}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => setControl("countryCode", e.target.value),
          }}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.notes[lang]}
          label={globalLang.notes[lang]}
          value={controls.notes}
          onChange={(e) => setControl("notes", e.target.value)}
        />
        <Box sx={{display:"flex",justifyContent:"center"}}>
          <InputLabel sx={{    color: "white",
             fontSize: "20px",
             fontWeight: "500",width:"20%"}}>
               {globalLang.meansofcomfort[lang]}
          </InputLabel>
     
         <AutocompleteField
        getOptionLabel={(option) => option.name?option.name:option.label}
         placeholder={globalLang.meansofcomfort[lang]}
         onOpen={getMeansOfComfort}
         isPending={meansofcomfortResponse.isPending}
         value={controls?.amenities_ids}
          
         isOptionEqualToValue={(option, value) => option.label===value.name||option.label===value.label}
         onChange={(e,options,reason) => {
          switch (reason) {
            case "selectOption":
              setControl("amenities_ids", options.map(ele=>ele));
              break;
            case "removeOption":
                setControl("amenities_ids", options.map(ele => ele));
                break;
            case "clear":
              setControl("amenities_ids", "");
          } 
        }}
         data={[
           ...meanspfcomfort.map((employee) => ({
             label: `${employee.name}`,
             value: employee.id,
             image:employee.icon
           })),
         ]}
         multiple
         sx={{display:"flex",alignItems:"center",justifyContent:"center",".MuiInput-root":{
          backgroundColor:"white",px:"5px"
         }}}
        /> 
        
        </Box>
        {/* <PictureField
          label={globalLang.reviewunit[lang]}
          placeholder={globalLang.reviewunit[lang]}
          accept="image/*"
          onChange={(e) => {
            if (Object.keys(e.target.files).length < 1) {
              alert(globalLang.reviewunit[lang]);
              e.target.value = [];
              return;
            }
           setControl("main_image", e.target.files[0])
          // handleFileUpload(e)
          }}
          sx={{backgroundColor:"#fff"}}
          value={controls.main_image}
        /> */}
         <DialogInputField
          label={globalLang.addmainImage[lang]}
          placeholder={globalLang.addmainImage[lang]}
          // required={required.includes("main_image")}
          // sx={{".MuiInputBase-root input":{color:"#fff",backgroundColor:"#fff"},".MuiFormLabel-root":{
          //   color:"#fff"
          // },".MuiFormControl-root":{
          //   display:"flex"
          // }}}
          onChange={(e) =>{
            
            setControl("main_image", e.target.files[0])
            
          }
          }
          type="file"
          inputProps={{ accept: 'image/*' }}
          // value={controls.main_image}
          // error={Boolean(invalid.main_image)}
          // helperText={invalid.main_image}
        />
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={unitPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      </Wrapper>
    </TabPanel>
    <TabPanel value="2">
    <DialogContent>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            justifyContent="center"
            gap="20px"
          >
            {/* <Box
              component="input"
              type="file"
              multiple={true}
              ref={changePictureRef}
              sx={{ display: "none" }}
              onChange={(e) => {
                handleChangePicture({
                  id: e.target.id,
                  image: e.target.files[0],
                });
              }}
            ></Box> */}
         
           
             
                <Box
                  component="input"
                  type="file"
                  accept="image/*,video/*"
                  multiple={true}
                  sx={{ display: "none" }}
                  ref={addPictureRef}
                  onChange={handleAddPicture}
                ></Box>
                                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 120,
                        height: 120,
                        bgcolor: "rgba(0, 0, 0, 0.2)",
                        borderRadius: 1.5,
                        cursor: "pointer",
                      }}
                      onClick={() => addPictureRef.current.click()}
                    >
                      <AddIcon sx={{ color: "white" }} />
                    </Box>
                

                {pictures?.length < 100 && (
                  <>
                    {picturePatchResponse.isPending && (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "50%",
                          height: "auto",
                          bgcolor: "rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CircularProgress sx={{ color: "white" }} />
                      </Stack>
                    )}
  </>
                )}
             
             </Stack>
           
               
                  
                     
             <Stack
            direction="row"
            sx={{ width: "100%",flexWrap:"wrap" ,marginY:"50px"}}
            justifyContent="center"
            gap="20px"
          >

                        {/* <Box
                          component="input"
                          type="file"
                          accept="image/*"
                          multiple={true}
                          ref={changePictureRef}
                          sx={{ display: "none" }}
                          onChange={(e) => {
                            handleChangePicture({
                              id: e.target.id,
                              files: e.target.files[0],
                            });
                          }}
                        ></Box> */}

                        {pictures &&
                          pictures.map((item, index) => (
                            
                            <Badge
                              badgeContent={
                                <IconButton
                                  sx={{
                                    bgcolor: "red",
                                    position: "relative",
                                    "&:hover": {
                                      bgcolor: "red",
                                    },
                                  }}
                                  onClick={() => handleDeletePicture(item.id)}
                                  key={`image ${index}`}
                                >
                                  {console.log(item.id)}
                                  <DeleteIcon sx={{ color: "white" }} />
                                </IconButton>
                              }
                            >
                              {picturePatchResponse.isPending && (
                                <Stack
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "50%",
                                    height: "auto",
                                    bgcolor: "rgba(0, 0, 0, 0.2)",
                                    zIndex: 1,
                                  }}
                                >
                                  <CircularProgress sx={{ color: "white" }} />
                                </Stack>
                              )}

                              <a
                                href={item.image}
                                target="_blank"
                                style={{
                                  position: "relative",
                                  zIndex: "2",
                                  top: "0",
                                  right: "0",
                                  marginTop: "-17px",

                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "40px",
                                }}
                              >
                                <OpenInNewOutlinedIcon
                                  sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                  }}
                                />
                              </a>
                              <Zoom>
                                <Avatar
                                  variant="rounded"
                                  src={item.file}
                                  sx={{
                                    width: "250px",
                                    height: "250px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    changePictureRef.current.id = item.id;
                                    changePictureRef.current.click();
                                  }}
                                >{["mp4","MOV","wmv","webm","mpeg","WMV","WEBM","MPEG"].includes(item.file.split(".").pop().trim())?<video style={{width:"100%",height:"100%"}} controls>
                                <source src={item.file}></source>
                              </video>:<img
                                  src={item.file}
                                  alt=""
                                  controls
                                  className="VideoInput_video"
                                />}
                                 
                                </Avatar>
                              </Zoom>
                            </Badge>
                          ))}
                        {/* <Box
                          component="input"
                          type="file"
                          accept="image/*,video/*"
                          multiple={true}
                          sx={{ display: "none" }}
                          ref={addPictureRef}
                          onChange={handleAddPicture}
                        ></Box> */}
                       
                      
                   </Stack>
               
           
         
        </DialogContent>
      </TabPanel></TabContext></Dialog>
   
    {unitPatchResponse.failAlert}
      {unitPatchResponse.successAlert}
    </>
  );
};

const InfoDialog = ({ open, onClose, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const unitsStore = useSelector((state) => state.units.value);
  ///////////////////////////////
  const info = [
    {
      name: globalLang.employeeName[lang],
      value: data?.agent?data?.agent:"-",
    },
    {
      name: globalLang.employeePhone[lang],
      value: data?.phone?data?.country_code+data?.phone:"-",
    },
    {
      name: globalLang.region[lang],
      value: data?.area?data?.area:"-",
    },
    {
      name: globalLang.address[lang],
      value: data?.address?data?.address:"-",
    },
    {
      name: globalLang.unitType[lang],
      value: data?.unit_type?data?.unit_type:"-",
    },
    {
      name: globalLang.floor[lang],
      value: data?.floor_number?data?.floor_number:"0",
    },
    {
      name: globalLang.area[lang],
      value: data?.area_size?data?.area_size:"0",
    },
    {
      name: globalLang.roomsNumber[lang],
      value: data?.room_number?data?.room_number:"0",
    },
    
    {
      name: globalLang.finishingType[lang],
      value: data?.complete_type? data?.complete_type:"-",
    },
    {
      name: globalLang.price[lang],
      value: data?.price?data?.price:"0",
    },

    {
      name: globalLang.country[lang],
      value: data?.country?data?.country:"-",
    },
    {
      name: globalLang.governorate[lang],
      value: data?.state?data?.state:"-",
    },
    {
      name: globalLang.city[lang],
      value: data?.city?data?.city:"-",
    },
    {
      name: globalLang.neighboring[lang],
      value: data?.part?data?.part:"-",
    },
    {
      name: globalLang.buildingNumber[lang],
      value: data?.flat_number?data?.flat_number:"0",
    },
    {
      name: globalLang.notes[lang],
      value: data?.comment?data?.comment:"-",
    },
    {
      name: globalLang.createdAt[lang],
      value: data?.created_at && format(data?.created_at),
    }, {
      name: globalLang.meansofcomfort[lang],
      value: data?.amenities,
      customEmpty:"0"
    },{
      name: globalLang.kitchen_count[lang],
      value: data?.kitchen_count?data?.kitchen_count:"0",
      customEmpty:"0"
    },{
      name: globalLang.livingNumber[lang],
      value: data?.living_room_count?data?.living_room_count:"0",
      customEmpty:"0"
    },{
      name: globalLang.bathroomsNumber[lang],
      value: data?.bath_count?data?.bath_count:"0",
    },
    {
      name: globalLang.category[lang],
      value: data?.category?data?.category:"-",
      customEmpty:"0"
    },{
      name: globalLang.organization[lang],
      value: data?.organization?data?.organization:"-",
      customEmpty:"0"
    },
    
    
  ];
  const userInf = useSelector((state) => state.userInfo.value);

  const checpermissionExistence = (perm) =>
    userInf.user_permissions?.some(({ codename }) => codename == perm);
  if (data) {
    if (
      checpermissionExistence("aqarviewclientdetailsinunits") ||
      ("client" && "phone_client" in data)
    ) {
      if ("client" in data) {
        info.splice(11, 0, {
          name: globalLang.customer[lang],
          value: data?.client,
        });
      }
      if ("phone_client" in data) {
        info.splice(12, 0, {
          name: globalLang.customerPhone[lang],
          value: data?.phone_client?data?.country_code_phone_client+data?.phone_client:"-",
        });
      }
    }
  }
  const addPictureRef = useRef(null);

  const changePictureRef = useRef(null);
  const thisPic = useRef(null);

  const [pictures, setPictures] = usePropState(data?.files, [data]);

  const [picturePatchRequest, picturePatchResponse] = useRequest({
    path: UNITS,
    method: "patch",
    successMessage:globalLang.successedit[lang]
  });
  // const [picturedeleteRequest, picturedeleteResponse] = useRequest({
  //   path: UNITS,
  //   method: "delete",
  // });

  const dispatch = useDispatch();

  const handleDeletePicture = (id) => {
    const requestBody = filter({
      obj: {
        file_id: id,
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        dispatch({
          type: "units/putItem",
          payload: { id: res.data.id, item: res.data },
        });

        setPictures(res.data.files);
      },
    });
  };

  const handleAddPicture = (e) => {
    console.log(e.target.files[0])
    const requestBody = filter({
      obj: {
        files: e.target.files[0],
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        // dispatch({
        //   type: "units/putItem",
        //   payload: { id: res.data.id, item: res.data },
        // });
        setPictures(res.data.files);
      },
    });
  };

  const handleChangePicture = ({ id, image }) => {
    const requestBody = filter({
      obj: {
        image_id: id,
        image: image,
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        console.log(res)
        dispatch({
          type: "units/putItem",
          payload: { id: res.data.id, item: res.data },
        });

        setPictures(res.data.files);
      },
    });
  };

  //===Start====== share logic ===========

  const userInfo = useSelector((state) => state.userInfo.value);

  const message = `متوفر لدى ${
    userInfo?.organization?.name ? userInfo?.organization?.name : "-"
  }
  نوع الوحدة: ${data?.unit_type ? data?.unit_type : "لا يوجد"}
  العنوان: ${data?.address ? data?.address : "لا يوجد"}
  المنطقة: ${data?.area ? data?.area : "لا يوجد"}
  الدور: ${data?.floor_number ? data?.floor_number : "لا يوجد"}
  المساحة: ${data?.area_size ? data?.area_size : "لا يوجد"}
  عدد الغرف: ${data?.room_number ? data?.room_number : "لا يوجد"}
  عدد الحمامات: ${data?.bath_count ? data?.bath_count : "لا يوجد"}
  نوع التشطيب: ${data?.complete_type ? data?.complete_type : "لا يوجد"}
  السعر: ${data?.price ? data?.price : "لا يوجد"}
  البلد: ${data?.country ? data?.country : "لا يوجد"}
  المحافظة: ${data?.state ? data?.state : "لا يوجد"}
  المدينة: ${data?.city ? data?.city : "لا يوجد"}
  رقم العمارة: ${data?.flat_number ? data?.flat_number : "لا يوجد"}\n`;

  //===End====== share logic ===========
  const [opened, setOpened] = useState(false);
  const [actived, setActived] = useState(false);
  
  const handleOpened = () => {
    setOpened(true);
    setActived(true);
  };
  const handleClosed = () => {
    setOpened(false);
    setActived(false);
  };
useEffect(()=>{
console.log(unitsStore)
console.log(pictures)
},[pictures])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setActived(false);
      }}
      sx={{ width: "50%", mx: "auto", opacity: actived == true ? "0" : "1" }}
    >
      
      <DialogInfoWindow information={info} />
      <DialogContent>
        <Stack direction="row" justifyContent="center"sx={{flexWrap:"wrap"}}>
          <FacebookShareButton
            quote={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <FacebookIcon size={32} round={true} />
            </IconButton>
          </FacebookShareButton>
          <WhatsappShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <WhatsappIcon size={32} round={true} />
            </IconButton>
          </WhatsappShareButton>
          <LinkedinShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <LinkedinIcon size={32} round={true} />
            </IconButton>
          </LinkedinShareButton>
          <TelegramShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <TelegramIcon size={32} round={true} />
            </IconButton>
          </TelegramShareButton>
          <PinterestShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <PinterestIcon size={32} round={true} />
            </IconButton>
          </PinterestShareButton>
          <VKShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <VKIcon size={32} round={true} />
            </IconButton>
          </VKShareButton>
          <OKShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <OKIcon size={32} round={true} />
            </IconButton>
          </OKShareButton>
          <RedditShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <RedditIcon size={32} round={true} />
            </IconButton>
          </RedditShareButton>
          <TumblrShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <TumblrIcon size={32} round={true} />
            </IconButton>
          </TumblrShareButton>
          <LivejournalShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <LivejournalIcon size={32} round={true} />
            </IconButton>
          </LivejournalShareButton>
          <TwitterShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <TwitterIcon size={32} round={true} />
            </IconButton>
          </TwitterShareButton>
          <MailruShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <MailruIcon size={32} round={true} />
            </IconButton>
          </MailruShareButton>
          <WorkplaceShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <WorkplaceIcon size={32} round={true} />
            </IconButton>
          </WorkplaceShareButton>
          <ViberShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <ViberIcon size={32} round={true} />
            </IconButton>
          </ViberShareButton>
          <LineShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <LineIcon size={32} round={true} />
            </IconButton>
          </LineShareButton>
          <EmailShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <EmailIcon size={32} round={true} />
            </IconButton>
          </EmailShareButton>
        </Stack>
      </DialogContent>
      {picturePatchResponse.successAlert}
      {picturePatchResponse.failAlert}
    </Dialog>
  );
};
const rooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];
const bathrooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];
