import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import Form, {
  InputField,
  NumberField,
  PhoneField,
  PictureField,
  SelectField,
  TextareaField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import { MenuItem, TextField } from "@mui/material";
import useRequest from "../../hooks/useRequest";
import {
  COUNTRY_FILTER,
  MEANSOFCOMFORT,
  STATES,
  STATE_CITIES,
  UNITS,
  UNITSFEKHEDMETAK,
} from "../../data/APIs";
import filter from "../../utils/ClearNull";
import { useSelector } from "react-redux";
import Videocomponent from "../../features/form/components/Videocomponent";

const Addunit = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addUnitsLang = useSelector((state) => state.lang.value.addUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];
  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "agent",
      value: "",
      isRequired: false,
    },
    {
      control: "phone",
      value: "",
      isRequired: false,
    },
    {
      control: "code",
      value: "",
      isRequired: false,
    },
    {
      control: "countryCode",
      value: "",
      isRequired: false,
    },
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "phone_client",
      value: "",
      isRequired: false,
    },
    {
      control: "country",
      value: "",
      isRequired: false,
    },
    {
      control: "state",
      value: "",
      isRequired: false,
    },
    {
      control: "city",
      value: "",
      isRequired: false,
    },
    {
      control: "area",
      value: "",
      isRequired: false,
    },
    {
      control: "part",
      value: "",
      isRequired: false,
    },
    {
      control: "address",
      value: "",
      isRequired: false,
    },
    {
      control: "floor_number",
      value: "",
      isRequired: false,
    },
    {
      control: "unit_type",
      value: "",
      isRequired: false,
    },
    {
      control: "complete_type",
      value: "",
      isRequired: false,
    },
    {
      control: "sales_type",
      value: "",
      isRequired: false,
    },
    {
      control: "price",
      value: "",
      isRequired: false,
    },
    {
      control: "flat_number",
      value: "",
      isRequired: false,
    },
    {
      control: "room_number",
      value: "",
      isRequired: false,
    },
    { control: "bath_count", value: "", isRequired: false },
    {
      control: "area_size",
      value: "",
      isRequired: false,
    },
    {
      control: "comment",
      value: "",
      isRequired: false,
    },
    {
      control: "property_images",
      value: [],
      isRequired: true,
    },
    {
      control: "category",
      value: "",
      isRequired: true,
    },
    {control:"video",
  value:"",
isRequired:false},{
  control:"amenities_ids",
  value:"",
  isRequired:false
}
  ]);

  const [unitPostRequest, unitPostResponse] = useRequest({
    path: UNITSFEKHEDMETAK,
    method: "post",
    successMessage: "تم إضافة وحدة جديدة بنجاح",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          agent: controls.agent,
          client: controls.client,
          phone: controls.code + controls.phone,
          country: controls.country,
          state: controls.state,
          city: controls.city,
          area: controls.area,
          part: controls.part,
          address: controls.address,
          floor_number: controls.floor_number,
          unit_type: controls.unit_type,
          phone_client: controls.countryCode + controls.phone_client,
          complete_type: controls.complete_type,
          sales_type: controls.sales_type,
          price: controls.price,
          flat_number: controls.flat_number,
          room_number: controls.room_number,
          bath_count: controls.bath_count,
          area_size: controls.area_size,
          comment: controls.comment,
          category: controls.category,
          property_images: [...controls.property_images],
        },
        output: "formData",
      });

      if (
        Object.keys(
          filter({
            obj: {
              agent: controls.agent,
              phone: controls.code + controls.phone,
              client: controls.client,
              // phone_client: controls.countryCode + controls.phone_client,
              country: controls.country,
              state: controls.state,
              city: controls.city,
              area: controls.area,
              part: controls.part,
              address: controls.address,
              floor_number: controls.floor_number,
              unit_type: controls.unit_type,
              phone_client: controls.countryCode + controls.phone_client,
              complete_type: controls.complete_type,
              sales_type: controls.sales_type,
              price: controls.price,
              flat_number: controls.flat_number,
              room_number: controls.room_number,
              bath_count: controls.bath_count,
              area_size: controls.area_size,
              comment: controls.comment,
              category: controls.category,
              property_images: [...controls.property_images],
            },
          })
        ).length <= 1
      ) {
        alert(globalLang.adddataneed[lang]);
        return;
      }
      // return;

      unitPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          
        },
      }).then(() => {
        console.log("done");
      });
    });
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);
  const [meanspfcomfort, setMeansOfConfort] = useState([]);
  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);
  const [meansofcomfortRequest,meansofcomfortResponse] = useRequest({
    path: MEANSOFCOMFORT,
    method: "get",
    successMessage: globalLang.getamenities[lang],
  });
  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
    successMessage:globalLang.getamenities[lang]
  });

  const getGovernorates = () => {
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };
  const getMeansOfComfort=()=>{
    meansofcomfortRequest({
      onSuccess:(res)=>{
   
        setMeansOfConfort(res.data.results)
      }
    })
  }
  //===End===== Get Countries logic =========

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.fekhidmtak[lang], addUnitsLang.addUnits[lang]]}
      />
      <Form
        component="form"
        // onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: unitPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: unitPostResponse.isPending,
          },
        }}
      >
        <SelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          required={required.includes("country")}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            setControl("governorate", "");
            setControl("city", "");
          }}
          error={Boolean(invalid.country)}
          helperText={invalid.country}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={getGovernorates}
          isPending={statesGetResponse.isPending}
          required={required.includes("state")}
          value={controls.state}
          onChange={(e) => {
            setControl("state", e.target.value);
            setControl("city", "");
          }}
          error={Boolean(invalid.state)}
          helperText={invalid.state}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </SelectField>
        <InputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          required={required.includes("city")}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
          error={Boolean(invalid.city)}
          helperText={invalid.city}
        />
        <InputField
          label={globalLang.region[lang]}
          placeholder={globalLang.region[lang]}
          required={required.includes("district")}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
          error={Boolean(invalid.district)}
          helperText={invalid.district}
        />
        <InputField
          label={globalLang.address[lang]}
          placeholder={globalLang.address[lang]}
          required={required.includes("address")}
          value={controls.address}
          onChange={(e) => setControl("address", e.target.value)}
          error={Boolean(invalid.address)}
          helperText={invalid.address}
        />
        <SelectField
          label={globalLang.category[lang]}
          placeholder={globalLang.category[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          required={required.includes("category")}
          value={controls.category}
          onChange={(e) => {
            setControl("category", e.target.value);
          }}
          renderValue={(selected) =>
            categorytype.find((saleType) => saleType.value === selected)?.name
          }
          error={Boolean(invalid.category)}
          helperText={invalid.category}
        >
          {lang == "ar"
            ? categorytype.map((category, index) => (
                <MenuItem value={category.value} key={`category ${index}`}>
                  {category.name}
                </MenuItem>
              ))
            : categorytype.map((category, index) => (
                <MenuItem value={category.value} key={`category ${index}`}>
                  {category.value}
                </MenuItem>
              ))}
        </SelectField>
      
        
        <InputField
          label={globalLang.neighboring[lang]}
          placeholder={globalLang.neighboring[lang]}
          required={required.includes("area")}
          value={controls.area}
          onChange={(e) => setControl("area", e.target.value)}
          error={Boolean(invalid.area)}
          helperText={invalid.area}
        />
        <InputField
          label={globalLang.part[lang]}
          placeholder={globalLang.part[lang]}
          required={required.includes("part")}
          value={controls.part}
          onChange={(e) => setControl("part", e.target.value)}
          error={Boolean(invalid.part)}
          helperText={invalid.part}
        />
        
        <InputField
          label={globalLang.agent[lang]}
          placeholder={globalLang.agent[lang]}
          required={required.includes("agent")}
          value={controls.agent}
          onChange={(e) => setControl("agent", e.target.value)}
          error={Boolean(invalid.agent)}
          helperText={invalid.agent}
        />
        <NumberField
          label={globalLang.buildingNumber[lang]}
          placeholder={globalLang.buildingNumber[lang]}
          required={required.includes("floor_number")}
          value={controls.floor_number}
          onChange={(e) => setControl("floor_number", e.target.value)}
          error={Boolean(invalid.floor_number)}
          helperText={invalid.floor_number}
        />
        <InputField
          label={globalLang.unitType[lang]}
          placeholder={globalLang.unitType[lang]}
          required={required.includes("unit_type")}
          value={controls.unit_type}
          onChange={(e) => setControl("unit_type", e.target.value)}
          error={Boolean(invalid.unit_type)}
          helperText={invalid.unit_type}
        />
        <SelectField
          label={globalLang.saleType[lang]}
          placeholder={globalLang.saleType[lang]}
          required={required.includes("sales_type")}
          value={controls.sales_type}
          renderValue={(selected) =>
            saleTypes.find((saleType) => saleType.value === selected)?.name
          }
          onChange={(e) => {
            setControl("sales_type", e.target.value);
        
          }}
          error={Boolean(invalid.sales_type)}
          helperText={invalid.sales_type}
        >
          {lang == "ar"
            ? saleTypes.map((saleType, index) => (
                <MenuItem value={saleType.value} key={`saleType ${index}`}>
                  {saleType.name}
                </MenuItem>
              ))
            : saleTypes.map((saleType, index) => (
                <MenuItem value={saleType.value} key={`saleType ${index}`}>
                  {saleType.value}
                </MenuItem>
              ))}
        </SelectField>
        <SelectField
          label={globalLang.meansofcomfort[lang]}
          placeholder={globalLang.meansofcomfort[lang]}
          onOpen={getMeansOfComfort}
          isPending={meansofcomfortResponse.isPending}
          required={required.includes("amenities_ids")}
          value={controls.amenities_ids}
          onChange={(e) => {
            setControl("amenities_ids", e.target.value);
           
          }}
          error={Boolean(invalid.amenities_ids)}
          helperText={invalid.amenities_ids}
        >
          {meanspfcomfort?.map((mean, index) => (
            <MenuItem value={mean.name} key={mean.id}>
              <img src={mean.icon} alt={mean.name} style={{marginLeft:lang==="ar"?"15px":"",marginRight:lang==="en"?"15px":""}}/>
              {mean.name}
            </MenuItem>
          ))}
        </SelectField>
        {/* <NumberField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          required={required.includes("floor")}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.value)}
          error={Boolean(invalid.floor)}
          helperText={invalid.floor}
        /> */}
        <SelectField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          value={controls.floor_number}
          renderValue={(selected) =>
            floors.find((f) => f.value === selected)?.name
          }
          onChange={(e) => setControl("floor_number", e.target.value)}
        >
          {floors.map((floor, index) => (
            <MenuItem value={floor.value} key={` ${index}`}>
              {floor.name}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.area[lang]}
          placeholder={globalLang.area[lang]}
          suffix={globalLang.meter[lang]}
          // isAllowed={({ value }) => value.length <= 5}
          required={required.includes("area_size")}
          value={controls.area_size}
          onChange={(e) =>
            setControl(
              "area_size",
              e.target.value.replace(globalLang.meter[lang], "").trim()
            )
          }
          error={Boolean(invalid.area_size)}
          helperText={invalid.area_size}
        />
        <SelectField
          label={globalLang.roomsNumber[lang]}
          placeholder={globalLang.roomsNumber[lang]}
          required={required.includes("room_number")}
          value={controls.room_number}
          onChange={(e) => setControl("room_number", e.target.value)}
          error={Boolean(invalid.room_number)}
          helperText={invalid.room_number}
        >
          {rooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        <SelectField
          label={globalLang.bathroomsNumber[lang]}
          placeholder={globalLang.bathroomsNumber[lang]}
          required={required.includes("bath_count")}
          value={controls.bath_count}
          onChange={(e) => setControl("bath_count", e.target.value)}
          error={Boolean(invalid.bath_count)}
          helperText={invalid.bath_count}
        >
          {bathrooms.map((bathroom, index) => (
            <MenuItem value={bathroom} key={`bathroom ${index}`}>
              {bathroom}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        <InputField
          label={globalLang.finishingType[lang]}
          placeholder={globalLang.finishingType[lang]}
          required={required.includes("complete_type")}
          value={controls.complete_type}
          onChange={(e) => setControl("complete_type", e.target.value)}
          error={Boolean(invalid.complete_type)}
          helperText={invalid.complete_type}
        />
        {/* <InputField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          required={required.includes("price")}
          value={controls.price}
          onChange={(e) => setControl("price", e.target.value)}
          error={Boolean(invalid.price)}
          helperText={invalid.price}
          type="number"
        /> */}
         <DialogSelectField
            label={globalLang.category[lang]}
            placeholder={globalLang.category[lang]}
            value={controls.category}
            onChange={(e) => {
              setControl("category", e.target.value);
            }}
          >
            {["resale", "sharing", "projects"].map((category, index) => (
              <MenuItem value={category} key={`category ${index}`}>
                {category}
              </MenuItem>
            ))}
          </DialogSelectField>
         <NumberField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          
          // isAllowed={({ value }) => value.length <= 5}
          required={required.includes("price")}
          value={controls.price}
          onChange={(e) =>
            setControl(
              "price",
              e.target.value
            )
          }
          error={Boolean(invalid.price)}
          helperText={invalid.price}
        />
        <InputField
          label={globalLang.customerName[lang]}
          placeholder={globalLang.customerName[lang]}
          required={required.includes("client")}
          value={controls.client}
          onChange={(e) => setControl("client", e.target.value)}
          error={Boolean(invalid.client)}
          helperText={invalid.client}
        />
        <PhoneField
          label={globalLang.customerPhone[lang]}
          placeholder={globalLang.customerPhone[lang]}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => {
              setControl("countryCode", e.target.value);
            },
          }}
          requiredCode
          required={required.includes("phone_client")}
          value={controls.phone_client}
          onChange={(e) => setControl("phone_client", e.target.value)}
          error={Boolean(invalid.phone_client)}
          helperText={invalid.phone_client}
        />
        <PhoneField
          label={globalLang.phone[lang]}
          placeholder={globalLang.phone[lang]}
          selectProps={{
            value: controls.code,
            onChange: (e) => {
              setControl("code", e.target.value);
            },
          }}
          requiredCode
          required={required.includes("phone")}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
          error={Boolean(invalid.phone)}
          helperText={invalid.phone}
        />
        <PictureField
          label={globalLang.picture[lang]}
          placeholder={globalLang.picture[lang]}
          multiple={true}
          onChange={(e) => {
            if (Object.keys(e.target.files).length < 1) {
              alert(globalLang.addimage[lang]);
              e.target.value = [];
              return;
            }
            setControl("property_images", [
              ...Object.keys(e.target.files).map((key) => e.target.files[key]),
            ]);
          }}
          error={Boolean(invalid.property_images)}
          helperText={invalid.property_images}/>
         <Videocomponent
        placeholder={globalLang.video[lang]}
        onChange={(e)=>setControl("video",e.target.value)}/>
        <TextareaField
          label={globalLang.notes[lang]}
          placeholder={globalLang.notes[lang]}
          multiline
          variant="standard"
          required={required.includes("comment")}
          value={controls.comment}
          onChange={(e) => setControl("comment", e.target.value)}
          error={Boolean(invalid.comment)}
          helperText={invalid.comment}
          sx={{ backgroundColor: "primary" }}
        />
      </Form>
      {unitPostResponse.successAlert}
      {unitPostResponse.failAlert}
    </Wrapper>
  );
};

export default Addunit;

const bathrooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];

const rooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];

const saleTypes = [
  {
    name: "بيع",
    value: "sale",
  },
  {
    name: "ايجار",
    value: "rent",
  },
];
const saleTypestranslate = [
  {
    name: "sale",
    value: "sale",
  },
  {
    name: "rent",
    value: "rent",
  },
];

