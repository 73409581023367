import { createSlice } from "@reduxjs/toolkit";

export const AddpathofpageSlice = createSlice({
  name: "path",
  initialState: {
    value: "/",
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    reset: (state) => {
      state.value = "/";
    },

    addItem: (state, action) => {
      state.value = action.payload;
    },
  },
});

export default AddpathofpageSlice.reducer;
