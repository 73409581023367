import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { CHANNELS } from "../../data/APIs";
import { useEffect } from "react";
import useDataGrid from "../../hooks/useDataGrid";
import { Avatar, TextField } from "@mui/material";
import format from "../../utils/ISOToReadable";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import { Stack } from "@mui/system";
import { InputField } from "../../features/form";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import useControls from "../../hooks/useControls";

const ViewChannels = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewChannelsLang = useSelector(
    (state) => state.lang.value.viewChannels
  );
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const channelsStore = useSelector((state) => state.channels.value);

  const columns = [
    {
      field: "picture",
      headerName: globalLang.picturejust[lang],
      customContent: ({ logo, name }) => (
        <Avatar src={logo} variant="rounded">
          {name[0].toUpperCase()}
        </Avatar>
      ),
    },
    {
      field: "name",
      headerName: globalLang.name[lang],
      customContent: ({ name }) => (name ? name : globalLang.unknown[lang]),
    },
    {
      field: "createdAt",
      headerName: globalLang.createdAt[lang],
      customContent: ({ created_at }) => format(created_at),
    },
  ];

  const filters = [
    {
      name: globalLang.name[lang],
    
      component: <NameFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
    },
  ];

  const dispatch = useDispatch();

  const [channelsGetRequest, channelsGetResponse] = useRequest({
    path: CHANNELS,
    method: "get",
  });

  const getChannels = (urlParams) => {
    channelsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "channels/set", payload: res.data });
      },
    });
  };

  const { handlePaginate, handleChangeAmount, handleFilter } = useDataGrid({
    onParamsChange: getChannels,
  });

  const [channelDeleteRequest, channelDeleteResponse] = useRequest({
    path: CHANNELS,
    method: "delete",
    successMessage:globalLang.detelesuccess[lang],
  });

  const deleteChannel = (e, row) => {
    channelDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "channels/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteChannel, deleteChannelConfirmDialog] = useConfirmMessage({
    onConfirm: deleteChannel,
    text: globalLang.makesureofdelete[lang],
  });

  const isPermitted = useIsPermitted();

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.channels[lang], viewChannelsLang.viewChannels[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={channelsStore.results}
        isPending={channelsGetResponse.isPending}
        total={channelsStore.count}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteChannel, ["delete_aqarchannel"])}
        filters={filters}
      />
      {deleteChannelConfirmDialog}
      {channelDeleteResponse.successAlert}
      {channelDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default ViewChannels;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [{ controls ,invalid,required}, { setControl, resetControls,setInvalid, validate}] = useControls(
    [
      {
        control: "startPoint",
        value:value.start,
        isRequired: true,
        
      },
      {
        control: "endPoint",
        value: value.end,
        isRequired: true,
      },
      // {
      //   control:"created_at",
      //   value:"",
      //   isRequired:false
        
      // }
    ],
    [value]
  );
  
  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!controls.startPoint && !controls.endPoint) return;
    validate().then((output) => {
      if (!output.isOk) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(controls.startPoint))}-${formatDate(String(controls.endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(controls.startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(controls.endPoint))}`,
      value: {
        start: controls.startPoint,
        end: controls.endPoint,
      },
    })
      })
  }, [controls.startPoint, controls.endPoint]);

  const handleChangeStartPoint = (e) => {
    setControl("startPoint",e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    if(controls.startPoint &&controls.startPoint>e.target.value){
      setInvalid((prevState) => ({
        ...prevState,
        endPoint: "End date should be greater than start date.",
      }));
    }else{
      setInvalid((prevState) => ({ ...prevState, endPoint: "" }));
      setControl("endPoint",e.target.value);

    }
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={controls?.startPoint}
        onChange={handleChangeStartPoint}
        required={required.includes("startPoint")}
        error={Boolean(invalid.startPoint)}
        helperText={invalid.startPoint}
        // onBlur={handleCompareDates}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={controls?.endPoint}
     
        onChange={handleChangeEndPoint}
        required={required.includes("endPoint")}
        error={Boolean(invalid.endPoint)}
        helperText={invalid.endPoint}
        // onBlur={handleCompareDates}
      />
    </Stack>
  );
};
