import { createSlice } from "@reduxjs/toolkit";

export const ContractSlice = createSlice({
  name: "contractslice",
  initialState: {
    value:{
      count: 0,
      next: null,
      previous: null,
      results: [],
    }
  },
  reducers: {
    set: (state, action) => {
        state.value=action.payload
    },
  
   addItem: (state, action) => {
    state.value = {...state.value,results:[...state.value.results,action.payload]};
   },
   deleteItem:(state,action)=>{
    
    state.value.results = state.value.results.filter(
        (item) => item.id !== action.payload.id
      );
      state.value.count = state.value.count - 1;
   },
    reset: (state) => {
      state.value = [];
    },
  },
});

export default ContractSlice.reducer;