export const stylerow = {
  fontSize: "16px",
  borderBottom: "none",
  padding: "10px",
  color: (theme) => theme.palette.grey.main,
};
export const styletd = {
  color: (theme) => theme.palette.primary.main,
  fontSize: "16px",
  borderBottom: "none",
  padding: "0px",
  width: "200px",
};
export const styledialog = {
  border: "0.5px solid #7E7E7E",
  boxSizing: "border-box",
  margin: "20px",
  borderRadius: "16px",
  // minWidth: "600px",
  padding: "20px 24px !important",
};
