import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import image from "../assets/keepintouch.png";
import carbon_location from "../assets/carbon_location.png";
import phone from "../assets/phone.png";
import email from "../assets/email.png";
import relaction from "../assets/relation.png";
import { useEffect } from "react";

function Keepintouch() {
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);
  useEffect(()=>{
    
  },[])
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        flexWrap="wrap"
        gap={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        // width={"50%"}
      >
        <Box
          sx={{
            width: { lg: "30%", md: "30%", sm: "50%", xs: "100%" },
            order: {
              lg: "1",
              md: "1",
              sm: "2",
              xs: "2",
            },
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            sx={{
              marginBottom: "1rem",
            }}
          >
            {global.keepintouch[lang]}
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            sx={{
              marginBottom: "1rem",
            }}
          >
            {global.contact[lang]}
          </Typography>
          <Typography
            variant="ul"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "1rem",
              /* justify-content: center; */
            }}
          >
            <Typography
              variant="li"
              color="grey"
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography
                sx={{
                  marginLeft: lang === "ar" ? "" : "2%",
                  marginRight: lang === "ar" ? "2%" : "",
                }}
              >
                <img src={carbon_location} alt="" />
              </Typography>
              {/* <img src={carbon_location} alt="" /> */}
              {global.cypartacom[lang]}
            </Typography>
            <Typography
              variant="li"
              color="grey"
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography
                sx={{
                  marginLeft: lang === "ar" ? "" : "2%",
                  marginRight: lang === "ar" ? "2%" : "",
                }}
              >
                <img src={email} alt="" />
              </Typography>
              {/* <img src={email} alt="" /> */}
              {global.emailcyparta[lang]}
            </Typography>
            <Typography
              variant="li"
              color="grey"
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography
                sx={{
                  marginLeft: lang === "ar" ? "" : "2%",
                  marginRight: lang === "ar" ? "2%" : "",
                }}
              >
                <img src={phone} alt="" />
              </Typography>

              {global.phonecyparta[lang]}
            </Typography>
            <Typography
              variant="li"
              color="grey"
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography
                sx={{
                  marginLeft: lang === "ar" ? "" : "2%",
                  marginRight: lang === "ar" ? "2%" : "",
                }}
              >
                <img src={relaction} alt="" />
              </Typography>
              {/* <img src={relaction} alt="" /> */}
              {global.cypartacom[lang]}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            order: {
              lg: "2",
              md: "2",
              sm: "1",
              xs: "1",
            },
          }}
        >
          <img src={image} alt="" />
        </Box>
      </Stack>
    </>
  );
}

export default Keepintouch;
