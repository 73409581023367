import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../components/Wrapper";
import Breadcrumbs from "../components/Breadcrumbs";
import Publisher from "../components/Publisher";
import useControls from "../hooks/useControls";
import Post, { PostSkeleton } from "../components/Post";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { POSTS, FCMDRIVERS } from "../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../hooks/useRequest";
import filter from "../utils/ClearNull";
import format from "../utils/ISOToReadable";
import InputField from "../features/form/components/InputField";
import { DialogButton, DialogButtonsGroup } from "../features/dialog";
import useAfterEffect from "../hooks/useAfterEffect";
import PermissionsGate from "../features/permissions/components/PermissionsGate";
import Compress from "react-image-file-resizer";
import useIsPermitted from "../features/permissions/hook/useIsPermitted";
import { gettoken } from "../firebase";
import compare from "../utils/Compare";
import { validate } from "uuid";
// import RouteGate from "../features/permissions/Hoc/RouteGate.js";

const Home = () => {
  //----store----
  const userInfo = useSelector((state) => state.userInfo.value);
  const postsStore = useSelector((state) => state.posts.value);

  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const homeLang = useSelector((state) => state.lang.value.home);
  const globalLang = useSelector((state) => state.lang.value.global);

  ////////////////////

  const dispatch = useDispatch();

  //----request hooks----
  const [postsGetRequest, postsGetResponse] = useRequest({
    path: POSTS,
    method: "get",
  });

  const [postsDeleteRequest, postsDeleteResponse] = useRequest({
    path: POSTS,
    method: "delete",
    successMessage: globalLang.detelesuccess[lang],
  });

  const [postsPostRequest, postsPostResponse] = useRequest({
    path: POSTS,
    method: "post",
    successMessage: globalLang.addsuccess[lang],
  });

  

  //----state----
  const [postEditData, setPostEditData] = useState(null);
  const [{ controls, invalid }, { setControl, resetControls, validate }] =
    useControls([
      {
        control: "content",
        value: "",
        isRequired: true,
      },
      {
        control: "picture",
        value: null,
        isRequired: false,
      },
    ]);
    const [isTokenFound, setTokenFound] = useState({
      status:false,
      token:""});
    const [sendtokenRequest, sendtokenResponse] = useRequest({
      path: FCMDRIVERS,
      method: "post",
    });
    const sendtoken=()=>{

      sendtokenRequest({
        body:{
          registration_id:isTokenFound?.token,
          type:"web"
        }
      })
    }
  //----effects----
  useEffect(() => {
    postsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "posts/set", payload: res.data });
      },
    });
    
  }, []);
useEffect(()=>{

  if(userInfo.token&&isTokenFound.status){
    // if(isTokenFound?.status){
     
        sendtoken()
    
      
    // }
  }else{
    setTokenFound({status:false,token:""})
  }
  
  
},[userInfo.token])
useEffect(()=>{
  gettoken()
},[userInfo.token])
  //----functions----
  const resize = (file) => {
    if (file) {
      return new Promise((resolve) => {
        Compress.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => resolve(uri),
          "file"
        );
      });
    }
  };

  const handlePublishSubmit = async () => {
    await validate().then(async (output) => {
      if (!output.isOk) return;
      const compressedImage = await resize(controls.picture);

      const requestBody = filter({
        obj: {
          content: controls.content,
          user: 44806,
          media: compressedImage,
        },
        output: "formData",
      });
      await postsPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          dispatch({ type: "posts/addItem", payload: res.data });
          resetControls();
        },
      });
    });
  };

  const handleDeletePost = (id) => {
    postsDeleteRequest({
      id,
      onSuccess: (res) => {
        dispatch({ type: "posts/deleteItem", payload: { id } });
      },
    });
  };

 

  //===Start==== Callback Permissions Logic ======

  const isPermitted = useIsPermitted();

  //===End==== Callback Permissions Logic ======
  
  return (
    <Wrapper>
      <Breadcrumbs path={[homeLang[lang]]} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <PermissionsGate permissions={["add_aqarpost"]}>
          <Publisher
            name={`${userInfo.first_name} ${userInfo.last_name}`}
            picture={userInfo.image}
            value={controls.content}
            onChange={(e) => setControl("content", e.target.value)}
            error={Boolean(invalid.content)}
            onSubmit={handlePublishSubmit}
            onPickPicture={(e) => setControl("picture", e.target.files[0])}
            onEmoji={(e, startIndex, endIndex, selected) => {
              setControl(
                "content",
                controls.content.slice(0, startIndex) +
                  selected.emoji +
                  controls.content.slice(endIndex, controls.content.length + 1)
              );
            }}
            isPending={postsPostResponse.isPending}
          />
        </PermissionsGate>

        <PermissionsGate permissions={["view_aqarpost"]}>
          <Typography
            variant="h6"
            color="primary"
            sx={{ padding: "30px 0", fontWeight: "bold" }}
          >
            {globalLang.latestPosts[lang]}
          </Typography>
          {postsGetResponse.isPending ? (
            <PostsSkeletons />
          ) : (
            postsStore.results.map((post, index) => (
              <Post
                key={`post ${index}`}
                name={post.user.name}
                picture={post.user.image}
                createdAt={post.created_at}
                images={post.medias}
                onDelete={isPermitted(
                  () => handleDeletePost(post.id),
                  ["delete_aqarpost"]
                )}
                onEdit={isPermitted(userInfo.username===post.user.username?
                  (data) => {
                    setPostEditData({ ...data, id: post.id });

                  }:false,
                  ["change_aqarpost"]
                )}
              >
                {post.content}
              </Post>
            ))
          )}
        </PermissionsGate>
      </Box>
      <PostEditDialog
        open={Boolean(postEditData)}
        onClose={() => setPostEditData(null)}
        data={
          postEditData
          
           
        }
       
      />
     
      {postsPostResponse.successAlert}
      {postsPostResponse.failAlert}
      {postsDeleteResponse.successAlert}
      {postsDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default Home;

const PostEditDialog = ({ data, open, onClose }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  let dispatch=useDispatch()

  ///////////////////////////////
  const [{ controls, invalid }, { setControl, resetControls,validate }] = useControls([
    {
      control: "content",
      value: data?.children,
      isRequired:true
    },
    {
      control: "pictures",
      value: {
        files: null,
        display: data?.images[0]?.media,
        displayed: "",
      },
    },
  ]);
  const [postsPatchRequest, postsPatchResponse] = useRequest({
    path: POSTS,
    method: "patch",
    successMessage: globalLang.successedit[lang],
  });
  //----hooks----
  const inputFileRef = useRef(null);
  const handleEditPost = (info) => {
    validate().then(({isOk})=>{
      if(!isOk) return;
      const isThereChange = compare(
        [
          [info?.country,controls.content,"content"],
        
        ],
        false
      );
    
      const requestBody = filter({
        obj: {
          content: info.data.content,
          user: 44806,
         
        },
        output: "formData",
      });
      
      postsPatchRequest({
        body:requestBody,
        id: info.data.id,
        onSuccess: (res) => {
          dispatch({
            type: "posts/putItem",
            payload: { id: res.data.id, item: res.data },
          })
          onClose()

          
        },
      });
    })
    
};
  //----functions----
  const handleEditSubmit = () => {
    handleEditPost({
      data: { ...controls, id: data.id, pictureId: data.images?.[0]?.id || "" },
      invalid,
    });
  };

  useAfterEffect(() => {
    if (!open) return;
   
    
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      PaperProps={{
        sx: {
          maxWidth: "max-content",
        },
      }}
    >
      <DialogContent sx={{ maxWidth: "max-content" }}>
        <Card
          sx={{
            maxWidth: "766px",
            width: "100vmax",
            boxShadow: "none",
          }}
        >
          <CardHeader
            avatar={
              <Avatar src={data?.picture}>
                {data?.picture ? data?.picture : ""}
              </Avatar>
            }
            title={data?.name}
            subheader={data?.createdAt?format(
              data?.createdAt 
            ):""}
            sx={{
              "& .MuiCardHeader-title": { color: "#233975" },
              "& .MuiCardHeader-subheader": {
                color: "#233975",
                fontSize: "12px",
              },
            }}
          />
          <CardContent>
            <Box
              sx={{
                padding: "0 55px",
              }}
            >
              <InputField
                value={controls.content}
                onChange={(e) => setControl("content", e.target.value)}
                sx={{ width: "100%" }}
                error={Boolean(invalid.content)}
                helperText={invalid.content}
              />
            </Box>
          </CardContent>
          {controls?.pictures?.displayed && (
            <>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={(e) => {
                  const blob = URL.createObjectURL(e.target.files?.[0]);
                  setControl("pictures", {
                    files: [
                      ...Object.keys(e.target.files).map(
                        (key) => e.target.files[key]
                      ),
                    ],
                    displayed: blob,
                  });
                }}
              />
              <CardMedia
                component="img"
                image={controls?.pictures?.displayed}
                alt="posts image"
                sx={{
                  bgcolor: "black",
                  objectFit: "contain",
                  aspectRatio: "2 / 1",
                  cursor: "pointer",
                }}
                onClick={() => inputFileRef.current.click()}
              />
            </>
          )}
        </Card>
      </DialogContent>
      <DialogButtonsGroup>
        <DialogButton variant="save" onClick={handleEditSubmit}>
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {postsPatchResponse.successAlert}
      {postsPatchResponse.failAlert}
    </Dialog>
  );
};

const PostsSkeletons = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        WebkitMaskImage:
          "linear-gradient(0deg, rgba(9,9,121,0) 0%, rgba(255,255,255,1) 100%)",
        maxHeight: 600,
      }}
    >
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
    </Box>
  );
};
