import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { InputField, PhoneField } from "../../features/form";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import compare from "../../utils/Compare";
import AddIcon from "@mui/icons-material/Add";
import {
  CLIENTS_FEkhedmetak,
  COUNTRY_FILTER,
  EMPLOYEES,
  FOLLOWERTREE,
  STATES,
  UNITS,
  USERFEKHEDMETAK,
  USERFEKHEDMETAKHISTORY,
  USERSALERT,
  USERS_FEkhedmetak,
  USER_CAN_LOGIN,
} from "../../data/APIs";
import useDataGrid from "../../hooks/useDataGrid";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import filter from "../../utils/ClearNull";
import {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogNumberField,
  DialogPhoneField,
  DialogSelectField,
} from "../../features/dialog";
import useControls from "../../hooks/useControls";
import useAfterEffect from "../../hooks/useAfterEffect";
import { Compare } from "@mui/icons-material";
import { WhatsappIcon } from "react-share";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import usePropState from "../../hooks/usePropState";
import DialogCheckboxField from "../../features/dialog/components/DialogCheckbox";
import { flatMap } from "lodash";
import Wrapper from "../../components/Wrapper";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styledialog, stylerow } from "../../css/tableview";
import Tree from "react-d3-tree";
import "../employee/tree.css";
import PureSvgNodeElement from "../employee/PropsTree";
import RestoreSharpIcon from "@mui/icons-material/RestoreSharp";
// import { userInfo } from "../../store/userInfoSlice";
function Users() {
  const usersStore = useSelector((state) => state.fekhidmaticUsers.value);
  const dispatch = useDispatch();
  const [infoData, setInfoData] = useState(null);
  const [OpenAlert, setOpenAlert] = useState(false);

  const lang = useSelector((state) => state.lang.value.lang);
  const userInfo = useSelector((state) => state.userInfo.value);
  const [selected, setSelected] = useState([]);
  const viewEmployeesLang = useSelector(
    (state) => state.lang.value.viewEmployees
  );
  // const confirmMessage = useSelector(
  //   (state) => state.lang.value.DeleteMessages.EmployeeMessage
  // );

  const globalLang = useSelector((state) => state.lang.value.global);
  const [clientGetRequest, clientGetResponse] = useRequest({
    path: USERS_FEkhedmetak,
    method: "get",
  });
  const handleGetClient = (urlParams) => {
    let resultoffilter = { ...urlParams };
    delete urlParams.currentPage;
    clientGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "fekhusers/set", payload: res.data });
      },
    });
  };
  const handleGetUser = (urlParams) => {
    clientGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "fekhusers/set", payload: res.data });
      },
    });
  };
  const NameFilter = ({ value, onChange, nameQuery, type = "text" }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      if (nameQuery === "points") {
        const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
        const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0); // Ensure value is not NaN and greater than or equal to 0
        onChange({
          query: [nameQuery, newValue],
          renderedValue: newValue,
          value: newValue,
        });
      } else {
        let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;
        let match = text.test(e.target.value);
        if (match) {
          onChange({
            query: [nameQuery, e.target.value],
            renderedValue: e.target.value,
            value: e.target.value,
          });
        }
      }
    };

    return (
      <InputField
        placeholder={nameQuery}
        value={value ? value : type === "number" ? 0 : undefined}
        onChange={handleChange}
        type={type}
      />
    );
  };

  const { handlePaginate, handleChangeAmount, handleFilter } = useDataGrid({
    onParamsChange: handleGetClient,
  });
  const [employeeDeteleRequest, employeeDeleteResponse] = useRequest({
    path: USERFEKHEDMETAK,
    method: "delete",
    successMessage: globalLang.detelesuccess[lang],
  });
  const deleteEmployee = (e, row) => {
    // if (Boolean(row.client_count)) {
    //   handlePreventDeleteEmployee();
    //   return;
    // }
    employeeDeteleRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "fekhusers/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteEmployee, deleteEmployeeConfirmDialog] = useConfirmMessage(
    {
      onConfirm: deleteEmployee,
      text: globalLang.makesureofdelete[lang],
    }
  );
  const [openEditInfo, setOpenEditInfo] = useState(null);
  const [editData, setEditData] = useState(null);
  const handleOpenEdit = (e, row) => {
    setOpenEditInfo(row);
  };
  const handleChecks = ({ checks }) => {
    setSelected(checks);
  };
  // const [blockEmployeePostRequest] = useRequest({
  //   path: BLOCK_EMPLOYEE,
  //   method: "post",
  // });

  // const handleBlockEmployee = (e, row) => {
  //   blockEmployeePostRequest({
  //     body: {
  //       agent: row.id,
  //     },
  //     onSuccess: (res) => {
  //       dispatch({ type: "employees/blockItem", payload: { id: row.id } });
  //     },
  //   });
  // };
  const [selectedRestoreRequest, selectedRestoreResponse] = useRequest({
    path: USERFEKHEDMETAK + "restore/",
    method: "patch",
    successMessage: globalLang.restoreuser[lang],
  });
  const [selectedUserCanLoginRequest, selectedUserCanLoginResponse] =
    useRequest({
      path: USER_CAN_LOGIN,
      method: "post",
      successMessage: globalLang.successlogincan[lang],
    });
  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: USERFEKHEDMETAK,
    method: "patch",
    successMessage: globalLang.successpatchuser[lang],
  });
  const userCanLogin = () => {
    selectedUserCanLoginRequest({
      body: {
        users: selected.map((client) => client.id),
      },
      onSuccess: (res) => {
        selected.map((client) => {
          dispatch({
            type: "fekhusers/putItem",
            payload: {
              id: client.id,
              item: { ...client, is_verified: !client.is_verified },
            },
          });
        });
        console.log(res.data, selected);
      },
    });
  };
  const Restoreuser = (idparams) => {
    selectedRestoreRequest({
      id: idparams,
      body: {},
      onSuccess: (res) => {
        console.log(res.data);
        handleGetUser();
        // selected.map((client) => {
        //   dispatch({ type: "fekhusers/putItem", payload: { id: client.id,item:{...client,can_login:!client.can_login} } });
        // });
        console.log(res.data, selected);
      },
    });
  };
  const [handleUserCanLogin, canLoginConfirmDialog] = useConfirmMessage({
    onConfirm: userCanLogin,
    text: globalLang.makesureoflogin[lang],
  });
  const [handleRestoreuser, setRestoreuser] = useConfirmMessage({
    onConfirm: Restoreuser,
    text: globalLang.restoreuser[lang],
  });

  const handleSubmit = (value, id) => {
    unitPatchRequest({
      id: id,
      body: { is_verified: value },
      onSuccess: (res) => {
        dispatch({
          type: "fekhusers/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        // resetControls();
        // onClose();
      },
    });
  };
  const [openEditPassword, setOpenEditPassword] = useState(null);

  const isPermitted = useIsPermitted();

  let columns = [
    {
      field: "id",
      headerName: globalLang.id[lang],
      customContent: (params) => params.id,
    },
    {
      field: "image",
      headerName: globalLang.picturejust[lang],
      customContent: (params) => {
        return (
          <Avatar
            src={params?.image}
            variant="rounded"
            sx={{ width: "90px", height: "90px" }}
          >
            U
          </Avatar>
        );
      },
    },
    {
      field: "name",
      headerName: globalLang.name[lang],
      customContent: (params) => params.name,
      customEmpty: "-",
    },
    {
      field: "phone",
      headerName: globalLang.phone[lang],
      customContent: (params) => params.country_code + params.phone,
      customEmpty: "-",
    },
    {
      field: "job",
      headerName: globalLang.job[lang],
      customContent: (params) => params.job,
      customEmpty: "-",
    },
    {
      field: "points",
      headerName: globalLang.points[lang],
      customContent: (params) => params.points,
      customEmpty: "0",
    },
    ,
    {
      field: "rent",
      headerName: globalLang.rent[lang],
      customContent: (params) => params.rent,
      customEmpty: "0",
    },
    {
      field: "buy",
      headerName: globalLang.buy[lang],
      customContent: (params) => params.buy,
      customEmpty: "0",
    },
    {
      field: "clients",
      headerName: globalLang.clients[lang],
      customContent: (params) => params.clients,
      customEmpty: "0",
    },
    {
      field: "units",
      headerName: globalLang.units[lang],
      customContent: (params) => params.units,
      customEmpty: "0",
    },
    {
      field: "followers_count",
      headerName: globalLang.followers_count[lang],
      customContent: (params) => params.followers_count,
      customEmpty: "0",
    },
    {
      field: "is_verified",
      headerName: globalLang.is_verified[lang],
      customContent: (params) =>
        params.is_verified ? (
          <IconButton
            onClick={() => {
              handleSubmit(!params.is_verified, params?.id);
            }}
          >
            {" "}
            <CheckIcon style={{ color: "green" }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              handleSubmit(!params.is_verified, params?.id);
            }}
          >
            <CloseIcon style={{ color: "red" }} />
          </IconButton>
        ),
      customEmpty: "-",
    },
    {
      field: "deleted_account",
      headerName: globalLang.restoreuser[lang],
      customContent: (params) =>
        params.deleted_account ? (
          <IconButton
            onClick={() => {
              handleRestoreuser(params?.id);
            }}
          >
            {" "}
            <RestoreSharpIcon style={{ color: "green" }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              handleRestoreuser(params?.id);
            }}
            disabled={true}
          >
            {" "}
            <RestoreSharpIcon style={{ color: "red" }} />
          </IconButton>
        ),
      customEmpty: "0",
    },
  ];
  const SelectFilter = ({
    value,
    onChange,
    nameoffiter,
    arrayoption,
    functiontofetch,
  }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////
    const handleChange = (e) => {
      onChange({
        query: [nameoffiter, e.target.value],
        renderedValue: e.target.value,
        value: e.target.value,
      });
    };

    return (
      <>
        <DialogSelectField
          onOpen={functiontofetch}
          placeholder={value}
          onChange={handleChange}
          renderValue={(selected) => {
            return arrayoption.find((mean) => mean.value === selected)?.title;
          }}
        >
          {arrayoption.map((ele) => {
            return <MenuItem value={ele.value}>{ele.title}</MenuItem>;
          })}
        </DialogSelectField>
      </>
      // <InputField
      //   placeholder={globalLang.name[lang]}
      //   value={value}
      //   onChange={handleChange}
      // />
    );
  };
  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter nameQuery={"name"} />,
    },
    {
      name: globalLang.job[lang],
      component: <NameFilter nameQuery={"job"} />,
    },
    {
      name: globalLang.points[lang],
      component: <NameFilter nameQuery={"points"} type="number" />,
    },
    {
      name: globalLang.is_verified[lang],
      component: (
        <SelectFilter
          arrayoption={[
            { title: <CheckIcon style={{ color: "green" }} />, value: "true" },
            { title: <CloseIcon style={{ color: "red" }} />, value: "false" },
          ]}
          nameoffiter={"is_verified"}
        />
      ),
    },
  ];
  return (
    <>
      <Wrapper sx={{ minHeight: "79%",paddingBottom:"0px" }}>
        <Typography
          variant="h5"
          sx={{ textAlign: lang === "ar" ? "left" : "right", marginY: "24px" }}
          color="primary"
        >
          {globalLang.jokers[lang]}
        </Typography>

        <DataGrid
          columns={columns}
          rows={usersStore.results}
          total={usersStore.count}
          onCheck={handleChecks}
          isPending={clientGetResponse.isPending}
          onDelete={handleDeleteEmployee}
          onEdit={(e, row) => setEditData(row)}
          onPaginate={handlePaginate}
          onChangePassword={(e, row) => setOpenEditPassword(row.id)}
          // onTrueorFalse={handleSubmit}
          onAmountChange={handleChangeAmount}
          onFilter={handleFilter}
          filters={filters}
          onView={(e, row) => {
            dispatch({ type: "fekhusers/getItem", payload: { ...row } });
            setInfoData(row);
          }}
        />
      </Wrapper>
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      />
      <EditPasswordDialog
        open={Boolean(openEditPassword)}
        onClose={() => setOpenEditPassword(null)}
        id={openEditPassword}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{marginBottom:"20px"}}
      >
        <Button
          variant="contained"
          disabled={!Boolean(selected.length)}
          sx={{ width: "200px", height: "50px" }}
          onClick={() => setOpenAlert(!OpenAlert)}
        >
          {globalLang.sendalert[lang]}
        </Button>
        <SendMessageDialog
          open={OpenAlert}
          onClose={() => setOpenAlert(false)}
          id={"sendmessagedialog"}
          data={selected}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={
            !Boolean(selected.length) || selectedUserCanLoginResponse.isPending
          }
          sx={{ width: "200px", height: "50px" }}
          onClick={handleUserCanLogin}
        >
          {globalLang.userCanLogin[lang]}
        </Button>
      </Stack>
      {selectedRestoreResponse.failAlert}
      {selectedRestoreResponse.successAlert}
      {deleteEmployeeConfirmDialog}
      {setRestoreuser}
      {employeeDeleteResponse.successAlert}
      {employeeDeleteResponse.failAlert}
      {canLoginConfirmDialog}
      {selectedUserCanLoginResponse.successAlert}
      {selectedUserCanLoginResponse.failAlert}
      {unitPatchResponse.failAlert}
      {unitPatchResponse.successAlert}
    </>
  );
}

export default Users;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const [value, setValue] = React.useState(2);
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const userInfo = useSelector((state) => state.userInfo.value);
  const [checkbox, setcheckbox] = useState(data?.can_login);
  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "username",
        value: data?.username,
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },

      {
        control: "job",
        value: data?.job,
        isRequired: false,
      },
      {
        control: "password",
        value: data?.password,
        isRequired: false,
      },
      {
        control: "code",
        value: data?.country_code ? data?.country_code : "+20",
        isRequired: false,
      },
      // {
      //   control: "canlogin",
      //   value: data?.can_login,
      //   isRequired: false,
      // },
      { control: "points", value: data?.points, isRequired: false },
      { control: "rate", value: Number(data?.rate), isRequired: false },
      { control: "status", value: data?.status, isRequired: false },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: USERFEKHEDMETAK,
    method: "patch",
    successMessage: globalLang.successpatchuser[lang],
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    // let {username,job,points,can_login,phone}=data

    const isThereChange = compare(
      [
        [controls.username, data.username, "username"],
        [controls.job, data.job, "job"],
        [Number(controls.points), data.points, "points"],
        [Number(controls.rate), data.rate, "rate"],

        // [controls.code, data.country_code,"country_code"],
        // [Boolean(controls.canlogin), data.can_login, "can_login"],
        [controls.phone, data.phone, "phone"],
      ],
      false
    );

    if (isThereChange.nochange) {
      const requestBody = filter({
        obj: {
          username: isThereChange.array["username"]
            ? `${isThereChange.array["username"]}`
            : undefined,
          phone:
            isThereChange.array["phone"] && isThereChange.array["country_code"]
              ? isThereChange.array["country_code"] +
                isThereChange.array["phone"]
              : !isThereChange.array["phone"] &&
                isThereChange.array["country_code"]
              ? isThereChange.array["country_code"] + controls.phone
              : isThereChange.array["phone"] &&
                !isThereChange.array["country_code"]
              ? controls.code + isThereChange.array["phone"]
              : undefined,
          job: isThereChange.array["job"],

          // can_login:
          //   isThereChange.array["can_login"] === false ||
          //   isThereChange.array["can_login"] === true
          //     ? isThereChange.array["can_login"]
          //     : undefined,
          points: isThereChange.array["points"],
          rate: isThereChange.array["rate"],
        },
      });

      unitPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "fekhusers/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          resetControls();
          onClose();
        },
      });
    } else {
      resetControls();
      onClose();
    }
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    if (countriesData.length) return;
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = () => {
    // if (governoratesData.length) return;
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };

  //===End===== Get Countries logic =========

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "100%" }}
        sx={{ ".MuiPaper-root": { minWidth: "750px", borderRadius: "10px" } }}
      >
        <DialogHeading>{globalLang.editUserData[lang]}</DialogHeading>
        <DialogForm>
          <DialogInputField
            label={globalLang.username[lang]}
            placeholder={globalLang.name[lang]}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ direction: "rtl", paddingLeft: "10px" }}
                >
                  @{userInfo?.organization?.name?.replace(/\s/gi, "")}.com
                </InputAdornment>
              ),
            }}
            value={controls.username.split("@")[0]}
            onChange={(e) =>
              setControl(
                "username",
                `${e.target.value}@${userInfo?.organization?.name?.replace(
                  /\s/gi,
                  ""
                )}_fk.com`
              )
            }
            type="text"
            // error={Boolean(invalid.username)}
            // helperText={invalid.username}
          />

          {/* </DialogInputField> */}
          <DialogInputField
            label={globalLang.job[lang]}
            placeholder={globalLang.job[lang]}
            // onOpen={getGovernorates}
            // isPending={statesGetResponse.isPending}
            value={controls.job}
            onChange={(e) => {
              setControl("job", e.target.value);
            }}
          />
          {/* <DialogInputField
          label={globalLang.password[lang]}
          placeholder={globalLang.password[lang]}
          value={controls.password}
          onChange={(e) => setControl("password", e.target.value)}
        /> */}
          <DialogPhoneField
            placeholder={globalLang.customerPhone[lang]}
            label={globalLang.customerPhone[lang]}
            selectProps={{
              value: controls.code,
              onChange: (e) => setControl("code", e.target.value),
            }}
            value={controls.phone}
            onChange={(e) => setControl("phone", e.target.value)}
          />
          <DialogInputField
            label={globalLang.points[lang]}
            placeholder={globalLang.points[lang]}
            value={controls.points ? controls.points : 0}
            onChange={(e) => {
              const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
              const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0); // Ensure value is not NaN and greater than or equal to 0
              setControl("points", newValue.toString()); // Update the state with the validated value
            }}
            type="number"
            min="0"
          />
          {/* <DialogInputField
            label={globalLang.buy[lang]}
            placeholder={globalLang.buy[lang]}
            value={controls.buy?controls.buy:0}
            onChange={(e) => {
              const inputValue = parseInt(e.target.value, 10); // Parse the input value as an integer
              const newValue = isNaN(inputValue) ? 0 : Math.max(inputValue, 0); // Ensure value is not NaN and greater than or equal to 0
              setControl("buy", newValue.toString()); // Update the state with the validated value
            }}
            type="number"
            min="0"
          />  */}
          {/* <DialogCheckboxField
            label={globalLang.can_login[lang]}
            placeholder={globalLang.can_login[lang]}
            value={controls.canlogin}
            onClick={(e) => setControl("canlogin", !controls.canlogin)}
          /> */}

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Typography component="legend">
              {globalLang.rating[lang]}
            </Typography>
            <Rating
              name="simple-controlled"
              value={controls.rate}
              onChange={(event, newValue) => {
                return setControl("rate", newValue);
              }}
              sx={{ direction: lang === "ar" ? "rtl" : "ltr" }}
            />
          </Stack>
          {/* {console.log(controls)} */}
        </DialogForm>
        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            disabled={unitPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
      </Dialog>
    </>
  );
};

const InfoDialog = ({ open, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const { getelementbyid } = useSelector((state) => state.fekhidmaticUsers);
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [dataresult, setdataresult] = useState(data ? data : {});
  const [dataTree, setDataTree] = useState(null);
  ///////////////////////////////

  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "id",
        value: data?.id,
        isRequired: false,
      },
      {
        control: "username",
        value: data?.username,
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone,
        isRequired: false,
      },
      {
        control: "job",
        value: data?.job,
        isRequired: false,
      },
      {
        control: "password",
        value: data?.password,
        isRequired: false,
      },
      {
        control: "code",
        value: data?.country_code,
        isRequired: false,
      },
      { control: "points", value: String(data?.points), isRequired: false },
      {
        control: "is_verified",
        value: String(data?.is_verified),
        isRequired: false,
      },
      {
        control: "followers",
        value: data?.followers,
        isRequired: false,
      },
      { control: "rent", value: String(data?.rent), isRequired: false },
      { control: "buy", value: String(data?.buy), isRequired: false },
      { control: "clients", value: String(data?.clients), isRequired: false },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;

    handleSubmit();
    handleTreeSubmit();
  }, [open]);
  const [userhistoryRequest, userhistoryResponse] = useRequest({
    path: USERFEKHEDMETAKHISTORY,
    method: "get",
    // successMessage: "تم  بنجاح",
  });
  const [followerstreesGetRequest, followerstreesGetResponse] = useRequest({
    path: FOLLOWERTREE,
    method: "get",
  });
  // const [unitPatchRequest, unitPatchResponse] = useRequest({
  //   path: USERFEKHEDMETAK,
  //   method: "get",
  //   successMessage: "تم  بنجاح",
  // });

  const handleSubmit = () => {
    userhistoryRequest({
      id: data.id,

      onSuccess: (res) => {
        // console.log(res.data.results);
        setrows(res?.data?.results);
      },
    });
  };
  const handleTreeSubmit = () => {
    followerstreesGetRequest({
      onSuccess: (res) => {
        setDataTree(res.data);
        // setrows(res?.data?.results);
      },
    });
  };
  const datatree = {
    name: "Root",
    children: [
      {
        name: "Node 1",
        children: [{ name: "Leaf 1" }, { name: "Leaf 2" }, { name: "Leaf 3" }],
      },
      {
        name: "Node 2",
        children: [{ name: "Leaf 4" }, { name: "Leaf 5" }],
      },
    ],
  };
  //===Start===== Get Countries logic =========

  //===End===== Get Countries logic =========

  return (
    <Dialog
      open={open}
      onClose={onClose}
      paperProps={{ height: "100%" }}
      sx={{ ".MuiPaper-root": { borderRadius: "10px", minWidth: "900px" } }}
    >
      <DialogHeading>
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          {globalLang.detailUserData[lang]}
        </Typography>
      </DialogHeading>
      <DialogContent
        sx={{ border: "1px solid gray", borderRadius: "10px", margin: "24px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.username[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.username}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.phone[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.code + controls.phone}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.job[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.job}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.points[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.points}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.rent[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.rent}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.clients[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.clients}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.buy[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.buy}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                {globalLang.is_verified[lang]}
              </TableCell>
              <TableCell color="primary" sx={{ border: "none" }}>
                {controls.is_verified === "true" ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogHeading>
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          {globalLang.detailHistoryUserData[lang]}
        </Typography>
      </DialogHeading>
      <DialogContent sx={styledialog}>
        <Table border={0}>
          <TableCell sx={stylerow}>{globalLang.username[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.phone[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.job[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.is_verified[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.points[lang]}</TableCell>

          {/* <TableCell sx={stylerow}>{globalLang.property_type[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.created_by[lang]}</TableCell> */}

          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.username}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.username}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.job}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.is_verified ? <CheckIcon /> : <CloseIcon />}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.points}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogHeading>
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          {globalLang.followerdetail[lang]}
        </Typography>
      </DialogHeading>
      <DialogContent sx={styledialog}>
        <Table border={0}>
          <TableCell sx={stylerow}>{globalLang.image[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.phone[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.username[lang]}</TableCell>
          <TableCell sx={stylerow}>{globalLang.points[lang]}</TableCell>

          <TableBody>
            {data?.followers?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  <Avatar src={row.image} />
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.username}
                </TableCell>
                <TableCell
                  align={lang === "ar" ? "left" : "right"}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    minWidth: "100px",
                  }}
                >
                  {row.points}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            width: "50vw",
            margin: "auto",
            transform: "translateY(-5%)",
          }}
        >
          {/* <Tree
        data={dataTree}
        rootNodeClassName="node__root"
        branchNodeClassName="node__branch"
        leafNodeClassName="node__leaf"
        orientation="vertical"
        zoomable={false}
        translate={{ x: 0, y: 0 }}
        shouldCollapseNeighborNodes
        nodeSize={{x:100,y:100}}
        pathFunc="diagonal"
        // separation={s}
        svgClassName="tree"
        hasInteractiveNodes
        //  svgClassName="Tree"
        renderCustomNodeElement={(rd3tProps, appState) => (
          <PureSvgNodeElement
            nodeDatum={rd3tProps.nodeDatum}
            toggleNode={rd3tProps.toggleNode}
            orientation={"horizontal"}
          />)}
          
      />    */}
        </div>
      </DialogContent>
    </Dialog>
  );
};
const EditPasswordDialog = ({ open, onClose, id }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [
    { controls, required, invalid },
    { setControl, resetControls, validate },
  ] = useControls([
    {
      control: "password",
      value: "",
      isRequired: false,
      validations: [
        {
          // cust:,
          customValidation: (controls) => controls.password.length >= 8,
          message: globalLang.passwordvalidation[lang],
        },
      ],
      // validation:
    },
  ]);
  ///////////////////////////////
  const [password, setPassword] = useState("");

  const [changePasswordPatchReqeust, changePasswordPatchResponse] = useRequest({
    path: USERFEKHEDMETAK,
    method: "patch",
    successMessage: globalLang.changepassword[lang],
  });

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const requestBody = filter({
        obj: {
          password: controls.password,
        },
        output: "object",
      });
      changePasswordPatchReqeust({
        body: requestBody,
        id: id,
        onSuccess: () => {
          // if(changePasswordPatchResponse){
          resetControls();

          onClose();
          // }
          // onClose();
        },
      });
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} paperProps={{ maxWidth: 600 }}>
        <DialogContent
          sx={{
            height: "100% !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#233975",
          }}
        >
          <Stack justifyContent="center" alignItems="center">
            <DialogButtonsGroup sx={{ width: "100%" }}>
              <TextField
                variant="standard"
                label={globalLang.password[lang]}
                placeholder={globalLang.password[lang]}
                value={controls.password}
                onChange={(e) => setControl("password", e.target.value)}
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-formControl": {
                    fontSize: 20,
                    fontWeight: "normal",
                    transform: "translate(10px, -10.5px) scale(0.75)",
                    color: "white",
                  },
                  "& .MuiInput-input": {
                    paddingBlock: 1.2,
                    fontSize: 15,
                  },
                  "& .MuiInputBase-formControl": {
                    borderColor: "white",
                    bgcolor: "white",
                  },

                  "& .MuiInputLabel-formControl.Mui-focused": {
                    color: "unset",
                  },
                }}
                type="password"
                required={required.includes("password")}
                error={Boolean(invalid.password)}
                helperText={invalid.password}
              />
            </DialogButtonsGroup>
            <DialogButtonsGroup>
              <DialogButton
                onClick={handleSubmit}
                sx={{ width: "100%" }}
                disabled={changePasswordPatchResponse.isPending}
              >
                {globalLang.save[lang]}
              </DialogButton>
              <DialogButton
                variant="close"
                onClick={onClose}
                sx={{ width: "100%" }}
              >
                {globalLang.cancel[lang]}
              </DialogButton>
            </DialogButtonsGroup>
          </Stack>
        </DialogContent>
      </Dialog>
      {changePasswordPatchResponse.failAlert}
      {changePasswordPatchResponse.successAlert}
    </>
  );
};
const SendMessageDialog = ({ open, onClose, id, data = [] }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [message, setMessage] = useState("");

  const [alertuserrequest, alertuserresponce] = useRequest({
    path: USERSALERT,
    method: "post",
    successMessage: globalLang.successedit[lang],
  });

  const handleSubmit = () => {
    if (!message) {
      onClose();
      return;
    }
    alertuserrequest({
      body: {
        users: data.map((ele) => ele.id),
        message: message,
      },

      onSuccess: () => {
        // if(alertuserresponce){
        setMessage("");
        onClose();
        // }
        // onClose();
      },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} paperProps={{ maxWidth: 600 }}>
        <DialogContent
          sx={{
            height: "100% !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#233975",
          }}
        >
          <Stack justifyContent="center" alignItems="center">
            <DialogButtonsGroup sx={{ width: "100%" }}>
              <TextField
                variant="standard"
                label={globalLang.message[lang]}
                placeholder={globalLang.message[lang]}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-formControl": {
                    fontSize: 20,
                    fontWeight: "normal",
                    transform: "translate(10px, -10.5px) scale(0.75)",
                    color: "white",
                  },
                  "& .MuiInput-input": {
                    paddingBlock: 1.2,
                    fontSize: 15,
                  },
                  "& .MuiInputBase-formControl": {
                    borderColor: "white",
                    bgcolor: "white",
                  },

                  "& .MuiInputLabel-formControl.Mui-focused": {
                    color: "unset",
                  },
                }}
                type="text"
              />
            </DialogButtonsGroup>
            <DialogButtonsGroup>
              <DialogButton
                onClick={handleSubmit}
                sx={{ width: "100%" }}
                disabled={alertuserresponce.isPending}
              >
                {globalLang.save[lang]}
              </DialogButton>
              <DialogButton
                variant="close"
                onClick={onClose}
                sx={{ width: "100%" }}
              >
                {globalLang.cancel[lang]}
              </DialogButton>
            </DialogButtonsGroup>
          </Stack>
        </DialogContent>
      </Dialog>
      {alertuserresponce.failAlert}
      {alertuserresponce.successAlert}
    </>
  );
};
