import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import Form, {
  InputField,
  NumberField,
  PhoneField,
  PictureField,
  SelectField,
  TextareaField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import { Card, CardMedia, IconButton, Input, InputAdornment, InputLabel, MenuItem, TextField } from "@mui/material";
import useRequest from "../../hooks/useRequest";
import { COUNTRY_FILTER, MEANSOFCOMFORT, STATES, STATE_CITIES, UNITS } from "../../data/APIs";
import filter from "../../utils/ClearNull";
import { useSelector } from "react-redux";
import Videocomponent from "../../features/form/components/Videocomponent";
import DialogCheckboxField from "../../features/dialog/components/DialogCheckbox";
import { ContactPageSharp } from "@mui/icons-material";
import AutocompleteField from "../../features/form/components/AutocompleteField";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import Compressor from "compressorjs";
const AddUnits = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addUnitsLang = useSelector((state) => state.lang.value.addUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];
  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "country",
      value: "",
      isRequired: false,
    },
    {
      control: "category",
      value: "",
      isRequired: true,
    },
    {
      control: "governorate",
      value: "",
      isRequired: false,
    },
    {
      control: "city",
      value: "",
      isRequired: false,
    },
    {
      control: "district",
      value: "",
      isRequired: false,
    },
    {
      control: "near",
      value: "",
      isRequired: false,
    },
    {
      control: "address",
      value: "",
      isRequired: false,
    },
    {
      control: "number",
      value: "",
      isRequired: false,
    },
    {
      control: "type",
      value: "",
      isRequired: false,
    },
    {
      control: "sale",
      value: "",
      isRequired: true,
    },
    {
      control: "floor",
      value: "",
      isRequired: false,
    },
    {
      control: "area",
      value: "",
      isRequired: false,
    },
    {
      control: "rooms",
      value: "",
      isRequired: false,
    },
    {
      control: "bathrooms",
      value: "",
      isRequired: false,
    },
    {
      control: "genre",
      value: "",
      isRequired: false,
    },
    {
      control: "price",
      value: "",
      isRequired: false,
    },
    {
      control: "client",
      value: "",
      isRequired: true,
    },
    {
      control: "phone",
      value: "",
      isRequired: true,
    },
    {
      control: "countryCode",
      value: "+20",
      isRequired: true,
    },
    {
      control: "notes",
      value: "",
      isRequired: false,
    },
    {
      control: "files",
      value: [],
      isRequired: false,
    },
    {
      control:"amenities_ids",
      value:[],
      isRequired:false
    },{
      control:"isPublic",
      value:false,
      isRequired:false
    },{
      control: "kitchen_count",
      value: "",
      isRequired: false,
    }, {
      control: "living_room_count",
      value:0,
      isRequired: false,
    },{
      control:"main_image",
      value:{},
      isRequired:true
    }
  ]);
  
  const [unitPostRequest, unitPostResponse] = useRequest({
    path: UNITS,
    method: "post",
    successMessage: "تم إضافة وحدة جديدة بنجاح",
  });
  const [meansofcomfortRequest,meansofcomfortResponse] = useRequest({
    path: MEANSOFCOMFORT,
    method: "get",
    successMessage: globalLang.getamenities[lang],
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          address: controls.address,
          area: controls.district,
          floor_number: controls.floor,
          room_number: controls.rooms,
          bath_count: controls.bathrooms,
          amenities_ids:controls.amenities_ids.map((ele)=>ele.value),
          price: controls.price,
          comment: controls.notes,
          unit_type: controls.type,
          area_size: controls.area,
          complete_type: controls.genre,
          sales_type: controls.sale,
          phone_client: controls.countryCode + controls.phone,
          client: controls.client,
          country: controls.country,
          is_public:controls.isPublic,
          state: controls.governorate,
          kitchen_count:controls.kitchen_count,
          city: controls.city,
          part: controls.near,
          flat_number: controls.number,
          category:controls.category,
          files: [...controls.files],
          living_room_count: controls.living_room_count,
          main_image:controls.main_image
        },
        output: "formData",
      });

      if (
        Object.keys(
          filter({
            obj: {
              address: controls.address,
              area: controls.district,
              floor_number: controls.floor,
              room_number: controls.rooms,
              bath_count: controls.bathrooms,
              price: controls.price,
              comment: controls.notes,
              unit_type: controls.type,
              area_size: controls.area,
              complete_type: controls.genre,
              sales_type: controls.sale,
              phone_client: controls.countryCode + controls.phone,
              client: controls.client,
              country: controls.country,
              state: controls.governorate,
              city: controls.city,
              category:controls.category,
              is_public:controls.isPublic,
              kitchen_count:controls.kitchen_count,
              part: controls.near,
              flat_number: controls.number,
              files: [...controls.files],
              living_room_count: controls.living_room_count,
              main_image:controls.main_image
            },
          })
        ).length <= 1
      )
        return;

      unitPostRequest({
        body: requestBody,
        onSuccess: () => resetControls(),
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            address: response?.address,
            district: response?.area,
            floor: response?.floor_number,
            rooms: response?.room_number,
            bathrooms: response?.bath_count,
            price: response?.price,
            notes: response?.comment,
            type: response?.unit_type,
            area: response?.area_size,
            genre: response?.complete_type,
            sale: response?.sales_type,
            kitchen_count:response?.kitchen_count,
            phone: response?.phone_client,
            client: response?.client,
            country: response?.country,
            governorate: response?.state,
            city: response?.city,
            near: response?.part,
            number: response?.flat_number,
            living_room_count: response?.living_room_count,
            main_image:response?.main_image
          },
        });

        setInvalid(responseBody);
      });
    });
  };
  // compress file for video upload
  
  
  
    
  

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);
  const [meanspfcomfort, setMeansOfConfort] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };
  const getMeansOfComfort=()=>{
    meansofcomfortRequest({
      onSuccess:(res)=>{

        setMeansOfConfort(res.data.results)
      }
    })
  }
  
  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = () => {
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };

  //===End===== Get Countries logic =========

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.units[lang], addUnitsLang.addUnits[lang]]}
      />
      <Form
        component="form"
        // onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: unitPostResponse.isPending,
            onClick:handleSubmit
          },
          closeBtn: {
            disabled: unitPostResponse.isPending,
            onClick:resetControls
          },
        }}
      >
        <SelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          required={required.includes("country")}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            setControl("governorate", "");
            setControl("city", "");
          }}
          error={Boolean(invalid.country)}
          helperText={invalid.country}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={getGovernorates}
          isPending={statesGetResponse.isPending}
          required={required.includes("governorate")}
          value={controls.governorate}
          onChange={(e) => {
            setControl("governorate", e.target.value);
            setControl("city", "");
          }}
          error={Boolean(invalid.governorate)}
          helperText={invalid.governorate}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </SelectField>
        <InputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          required={required.includes("city")}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
          error={Boolean(invalid.city)}
          helperText={invalid.city}
        />
        <InputField
          label={globalLang.region[lang]}
          placeholder={globalLang.region[lang]}
          required={required.includes("district")}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
          error={Boolean(invalid.district)}
          helperText={invalid.district}
        />
         <InputField
          label={globalLang.address[lang]}
          placeholder={globalLang.address[lang]}
          required={required.includes("address")}
          value={controls.address}
          onChange={(e) => setControl("address", e.target.value)}
          error={Boolean(invalid.address)}
          helperText={invalid.address}
        />
         <InputField
          label={globalLang.unitType[lang]}
          placeholder={globalLang.unitType[lang]}
          required={required.includes("type")}
          value={controls.type}
          onChange={(e) => setControl("type", e.target.value)}
          error={Boolean(invalid.type)}
          helperText={invalid.type}
        />
        <SelectField
        label={globalLang.floor[lang]}
        placeholder={globalLang.floor[lang]}
        value={controls.floor}
        renderValue={(selected) =>
          floors.find((f) => f.value === selected).name
        }
        onChange={(e) => setControl("floor", e.target.value)}
        error={Boolean(invalid.floor)}
          helperText={invalid.floor}
      >
        {floors.map((floor, index) => (
          <MenuItem value={floor.value} key={` ${index}`}>
            {floor.name}
          </MenuItem>
        ))}
      </SelectField>
      <NumberField
          label={globalLang.area[lang]}
          placeholder={globalLang.area[lang]}
          suffix=" متر"
          // isAllowed={({ value }) => value.length <= 5}
          required={required.includes("area")}
          value={controls.area}
          onChange={(e) =>
            setControl("area", e.target.value.replace("متر", "").trim())
          }
          error={Boolean(invalid.area)}
          helperText={invalid.area}
        />
        <InputField
          label={globalLang.finishingType[lang]}
          placeholder={globalLang.finishingType[lang]}
          required={required.includes("genre")}
          value={controls.genre}
          onChange={(e) => setControl("genre", e.target.value)}
          error={Boolean(invalid.genre)}
          helperText={invalid.genre}
        />
         <NumberField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          
          // isAllowed={({ value }) => value.length <= 5}
          required={required.includes("price")}
          value={controls.price}
          onChange={(e) =>
            setControl(
              "price",
              e.target.value
            )
          }
          error={Boolean(invalid.price)}
          helperText={invalid.price}
        />
      <SelectField
        label={globalLang.kitchen_count[lang]}
        placeholder={globalLang.kitchen_count[lang]}
        value={controls.kitchen_count}
        renderValue={(selected) =>
          rooms.find((f) => f === selected)
        }
        onChange={(e) => setControl("kitchen_count", e.target.value)}
        error={Boolean(invalid.kitchen_count)}
        helperText={invalid.kitchen_count}
      >
        {rooms.map((floor, index) => (
          <MenuItem value={floor} key={` ${index}`}>
            {floor}
          </MenuItem>
        ))}
      </SelectField>
      <SelectField
            placeholder={globalLang.livingNumber[lang]}
            label={globalLang.livingNumber[lang]}
            value={controls.living_room_count}
            onChange={(e) => setControl("living_room_count", e.target.value)}
            error={Boolean(invalid.living_room_count)}
            helperText={invalid.living_room_count}
          >
            {rooms.map((room, index) => (
              <MenuItem value={room} key={`room ${index}`}>
                {room}
              </MenuItem>
            ))}
          </SelectField>
     
        <InputField
          label={globalLang.neighboring[lang]}
          placeholder={globalLang.neighboring[lang]}
          required={required.includes("near")}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
          error={Boolean(invalid.near)}
          helperText={invalid.near}
        />
         {/* <InputField
          label={globalLang.neighboring[lang]}
          placeholder={globalLang.neighboring[lang]}
          required={required.includes("near")}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
          error={Boolean(invalid.near)}
          helperText={invalid.near}
        /> */}
        <NumberField
          label={globalLang.buildingNumber[lang]}
          placeholder={globalLang.buildingNumber[lang]}
          required={required.includes("number")}
          value={controls.number}
          onChange={(e) => setControl("number", e.target.value)}
          error={Boolean(invalid.number)}
          helperText={invalid.number}
          
        />
         <SelectField
          label={globalLang.category[lang]}
          placeholder={globalLang.category[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          required={required.includes("category")}
          value={controls.category}
          onChange={(e) => {
            setControl("category", e.target.value);
          }}
          renderValue={(selected) =>
            ["resale", "sharing", "projects"].find((saleType) => saleType === selected)
          }
          error={Boolean(invalid.category)}
          helperText={invalid.category}
        >
          {lang == "ar"
            ? ["resale", "sharing", "projects"].map((category, index) => (
                <MenuItem value={category} key={`category ${index}`}>
                  {category}
                </MenuItem>
              ))
            : ["resale", "sharing", "projects"].map((category, index) => (
                <MenuItem value={category} key={`category ${index}`}>
                  {category}
                </MenuItem>
              ))}
        </SelectField>
       
        <SelectField
          label={globalLang.saleType[lang]}
          placeholder={globalLang.saleType[lang]}
          required={required.includes("sale")}
          value={controls.sale}
          renderValue={(selected) =>
            saleTypes.find((saleType) => saleType.value === selected).name
          }
          onChange={(e) => setControl("sale", e.target.value)}
          error={Boolean(invalid.sale)}
          helperText={invalid.sale}
        >
          {saleTypes.map((saleType, index) => (
            <MenuItem value={saleType.value} key={`saleType ${index}`}>
              {saleType.name}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        {/* <SelectField
          label={globalLang.meansofcomfort[lang]}
          placeholder={globalLang.meansofcomfort[lang]}
          onOpen={getMeansOfComfort}
          isPending={countriesGetResponse.isPending}
          required={required.includes("amenities_ids")}
          value={controls.amenities_ids}
          onChange={(e) => {
            setControl("amenities_ids", e.target.value);
           
          }}
          error={Boolean(invalid.amenities_ids)}
          helperText={invalid.amenities_ids}
          multiple={true}
        >
          {meanspfcomfort?.map((mean, index) => (
            <MenuItem value={mean.name} key={mean.id}>
              <img src={mean.icon} alt={mean.name} style={{marginLeft:lang==="ar"?"15px":"",marginRight:lang==="en"?"15px":""}}/>
              {mean.name}
            </MenuItem>
          ))}
        </SelectField> */}
        <AutocompleteField 
         label={globalLang.meansofcomfort[lang]}
         placeholder={globalLang.meansofcomfort[lang]}
         onOpen={getMeansOfComfort}
         isPending={meansofcomfortResponse.isPending}

         value={controls.amenities_ids}
         isOptionEqualToValue={(option, value) => option.value === value.value}
         onChange={(e,options,reason) => {
          switch (reason) {
            case "selectOption":
              setControl("amenities_ids", options.map((ele)=>ele));
              break;
              case "removeOption":
                setControl("amenities_ids", options.map(ele => ele));
                break;
            case "clear":
              setControl("amenities_ids", "");
          }
          // setControl("amenities_ids", options.map((ele)=>ele.value));
        }}
         data={[
           ...meanspfcomfort.map((employee) => ({
             label: `${employee.name}`,
             value: employee.id,
             image:employee.icon
           })),
         ]}
         multiple
        /> 
        {/* <NumberField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          required={required.includes("floor")}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.value)}
          error={Boolean(invalid.floor)}
          helperText={invalid.floor}
        /> */}
        
        
        <SelectField
          label={globalLang.roomsNumber[lang]}
          placeholder={globalLang.roomsNumber[lang]}
          required={required.includes("rooms")}
          value={controls.rooms}
          onChange={(e) => setControl("rooms", e.target.value)}
          error={Boolean(invalid.rooms)}
          helperText={invalid.rooms}
        >
          {rooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        <SelectField
          label={globalLang.bathroomsNumber[lang]}
          placeholder={globalLang.bathroomsNumber[lang]}
          required={required.includes("bathrooms")}
          value={controls.bathrooms}
          onChange={(e) => setControl("bathrooms", e.target.value)}
          error={Boolean(invalid.bathrooms)}
          helperText={invalid.bathrooms}
        >
          {bathrooms.map((bathroom, index) => (
            <MenuItem value={bathroom} key={`bathroom ${index}`}>
              {bathroom}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
       
       
        <InputField
          label={globalLang.customerName[lang]}
          placeholder={globalLang.customerName[lang]}
          required={required.includes("client")}
          value={controls.client}
          onChange={(e) =>{
            let text=/^(?:[A-Za-z\u0600-\u06ff\s]*)$/
            let match=text.test(e.target.value)
            if(match){
            setControl("client", e.target.value)
            }
          }
          }
            
          
          error={Boolean(invalid.client)}
          helperText={invalid.client}
        />
         
        <PhoneField
          label={globalLang.customerPhone[lang]}
          placeholder={globalLang.customerPhone[lang]}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => {
              setControl("countryCode", e.target.value);
            },
          }}
          requiredCode
          required={required.includes("phone")}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
          error={Boolean(invalid.phone)}
          helperText={invalid.phone}
        />
     
        <PictureField
          label={globalLang.reviewunit[lang]}
          placeholder={globalLang.reviewunit[lang]}
          accept="image/*, video/*"
          onChange={(e) => {
            if (Object.keys(e.target.files).length < 1) {
              alert(globalLang.reviewunit[lang]);
              e.target.value = [];
              return;
            }
           setControl("files", [
              ...Object.keys(e.target.files).map((key) => e.target.files[key]),
            ])
          // handleFileUpload(e)
          }}
          value={controls.files}
        />
          {/* <PictureField
          label={globalLang.reviewunit[lang]}
          placeholder={globalLang.reviewunit[lang]}
          accept="image/*"
          onChange={(e) => {
            if (Object.keys(e.target.files).length < 1) {
              alert(globalLang.reviewunit[lang]);
              e.target.value = [];
              return;
            }
           setControl("main_image", e.target.files[0])
          // handleFileUpload(e)
          }}
        
          value={controls.files}
        /> */}
        
         <InputField
          label={globalLang.addmainImage[lang]}
          placeholder={globalLang.addmainImage[lang]}
          required={required.includes("main_image")}
          onChange={(e) =>{
            console.log(e.target.files);
           
            setControl("main_image", e.target.files[0])
            
          }
          }

          type="file"
          inputProps={{ accept: 'image/*' }}
       
          error={Boolean(invalid.main_image)}
          helperText={invalid.main_image}
        />
        {/* <Videocomponent
        placeholder={globalLang.video[lang]}
        onChange={(e)=>setControl("video",e.target.value)}/> */}

        
        <DialogCheckboxField
        label={globalLang.isPublic[lang]}
        placeholder={globalLang.isPublic[lang]}
        value={controls.isPublic}
      onClick={(e) =>setControl("isPublic", !controls.isPublic)}
        />
        <TextareaField
          label={globalLang.notes[lang]}
          placeholder={globalLang.notes[lang]}
          multiline
          variant="standard"
          required={required.includes("notes")}
          value={controls.notes}
          onChange={(e) => setControl("notes", e.target.value)}
          error={Boolean(invalid.notes)}
          helperText={invalid.notes}
          sx={{ backgroundColor: "primary","textarea":{
            height:"134px !important"
          } }}
        />
        {/* <Check/> */}
          
      </Form>
      {unitPostResponse.successAlert}
      {unitPostResponse.failAlert}
    </Wrapper>
  );
};

export default AddUnits;

const bathrooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];

const rooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];

const saleTypes = [
  {
    name: "بيع",
    value: "sell",
  },
  {
    name: "إيجار",
    value: "rent",
  },
];
