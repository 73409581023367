import { useEffect } from "react";
import audiovoise from "../assets/notificationsfx.wav"
import React from "react"
import { useSelector } from "react-redux";
const PlaySoundEffect = ({show}) => {
  const unread = useSelector((state) => state.unread.value);
 
  useEffect(()=>{


  },[show])
  return  <audio controls autoPlay={show?true:false} style={{display:"none"}}>
  <source src={audiovoise}type="audio/wav"/>
</audio>
  
};

export default PlaySoundEffect;
