import React, { useEffect, useState, Fragment } from "react";
import Sidebar from "./components/Sidebar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
// import runtime from 'serviceworker-webpack-plugin/lib/runtime';

import notificationSfx from "../src/assets/notificationsfx.wav";
import { Box, Snackbar, useMediaQuery } from "@mui/material";
import Topbar from "./components/Topbar";
import useToggle from "./hooks/useToggle";
import pages from "./data/pages";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "./hooks/useRequest";
import {
  COUNTRYCODE,
  FCMDRIVERS,
  NOTIFICATIONS,
  PERMISSIONS,
  USER_INFO,
  fcmdevices,
} from "./data/APIs";
import Login from "./pages/Login";
import PrivateRoute from "./features/permissions/components/PrivateRoute";
import filter from "./utils/ClearNull";
import Compress from "react-image-file-resizer";
import { CacheProvider } from "@emotion/react";
import { cacheLtr, cacheRtl } from "./Theme";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
import Registeration from "./pages/Register";
import Keepintouch from "./pages/Keepintouch";
import {
  firebaseApp,
  gettoken,
  messaging,
  onMessageListener,
  requestPermission,
} from "./firebase";
import playSoundEffect from "./utils/playSoundEffect";
import audiovoise from "./assets/notificationsfx.wav";
import PlaySoundEffect from "./utils/playSoundEffect";
const sidebarWidth = 240;

Date.prototype.toCorrectISOString = function () {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds()) +
    "." +
    (this.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
    "Z"
  );
};

const Layout = ({
  children,
  permissions,
  notifications,
  notification,
  onRemoveNotifications = () => {},
  onClear = () => {},
  onChangeAvatar,
  isAvatarPending = false,
  isNotificationPending = false,
  loading,
  isPending = false,
  userInfo = {},
}) => {
  //----hooks----
  const sm = useMediaQuery("(max-width:712px)");
  const userInfotoprofile = useSelector((state) => state.userInfo.value);
  const [openSidebar, toggleOpenSidebar] = useToggle(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  //----styles----
  let gridStyle = {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridTemplateColumns: sm ? "1fr" : `${sidebarWidth}px 1fr`,
    height: "100vh",

    "& > :nth-child(1)": {
      gridColumn: sm ? "1 / -1" : "2 / 2",
    },

    "& > :nth-child(3)": {
      gridColumn: sm ? "1 / -1" : "2 / 2",
      gridRow: sm ? "2 / 3" : "2 / 3",
      "::-webkit-scrollbar": {
        width: '0.5em'
      },
       
      '::-webkit-scrollbar-track' :{
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
      },
       
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey',
        borderRadius:"12px"
      }
    },
  };
  const [logoutDives, setlogoutdives] = useRequest({
    path: FCMDRIVERS,
    method: "delete",
    // successMessage:globa
  });
  const logoutdives = () => {
    logoutDives({
      body: {
        registration_id: JSON.parse(localStorage.getItem("divceToken"))?.token,
      },
      onSuccess: (res) => {
        localStorage.removeItem("divceToken")
      },
    });
  };
  return (
    <Box sx={gridStyle}>
      <Topbar
        showBurger={sm}
        onBurgerClick={() => toggleOpenSidebar()}
        notifications={notifications && notifications}
        isNotificationPending={false}
        onNotificationsOpen={onRemoveNotifications}
        onClear={onClear}
        loading={loading}
        notification={notification}
      />
      <Sidebar
        permanent={!sm}
        open={openSidebar}
        onOpen={() => toggleOpenSidebar(true)}
        onClose={() => toggleOpenSidebar(false)}
        onLogout={() => {
          dispatch({ type: "userInfo/logout" });
          navigate("/signin");
          logoutdives();
        }}
        permissions={permissions}
        onChangeAvatar={onChangeAvatar}
        name={userInfo.name}
        role={userInfo.role}
        organization={userInfo.organization}
        avatar={userInfotoprofile.image}
        width={sidebarWidth}
        isNamePending={isPending}
        isRolePending={isPending}
        isOrganizationPending={isPending}
        isTabsPending={isPending}
        isAvatarPending={isPending || isAvatarPending}
      />
      <Box sx={{ overflowY: "auto" }}>{children}</Box>
    </Box>
  );
};

const App = () => {
  //----store----
  const token = useSelector((state) => state.userInfo.value.token);
  const lang = useSelector((state) => state.lang.value.lang);
  const unread = useSelector((state) => state.unread.value);
  // notifications: NotificationSlice,
  // const notification = useSelector((state) => state.notifications);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [countrys, setCountry] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTokenFound, setTokenFound] = useState({
    status: false,
    token: "",
  });
  const [open, setopen] = useState(notification ? true : false);
  //----states----

  const [notifications, setNotifications] = useState([]);
  const [notificationsGetRequest, notificationsGetResponse] = useRequest({
    path: NOTIFICATIONS,
    method: "get",
  });
  const userInfo = useSelector((state) => state.userInfo.value);

  const [userInfoGetRequest, userInfoGetResponse] = useRequest({
    path: USER_INFO,
    method: "get",
  });
  const [allPermissionsGetRequest, allPermissionsGetResponse] = useRequest({
    path: PERMISSIONS,
    method: "get",
  });
  const [allcountrycodeRequest, allcountrycodeResponse] = useRequest({
    path: COUNTRYCODE,
    method: "get",
  });
  const [sendtokenRequest, sendtokenResponse] = useRequest({
    path: FCMDRIVERS,
    method: "post",
    // successMessage:globalLang.logindone[lang],
  });
  const notifictionsStore = useSelector((state) => state.notifications.value);

  // ----request hooks----
  const [missedNotificationsGetRequest, missedNotificationsGetResponse] =
    useRequest({
      path: NOTIFICATIONS,
      method: "get",
    });
  const [loading, setloading] = useState(true);
  const urlParams = new URLSearchParams();
  const getNotifications = async () => {
    await missedNotificationsGetRequest({
      // params: urlParams,
      onSuccess: (res) => {
        setloading(false);
        dispatch({
          type: "notifications/set",
          payload: res.data,
        });
      },
    });
  };
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch((error) => {
        console.log("Service worker registration failed, error:", error);
      });
  }
 
// if ('serviceWorker' in navigator) {
//   const registration = runtime.register();
// }
  //----effects----
  useEffect(() => {
    if (!token) return;
    userInfoGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "userInfo/setUserInfo", payload: res.data });
      },
    });
    let ws = new WebSocket(`wss://crmsystem.cyparta.com/ws/?${token}`);

    // const ws = new WebSocket(`wss://crmsystem.cyparta.com/ws/?${token}`);
    const handleNotifications = (data) => {
      setNotifications((old) => [data?.data, ...old]);
    };

    const handleImportProgress = (data) => {
      dispatch({
        type: "exportClients/setProgress",
        payload: data?.data?.message,
      });
    };
    const handleExportProgress = (data) => {
      dispatch({
        type: "ImportClients/setProgress",
        payload: data?.data?.message,
      });
    };
    ws.onmessage = (event) => {
      console.log(event.data);
      const data = JSON.parse(event.data);
      missedNotificationsGetRequest({
        params: urlParams,
        onSuccess: (res) => {
          setloading(false);
          dispatch({
            type: "notifications/set",
            payload: res.data?.notifications,
          });
        },
      });

      switch (data.action) {
        case "notifications":
          handleNotifications(data);
          break;
        case "import":
          handleImportProgress(data);
          break;
        case "export":
          handleExportProgress(data);
          break;
      }
    };
    allPermissionsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "allPermissions/set", payload: res.data.permissions });
      },
    });
    allcountrycodeRequest({
      onSuccess: (res) => {
        dispatch({
          type: "countryslice/set",
          payload: res.data.map((item) => {
            return {
              name: item.name.common,
              short: item.cca2,
              flag: item.flags.png,
            };
          }),
        });
      },
    });
  }, [token]);
 
  const sendtoken = (tokenofdevice) => {
    console.log(
      tokenofdevice,
      JSON.parse(localStorage.getItem("userInfo"))?.token,JSON.parse(localStorage.getItem("divceToken"))?.token
    );
    if (JSON.parse(localStorage.getItem("userInfo"))?.token) {
      sendtokenRequest({
        body: {
          registration_id: JSON.parse(localStorage.getItem("divceToken"))?.token,
          type: "web",
        },
        onSuccess: (res) => {
          // console.log()
        },
        customToken: JSON.parse(localStorage.getItem("userInfo")).token,
      });
    }
  };
  useEffect( () => {
    async function fetchData() {
      if (token && !localStorage.getItem("divceToken")) {   
              await gettoken().then((res)=>{
                console.log(res);
                sendtoken(JSON.parse(localStorage.getItem("divceToken"))?.token);
               })
        }
       
  
    }
    fetchData();
    
  }, [token]);
  useEffect(() => {
    getNotifications();
    // let ws=new WebSocket(`wss://crmsystem.cyparta.com/ws/?${token}`);
    console.log(notifications);
    return () => {
      // ws.close;
    };
  }, []);

  // const handleSeenNotifications = () => {
  //   missedNotificationsGetRequest({
  //     customMethod: "delete",
  //   });
  // };

  //===Start===== change avatar login ========
  const resize = (file) => {
    return new Promise((resolve) => {
      Compress.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => resolve(uri),
        "file"
      );
    });
  };

  const [changeAvatarPatchRequest, changeAvatarPatchResponse] = useRequest({
    path: USER_INFO,
    method: "patch",
    // successMessage:global
  });

  const changeAavatar = async (file) => {
    const image = await resize(file);

    const requestBody = filter({
      obj: {
        image: image,
      },
      output: "formData",
    });

    changeAvatarPatchRequest({
      body: requestBody,
      id: userInfo.id,
      onSuccess: (res) => {
        dispatch({ type: "userInfo/setUserInfo", payload: res.data });
      },
    });
  };
  const [show, setShow] = useState(false);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      console.log(payload);
      if (payload) {
        dispatch({
          type: "notifications/addItem",
          payload: {
            title: payload.notification.title,
            body: payload.notification.body,
          },
        });
        dispatch({
          type: "unread/addItem",
          payload:
            Math.minimumZero(notifictionsStore?.results?.length) +
            Math.minimumZero(0),
        });
      }
    })
    .catch((err) => console.log("failed: ", err));

  //====End==== change avatar login ========

  useEffect(() => {
    document.dir = lang === "en" ? "ltr" : "rtl";
  }, [lang]);
  useEffect(() => {
    requestPermission();
  }, []);
  useEffect(() => {
    // requestPermission()
  }, [unread]);
  return (
    <>
      <CacheProvider value={lang === "en" ? cacheLtr : cacheRtl}>
        {/* {notifictionsStore?.results?.length>0?<PlaySoundEffect show={true}/>:<PlaySoundEffect show={false}/>} */}

        <PlaySoundEffect show={unread > 0 && true} />
        {token && (
          <>
            {" "}
            <Layout
              permissions={userInfo?.user_permissions?.map(
                (perm) => perm.codename
              )}
              notifications={notifictionsStore && notifictionsStore}
              notification={notification && notification}
              isNotificationPending={missedNotificationsGetResponse.isPending}
              // onRemoveNotifications={handleSeenNotifications}
              loading={loading}
              userInfo={{
                name: `${userInfo.first_name} ${userInfo.last_name}`,
                role: userInfo.job_title,
                organization: userInfo?.organization?.name,
                image: userInfo.image,
              }}
              isPending={userInfoGetResponse.isPending}
              onChangeAvatar={changeAavatar}
              isAvatarPending={changeAvatarPatchResponse.isPending}
              // onClear={() => setNotifications([])}
            >
              <Routes>
                {pages.map((page, pageIndex) => {
                  if (!Boolean(page)) return;
                  switch (page.hasOwnProperty("subtabs")) {
                    case false:
                      return (
                        <Route
                          path={page.path}
                          element={
                            <PrivateRoute permissions={page.permitted}>
                              {page.element}
                            </PrivateRoute>
                          }
                          key={`route page ${pageIndex}`}
                        />
                      );
                    case true:
                      return page.subtabs.map((subtab, subtabIndex) => (
                        <Route
                          path={page.path + subtab.path}
                          element={
                            <PrivateRoute permissions={subtab.permitted}>
                              {subtab.element}
                            </PrivateRoute>
                          }
                          key={`route subpage ${subtabIndex}`}
                        />
                      ));
                    default:
                      return;
                  }
                })}
                <Route path="/keep" element={<Keepintouch />} />
                <Route path="/*" element={<Navigate replace to="/" />} />
                {/* <Route path="/firebase-messaging-sw.js" element={</>}/> */}
                {/* <Route path="/" element={<Navigate replace to="/" />} /> */}
                {/* <Route path="/404" element={<Notfound />} /> */}
              </Routes>
            </Layout>
            {/* <Routes>
             
            </Routes> */}
          </>
        )}
        {!token && (
          <Routes>
            <Route path="/signin" element={<Login />} />
            <Route path="/register" element={<Registeration />} />
            <Route path="/" element={<Registeration />} />
            <Route path="/keep" element={<Keepintouch />} />
            <Route path="/*" element={<Login />} />

            {/* <Route path="/*" element={<Navigate replace to="/" />} /> */}
          </Routes>
        )}
        <div>
          {/* 
        <Snackbar
           open={open}
          autoHideDuration={6000}
           onClose={()=>setopen(false)}
           message={notification?.title+"\n"+notification.body+"text need to send"}
  // action={action}
      /> */}

          {/* <div >
          <h3>{notification?.title}</h3>
          <p>{notification?.body}</p>
        </div>  */}
        </div>
        {/* {missedNotificationsGetResponse.successAlert}
        {missedNotificationsGetResponse.failAlert} */}
        {/* {allPermissionsGetResponse.successAlert}
        {allPermissionsGetResponse.failAlert} */}
        {/* {sendtokenResponse.failAlert}
        {sendtokenResponse.successAlert} */}
      </CacheProvider>
    </>
  );
};

export default App;

const dummyPermissions = [
  "add_aqarpost",
  "view_aqarpost",
  "delete_aqarpost",
  "change_aqarpost",
  "aqarstatistics",
  "add_aqarclient",
  "view_aqarclient",
  "delete_aqarclient",
  "add_aqarevent",
  "view_aqarevent",
  "aqarexport_file",
  "aqarexport_file",
  "view_aqarimportexportfiels",
  "add_aqarproject",
  "view_aqarproject",
  "add_aqaremployee",
  "view_aqaremployee",
  "add_aqarjob",
  "view_aqarjob",
  "add_aqaremployee",
  "view_aqaremployee",
  "add_aqaremployee",
  "view_aqaremployee",
  "add_aqaremployee",
  "view_aqaremployee",
];
