import { createSlice } from "@reduxjs/toolkit";

export const UnreadSlice = createSlice({
  name: "unread",
  initialState: {
    value: 0,
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    reset: (state) => {
      state.value = 0;
    },
    deleteItem: (state, action) => {
      state.value =0
    },
    addItem: (state, action) => {
      state.value = state.value++;
    },
   
  },
});

export default UnreadSlice.reducer;