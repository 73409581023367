import { createSlice } from "@reduxjs/toolkit";

export const AddclientimageSlice = createSlice({
  name: "imageunit",
  initialState: {
    files: [],
  },
  reducers: {
    set: (state, action) => {
      state.files = action.payload;
    },
    reset: (state) => {
      state.files = [];
    },

    addItem: (state, action) => {
      state.files = [action.payload, ...state.files];
    },
    deleteItem: (state, action) => {
      state.files = state.files.filter(
        (item) => item.id !== action.payload.id
      );
    },
  },
});

export default AddclientimageSlice.reducer;
