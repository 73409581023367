import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyAmHXQEvslz5HRfKLtj39Oi3CEJfXo3YRo",
    authDomain: "crm-f03ae.firebaseapp.com",
    projectId: "crm-f03ae",
    storageBucket: "crm-f03ae.appspot.com",
    messagingSenderId: "43857829456",
    appId: "1:43857829456:web:d740c65fcc86a00d6e7b55",
    measurementId: "G-YTPLM4Y9XN"
  };
export const firebaseApp = initializeApp(firebaseConfig);
 const messaging = getMessaging(firebaseApp);

export const gettoken = async() => {
   return await getToken(messaging, {vapidKey: 'BFISrZjyjAA8hWQlV9oNsj8DoOLpgDE-AEbEr5tLD7ewonMHmLfeVd5dtJpzm2KSHsFzaYCZO8mV5Na7EaAD-b8'}).then((currentToken) => {
    
      if (currentToken) {
        // setTokenFound({status:true,token:currentToken});
        localStorage.setItem("divceToken",JSON.stringify({status:true,token:currentToken}))
        return {status:true,token:currentToken}
        // return currentToken        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // setTokenFound({status:false,token:""});
        
        return {status:false,token:""}
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      // setTokenFound({status:false,token:""});
      // raise err
      console.log('An error occurred while retrieving token. ', err);
      return {status:false,token:""}
      // return err
      // catch error while creating client token
    })}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
    console.log(permission)
  })}

